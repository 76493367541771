import verifKtp from 'assets/images/verif-ktp.png'
import { P } from 'components'
import CustomButton from 'components/Buttons/CustomButton'
import FullModal from 'components/Modal/FullModal'
import { useEffect, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { Card, Modal } from 'reactstrap'

export default function Verification({ profile }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onToVerif = () => {
    let url = `${process.env?.REACT_APP_VERIFICATION_URL}/langsungaja?verificationCode=${profile?.data?.ownerData?.verificationCode}&r=business`
    window.location.href = url
  }

  if (profile?.data?.ownerData?.status === 'UNVERIFIED') {
    return (
      <>
        <Card className="mb-3 p-3" lg="4" sm="12" style={{ border: 'none' }}>
          <P bold>Selamat datang di PasarMIKRO!</P>
          <P size="regular">
            Ayo verifikasi akun Anda agar bisa menggunakan semua fitur seperti
            transaksi dan lainnya!
          </P>
          <CustomButton
            onClick={onToVerif}
            className="mt-3"
            outline
            color="primary"
            style={{
              borderColor: '#D0D5DD',
            }}
          >
            <P>Verifikasi Sekarang</P>
          </CustomButton>
        </Card>
        {/* {renderModal()} */}
      </>
    )
  }

  function contentVerification() {
    return (
      <div>
        <div
          className="p-3 d-flex gap-4"
          style={{ borderBottom: '1px solid #D0D5DD' }}
        >
          <ArrowLeft color="#667085" />
          <P>Verifikasi akun</P>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          <img src={verifKtp} style={{ width: 250, height: 250 }} />
        </div>
      </div>
    )
  }

  function renderModal() {
    return (
      <>
        {isMobile ? (
          <FullModal isOpen={true}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
              }}
            >
              {contentVerification()}
            </div>
          </FullModal>
        ) : (
          <Modal isOpen={true} centered>
            {contentVerification()}
          </Modal>
        )}
      </>
    )
  }
}
