import moment from 'moment-timezone'
import { useCallback, useRef } from 'react'

export const filterLatLong = (data) => {
  return data
    .map((location) => {
      if (location.plottingPoint && location.plottingPoint.coordinates) {
        return {
          lat: location.plottingPoint.coordinates[1],
          lng: location.plottingPoint.coordinates[0],
        }
      } else {
        console.error('Invalid location data:', location)
        return null
      }
    })
    .filter(Boolean)
}

export function getFileExtension(url) {
  const dotIndex = url.lastIndexOf('.')
  const queryIndex = url.indexOf('?', dotIndex)

  if (dotIndex === -1) {
    return null
  }
  if (queryIndex === -1) {
    return url.substring(dotIndex)
  } else {
    return url.substring(dotIndex, queryIndex)
  }
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const PMBFormatDate = (dateString) =>
  dateString ? moment(dateString).format('Do MMM YYYY, HH:mm') : '-'

export function getActionDescription(log) {
  let data = {
    vessel_name:
      log?.parsedExtraData?.resp?.data?.vessel?.shipName ||
      log?.parsedExtraData?.resp?.vesselName,
    fishermen: [],
    fisherman_name: log?.user?.name,
    fishery_name: log?.parsedExtraData?.resp?.name,
    port_name: log?.parsedExtraData?.resp?.port,
    company_name: log?.parsedExtraData?.resp?.name,
    gear_name: log?.parsedExtraData?.resp?.gearData?.name,
    name: log?.parsedExtraData?.before?.name,
    status: log?.parsedExtraData?.resp?.status,
    receiver_name: log?.parsedExtraData?.resp?.assignedUserName,
    assignment_note: log?.parsedExtraData?.resp?.assignNotes,
    resolved_note: log?.parsedExtraData?.resp?.resolveNotes,
    unresolved_note: log?.parsedExtraData?.resp?.unresolveNotes,
  }
  switch (log?.action) {
    case 'ap2himastervessels_update':
      return `Updated a vessel named '${data.vessel_name}'`

    case 'ap2hi_mastervessels_create':
      return `Created a vessel named '${data.vessel_name}'`

    case 'ap2himastervessels_delete':
      return `Removed a vessel named '${data.vessel_name}'`

    case 'ap2hi_mastervessels_setusers':
      return `Linked a vessel named to fishermen`

    // case 'ap2hi_mastervessels_setusers':
    //   return data.fishermen.length > 1
    //     ? `Linked a vessel named '${data.vessel_name}' to fishermen`
    //     : `Linked a vessel named '${data.vessel_name}' to a fisherman`

    case 'ap2hi_mastervessels_unsetusers':
      return `Unlinked a vessel named '${data.vessel_name}' from '${data.fisherman_name}'`

    case 'ap2hi_fisheries_upsert':
      return `Added a fishery named '${data.fishery_name}'`

    case 'ap2hi_ports_upsert':
      return `Added a port named '${data.port_name}'`

    case 'ap2hi_companies_upsert':
      return `Added a company named '${data.company_name}'`

    case 'ap2hi_gears_upsert':
      return `Added a fishing gear named '${data.gear_name}'`

    case 'ap2hi_fisheries_update':
      return `Updated a fishery named '${data.fishery_name}'`

    case 'ap2hi_ports_update':
      return `Updated a port named '${data.port_name}'`

    case 'ap2hi_companies_update':
      return `Updated a company named '${data.company_name}'`

    case 'ap2hi_gears_update':
      return `Updated a fishing gear named '${data.gear_name}'`

    case 'ap2hi_fisheries_delete':
      return `Removed a fishery named '${data.fishery_name}'`

    case 'ap2hi_ports_delete':
      return `Removed a port named '${data.port_name}'`

    case 'ap2hi_companies_delete':
      return `Removed a company named '${data.company_name}'`

    case 'ap2hi_gears_delete':
      return `Removed a fishing gear named '${data.gear_name}'`

    case 'ap2hi_whistleblowings_create':
      return `Submitted a whistleblowing issue titled '${data.name}'`

    case 'ap2hi_whistleblowings_update':
      if (data.status === 'ASSIGNED') {
        return `Assigned whistleblowing issue titled '${data.name}' to ${data.receiver_name} with note: '${data.assignment_note}'`
      } else if (data.status === 'RESOLVED') {
        return `Marked whistleblowing issue titled '${data.name}' as resolved with note: '${data.resolved_note}'`
      } else if (data.status === 'UNRESOLVED') {
        return `Reassign whistleblowing issue titled '${data.name}' to ${data.receiver_name} with note: '${data.unresolved_note}'`
      }
      return `action ${log?.action}`

    case 'traceability_ap2hi_save':
      return `Trip has been created`
    case 'ap2hi_updateusercatchesfishes':
      return 'Update Berat tangkapan on Informasi hasil tangkapan'
    case 'ap2hi_approveusercatches':
      return 'Changed trip status to APPROVE'
    default:
      return `action ${log?.action}`
  }
}

export const parseExtraData = (extraData) => {
  try {
    if (!extraData) {
      return null
    }

    const cleanedExtraData = extraData
      .replace(/\\\"/g, '"')
      .replace(/\\\\/g, '\\')
      .replace(/^"|"$/g, '')

    const parsedData = JSON.parse(cleanedExtraData)

    return parsedData
  } catch (error) {
    return null
  }
}

export const useDebounce = (callback, delay = 600) => {
  const debounceTimer = useRef(null)

  const debouncedFunction = useCallback(
    (...args) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }
      debounceTimer.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay],
  )

  return debouncedFunction
}

const addLabelToItem = (item) => {
  const { shortDesc, amountIn, amount, extraData, trxId } = item
  let label = ''

  switch (shortDesc) {
    case '0000':
      label = 'Initial Deposit'
      break
    case '0020':
    case '0021':
    case '0022':
    case '0023':
    case '8020':
    case '0024':
    case '8020':
    case '9020':
    case 'topupDepositsByIdentity':
    case 'setUserDepositsDirectly':
    case 'sendLenderDepositsToUser':
    case 'setLenderDepositsDirectly':
      label = 'Penambahan Uang Belanja'
      break
    case '9000':
      label = amountIn ? 'Tambah Uang Usaha' : 'Penarikan Otomatis'
      break
    case '8043':
      label = 'Penarikan Instan'
      break
    case '8040':
      label = amount === 7500 ? 'Biaya Penarikan Siang' : 'Biaya Bayar Instan'
      break
    case '8100':
      label = extraData?.instantDisbursement
        ? 'Pembayaran Instan'
        : 'Penarikan Instan'
      break
    case '8101':
      label = 'Pembatalan Pembayaran/Penarikan Instan'
      break
    case '1050':
      label = 'Pembayaran di Muka'
      break
    case '1051':
      label = 'Biaya Jatuh Tempo'
      break
    case '1052':
      label = 'Biaya Transaksi'
      break
    case '1053':
      label = 'Pengembalian Pembayaran di Muka'
      break
    case '1054':
      label = 'Pengembalian Biaya Jatuh Tempo'
      break
    case '1055':
      label = 'Pengembalian Biaya Transaksi'
      break
    case '1056':
    case '1058':
      label = 'PPN'
      break
    case '1057':
    case '1059':
      label = 'Penyesuaian Harga'
      break
    case '8030':
      label = 'Pembayaran utang'
      break
    case '8041':
      label = 'Bayar Instan'
      break
    case '8042':
      label = 'Pengembalian Bayar Instan'
      break
    case 'withdrawUserDepositsToBank':
    case '9001':
      label = 'Penarikan Otomatis'
      break
    case '1030':
      label = 'Pembayaran Tempo'
      break
    case '1031':
      label = 'Biaya Perpanjangan'
      break
    case '1032':
      label = 'Denda Keterlambatan'
      break
    case '9031':
    case '1041':
    case '9044':
      label = 'Penjualan'
      break
    case '1040':
      label = 'Pembayaran Tunai'
      break
    case '1042':
    case '9043':
      label = 'Dibatalkan'
      break
    case '9045':
      label = 'Pengembalian Dana'
      break
    case '1056':
    case '1058':
      label = 'PPN'
      break
    case '1057':
      label = 'Penyesuaian Harga'
      break
    case '1059':
      label = 'Penyesuaian harga'
      break
    default:
      label = 'Unknown'
      break
  }

  return {
    ...item,
    label,
  }
}

export const labelCode = (item) => {
  const { shortDesc, amountIn, amount, extraData, trxId } = item

  switch (shortDesc) {
    case '0000':
      return 'Initial Deposit'
    case '0020':
    case '0021':
    case '0022':
    case '0023':
    case '8020':
    case '0024':
    case '9020':
    case 'topupDepositsByIdentity':
    case 'setUserDepositsDirectly':
    case 'sendLenderDepositsToUser':
    case 'setLenderDepositsDirectly':
      return 'Penambahan Uang Belanja'
    case '9000':
      return amountIn ? 'Tambah Uang Usaha' : 'Penarikan Otomatis'
    case '8043':
      return 'Penarikan Instan'

    case '8040':
      return amount === 7500 ? 'Biaya Penarikan Siang' : 'Biaya Bayar Instan'

    case '8100':
      return extraData?.instantDisbursement
        ? 'Pembayaran Instan'
        : 'Penarikan Instan'

    case '8101':
      return 'Pembatalan Pembayaran/Penarikan Instan'

    case '1050':
      return 'Pembayaran di Muka'

    case '1051':
      return 'Biaya Jatuh Tempo'

    case '1052':
      return 'Biaya Transaksi'

    case '1053':
      return 'Pengembalian Pembayaran di Muka'

    case '1054':
      return 'Pengembalian Biaya Jatuh Tempo'

    case '1055':
      return 'Pengembalian Biaya Transaksi'

    case '1056':
    case '1058':
      return 'PPN'

    case '1057':
    case '1059':
      return 'Penyesuaian Harga'

    case '8030':
      return 'Pembayaran utang'

    case '8041':
      return 'Bayar Instan'

    case '8042':
      return 'Pengembalian Bayar Instan'

    case 'withdrawUserDepositsToBank':
    case '9001':
      return 'Penarikan Otomatis'

    case '1030':
      return 'Pembayaran Tempo'

    case '1031':
      return 'Biaya Perpanjangan'

    case '1032':
      return 'Denda Keterlambatan'

    case '9031':
    case '1041':
    case '9044':
      return 'Penjualan'

    case '1040':
      return 'Pembayaran Tunai'

    case '1042':
    case '9043':
      return 'Dibatalkan'

    case '9045':
      return 'Pengembalian Dana'

    case '1056':
    case '1058':
      return 'PPN'

    case '1057':
      return 'Penyesuaian Harga'

    case '1059':
      return 'Penyesuaian harga'

    default:
      return 'Unknown'
  }
}

export const groupTransactionsByDate = (transactions) => {
  const groupedData = transactions.reduce((acc, item) => {
    const date = moment(item.createdAt).format('dddd, D MMMM YYYY')
    acc[date] = acc[date] || []
    acc[date].push(addLabelToItem(item))
    return acc
  }, {})

  return Object.entries(groupedData).map(([date, list]) => ({
    createdAt: date,
    list,
  }))
}

export const getActionWording = (action, data) => {
  let wording = ''
  if (action === 'TRANSFORM_OUT' || action === 'TRANSFORM_IN') {
    const createMethodLabel =
      data?.warehousetransferitem?.[0]?.inventory?.item?.createMethodLabel
    wording = createMethodLabel
      ? `proses - ${createMethodLabel}`
      : 'proses - ubah bentuk'
  } else {
    switch (action) {
      case 'TRANSFER':
        wording = 'kirim'
        break
      case 'CREATE':
        wording = 'tambah'
        break
      case 'TRADE':
        wording = 'transaksi'
        break
      case 'DELETED':
        wording = 'hapus - buang'
        break
      case 'SELL':
        wording = 'hapus - jual'
        break
      case 'SPLIT':
        wording = 'pisah'
        break
      case 'MERGE':
        wording = 'gabung'
        break
      default:
        wording = '-'
    }
  }

  return wording.toLowerCase()
}

export const validateEmail = (email) => {
  if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))
    return true
  return false
}

export const checkInputType = (input) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^(?:\+62|62|0)[2-9][0-9]{7,12}$/ // Format Indonesia

  if (emailRegex.test(input)) {
    return true
  } else if (phoneRegex.test(input)) {
    return false
  } else {
    return false
  }
}
