import { useEffect, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import PMBPagination from 'components/PMBPagination'
import { TableComponent as DataTable } from 'components/TableComponent'
import Thumbnail from 'components/Thumnail'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import ExpandableComponent2 from './ExpandedComponent2'
import { getLearningContentList, getUserWatch } from './learningReportAction'
import { setFetchOptionUser } from './learningReportSlice'

const ExpandableComponent = ({ data: row }) => {
  const dispatch = useDispatch()
  const { tagUsers } = useSelector((s) => s.learningReport)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const fetchData = async (options) => {
    setLoading(true)

    try {
      const { payload: response1 } = await dispatch(
        getLearningContentList(tagUsers[row?.userId]),
      )

      const { payload: response2 } = await dispatch(getUserWatch(row?.userId))

      let data1 = response1?.items
      const data2 = response2?.items

      const combinedData = data1.map((item) => {
        const updatedContents = item?.tutorialcontent.map((content) => {
          const matchedData2 = data2.find(
            (d) => d.tutorialContentId === content.id,
          )
          if (matchedData2) {
            return {
              ...content,
              ...matchedData2.tutorialcontent,
              watchedDuration: matchedData2.duration,
              finishedAt: matchedData2.finishedAt,
            }
          }
          return content
        })

        const totalWatchedDuration = updatedContents.reduce(
          (total, content) => total + (content.watchedDuration || 0),
          0,
        )
        const totalContentDuration = updatedContents.reduce(
          (total, content) => total + (content.contentDuration || 0),
          0,
        )

        const progress =
          totalContentDuration <= 0
            ? 100
            : Math.min((totalWatchedDuration / totalContentDuration) * 100, 100)

        return {
          ...item,
          tutorialcontent: updatedContents,
          progress,
        }
      })

      setData(combinedData)

      setTotalRows(response1.totalItems)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      name: <span>Judul</span>,
      selector: (row) => row.title,
      grow: 2,
      cell: (row) => <span>{row?.title}</span>,
    },
    {
      name: <span>Deskripsi</span>,
      selector: (row) => row.description,
      grow: 4,
      cell: (row) => <span>{row?.description}</span>,
    },
    {
      name: <span>Thumbnail Photo</span>,
      selector: (row) => row?.thumbnail,
      grow: 2,
      center: true,
      cell: (row) => (
        <div className="my-2">
          {' '}
          <Thumbnail
            className="lazy"
            src={row?.thumbnail}
            height={96}
            width={96}
          />
        </div>
      ),
    },
    {
      name: <span>Kemajuan</span>,
      grow: 2,
      right: true,
      cell: (row) => (
        <div>
          {row?.finishedAt ? (
            <span>100%</span>
          ) : (
            <>
              <FormattedNumber
                value={row?.progress ?? 0}
                useGrouping="always"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
              %{' '}
            </>
          )}
        </div>
      ),
    },
    {
      name: <span>Status</span>,
      grow: 2,
      cell: (row) => {
        if (row?.progress === 100) {
          return (
            <span className="badge rounded-pill bg-success-50 text-success">
              selesai
            </span>
          )
        } else {
          return (
            <span className="badge rounded-pill bg-warning-50 text-warning-700">
              belum selesai
            </span>
          )
        }
      },
    },
  ]

  const ExpandedComponent = ({ data }) => {
    return (
      <>
        <ExpandableComponent2 data={data} />
      </>
    )
  }

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '3px',
        paddingRight: '3px',
        wordBreak: 'break-word',
        color: '#667085',
      },
    },
    cells: {
      style: {
        paddingLeft: '3px',
        paddingRight: '3px',
      },
    },
    expanderCell: {
      style: {
        minWidth: '28px',
        flex: '0 0 28px',
      },
    },
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Container className="border-0">
        <DataTable
          customStyles={customStyles}
          className="border-0"
          columns={columns}
          data={data}
          progressPending={loading}
          responsive
          persistTableHead
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          pagination
          paginationServer
          paginationComponent={(props) => (
            <PMBPagination
              rowCount={totalRows}
              currentPage={tagUsers[row?.userId]?.page ?? 0}
              rowsPerPage={tagUsers[row?.userId]?.size ?? 10}
              setCurrentPage={(e) => {
                dispatch(
                  setFetchOptionUser({
                    options: { page: e },
                    userId: row?.userId,
                  }),
                )
              }}
              setRowsPerPage={(e) => {
                dispatch(
                  setFetchOptionUser({
                    options: { size: e },
                    userId: row?.userId,
                  }),
                )
              }}
              props={props}
            />
          )}
        />
      </Container>
    </>
  )
}

export default ExpandableComponent
