import { createBrowserRouter, json } from 'react-router-dom'
import App from '../App'

// Public Router

import OrderInfoView from 'features/public-views/order'
import SuccessVerifyAccount from '../public-views/success-verify-account'
import TncView from '../public-views/tnc'

// Setting Pages
import RequestTransaction from 'features/private-views/request-transaction'

import PageError from 'components/PageError'
import DetailLearning from 'features/private-views/learning/DetailLearning'
import FinishPage from 'features/private-views/learning/FinishPage'
import ConfirmPage from 'features/public-views/order/ConfirmPage'
import SuccessPage from 'features/public-views/order/SuccessPage'
import LandPlotView from 'features/public-views/plot'
import Spatial from 'features/public-views/spatial'
import { URL_FINISH_KURSUS, URL_KURSUS_DETAIL } from 'utils/pathUrl'
import { authRouter } from './authRouter'
import { errorRouter } from './errorRouter'
import { privateRouter } from './privateRouter'
import { publicRouter } from './publicRouter'
import DefaultLayout from 'features/layout/DefaultLayout'
import BlankLayout from 'features/layout/BlankLayout'
import Page404 from 'components/Page404'

export const routes = createBrowserRouter([
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <App />,
        children: [...privateRouter],
        errorElement: <PageError />,
      },
    ],
  },

  {
    element: <BlankLayout />,
    children: [
      {
        path: '/request-transaction',
        element: <RequestTransaction />,
      },
      {
        path: '/transaction',
        element: <ConfirmPage />,
      },
      {
        path: '/success-transaction',
        element: <SuccessPage />,
      },
      {
        path: '/syarat-dan-ketentuan',
        element: <TncView />,
      },
      {
        path: '/sukses-verifikasi',
        element: <SuccessVerifyAccount />,
      },
      {
        path: '/public/order',
        element: <OrderInfoView />,
      },
      {
        path: '/plot/view/:code',
        element: <LandPlotView />,
      },
      {
        path: '/plot/view/pin/:lat/:long',
        element: <LandPlotView />,
      },
      {
        path: '/spatial',
        element: <Spatial />,
      },
      {
        path: URL_KURSUS_DETAIL,
        element: <DetailLearning />,
      },
      {
        path: URL_FINISH_KURSUS,
        element: <FinishPage />,
      },
      ...publicRouter,
      ...errorRouter,
      ...authRouter,
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
])
