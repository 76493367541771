import { Trash } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'

import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import LoadingAnimation from 'components/LoadingAnimation'
import {
  fetchInventoryGroups,
  getInventory,
} from 'features/public-views/manageStock/API/manageStockAction'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import {
  getComodities,
  getInventories,
  getPackings,
} from '../sellCommodityAction'
import {
  removeItem,
  removePacking,
  setInvPacking,
  setOrdersPayload,
} from '../sellCommoditySlice'
import useAutoFetchPublicVersion from 'hooks/useAutoFetchPublicVersion'

const Item2 = ({ fieldNum, idx, refItem }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const autoFetchPublicVersion = useAutoFetchPublicVersion()

  const {
    orders,
    addItemCount,
    comodities,
    inventories,
    packings,
    invPacking,
  } = useSelector((state) => state.sellCommodity)
  const [units, setUnits] = useState([])
  const [listComodities, setListComodities] = useState([])
  const [listInventories, setListInventories] = useState([])
  const [isInventory, setIsInventory] = useState([false])
  const [openOptionId, setOpenOptionId] = useState(null)
  const [loading, setLoading] = useState({
    inventory: false,
  })

  const handleToggle = (id) => {
    setOpenOptionId(openOptionId === id ? null : id)
  }

  // useEffect(() => {
  //   if (!loadingPubDev) {
  //     if (successPubDev) {
  //       const newData = dataPubDev?.unitList?.split(',')
  //       const newestData = newData?.map((e) => ({
  //         value: e,
  //         label: e,
  //       }))
  //       setUnits(newestData)
  //     } else {
  //       setUnits([])
  //     }
  //   }
  // }, [dataPubDev, successPubDev, loadingPubDev])

  const handleChange = (name, e, i, isInv, isPack) => {
    let key = Object.prototype.hasOwnProperty.call(e, 'sku') ? 'nameItem' : name
    let value = key === 'nameItem' ? (isInv ? e?.label2 : e.label) : e
    let imageUrl = key === 'nameItem' ? e.img : orders.item[i].imageUrl
    let sku = key === 'nameItem' ? e.sku : orders.item[i].SKU

    let newData = [...orders.item]

    newData[i] = {
      ...newData[i],
      [key]: value,
      SKU: sku,
      imageUrl: imageUrl,
      isPacking: e.isPacking ?? false,
      ...(isInv && { inventoryId: e.id }),
      ...(isInv && {
        amount:
          typeof e.amount === 'number'
            ? intl
                .formatNumber(e.amount, {
                  useGrouping: 'always',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 0,
                })
                .toString()
            : e?.amount,
      }),
      ...(isInv && {
        unit: autoFetchPublicVersion?.unitArray.find(
          (z) =>
            (z?.value ?? '').toLowerCase() ===
            (e?.unit ?? e?.item?.unit ?? '').toLowerCase(),
        ),
      }),
      totalPrice:
        key === 'price' && orders.item[i]?.amount
          ? Number(
              value
                ?.replace(/,/g, '.')
                ?.replace(/(?<=\d)\.(?=\d{3})/g, '')
                ?.slice(0, 15),
            ) *
            Number(
              newData[i]?.amount
                ?.replace(/,/g, '.')
                ?.replace(/(?<=\d)\.(?=\d{3})/g, '')
                ?.slice(0, 15),
            )
          : key === 'amount' && orders.item[i]?.price
            ? Number(
                newData[i]?.price
                  ?.replace(/,/g, '.')
                  ?.replace(/(?<=\d)\.(?=\d{3})/g, '')
                  ?.slice(0, 15),
              ) *
              Number(
                value
                  ?.replace(/,/g, '.')
                  ?.replace(/(?<=\d)\.(?=\d{3})/g, '')
                  ?.slice(0, 15),
              )
            : newData[i]?.totalPrice,
      ...(isPack && { price: null, totalPrice: null, inventoryId: null }),
    }

    dispatch(setOrdersPayload(newData))
  }

  const handleChangeInputSeparator = (data, name, idx, resData) => {
    const parsedData = Number(
      data?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
    )

    if (resData && data && parsedData !== invPacking?.[idx]?.[name]) {
      const updatedPacking = invPacking.map((item) => {
        if (item?.inventoryId === resData?.inventoryId) {
          return {
            ...item,
            price: parsedData,
            totalPrice: parsedData * item.amount,
          }
        }
        return item
      })

      if (JSON.stringify(updatedPacking) !== JSON.stringify(invPacking)) {
        dispatch(setInvPacking(updatedPacking))
      }
    } else if (data !== orders?.item?.[idx]?.[name] && !resData) {
      handleChange(name, data, idx)
    }
  }

  const handleLastPick = (e) => {
    const newListComodities = [...listComodities]

    const updatedOptions = newListComodities[0]?.options?.filter(
      (item) => item.id !== e.id,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities[1] = {
        ...newListComodities[1],
        options: [...newListComodities[1].options, removedItem],
      }
    }

    newListComodities[0] = {
      ...newListComodities[0],
      options: updatedOptions,
    }

    newListComodities[1] = {
      ...newListComodities[1],
      options: newListComodities[1].options.filter((item) => item.id !== e.id),
    }

    localStorage.setItem(
      'lastPickedCommoditiesSell',
      JSON.stringify(updatedOptions),
    )

    setListComodities(newListComodities)
  }

  const handleGetPack = async (collectedInventory, fieldNum) => {
    try {
      if (
        !Array.isArray(collectedInventory) ||
        collectedInventory.length === 0
      ) {
        dispatch(setInvPacking([]))
        return
      }

      const newData = collectedInventory.map((e) => ({
        SKU: e?.item?.SKU,
        amount: e?.amount ?? e?.item?.amount,
        imageUrl: e?.item?.linkFoto,
        nameItem: e?.item?.nameItem,
        unit: autoFetchPublicVersion?.unitArray.find(
          (z) =>
            (z?.value ?? '').toLowerCase() ===
            (e?.unit ?? e?.item?.unit ?? '').toLowerCase(),
        ),
        inventoryId: e?.id,
        packingId: e?.groupId,
        fieldNum: fieldNum,
      }))

      const currentPacking = Array.isArray(invPacking)
        ? invPacking
        : Object.values(invPacking ?? {})

      const updatedPacking = currentPacking.filter(
        (existingItem) =>
          !newData.some(
            (newItem) => newItem.fieldNum === existingItem.fieldNum,
          ),
      )

      const finalPacking = [...updatedPacking, ...newData]

      dispatch(setInvPacking(finalPacking))
    } catch (error) {
      console.error('Error in handleGetPack:', error)
    } finally {
      setLoading((prevState) => ({ ...prevState, inventory: false }))
    }
  }

  const collectInventoryRecursively = async (data) => {
    if (!data?.id) {
      throw new Error('Missing group ID.')
    }

    try {
      let collectedInventory = []

      const groupResponse = await dispatch(
        fetchInventoryGroups({ parentGroupId: data.id }),
      ).unwrap()

      if (groupResponse?.items?.length > 0) {
        for (const subGroup of groupResponse.items) {
          const subInventory = await collectInventoryRecursively(subGroup)
          collectedInventory.push(...subInventory)
        }
      }

      const inventoryResponse = await dispatch(
        getInventory({
          page: 0,
          size: 9999,
          groupId: data.id,
        }),
      ).unwrap()

      if (inventoryResponse?.items?.length > 0) {
        collectedInventory.push(...inventoryResponse.items)
      }
      return collectedInventory
    } catch (error) {
      console.error(`Error collecting inventory for groupId: ${data.id}`, error)
      throw error
    }
  }

  useEffect(() => {
    if (!comodities) {
      dispatch(getComodities())
    } else if (!inventories) {
      dispatch(getInventories())
    } else if (!packings) {
      dispatch(getPackings())
    }
    setListComodities(comodities)
    setListInventories([
      {
        label: 'GRUP INVENTORI',
        options: packings?.map((e) => {
          return {
            label: (
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ width: '50%', wordWrap: 'break-word' }}>
                  {e?.nameItem}
                </div>
                <div
                  className="text-muted"
                  style={{
                    width: '50%',
                    textAlign: 'right',
                    wordWrap: 'break-word',
                  }}
                >
                  {e?.warehouseName}
                </div>
              </div>
            ),
            ...e,
          }
        }),
      },
      {
        label: 'SEMUA INVENTORI',
        options: inventories?.map((e) => {
          return {
            label: (
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ width: '50%', wordWrap: 'break-word' }}>
                  {e?.nameItem}
                </div>
                <div
                  className="text-muted d-flex align-items-end"
                  style={{
                    width: '50%',
                    textAlign: 'right',
                  }}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {intl.formatNumber(e?.amount, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}{' '}
                    {e?.unit} |
                  </span>
                  <span style={{ marginLeft: '10px', wordWrap: 'break-word' }}>
                    {' '}
                    {e?.warehouse}
                  </span>
                </div>
              </div>
            ),
            ...e,
          }
        }),
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comodities, inventories, packings])

  useEffect(() => {
    if (isInventory.length < addItemCount.length) {
      setIsInventory((prev) => [...prev, false])
    }
  }, [addItemCount, isInventory])

  return (
    <Card className="border-0 p-0">
      <CardBody className="p-0 mt-3">
        <FormGroup>
          <Label>Kategori komoditas</Label>
          <Row>
            <Col>
              <div
                onClick={() => {
                  setIsInventory((prev) => {
                    const updated = [...prev]
                    updated[fieldNum - 1] = false
                    return updated
                  })
                }}
                className={`d-flex justify-content-between align-items-center p-3 rounded border pointer ${!isInventory[fieldNum - 1] ? 'bg-primary-50 border-primary' : ''}`}
              >
                Komoditas Umum
                <Input
                  name={`category-${fieldNum}`}
                  id={`category-${fieldNum}`}
                  onClick={() => {
                    setIsInventory((prev) => {
                      const updated = [...prev]
                      updated[fieldNum - 1] = false
                      return updated
                    })
                  }}
                  value={isInventory[fieldNum - 1]}
                  checked={isInventory[fieldNum - 1] === false}
                  type="radio"
                />
              </div>
            </Col>
            <Col>
              <div
                onClick={() => {
                  setIsInventory((prev) => {
                    const updated = [...prev]
                    updated[fieldNum - 1] = true
                    return updated
                  })
                }}
                className={`d-flex justify-content-between align-items-center p-3 rounded border pointer ${isInventory[fieldNum - 1] ? 'bg-primary-50 border-primary' : ''}`}
              >
                Inventori Anda
                <Input
                  name={`category-${fieldNum}`}
                  id={`category-${fieldNum}`}
                  onClick={() => {
                    setIsInventory((prev) => {
                      const updated = [...prev]
                      updated[fieldNum - 1] = true
                      return updated
                    })
                  }}
                  value={isInventory[fieldNum - 1]}
                  checked={isInventory[fieldNum - 1] === true}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormGroup>

        {isInventory[fieldNum - 1] ? (
          <>
            <FormGroup>
              <Label for="nameItem">Komoditas </Label>
              <Select
                id="nameItem"
                name="nameItem"
                placeholder="Pilih komoditas"
                options={listInventories ?? []}
                onChange={async (e) => {
                  try {
                    if (e?.isPacking) {
                      handleChange('nameItem', e, idx, false, true)
                      setLoading((prevState) => ({
                        ...prevState,
                        inventory: true,
                      }))
                      const collectedInventory =
                        await collectInventoryRecursively(e)
                      await handleGetPack(collectedInventory, fieldNum)
                    } else {
                      handleChange('nameItem', e, idx, true)

                      if (invPacking?.length > 0) {
                        dispatch(removePacking(fieldNum))
                      }
                    }
                  } catch (error) {
                    console.error('Error in onChange:', error)
                  }
                }}
                value={
                  listInventories?.[0]?.options?.find(
                    (z) =>
                      (z?.label ?? '') ===
                      (
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ width: '50%', wordWrap: 'break-word' }}>
                            {z?.nameItem}
                          </div>
                          <div
                            className="text-muted"
                            style={{
                              width: '50%',
                              textAlign: 'right',
                              wordWrap: 'break-word',
                            }}
                          >
                            {z?.warehouseName}
                          </div>
                        </div>
                      ),
                  ) ??
                  listInventories?.[1]?.options?.find(
                    (z) =>
                      (z?.label ?? '') ===
                      (
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ width: '50%', wordWrap: 'break-word' }}>
                            {z?.nameItem}
                          </div>
                          <div
                            className="text-muted  d-flex align-items-end"
                            style={{
                              width: '50%',
                              textAlign: 'right',
                            }}
                          >
                            <span style={{ whiteSpace: 'nowrap' }}>
                              {intl.formatNumber(z?.amount, {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              })}{' '}
                              {z?.unit} |
                            </span>
                            <span
                              style={{
                                marginLeft: '10px',
                                wordWrap: 'break-word',
                              }}
                            >
                              {' '}
                              {z?.warehouse}
                            </span>
                          </div>
                        </div>
                      ),
                  )
                }
              />
            </FormGroup>
            {loading?.inventory ? (
              <div className="align-items-center d-flex justify-content-center">
                <LoadingAnimation />
              </div>
            ) : null}

            {orders?.item?.[idx]?.SKU !== '' &&
            !orders?.item?.[idx]?.isPacking ? (
              <>
                {/* section 1 */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="amount">Jumlah</Label>
                      <Input
                        id="amount"
                        name="amount"
                        type="text"
                        disabled
                        placeholder={intl.formatNumber(
                          isNaN(orders?.item[idx]?.amount)
                            ? 0
                            : orders?.item[idx]?.amount,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          },
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="unit">Satuan</Label>
                      <Select
                        id="unit"
                        isDisabled
                        name="unit"
                        placeholder="Pilih unit"
                        options={autoFetchPublicVersion?.unitArray ?? []}
                        onChange={(e) => handleChange('unit', e, idx)}
                        value={orders?.item[idx]?.unit}
                        defaultValue={orders?.item[idx]?.unit}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* section 2 */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="price">Harga per satuan</Label>
                      <InputGroup>
                        <InputGroupText className="bg-transparent">
                          Rp
                        </InputGroupText>
                        <AmountInput
                          name="price"
                          idx={idx}
                          cb={handleChangeInputSeparator}
                          placeholder="0"
                          dotOnly
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="total">Total harga</Label>
                      <InputGroup>
                        <InputGroupText className="bg-transparent">
                          Rp
                        </InputGroupText>
                        <Input
                          id="total"
                          name="total"
                          type="text"
                          disabled
                          placeholder={intl.formatNumber(
                            isNaN(orders?.item[idx]?.totalPrice)
                              ? 0
                              : orders?.item[idx]?.totalPrice,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            },
                          )}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <div className="">
                    {fieldNum !== 1 && (
                      <CustomButton
                        color="danger"
                        outline
                        className="float-end"
                        onClick={() => dispatch(removeItem(fieldNum))}
                        size="sm"
                      >
                        <Trash size={16} /> Hapus
                      </CustomButton>
                    )}
                  </div>
                </Row>
              </>
            ) : (
              <>
                <div className="d-flex gap-2 flex-column">
                  {invPacking?.map((e, i) => {
                    if (e?.fieldNum === fieldNum) {
                      return (
                        <div key={i}>
                          <Row className="fw-semibold">
                            <Col md={6}>
                              <FormGroup>
                                <span>{e?.nameItem}</span>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="text-end">
                                <span>
                                  Rp
                                  {intl.formatNumber(e?.totalPrice ?? 0, {
                                    useGrouping: 'always',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <span>Jumlah</span>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="text-end">
                                <span>
                                  {intl.formatNumber(e?.amount ?? 0, {
                                    useGrouping: 'always',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                  })}
                                  kg
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <span>Harga per satuan</span>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <InputGroup>
                                  <InputGroupText className="bg-transparent">
                                    Rp
                                  </InputGroupText>

                                  <AmountInput
                                    name="price"
                                    idx={idx}
                                    cb={(w) =>
                                      handleChangeInputSeparator(
                                        w,
                                        'price',
                                        idx,
                                        e,
                                      )
                                    }
                                    placeholder="0"
                                    dotOnly
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <CardBody>
                              <hr style={{ height: '2px' }} />
                            </CardBody>
                          </Row>
                        </div>
                      )
                    }
                  })}
                  <div className="">
                    {fieldNum !== 1 && (
                      <CustomButton
                        color="danger"
                        outline
                        className="float-end"
                        onClick={() => dispatch(removeItem(fieldNum))}
                        size="sm"
                      >
                        <Trash size={16} /> Hapus
                      </CustomButton>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <FormGroup>
              <Label for="nameItem">Komoditas</Label>
              <Select
                id="nameItem"
                name="nameItem"
                placeholder="Pilih komoditas"
                options={listComodities ?? []}
                onChange={(e) => {
                  handleChange('nameItem', e, idx)
                  handleLastPick(e)
                }}
                value={orders[idx]?.nameItem}
                defaultValue={comodities?.[idx]?.nameItem}
                ref={refItem}
              />
            </FormGroup>
            {/* section 1 */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="amount">Jumlah</Label>
                  <AmountInput
                    name="amount"
                    idx={idx}
                    cb={handleChangeInputSeparator}
                    placeholder="0"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="unit">Satuan</Label>
                  <Select
                    id="unit"
                    name="unit"
                    placeholder="Pilih unit"
                    options={autoFetchPublicVersion?.unitArray ?? []}
                    onChange={(e) => handleChange('unit', e, idx)}
                    value={orders?.item[idx]?.unit}
                    defaultValue={orders?.item[idx]?.unit}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* section 2 */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="price">Harga per satuan</Label>
                  <InputGroup>
                    <InputGroupText className="bg-transparent">
                      Rp
                    </InputGroupText>
                    <AmountInput
                      name="price"
                      idx={idx}
                      cb={handleChangeInputSeparator}
                      placeholder="0"
                      dotOnly
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="total">Total harga</Label>
                  <InputGroup>
                    <InputGroupText className="bg-transparent">
                      Rp
                    </InputGroupText>
                    <Input
                      id="total"
                      name="total"
                      type="text"
                      disabled
                      placeholder={intl.formatNumber(
                        isNaN(orders?.item[idx]?.totalPrice)
                          ? 0
                          : orders?.item[idx]?.totalPrice,
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        },
                      )}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <div className="">
                {fieldNum !== 1 && (
                  <CustomButton
                    color="danger"
                    outline
                    className="float-end"
                    onClick={() => dispatch(removeItem(fieldNum))}
                    size="sm"
                  >
                    <Trash size={16} /> Hapus
                  </CustomButton>
                )}
              </div>
              {addItemCount.length > 1 &&
                fieldNum !== addItemCount[addItemCount.length - 1] && (
                  <div>
                    <hr />
                  </div>
                )}
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default Item2
