import axios from 'axios'
import CardPin from 'components/CardPin'
import { apiEndpoint } from 'configs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

const CheckPinTrx = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const datas = location.state
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const { token } = useSelector((e) => e.auth)
  const { profile } = useSelector((e) => e.dashboard)

  const handleSubmit = async (pin) => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/checkpasswordtrx`,
        { pin: pin },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (data?.success === 1 && data.tempToken) {
        if (datas?.userData?.trxId) {
          const obj = {
            tempToken: data.tempToken,
            id: datas?.userData?.trxId,
            selfieurl: datas?.selfieUrl?.url,
            ...(datas?.dataState?.talangin && {
              loanDays: datas?.dataState?.selectedTenor?.loanDays,
              creditRequested: datas?.dataState?.dataTenor?.creditRequested,
            }),
            ...(datas?.isCash &&
              datas?.userData?.useProofOfPayment && {
                useProofOfPayment: datas?.userData?.useProofOfPayment,
              }),
            ...(datas?.isCash &&
              datas?.userData?.useProofOfPayment && {
                proofOfPayment: datas?.userData?.proofOfPayment,
              }),
          }
          const res = await axios.post(
            `${apiEndpoint}/api/v2/bussines/transaction/payment`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          if (res.status === 200) {
            localStorage.removeItem('userData')
            handleSavedContacts(datas?.userData?.dataSeller)
            setLoading(false)
            navigate('/transaksi-pembelian-sukses')
          }
        } else {
          const obj = {
            tempToken: data.tempToken,
            userId: datas?.dataProfile?.ownerData?.userId,
            selfieurl: datas?.selfieUrl?.url,
            item: datas?.userData?.dataComodities?.map((e) => {
              return {
                SKU: e?.comodity?.sku ?? e?.comodity?.SKU,
                nameItem: e?.comodity?.label,
                unit: e?.unit?.value,
                amount: Number(
                  e.quantity
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ),
                price: Number(
                  e.price
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ),
                imageUrl: e?.comodity?.img ?? e?.comodity?.linkFoto,
              }
            }),
            order: {
              type: 'BUY',
              transaction_number: datas?.userData?.customNumber,
              note: datas?.userData?.notes,
              ...(datas?.userData?.alreadyReceived && {
                autoRelease: datas?.userData?.alreadyReceived,
              }),
              ...(datas?.userData?.files.length > 0 && {
                escrowFiles: datas?.userData?.files,
              }),
              ...(datas?.selectedPricing && {
                useDisbursementpricingId: datas?.selectedPricing?.id,
              }),
              ...(datas?.isCash &&
                datas?.userData?.useProofOfPayment && {
                  useProofOfPayment: datas?.userData?.useProofOfPayment,
                }),
              ...(datas?.isCash &&
                datas?.userData?.useProofOfPayment && {
                  proofOfPayment: datas?.userData?.proofOfPayment,
                }),
            },
            buyer: {
              fullName: datas?.dataProfile?.ownerData?.name,
              phoneNumber: datas?.dataProfile?.ownerData?.phoneNumber,
              userId: datas?.dataProfile?.ownerData?.userId,
            },
            seller: {
              fullName: datas?.userData?.dataSeller?.fullName,
              phoneNumber: datas?.userData?.dataSeller?.phoneNumber,
              bankCode: datas?.userData?.dataSeller?.bankCode,
              bankAccountName: datas?.userData?.dataSeller?.bankAccountName,
              bankAccountNumber: datas?.userData?.dataSeller?.bankAccountNumber,
              isLogin: datas?.userData?.dataSeller?.isLogin ?? false,
              ...(datas?.userData?.dataSeller?.status === 'REGISTERED' && {
                userId: datas?.userData?.dataSeller?.userId,
              }),
            },
            ...(datas?.dataState?.talangin && {
              loanDays: datas?.dataState?.selectedTenor?.loanDays,
              creditRequested: datas?.dataState?.dataTenor?.creditRequested,
            }),
            trxDate: datas?.userData?.trxDate,
          }
          const res = await axios.post(
            `${apiEndpoint}/api/v2/bussines/transaction/buy`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          if (res.status === 200) {
            if (datas?.selectedPricing) {
              localStorage.setItem(
                'PMB_isInstant',
                JSON.stringify(datas?.selectedPricing),
              )
            } else {
              localStorage.removeItem('PMB_isInstant')
            }
            localStorage.removeItem('userData')
            setLoading(false)
            handleSavedContacts(datas?.userData?.dataSeller)
            if (datas?.isCash) {
              navigate('/transaksi-pembelian-sukses', {
                state: {
                  title: 'Transaksi berhasil dibuat!',
                  desc: 'Setelah penjual melakukan konfirmasi, transaksi akan selesai otomatis.',
                },
              })
            } else {
              navigate('/transaksi-pembelian-sukses')
            }
          }
        }
      } else {
        navigate(-1)
      }
    } catch (error) {
      setLoading(false)
      setErrMsg(
        error?.response?.data?.message ??
          error?.response?.data?.meta?.message ??
          error?.message,
      )
      console.log(error)
    }
  }

  const handleSavedContacts = (e) => {
    const saved =
      JSON.parse(localStorage.getItem(`savedContacts-${profile?.userId}`)) ?? []
    let newListComodities = [...saved]

    const updatedOptions = newListComodities.filter(
      (item) => item.userId !== e.userId,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities = {
        removedItem,
      }
    }

    localStorage.setItem(
      `savedContacts-${profile?.userId}`,
      JSON.stringify(updatedOptions),
    )
  }

  return (
    <CardPin
      title={`Konfirmasi PIN`}
      desc={` Masukkan PIN untuk melanjutkan`}
      length={6}
      handleSubmit={handleSubmit}
      loading={loading}
      errMsg={errMsg}
      setErrMsg={setErrMsg}
    />
  )
}

export default CheckPinTrx
