import ICPemasukan2 from 'assets/icons/pemasukan-2.svg'
import ICPengeluaran2 from 'assets/icons/pengeluaran-2.svg'
import 'assets/scss/flatpickr/flatpickr.scss'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import PMBProgressBarExportFile from 'components/PMBProgressBarExportFile'
import { TableComponent as DataTable } from 'components/TableComponent'
import { Can } from 'context/Acl'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import {
  ArrowDownCircle,
  ArrowUpCircle,
  Calendar,
  Download,
} from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import {
  Badge,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap'
import { formatDatePMB } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { ARUS_KAS_FORM } from 'utils/pathUrl'
import { BUSINESS_BOOKKEEPING, MANAGE } from 'utils/subjectActions'
import { useLazyGetArusKasQuery } from './cashFlowApiSlice'
import {
  setDate,
  setFetchOption,
  setFilters,
  toggleSidebarCashflow,
} from './cashFlowSlice'
import { formatNotes, handleDownloadExcel } from './downloadHistory'
import SidebarCashFlow from './SidebarCashFlow'

const HistoryCashFlow = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const [data, setData] = useState([])
  const { isOpenSidebarCashFlow, isOpenBanner, tag, filters } = useSelector(
    (s) => s.cashflow,
  )
  const { selectedDate, selectedFilter } = filters
  const [getArusKas, res] = useLazyGetArusKasQuery()

  const [selectedRow, setSelectedRow] = useState(null)
  const [isDownload, setIsDownload] = useState(false)
  const [firstRows, setFirstRows] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const handleDateChange = (dates) => {
    dispatch(
      setFilters({
        selectedDate: dates,
      }),
    )
  }

  const handleFilterChange = (filter) => {
    dispatch(
      setFilters({
        selectedFilter: filter,
      }),
    )
  }

  const handleFinishedDelete = async () => {
    const resp = await getArusKas({
      startdate: moment(selectedDate[0]).format('YYYY-MM-DD'),
      enddate: moment(selectedDate[1])
        .local()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      filter: selectedFilter?.value,
      page: tag?.page,
      size: tag?.size,
    })
    setData(resp?.data)
  }

  const columns = [
    {
      name: <span>Tanggal Transaksi</span>,
      wrap: true,
      grow: 2,
      cell: (row) =>
        formatDatePMB(
          `${row?.createddate + ' ' + row?.createdhour}`,
          `${row?.createddate + ' ' + row?.createdhour}`,
        ),
    },
    {
      name: <span>Kategori Transaksi</span>,
      width: '25%',
      grow: 2,
      cell: (row) => (
        <div className="d-flex gap-2 align-items-center fw-500">
          <img
            width={40}
            height={40}
            src={
              row?.incomeorexpense === 'income' ? ICPemasukan2 : ICPengeluaran2
            }
            alt={
              row?.incomeorexpense === 'income' ? 'Pemasukan' : 'Pengeluaran'
            }
          />
          <span>{row?.extradata?.label ?? row?.label}</span>
        </div>
      ),
    },
    {
      name: (
        <div
          style={{
            textAlign: 'right',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          Total Transaksi
        </div>
      ),
      wrap: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex justify-content-end w-100">
          <span>
            Rp
            {newFormatThousandSeparator(row?.amount, 0)}
          </span>
        </div>
      ),
    },
    {
      name: <span>Jenis Pencatatan</span>,
      wrap: true,
      grow: 2,
      cell: (row) => {
        return (
          <Badge className={!row?.manual ? 'badge-verify' : 'badge-process'}>
            {!row?.manual ? 'Transaksi' : 'Catat Manual'}
          </Badge>
        )
      },
    },
    {
      name: <span>Catatan</span>,
      wrap: true,
      grow: 2,
      cell: (row) => (
        <span
          className="truncate"
          dangerouslySetInnerHTML={{
            __html: row?.notes ? formatNotes(row?.notes) : '-',
          }}
        />
      ),
    },
    {
      name: '',
      wrap: true,
      grow: 1,
      selector: (row) => (
        <div
          onClick={() => {
            dispatch(toggleSidebarCashflow())
            setSelectedRow(row)
          }}
          style={{ cursor: 'pointer' }}
          className="fw-600 text-primary"
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  const filterOptions = [
    { label: 'Semua catatan', value: 'all' },
    { label: 'Catatan transaksi di PasarMikro', value: 'pasarmikro' },
    { label: 'Catatan manual', value: 'manual' },
  ]

  useEffect(() => {
    dispatch(
      setDate({
        startdate: moment(selectedDate[0]).format('YYYY-MM-DD'),
        enddate: moment(selectedDate[1])
          .local()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      }),
    )
    getArusKas({
      startdate: moment(selectedDate[0]).format('YYYY-MM-DD'),
      enddate: moment(selectedDate[1])
        .local()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      filter: selectedFilter?.value,
      page: tag?.page,
      size: tag?.size,
    })
      .unwrap()
      .then((resAPI) => {
        setData(resAPI)
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.status === 403 &&
          typeof e.response.data.message !== 'undefined' &&
          e.response.data.message === 'access denied'
        ) {
          console.warn(e)
        }
        console.warn(e)
      })
  }, [selectedDate, selectedFilter, tag])

  return (
    <div className="w-100 px-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Riwayat Arus Kas'} />
      </div>
      {/* SIDEBAR */}
      <SidebarCashFlow
        handleFinishedDelete={handleFinishedDelete}
        row={selectedRow}
        isOpen={isOpenSidebarCashFlow}
        toggle={() => dispatch(toggleSidebarCashflow())}
        formatNotes={formatNotes}
      />
      {/* PROGRESS DOWNLOAD */}
      <PMBProgressBarExportFile
        isDialogShow={isDownload}
        firstRows={firstRows}
        totalRows={totalRows}
        onCancel={() => setIsDownload(false)}
      />

      <div className="mb-2 d-flex flex-column gap-3">
        <Card className="border-0 shadow-sm">
          <CardBody>
            <Row className="align-items-center">
              <Col xs={12} md={2} lg={4}>
                <h5 className="fw-semibold">Riwayat Arus Kas</h5>
              </Col>
              <Col
                xs={12}
                md={10}
                lg={8}
                className="d-flex justify-content-md-end mt-2 mt-md-0"
              >
                <div className="d-flex flex-column flex-md-row align-items-start gap-2 w-100 justify-content-md-end">
                  <Can I={MANAGE} this={BUSINESS_BOOKKEEPING}>
                    <CustomButton
                      disabled={isDownload || !data?.items?.length}
                      className="rounded"
                      onClick={() =>
                        handleDownloadExcel(
                          res,
                          setIsDownload,
                          setFirstRows,
                          setTotalRows,
                        )
                      }
                      color="primary"
                      outline
                      size="sm"
                    >
                      {isDownload ? (
                        <Spinner size="sm" color="primary" />
                      ) : (
                        <Download size={15} />
                      )}{' '}
                      Download
                    </CustomButton>

                    <UncontrolledDropdown>
                      <DropdownToggle size="sm" color="primary">
                        + Tambah
                      </DropdownToggle>
                      <DropdownMenu container="body">
                        <DropdownItem
                          onClick={() =>
                            navigate(ARUS_KAS_FORM, { state: { income: true } })
                          }
                        >
                          <ArrowDownCircle className="text-success" /> Catat
                          Pemasukan
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            navigate(ARUS_KAS_FORM, {
                              state: { expense: true },
                            })
                          }
                        >
                          <ArrowUpCircle className="text-danger" /> Catat
                          Pengeluaran
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Can>
                </div>
              </Col>
            </Row>

            <div className="my-4">
              <div className="d-flex flex-row gap-2">
                <div>
                  <p className="mb-2">Tanggal</p>
                  <div className="d-flex align-items-center justify-content-center border rounded">
                    <Calendar size={17} className="ms-3" />
                    <Flatpickr
                      options={{
                        mode: 'range',
                        dateFormat: 'd-m-Y',
                        defaultDate: selectedDate,
                        onClose: handleDateChange,
                        maxDate: moment().format('YYYY-MM'),
                      }}
                      className="form-control flat-picker bg-transparent border-0 shadow-none"
                    />
                  </div>
                </div>
                <div>
                  <p className="mb-2">Jenis</p>
                  <Select
                    isSearchable={false}
                    onChange={handleFilterChange}
                    options={filterOptions}
                    value={selectedFilter}
                  />
                </div>
              </div>
            </div>

            <Card className="border-0 shadow-none">
              <DataTable
                columns={columns}
                data={data?.items}
                pagination
                paginationServer
                progressPending={res?.isFetching}
                progressComponent={
                  <div style={{ margin: '100px 0px' }}>
                    <Spinner color="primary" />
                  </div>
                }
                paginationComponent={(props) => (
                  <PMBPagination
                    rowCount={data?.totalItems || 0}
                    currentPage={tag?.page}
                    rowsPerPage={tag?.size}
                    setCurrentPage={(e) => {
                      dispatch(setFetchOption({ page: e }))
                    }}
                    setRowsPerPage={(e) => {
                      dispatch(setFetchOption({ size: e }))
                    }}
                    props={props}
                  />
                )}
              />
            </Card>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default HistoryCashFlow
