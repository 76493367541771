import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'reactstrap'

const AmountInput = (props) => {
  const intl = useIntl()
  const { name, id, max, cb, placeholder, dotOnly, value, ...restProps } = props
  const [amount, setAmount] = useState(value || '')

  const formatAmount = (value) => {
    const stringValue = typeof value === 'number' ? String(value) : value

    let numericValue =
      stringValue.includes(',') && !dotOnly
        ? stringValue
        : Number(
            stringValue
              ?.replace(/[^\d,]/g, '')
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.'),
          )
    let maxAmount = max - parseFloat(numericValue)

    if (maxAmount < 0) {
      numericValue = max
    }

    if (typeof numericValue === 'number') {
      const formattedValue = intl.formatNumber(numericValue, {
        useGrouping: 'always',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return formattedValue
    } else {
      let cleaned = numericValue.replace(/[^\d,]/g, '')

      cleaned = cleaned.replace(/,+/g, ',')

      let parts = cleaned.split(',')
      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 2)
      }

      cleaned = parts.join(',')

      return cleaned
    }
  }

  const handleChangeAmountSeparator = (e) => {
    const formattedValue = formatAmount(
      typeof e === 'object' ? e.target.value : e,
    )
    setAmount(formattedValue)
  }

  useEffect(() => {
    if (props?.value) {
      handleChangeAmountSeparator(props?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    const amountString = typeof amount === 'string' ? amount : ''
    if (typeof cb === 'function') {
      cb(amountString, props?.name, props?.idx)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, cb])

  return (
    <Input
      name={name}
      id={id}
      onChange={(e) => handleChangeAmountSeparator(e)}
      value={amount}
      type="text"
      placeholder={placeholder}
      {...restProps}
    />
  )
}

export default AmountInput
