// ** Third Party Components
import classnames from 'classnames'
import Proptypes from 'prop-types'
import { useEffect } from 'react'
import { ArrowLeft, X } from 'react-feather'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const SidebarDetails = (props) => {
  // ** Props
  const {
    width,
    open,
    toggleSidebar,
    size,
    bodyClassName,
    contentClassName,
    wrapperClassName,
    headerClassName,
    className,
    title,
    children,
    closeBtn,
    noClose = false,
    isBack = false,
    handleBack,
    isNavbar = false,
    noHeader = false,
    isLeftAnimation = false,
    ...rest
  } = props

  // ** If user passes custom close btn render that else default close btn
  const renderCloseBtn = !noClose ? (
    closeBtn ? (
      closeBtn
    ) : (
      <X
        className="pe-auto"
        style={{
          cursor: 'pointer',
        }}
        size={20}
        onClick={toggleSidebar}
      />
    )
  ) : (
    <></>
  )

  useEffect(() => {
    if (isNavbar && open) {
      document.body.style.overflow = 'auto'
    } else if (!isNavbar && open) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isNavbar, open])

  return (
    <Modal
      isOpen={open}
      toggle={toggleSidebar}
      contentClassName={classnames({
        [contentClassName]: contentClassName,
      })}
      modalClassName={classnames(
        `${isLeftAnimation ? 'modal-slide-in-left' : 'modal-slide-in'}`,
        {
          [wrapperClassName]: wrapperClassName,
        },
      )}
      className={classnames({
        [className]: className,
        'SidebarDetails-lg': size === 'lg',
        'SidebarDetails-sm': size === 'sm',
        'sidebar-navbar': isNavbar,
      })}
      style={{
        ...(width !== undefined &&
          !isNavbar && { width: String(width) + 'px' }),
        ...(isNavbar && { left: 0, right: 'auto', width: '300px' }),
      }}
      {...rest}
    >
      {noHeader ? (
        <></>
      ) : (
        <div
          className={
            'd-flex justify-content-between ' +
            classnames({
              [headerClassName]: headerClassName,
            })
          }
          toggle={toggleSidebar}
          close={renderCloseBtn}
          tag="div"
          style={{ padding: 20 }}
        >
          <div className="d-flex align-items-center gap-2">
            {isBack && (
              <ArrowLeft style={{ cursor: 'pointer' }} onClick={handleBack} />
            )}
            <h5 className="modal-title">
              <span className="align-middle">{title}</span>
            </h5>
          </div>
        </div>
      )}
      <ModalBody
        style={{ backgroundColor: '#F8F9FA' }}
        className={classnames('flex-grow-1', {
          [bodyClassName]: bodyClassName,
        })}
      >
        {children}
      </ModalBody>
    </Modal>
  )
}

export default SidebarDetails

// ** PropTypes
SidebarDetails.propTypes = {
  title: Proptypes.string.isRequired,
  open: Proptypes.bool.isRequired,
  toggleSidebar: Proptypes.func.isRequired,
  size: Proptypes.oneOf(['sm', 'lg']),
  className: Proptypes.string,
  bodyClassName: Proptypes.string,
  contentClassName: Proptypes.string,
  wrapperClassName: Proptypes.string,
  children: Proptypes.any.isRequired,
  width: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  isNavbar: Proptypes.bool,
}
