import Logo from 'assets/images/pmg-logo-beta.png'
import { P } from 'components'
import { validateEmail } from 'helpers'
import useEnterSubmit from 'hooks/useEnterSubmit'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { ArrowLeft, Eye, EyeOff } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, FormGroup, Input, InputGroupText, Spinner } from 'reactstrap'
import { URL_FORGET_PASSWORD_STEP_1 } from 'utils/pathUrl'
import {
  useCheckPasswordMutation,
  useLoginUserNewMutation,
  useSendOtpLoginMutation,
} from '../login-new/loginNewApiSlice'
import { login } from '../login/loginSlice'
import '../register-universal/register.css'
import './login-universal.css'
import Tnc from './Tnc'

const LoginStepTwo = () => {
  const location = useLocation()
  const dataState = location.state
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isShowPassword, setIsShowPassword] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [loginUser, { isLoading }] = useLoginUserNewMutation()
  const [payload, setPayload] = useState({})

  const [sendOtp, { isLoading: isLoadingSendOTP }] = useSendOtpLoginMutation()
  const [checkPassword, { isLoading: isLoadingCheckPassword }] =
    useCheckPasswordMutation()

  const browserHashLocalStorage = localStorage.getItem('browserHash')
  const browserFingerPrintLocalStorage =
    localStorage.getItem('browserFingerPrint')
  const browserCustomFingerPrintLocalStorage = localStorage.getItem(
    'browserCustomFingerPrint',
  )

  const handleSubmit = async () => {
    try {
      if (!dataState) {
        navigate('/', { replace: true })
        return
      }
      const loginPayload = {
        identity: dataState?.identity,
        deviceHash: dataState?.deviceHash,
        password: payload.identity,
        fcmToken: dataState?.fcmToken ?? '',
        version: ` ${dataState?.mobileVersion ? `PM ${process.env.REACT_APP_VERSION} (${dataState?.mobileVersion})` : `PMB ${process.env.REACT_APP_VERSION}`} `,
        browserHash: browserHashLocalStorage ?? dataState?.browserHash,
        browserFingerPrint:
          browserFingerPrintLocalStorage ?? dataState?.browserFingerPrint,
        browserCustomFingerPrint:
          browserCustomFingerPrintLocalStorage ??
          dataState?.browserCustomFingerPrint,
      }

      localStorage.setItem('browserHash', loginPayload?.browserHash)
      localStorage.setItem(
        'browserFingerPrint',
        loginPayload?.browserFingerPrint,
      )
      localStorage.setItem(
        'browserCustomFingerPrint',
        browserCustomFingerPrintLocalStorage ??
          dataState?.browserCustomFingerPrint,
      )

      const res = await checkPassword(loginPayload)

      if (!res?.error) {
        if (dataState?.sendOtp) {
          fetchOtp('login')
          return
        } else {
          const res = await loginUser(loginPayload)
          if (!res?.error) {
            dispatch(
              login({
                identity: dataState?.identity,
                ...res?.data,
              }),
            )
            navigate('/', { replace: true })
          } else {
            throw res
          }
        }
      } else {
        throw res
      }
    } catch (error) {
      enqueueSnackbar(
        error?.error?.data?.meta?.message ??
          error?.data?.meta?.message ??
          error?.data?.message ??
          error?.message ??
          'Gagal login!',
        { variant: 'error' },
      )
    }
  }

  const fetchOtp = async (status) => {
    try {
      const res5 = await sendOtp({
        identity: dataState?.identity,
        deviceHash: dataState?.deviceHash,
        type: status,
        channel: validateEmail(dataState?.identity) ? 'EMAIL' : 'WHATSAPP',
        browserHash: browserHashLocalStorage ?? dataState?.browserHash,
        browserFingerPrint:
          browserFingerPrintLocalStorage ?? dataState?.browserFingerPrint,
        browserCustomFingerPrint:
          browserCustomFingerPrintLocalStorage ??
          dataState?.browserCustomFingerPrint,
      })

      if (res5?.data?.meta?.error || res5?.error?.data?.meta?.error) {
        enqueueSnackbar(
          res5?.data?.meta?.message ??
            res5?.data?.message ??
            res5?.error?.data?.meta?.message ??
            'Terjadi kesalahan.',
          {
            variant: 'error',
          },
        )
        return
      } else {
        navigate('/otp', {
          state: {
            type: status,
            identity: dataState?.identity,
            deviceHash: dataState?.deviceHash,
            countDown: res5?.data?.data?.expireTime,
            browserHash: browserHashLocalStorage ?? dataState?.browserHash,
            browserFingerPrint:
              browserFingerPrintLocalStorage ?? dataState?.browserFingerPrint,
            browserCustomFingerPrint:
              browserCustomFingerPrintLocalStorage ??
              dataState?.browserCustomFingerPrint,
            status: status,
            fcmToken: dataState?.fcmToken ?? '',
            mobileVersion: dataState?.mobileVersion ?? '',
            password: payload.identity,
          },
        })
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar(
        error?.data?.meta?.message ??
          error?.data?.message ??
          error?.error?.data?.meta?.message ??
          'Terjadi kesalahan.',
        {
          variant: 'error',
        },
      )
    }
  }

  useEnterSubmit(handleSubmit, !payload?.identity || isLoading)

  // AUTO FOCUS
  const inputRef = useRef(null)
  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 100)
  }, [])

  return (
    <div className="centered-container">
      <div className="mobile-header" onClick={() => window.history.back()}>
        <ArrowLeft size={20} className="chevron-icon" />
        {/* <P>Daftar akun</P> */}
      </div>
      <div
        className="card-wrapper"
        style={{
          borderRadius: 10,
          maxWidth: '600px',
        }}
      >
        <img
          src={Logo}
          alt="Logo PasarMikro"
          style={{
            marginBottom: 24,
            maxWidth: '177px',
            height: 'auto',
          }}
        />

        <div className="mt-2">
          <P size="regular">Masukkan password</P>
          <FormGroup className="input-group mt-1">
            <Input
              innerRef={inputRef}
              type={isShowPassword ? 'text' : 'password'}
              value={payload.identity}
              onChange={(e) => {
                setErrMsg('')
                setPayload((prevState) => ({
                  ...prevState,
                  ['identity']: e?.target?.value,
                }))
              }}
              placeholder="Masukkan password"
              className="password-input"
            />
            <InputGroupText
              className="toggle-password"
              onClick={() => setIsShowPassword(!isShowPassword)}
            >
              {isShowPassword ? (
                <EyeOff size={15} style={{ cursor: 'pointer' }} />
              ) : (
                <Eye size={15} style={{ cursor: 'pointer' }} />
              )}
            </InputGroupText>
          </FormGroup>

          <div
            className="mt-2"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (dataState?.type === 'email') {
                navigate(URL_FORGET_PASSWORD_STEP_1, { replace: true })
              } else {
                fetchOtp('resetPassword')
              }
            }}
          >
            <P size="regular" color={'#006386'}>
              Lupa password?
            </P>
            <P color={'#B42318'} size="regular">
              {errMsg}
            </P>
          </div>
        </div>

        <div className="w-100 mt-3">
          <Button
            disabled={!payload?.identity || isLoading}
            className="w-100 d-flex justify-content-center align-items-center"
            color="primary"
            onClick={handleSubmit}
          >
            {isLoading && <Spinner className="me-2" size="sm" color="light" />}
            <P size="regular">Masuk</P>
          </Button>
        </div>
      </div>
      {/* Footer */}
      <footer
        className="w-100 text-center border border-bottom-0"
        style={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Tnc />
      </footer>
    </div>
  )
}

export default LoginStepTwo
