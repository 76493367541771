import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { Button, Modal, Spinner } from 'reactstrap'

const Camera = ({ onClose, onSaveImage, isOpen, loading }) => {
  const webcamRef = useRef(null)
  const [image, setImage] = useState(null)
  const [imageFile, setImageFile] = useState(null)

  const onCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' })
        setImageFile(file)
      })
  }

  const onCancel = () => {
    setImage(null)
    setImageFile(null)
    onClose()
  }
  return (
    <Modal
      centered
      isOpen={isOpen}
      style={{
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <div className="flex flex-col items-center">
        {!image ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="rounded-lg shadow-lg"
            style={{
              width: '100%',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          />
        ) : (
          <img src={image} alt="Captured" className="rounded-lg shadow-lg" />
        )}
        <div style={{ width: '100%' }}>
          <div className="p-2">
            {!image ? (
              <Button
                disabled={loading}
                onClick={onCapture}
                style={{ width: '100%' }}
                color="primary"
              >
                {loading ? <Spinner color="primary" /> : 'Ambil'}
              </Button>
            ) : (
              <>
                <Button
                  disabled={loading}
                  onClick={() => onSaveImage(imageFile)}
                  style={{ width: '100%' }}
                  color="primary"
                >
                  {loading ? <Spinner size={'sm'} /> : 'Simpan'}
                </Button>
              </>
            )}
            <div className="d-flex gap-2">
              <Button
                disabled={loading}
                onClick={onCancel}
                className="mt-2 w-100"
                color="transparent"
                style={{ borderColor: '#006386', color: '#006386' }}
              >
                {loading ? <Spinner color="primary" size={'sm'} /> : 'Batal'}
              </Button>
              {image ? (
                <Button
                  disabled={loading}
                  onClick={() => setImage(null)}
                  className="mt-2 w-100"
                  color="transparent"
                  style={{ borderColor: '#006386', color: '#006386' }}
                >
                  {loading ? <Spinner color="primary" size={'sm'} /> : 'Ulangi'}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Camera
