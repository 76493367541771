import { P } from 'components'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import { TableComponent as DataTable } from 'components/TableComponent'
import { Can } from 'context/Acl'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useState } from 'react'
import {
  ArrowRightCircle,
  CheckCircle,
  ChevronDown,
  Eye,
  Plus,
  Search,
  Settings,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Card,
  CardBody,
  CardText,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { BUSINESS_COMPANY, MANAGE } from 'utils/subjectActions'
import TabManageStaffGroup from '../components/TabManageStaffGroup'
import { useFetchFarmersV2Query } from '../farmers-list/farmersListApiSlice'
import { clearState } from '../forms/formSlice'
import ModalChangeRole from './ModalChangeRole'
import { setFetchOption, toggleBanner, toggleModal } from './staffListSlice'
import { getGroupList } from '../groups-list/groupListAction'
import PMBSelect from 'components/PMBSelect'

const defaultGroupFilter = {
  id: 0,
  name: 'Semua Grup',
  value: 'Semua Grup',
  label: 'Semua Grup',
}

const StaffList = () => {
  const { tag, isOpenBanner } = useSelector((s) => s.staffList)
  const { ownerData } = useSelector((s) => s.dashboard)
  const { loading, bannerMsg, groups, allGroupCount } = useSelector(
    (s) => s.groupsList,
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const [selectedRow, setSelectedRow] = useState(null)

  const [showFilterByGroup, setShowFilterByGroup] = useState(false)
  const [selectedGroupFilter, setSelectedGroupFilter] =
    useState(defaultGroupFilter)
  const [currentSortColumn, setCurrentSortColumn] = useState()
  const [currentSortDirection, setCurrentSortDirection] = useState()
  const [search, setSearch] = useState()

  const {
    data: dataJoin,
    isLoading: isLoadingJoin,
    refetch: refetchJoin,
  } = useFetchFarmersV2Query(
    {
      size: tag?.size,
      page: tag?.page,
      status: 'JOIN',
      sortby: currentSortColumn,
      sortdir: currentSortDirection,
      search,
      groupCode: selectedGroupFilter?.code,
    },
    { skip: !page },
  )

  const {
    data: dataPending,
    isLoading: isLoadingPending,
    refetch: refetchPending,
  } = useFetchFarmersV2Query(
    {
      size: tag?.size,
      page: tag?.page,
      status: 'PENDING',
    },
    { skip: !page },
  )

  const columns = [
    {
      name: <span>Nama Anggota</span>,
      selector: 'name',
      grow: 2,
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <span>{row.name}</span>
          </div>
        )
      },
    },
    {
      name: <span>Nomor Telepon</span>,
      grow: 2,
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
    {
      name: <span>Grup</span>,
      selector: 'groupName',
      grow: 2,
      sortable: true,
      cell: (row) => {
        return <span>{row?.groupName}</span>
      },
    },
    {
      name: 'Status',
      selector: 'vpstatus',
      sortable: true,
      width: '200px',
      cell: (row) => {
        return (
          <span
            onClick={() => {
              dispatch(toggleModal())
              setSelectedRow(row)
            }}
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              color: row?.vpStatus === 'VERIFIED' ? '#027A48' : '#B42318', // Set font color
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 99,
              backgroundColor:
                row?.vpStatus === 'VERIFIED' ? '#ECFDF3' : '#FEF3F2', // Set background color
            }}
          >
            <P size="small" bold>
              {row?.vpStatus === 'VERIFIED'
                ? 'Terverifikasi'
                : 'Belum Terverifikasi'}
            </P>
          </span>
        )
      },
    },
    {
      cell: (row) => {
        return (
          <Can I={MANAGE} this={BUSINESS_COMPANY}>
            <span
              style={{ cursor: 'pointer' }}
              className="fw-semibold text-center"
            >
              <Eye
                size={15}
                color="#667085"
                onClick={() => navigate(`/farmers/${row?.userId}`)}
              />
              <Settings
                size={15}
                color="#667085"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  dispatch(toggleModal())
                  setSelectedRow(row)
                }}
              />
            </span>
          </Can>
        )
      },
    },
  ]

  const handleGroupSelect = (el) => {
    setSearch('')
    setSelectedGroupFilter(el)
  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value)
    }, 300),
    [],
  )

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value)
  }

  const handleFinishedChangeRole = () => {
    dispatch(toggleBanner())
    refetchJoin()
  }

  const handleSort = (column, direction) => {
    const sortColumn = column?.selector
    const sortDirection = direction?.toLowerCase() === 'asc' ? 'ASC' : 'DESC'

    setCurrentSortColumn(sortColumn)
    setCurrentSortDirection(sortDirection)
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  useEffect(() => {
    dispatch(getGroupList({}))
  }, [])

  useEffect(() => {
    dispatch(clearState())
  }, [])

  return (
    <div className="w-100 px-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs
          breadCrumbActive={'Daftar Anggota'}
          // breadCrumbParent={'Daftar Anggota'}
          breadCrumbParentLink={'/farmers'}
        />
      </div>
      <ModalChangeRole
        isSubGroup={true}
        row={selectedRow}
        handleFinishedChangeRole={handleFinishedChangeRole}
      />
      <Card className="border-0 shadow-sm">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={dataJoin?.items}
            // selectableRows
            onSort={handleSort}
            // onSelectedRowsChange={(e) => console.log(e)}
            progressPending={isLoadingJoin}
            responsive
            persistTableHead
            progressComponent={<Spinner />}
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada pengguna yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={dataJoin?.totalItems}
                currentPage={tag?.page}
                rowsPerPage={tag?.size ?? 10}
                setCurrentPage={(e) => {
                  dispatch(setFetchOption({ page: e }))
                }}
                setRowsPerPage={(e) => {
                  dispatch(setFetchOption({ size: e }))
                }}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <TabManageStaffGroup />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {isLoadingJoin ? (
                    <Spinner color="primary" />
                  ) : (
                    <h5 className="fw-semibold">Daftar Anggota</h5>
                  )}
                  <div>
                    <CustomButton
                      className="d-flex align-items-center gap-1"
                      color="primary"
                      onClick={() => {
                        navigate('/farmers/check', {
                          state: { name: 'Daftar Anggota' },
                        })
                      }}
                    >
                      <Plus size={14} />
                      <P size="regular">Tambah Anggota</P>
                    </CustomButton>
                  </div>
                </div>
                {dataPending?.totalItems ? (
                  <Col lg="2" md="6" sm="12" className="d-flex mb-4">
                    <Card
                      onClick={() => navigate(`/farmers/pending`)}
                      body
                      style={{ cursor: 'pointer' }}
                      className="w-100 border-0 shadow-sm"
                    >
                      <CardText tag="small" className="fw-semibold">
                        Menunggu Konfirmasi
                      </CardText>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="text-primary h3">
                          {dataPending?.totalItems}
                        </div>
                        <div>
                          <ArrowRightCircle size={20} />
                        </div>
                      </div>
                    </Card>
                  </Col>
                ) : null}

                <div className="d-flex gap-2 mb-2">
                  <FormGroup
                    style={{
                      width: '240px',
                      display: 'flex',
                      border: '1px solid #ced4da',
                      borderRadius: 8,
                    }}
                  >
                    <Input
                      placeholder="Cari anggota..."
                      onChange={handleSearchChange}
                      style={{ border: 'none', boxShadow: 'none' }}
                    />
                    <InputGroupText
                      style={{
                        backgroundColor: '#ffffff',
                        border: 'none',
                      }}
                    >
                      <Search size={15} style={{ cursor: 'pointer' }} />
                    </InputGroupText>
                  </FormGroup>
                  <FormGroup>
                    <PMBSelect
                      style={{ cursor: 'pointer' }}
                      placeholder="Semua Grup"
                      options={[
                        defaultGroupFilter,
                        ...(groups?.items || []),
                      ]?.map((el) => ({
                        ...el,
                        label: el?.name,
                        value: el?.name,
                      }))}
                      onChange={(e) => {
                        handleGroupSelect(e)
                      }}
                      value={selectedGroupFilter}
                    />
                  </FormGroup>
                </div>
                <div>
                  <Alert
                    isOpen={isOpenBanner}
                    toggle={() => dispatch(toggleBanner())}
                    color="success"
                    className="d-flex align-items-center mt-4 w-100"
                  >
                    <span>
                      <CheckCircle size={17} /> Peran berhasil diubah.
                    </span>
                  </Alert>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default StaffList
