import { useNavigate } from 'react-router-dom'
import Logo404 from 'assets/images/404-logo.png'
import CustomButton from './Buttons/CustomButton'
import { ArrowLeft } from 'react-feather'

const Page404 = () => {
  const navigate = useNavigate()
  return (
    <div className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center gap-4">
      <img src={Logo404} alt="error-image" className="img-fluid" />
      <div className="text-center">
        <h3 className="fw-bold">Halaman tidak ditemukan</h3>
        <p className="text-muted">
          Maaf, halaman yang Anda cari tidak dapat ditemukan.
        </p>
      </div>
      <div className="d-flex">
        <CustomButton
          onClick={() => navigate(-1)}
          color="secondary"
          className="btn-md mb-2 me-2"
          outline
        >
          <ArrowLeft size={20} className="me-1" />
          Kembali
        </CustomButton>
        <CustomButton
          tag="a"
          href="/"
          color="primary"
          className="btn-md mb-2 ms-2"
        >
          Beranda
        </CustomButton>
      </div>
    </div>
  )
}

export default Page404
