import barcodeMenu from 'assets/icons/qrcode.svg'

import BreadCrumbs from 'components/breadcrumbs'
import PMBPagination from 'components/PMBPagination'
import SidebarDetails from 'components/SidebarDetails'
import { TableComponent as DataTable } from 'components/TableComponent'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { ChevronDown, Eye, Search } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap'
import { URL_INVENTORY_MEMBERS } from 'utils/pathUrl'
import {
  fetchInventoryGroups,
  getInventoryMembers,
  getPackingDetail,
} from './API/manageStockAction'
import { ActionModal, DetailModal, IDStockModal } from './Components'
import './styles.css'

const InventoryMembers = ({
  isDetail,
  id,
  dataState,
  breadCrumbsData,
  onManageBreadCrumb,
}) => {
  const navigate = useNavigate()

  const intl = useIntl()
  const debounceTimer = useRef(0)
  const debounceTimerGrup = useRef(0)
  const {
    detail,
    loading: isLoading,
    warehouses,
  } = useSelector((s) => s.inventoryMembers)

  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState({
    menuOption: false,
    detail: false,
    qrCode: false,
  })
  const [selectedSort, setSelectedSort] = useState({
    type: 'updatedAt',
    mode: 'desc',
  })

  const [selectedSortGroup, setSelectedSortGroup] = useState({
    type: 'updatedAt',
    mode: 'desc',
  })

  const [isFetch, setIsFetch] = useState('')
  const [inventoryList, setInventoryList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [size, setSize] = useState(10)
  const [selectedStock, setSelectedStock] = useState(null)
  const [searchInventory, setSearchInventory] = useState('')
  const [searchGrupInventory, setSearchGrupInventory] = useState('')
  const [loading, setLoading] = useState({
    getInventory: false,
  })

  const [paginationGroup, setPaginationGroup] = useState({})

  const [page, setPage] = useState(() => {
    const state = window.history.state
    return state && state.page ? state.page : 0
  })

  const [currentGroups, setCurrentGroups] = useState([])
  const [parentGroupId, setParentGroupId] = useState(null)

  useEffect(() => {
    fetchInventory(0, 10, '', selectedSort?.type, selectedSort?.mode)
    if (id) {
      fetchGroupInventory(0, '', '', '', '', id)
    }

    if (!isDetail) {
      fetchGroupInventory(
        0,
        10,
        '',
        selectedSortGroup?.type,
        selectedSortGroup?.mode,
      )
    }

    if (isDetail && !dataState) {
      dispatch(getPackingDetail({ page: 0, id }))
    }
  }, [isDetail, warehouses, id])

  useEffect(() => {
    if (isFetch) {
      if (isFetch === 'all') {
        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
        )
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          searchInventory,
          selectedSortGroup?.type,
          selectedSortGroup?.mode,
          id,
        )
      } else if (isFetch === 'grup') {
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          searchInventory,
          selectedSortGroup?.type,
          selectedSortGroup?.mode,
          id,
        )
      } else {
        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
        )
        fetchGroupInventory(0, 10, '', '', '', parentGroupId)
      }
    }
  }, [isFetch])

  const fetchInventory = (page, size, search, sortBy, asc, groupId = id) => {
    setLoading((prevState) => ({ ...prevState, getInventory: true }))
    dispatch(
      getInventoryMembers({
        page: page,
        size: size,
        search: search,
        sortBy: sortBy,
        asc: asc,
        ...(isDetail
          ? {
              groupId: groupId,
            }
          : { groupId: 'null' }),
        all: true,
      }),
    )
      .then((res) => {
        setInventoryList(res?.payload?.items)
        setLoading((prevState) => ({ ...prevState, getInventory: false }))
        setCurrentPage(res?.payload?.currentPage)
        setTotalItems(res?.payload?.totalItems)
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, getInventory: false }))
        setIsFetch(false)
      })
  }

  // const fetchGabungInventory = (page, row) => {
  //   setLoading((prevState) => ({ ...prevState, merge: true }))
  //   dispatch(
  //     getInventoryMembers({
  //       page: page,
  //       excludeId: row?.id,
  //       all: true,
  //     }),
  //   )
  //     .then((res) => {
  //       // setInventoryGabungList(res?.payload?.items)
  //       setLoading((prevState) => ({ ...prevState, merge: false }))
  //       setPaginationGabung({
  //         totalPages: res?.payload?.totalPages,
  //         currentPage: res?.payload?.currentPage,
  //       })
  //     })
  //     .finally(() => {
  //       setLoading((prevState) => ({ ...prevState, merge: false }))
  //     })
  // }

  const fetchGroupInventory = (
    page,
    size,
    search,
    sortBy,
    asc,
    parentGroupId,
  ) => {
    setLoading((prevState) => ({ ...prevState, group: true }))
    dispatch(
      fetchInventoryGroups({
        page: page,
        size: size,
        search: search,
        sortBy: sortBy,
        asc: asc,
        parentGroupId: parentGroupId ?? '',
      }),
    )
      .then((res) => {
        setCurrentGroups(res?.payload?.items)
        setPaginationGroup((prev) => ({
          ...prev,
          totalPages: res?.payload?.totalPages,
          currentPage: res?.payload?.currentPage,
          totalItems: res?.payload?.totalItems,
        }))
        setParentGroupId(parentGroupId)
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, group: false }))
        setIsFetch(false)
      })
  }

  const handleSort = (column, direction) => {
    setSelectedSort({ type: column?.sortField, mode: direction })
    fetchInventory(0, size, searchInventory, column?.sortField, direction)
  }

  const handleSortGroup = (column, direction) => {
    setSelectedSortGroup({ type: column?.sortField, mode: direction })
    fetchGroupInventory(
      0,
      size,
      searchGrupInventory,
      column?.sortField,
      direction,
    )
  }

  const onShowHideModal = (key, value, editType, index, data) => {
    setPage((prevPage) => prevPage + 1)
    setShowModal((prevState) => ({
      ...prevState,
      menuOption: false,
      [key]: value,
    }))
  }

  const handleSearchChange = (text) => {
    setSearchInventory(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        fetchInventory(0, size, text, selectedSort?.type, selectedSort?.mode)
      } else {
        fetchInventory(0, size, '', selectedSort?.type, selectedSort?.mode)
      }
    }, 600)
  }

  const handleSearchGroupChange = (text) => {
    setSearchGrupInventory(text)
    if (debounceTimerGrup.current) {
      clearTimeout(debounceTimerGrup.current)
    }

    debounceTimerGrup.current = setTimeout(() => {
      if (text.trim() !== '') {
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          text,
          selectedSortGroup?.sortField,
          selectedSortGroup?.mode,
          id,
        )
      } else {
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          '',
          selectedSortGroup?.sortField,
          selectedSortGroup?.mode,
          id,
        )
      }
    }, 600)
  }

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault()
      if (!isDetail) {
        if (page > 0) {
          setPage((prevPage) => prevPage - 1)

          setShowModal((prevState) => {
            const updatedModalState = { ...prevState }
            Object.keys(updatedModalState).forEach((key) => {
              updatedModalState[key] = false
            })
            return updatedModalState
          })
        }
      } else {
        if (breadCrumbsData?.length > 1) {
          onManageBreadCrumb({
            label: breadCrumbsData?.[breadCrumbsData?.length - 2]?.label,
            id: breadCrumbsData?.[breadCrumbsData?.length - 2]?.id,
          })
        }
        setShowModal((prevState) => {
          const updatedModalState = { ...prevState }
          Object.keys(updatedModalState).forEach((key) => {
            updatedModalState[key] = false
          })
          return updatedModalState
        })
      }
    }
    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [page, isDetail, breadCrumbsData?.length])

  useEffect(() => {
    if (!isDetail) {
      if (page >= 0) {
        window.history.pushState({ page }, '', ``)
      }
    }
  }, [page])

  const columns = [
    {
      name: <span>Pemilik</span>,
      sortable: true,
      sortField: 'profile.name',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span>{row?.profile?.name ?? '-'}</span>
          </div>
        )
      },
    },
    {
      name: <span>Kode Inventori</span>,
      sortable: true,
      sortField: 'ownerCode',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span>{row?.ownerCode ?? '-'}</span>
          </div>
        )
      },
    },
    {
      name: <span>Nama Inventori</span>,
      sortable: true,
      sortField: 'name',
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <span>{row?.item?.nameItem || row?.label}</span>
            </div>
          </>
        )
      },
    },
    {
      name: <span>Jumlah</span>,
      sortable: true,
      sortField: 'amount',
      cell: (row) => {
        return (
          <span>
            {intl.formatNumber(row?.amount || row?.totalInventory, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}{' '}
            {row?.unit || row?.item?.unit}{' '}
          </span>
        )
      },
    },
    {
      name: <span>Gudang</span>,
      cell: (row) => {
        return <span>{row?.warehouse?.name || row?.warehouseName}</span>
      },
    },
    {
      name: <span>Terakhir diperbarui</span>,
      sortable: true,
      sortField: 'updatedAt',
      cell: (row) => {
        return (
          <span>{row?.updatedAt ? moment(row.updatedAt).fromNow() : '-'}</span>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <Eye
                size={20}
                color="#475467"
                className="button"
                onClick={() => {
                  if (row?.parentGroupId) {
                    navigate(`${URL_INVENTORY_MEMBERS}/${row?.id}`, {
                      state: row?.label,
                    })
                    setSearchInventory('')
                  } else {
                    onShowHideModal('detail', true, 'general')
                    setSelectedStock({ ...row?.item, ...row })
                  }
                }}
              />
              {row?.parentGroupId ? (
                <></>
              ) : (
                <>
                  <img
                    className="button"
                    onClick={() => {
                      onShowHideModal('qrCode', true, 'general')
                      setSelectedStock({ ...row?.item, ...row })
                    }}
                    alt="idStock"
                    src={barcodeMenu}
                  />
                </>
              )}
            </div>
          </>
        )
      },
    },
  ]

  const columns2 = [
    {
      name: <span>Pemilik</span>,
      sortable: true,
      sortField: 'profile.name',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span>{row?.profile?.name ?? '-'}</span>
          </div>
        )
      },
    },
    {
      name: <span>Nama Grup / Label</span>,
      sortable: true,
      sortField: 'label',
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <span>{row?.label}</span>
            </div>
          </>
        )
      },
    },
    {
      name: <span>Jumlah</span>,
      sortable: true,
      sortField: 'totalInventory',
      cell: (row) => {
        return (
          <span>
            {intl.formatNumber(row?.inventory?.length || row?.totalInventory, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}{' '}
            inventori
          </span>
        )
      },
    },
    // {
    //   name: <span>Gudang</span>,
    //   cell: (row) => {
    //     return <span>{row?.warehouseName}</span>
    //   },
    // },
    {
      name: <span>Terakhir diperbarui</span>,
      sortable: true,
      sortField: 'updatedAt',
      cell: (row) => {
        return (
          <span>{row?.updatedAt ? moment(row.updatedAt).fromNow() : '-'}</span>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <Eye
                size={20}
                color="#475467"
                className="button"
                onClick={() => {
                  if (row?.id && row?.label && onManageBreadCrumb) {
                    onManageBreadCrumb({
                      id: row?.id,
                      label: row?.label,
                    })
                  }
                  navigate(`${URL_INVENTORY_MEMBERS}/${row?.id}`, {
                    state: row?.label,
                  })
                  setSearchGrupInventory('')
                }}
              />
            </div>
          </>
        )
      },
    },
  ]

  return (
    <div className="w-100 px-3">
      {renderModal()}
      <>
        <div className="d-flex align-items-start gap-2 pt-3">
          {isDetail ? (
            <BreadCrumbs
              breadCrumbActive={
                breadCrumbsData ?? [
                  { label: detail?.label ?? dataState, id: id },
                ]
              }
              breadCrumbParent={'Inventori'}
              breadCrumbParentLink={URL_INVENTORY_MEMBERS}
              onClickBreadCrumb={(data) => {
                onManageBreadCrumb({
                  label: data?.label,
                  id: data?.id,
                })
                navigate(`${URL_INVENTORY_MEMBERS}/${data?.id}`, {
                  state: data?.label,
                })
                setSearchGrupInventory('')
              }}
            />
          ) : (
            <BreadCrumbs breadCrumbActive={'Inventori Anggota'} />
          )}
        </div>

        <Card className="mb-3 border-0 shadow-sm">
          <CardBody>
            <DataTable
              columns={columns}
              data={inventoryList}
              progressPending={loading?.getInventory}
              sortIcon={<ChevronDown />}
              sortServer
              onSort={handleSort}
              defaultSortFieldId={5}
              defaultSortAsc={false}
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada inventori yang ditambahkan.
                </p>
              }
              pagination
              paginationServer
              paginationComponent={(props) => (
                <PMBPagination
                  rowCount={totalItems}
                  currentPage={currentPage}
                  rowsPerPage={size ?? 10}
                  setCurrentPage={(e) => {
                    setCurrentPage(e)
                    fetchInventory(
                      e,
                      size,
                      searchInventory,
                      selectedSort?.type,
                      selectedSort?.mode,
                    )
                  }}
                  setRowsPerPage={(e) => {
                    setSize(e)
                    fetchInventory(
                      currentPage,
                      e,
                      searchInventory,
                      selectedSort?.type,
                      selectedSort?.mode,
                    )
                  }}
                  props={props}
                />
              )}
              subHeader={true}
              subHeaderComponent={
                <div className="container-fluid p-0">
                  <Row className="mb-2">
                    <Col>
                      <h5 className="fw-semibold">Inventori Anggota</h5>
                    </Col>
                  </Row>

                  <div className="d-flex gap-2 gap-md-3 mb-1 mb-md-2 flex-column flex-md-row">
                    <Col
                      style={{
                        maxWidth: '346px',
                      }}
                    >
                      <p className="mb-2">Cari</p>
                      <div className="d-flex mb-3">
                        <InputGroup style={{ borderColor: '#D0D5DD' }}>
                          <InputGroupText
                            style={{
                              backgroundColor: 'white',
                              borderRightColor: 'white',
                            }}
                          >
                            <Search color="#667085" size={16} />
                          </InputGroupText>
                          <Input
                            style={{
                              maxWidth: '100%',
                            }}
                            value={searchInventory}
                            placeholder="Cari inventori..."
                            onChange={(text) =>
                              handleSearchChange(text.target.value)
                            }
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </div>
                </div>
              }
            />
          </CardBody>
        </Card>

        <Card className="mb-3 border-0 shadow-sm">
          <CardBody>
            <Row>
              <Col>
                <h5 className="fw-semibold">Grup Inventori</h5>
              </Col>
            </Row>
            <div className="d-flex gap-2 gap-md-3 mb-1 mb-md-2 flex-column flex-md-row mt-2">
              <Col
                style={{
                  maxWidth: '346px',
                }}
              >
                <p className="mb-2">Cari</p>
                <div className="d-flex mb-3">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      style={{
                        maxWidth: '100%',
                      }}
                      value={searchGrupInventory}
                      placeholder="Cari label..."
                      onChange={(text) =>
                        handleSearchGroupChange(text.target.value)
                      }
                    />
                  </InputGroup>
                </div>
              </Col>
            </div>
            {currentGroups?.length < 1 ? (
              <span>Belum ada grup inventori anggota.</span>
            ) : (
              <div>
                <DataTable
                  columns={columns2}
                  data={currentGroups?.length ? [...currentGroups] : []}
                  progressPending={loading?.group}
                  sortServer
                  onSort={handleSortGroup}
                  defaultSortFieldId={4}
                  defaultSortAsc={false}
                  noDataComponent={
                    <p className="my-3 fw-semibold">
                      Belum ada inventori grup yang ditambahkan.
                    </p>
                  }
                  pagination
                  paginationServer
                  paginationComponent={(props) => (
                    <PMBPagination
                      rowCount={paginationGroup?.totalItems}
                      currentPage={paginationGroup?.currentPage}
                      rowsPerPage={paginationGroup?.size ?? 10}
                      setCurrentPage={(e) => {
                        setPaginationGroup((prev) => ({
                          ...prev,
                          currentPage: e,
                        }))
                        fetchGroupInventory(
                          e,
                          paginationGroup?.size,
                          searchInventory,
                          selectedSortGroup?.sortField,
                          selectedSortGroup?.mode,
                        )
                      }}
                      setRowsPerPage={(e) => {
                        setPaginationGroup((prev) => ({
                          ...prev,
                          size: e,
                        }))
                        fetchGroupInventory(
                          paginationGroup?.currentPage,
                          e,
                          searchInventory,
                          selectedSortGroup?.sortField,
                          selectedSortGroup?.mode,
                        )
                      }}
                      props={props}
                    />
                  )}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </>
    </div>
  )

  function renderModal() {
    return (
      <>
        <ActionModal
          isOpen={showModal?.menuOption}
          onClose={() => onShowHideModal('menuOption', false)}
          onSelectMenu={(item) =>
            onShowHideModal(item?.modal, true, item?.editType)
          }
        />
        <SidebarDetails
          open={showModal?.qrCode}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('qrCode', false)}
        >
          <IDStockModal
            isOpen={showModal?.qrCode}
            onClose={() => onShowHideModal('qrCode', false)}
            selectedStock={selectedStock}
          />
        </SidebarDetails>

        <SidebarDetails
          open={showModal?.detail}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('detail', false)}
        >
          <DetailModal
            onClose={() => onShowHideModal('detail', false)}
            selectedStock={selectedStock}
            onClickMenu={() => onShowHideModal('menuOption', true)}
          />
        </SidebarDetails>
      </>
    )
  }
}

export default InventoryMembers
