import {
  ARUS_KAS,
  ARUS_KAS_FORM,
  HISTORY_ARUS_KAS,
  URL_406,
  URL_AGENT_APPROVAL,
  URL_AP2HI_INSIGHT,
  URL_AP2HI_LOG,
  URL_APPROVAL_BUY,
  URL_APPROVAL_SELL,
  URL_APPROVAL_WITHDRAWAL,
  URL_BALANCE,
  URL_CATCHES,
  URL_CATCHES_DETAIL,
  URL_CHECK_FARMERS,
  URL_COMPANIES,
  URL_COMPANIES_ADD,
  URL_CONFIRM_PIN_TALANGIN,
  URL_CONFIRM_PIN_TRANSAKSI,
  URL_DETAIL_INVENTORY,
  URL_DETAIL_TRANSACTION,
  URL_DETAIL_WAREHOUSE,
  URL_FARMERS,
  URL_FARMERS_DETAIL,
  URL_FARMERS_GROUPS,
  URL_FARMERS_PENDING,
  URL_FARMERS_REGIST,
  URL_FARMERS_SUBGROUP,
  URL_FINISH_KURSUS,
  URL_FISHERIES,
  URL_FISHERIES_ADD,
  URL_FORGET_PASSWORD_STEP_1,
  URL_FORGET_PASSWORD_STEP_2,
  URL_FORM_TRANSFER_INVENTORY,
  URL_FORM_UTANG_PIUTANG,
  URL_FORM_WAREHOUSE,
  URL_GEARS,
  URL_INVENTORY,
  URL_INVENTORY_MEMBERS,
  URL_KURSUS,
  URL_KURSUS_DETAIL,
  URL_LEARNIING_REPORT,
  URL_PIUTANG,
  URL_PORTS,
  URL_TNC_PRIVATE,
  URL_TRANSFER_INVENTORY,
  URL_UTANG,
  URL_UTANG_APPROVAL,
  URL_VESSELS,
  URL_VESSELS_ADD,
  URL_VESSELS_DETAIL,
  URL_WAREHOUSE,
  URL_WHISTLE_BLOWINGS,
  URL_DETAIL_TRANSACTION_ID,
} from 'utils/pathUrl'

export const translations = {
  '/request-transaction': 'Permintaan Transaksi',
  '/transaction': 'Konfirmasi Transaksi',
  '/success-transaction': 'Konfirmasi diterima',
  '/syarat-dan-ketentuan': 'Syarat dan Ketentuan',
  '/sukses-verifikasi': 'Sukses Verifikasi',
  '/public/order': 'Permintaan Transaksi',
  '/plot/view/:code': 'Landplot',
  '/plot/view/pin/:lat/:long': 'Landplot',
  '/spatial': 'Landplot',
  [URL_KURSUS_DETAIL]: 'Detail Kursus',
  [URL_FINISH_KURSUS]: 'Finish Kursus',
  [URL_FORGET_PASSWORD_STEP_1]: 'Atur Ulang Password',
  [URL_FORGET_PASSWORD_STEP_2]: 'Atur Ulang Password',
  [URL_FORGET_PASSWORD_STEP_2]: 'Atur Ulang Password Berhasil',
  [URL_FORGET_PASSWORD_STEP_2]: 'Sambungkan Akun',
  '/public-payment': 'Pembayaran',
  '/public-payment-instruksi': 'Pembayaran instruksi',
  '/public-payment-expired': 'Pembayaran Expired',
  '/public-payment-success': 'Pembayaran Sukses',
  '/public-payment-failed': 'Pembayaran Gagal',
  '/login-v2': 'Login',
  '/pin': 'Login Pin',
  '/otp': 'Login OTP',
  '/traceability': 'Traceability',
  [URL_406]: 'Tidak Ada Akses',
  '/login': 'Login',
  '/': 'Beranda',
  '/transaksi-pembelian-tambah-komoditas': 'Pembelian',
  '/transaksi-pembelian-pembayaran': 'Pembelian Pembayaran',
  '/transaksi-pembelian-pembayaran-uang-belanja-konfirmasi':
    'Pembelian Konfirmasi Pembayaran',
  '/transaksi-pembelian-pembayaran-talangin': 'Pembelian Talangin',
  [URL_DETAIL_TRANSACTION]: 'Detail Transaksi',
  '/transaksi-pembelian-cash': 'Pembelian Tunai',
  '/transaksi-pembelian-sukses': 'Pembelian Berhasil',
  '/purchase-commodity': 'Pembelian',
  '/sell-commodity': 'Penjualan',
  '/sell-commodity-success': 'Penjualan Berhasil',
  '/topup': 'Tambah Uang Belanja',
  '/tagihan-talangin': 'Tagihan Talangin',
  '/penarikan-uang': 'Uang Usaha',
  '/perpanjang-tempo-pembayaran': 'Perpanjang Tempo Pembayaran',
  '/perpanjang-tempo-pembayaran-konfirmasi':
    'Perpanjang Tempo Pembayaran Konfirmasi',
  [URL_APPROVAL_BUY]: 'Permintaan Persetujuan Pembelian',
  [URL_APPROVAL_SELL]: 'Permintaan Persetujuan Penjualan',
  [URL_APPROVAL_WITHDRAWAL]: 'Permintaan Persetujuan Penarikan Uang',
  [URL_AGENT_APPROVAL]: 'Permintaan Menjadi Agen',
  [URL_FARMERS]: 'Daftar Anggota',
  [URL_FARMERS_REGIST]: 'Tambah Anggota Data',
  [URL_FARMERS_DETAIL]: 'Detail Anggota',
  [URL_CHECK_FARMERS]: 'Tambah Anggota',
  [URL_FARMERS_PENDING]: 'Daftar Anggota Pending',
  [URL_FARMERS_GROUPS]: 'Daftar Grup',
  [URL_FARMERS_SUBGROUP]: 'Detail Grup',
  '/pengaturan-profile': 'Profil Saya',
  '/pengaturan-perusahaan': 'Perusahaan',
  '/pengaturan-tim': 'Tim',
  '/pengaturan-password': 'Password',
  '/repo-komoditas': 'Dokumen Repo',
  '/repo-komoditas/list': 'Daftar Pengajuan Repo',
  [URL_INVENTORY]: 'Inventori',
  [URL_INVENTORY_MEMBERS]: 'Inventori Anggota',
  [URL_DETAIL_INVENTORY]: 'Detail Inventori',
  [ARUS_KAS]: 'Laporan Arus Kas',
  [HISTORY_ARUS_KAS]: 'Riwayat Arus Kas',
  [ARUS_KAS_FORM]: 'Catat Arus Kas',
  [URL_UTANG]: 'Utang',
  [URL_UTANG_APPROVAL]: 'Konfirmasi Catatan Utang',
  [URL_FORM_UTANG_PIUTANG]: 'Catat Utang Piutang',
  [URL_PIUTANG]: 'Piutang',
  [URL_TNC_PRIVATE]: 'Syarat dan Ketentuan',
  [URL_VESSELS]: 'Daftar Kapal',
  [URL_VESSELS_ADD]: 'Tambah Kapal',
  [URL_VESSELS_DETAIL]: 'Detail Vessel',
  [URL_CATCHES]: 'Daftar Perjalanan',
  [URL_CATCHES_DETAIL]: 'Detail Perjalanan',
  [URL_WHISTLE_BLOWINGS]: 'Daftar Laporan Pengaduan',
  [URL_GEARS]: 'Daftar Gears',
  [URL_PORTS]: 'Daftar Ports',
  [URL_COMPANIES]: 'Daftar Perusahaan',
  [URL_COMPANIES_ADD]: 'Tambah Perusahaan',
  [URL_FISHERIES]: 'Daftar Fisheries',
  [URL_FISHERIES_ADD]: 'Tambah Fishery',
  [URL_AP2HI_LOG]: 'Log History',
  [URL_AP2HI_INSIGHT]: 'Laporan Tangkapan',
  [URL_KURSUS]: 'Kursus',
  [URL_WAREHOUSE]: 'Gudang',
  [URL_FORM_WAREHOUSE]: 'Tambah Gudang',
  [URL_TRANSFER_INVENTORY]: 'Daftar Transfer Inventori',
  [URL_FORM_TRANSFER_INVENTORY]: 'Transfer Inventori',
  [URL_DETAIL_WAREHOUSE]: 'Detail Gudang',
  [URL_BALANCE]: 'Uang Belanja',
  [URL_LEARNIING_REPORT]: 'Laporan Pembelajaran',
  [URL_CONFIRM_PIN_TALANGIN]: 'Konfirmasi PIN Talangin',
  [URL_CONFIRM_PIN_TRANSAKSI]: 'Konfirmasi PIN Transaksi',
  [URL_DETAIL_TRANSACTION_ID]: 'Detail Transaksi',
}
