import { Button, Modal, ModalBody, ModalFooter, Progress } from 'reactstrap'

const ProgressBarExportFile = (props) => {
  const { isDialogShow, firstRows = 0, totalRows = 0, onCancel, isFull } = props

  const progress = isFull
    ? 100
    : Math.min(100, Math.floor((firstRows / totalRows) * 100))

  return (
    <Modal
      isOpen={isDialogShow}
      className="modal-lg modal-dialog-centered"
      placement="top"
      target="center"
      size="md"
    >
      <ModalBody>
        {isDialogShow && (
          <div className="mt-2">
            <div className="text-center">{progress}%</div>
            <div>
              <Progress value={progress} animated />
            </div>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={() => onCancel()}>
          Batal
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ProgressBarExportFile
