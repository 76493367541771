import IPhoto from 'assets/icons/Pak-Tani.png'
import { TableComponent as DataTable } from 'components/TableComponent'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Card, CardText } from 'reactstrap'
import SidebarTalanginInvoice from './SidebarTalanginInvoice'
import { setSelectedRow, toggleSidebarTalangin } from './talanginInvoiceSlice'

import BreadCrumbs from 'components/breadcrumbs'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import PMBSelect from 'components/PMBSelect'
import { useDebounce } from 'helpers'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { Calendar } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { Navigate } from 'react-router-dom'
import { formatDatePMB } from 'utils/formatDate'
import { URL_406 } from 'utils/pathUrl'
import { useFetchTalanginInvoiceQuery } from './talanginInvoiceApiSlice'
import { setFilter, setPage as setPageRedux, setSize } from './talanginSlice'

const TalanginInvoice = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const intl = useIntl()
  const dispatch = useDispatch()
  const talanginTable = useSelector((s) => s.talanginTable)

  let [page, setPage] = useState({ page: talanginTable?.page })
  const [searchInput, setSearchInput] = useState('')

  const dataTalangin = useFetchTalanginInvoiceQuery({
    ...talanginTable,
    pmbSearch: talanginTable.pmbSearch,
    pmbLunas: talanginTable.selectedFilter === 'paid',
    pmbBelumLunas: talanginTable.selectedFilter === 'unpaid',
    pmbDueDateToday: talanginTable.selectedFilter === 'today',
    pmbLate: talanginTable.selectedFilter === 'late',
    pmbRollover: talanginTable.selectedFilter === 'rollOver',
    selectedRepo: talanginTable.selectedRepo,
    from: talanginTable.startDate
      ? moment
          .tz(talanginTable.startDate, 'Asia/Jakarta')
          .startOf('day')
          .utc()
          .format()
      : undefined,
    to: talanginTable.endDate
      ? moment
          .tz(talanginTable.endDate, 'Asia/Jakarta')
          .endOf('day')
          .utc()
          .format()
      : undefined,
    sortBy: talanginTable.sortBy || 'duedate',
    sortDir: talanginTable.sortDir || 'ASC',
  })
  const { isOpenSidebar, selectedRow, justRolledOver, statusSelected } =
    useSelector((e) => e.talanginInvoice)

  const toggleSidebar = () => dispatch(toggleSidebarTalangin())

  const debouncedSearch = useDebounce((value) => {
    dispatch(
      setFilter({
        pmbSearch: value,
        page: 0,
        size: 10,
      }),
    )
  }, 600)

  const handleKeyDown = (e) => {
    if (e.key === ' ' && searchInput.length === 0) {
      e.preventDefault()
    }
  }

  const handleFilterChange = (filter) => {
    dispatch(
      setFilter({
        selectedFilter: filter.value,
        pmbLunas: filter.value === 'paid',
        pmbBelumLunas: filter.value === 'unpaid',
        pmbDueDateToday: filter.value === 'today',
        pmbLate: filter.value === 'late',
        pmbRollover: filter.value === 'rollOver',
        page: 0,
        size: 10,
      }),
    )
  }

  const handleRepoFilterChange = (filter) => {
    dispatch(
      setFilter({
        selectedRepo: filter.value,
        page: 0,
        size: 10,
      }),
    )
  }

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      const [start, end] = dates
      dispatch(
        setFilter({
          startDate: moment(start).tz('Asia/Jakarta').format('YYYY-MM-DD'),
          endDate: moment(end).tz('Asia/Jakarta').format('YYYY-MM-DD'),
          page: 0,
          size: 10,
        }),
      )
    }
  }

  const handleSort = (column, direction) => {
    const sortMapping = {
      sellerName: 'sellerName',
      'Total Tagihan': 'escrowpaymentAmount',
      'Jatuh Tempo': 'duedate',
      'Dibayar oleh': 'payerName',
      Status: 'updatedAt',
    }

    dispatch(
      setFilter({
        sortBy: sortMapping[column.name] || 'duedate',
        sortDir: direction.toUpperCase(),
        page: 0,
      }),
    )
  }

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(amount)
      .replace(/\./g, 'TEMP')
      .replace(/,/g, '.')
      .replace(/TEMP/g, '.')
  }

  const filterOptions = [
    { label: 'Semua', value: 'all' },
    { label: 'Jatuh Tempo Hari Ini', value: 'today' },
    { label: 'Terlambat', value: 'late' },
    { label: 'Lunas', value: 'paid' },
    { label: 'Belum Lunas', value: 'unpaid' },
    { label: 'Diperpanjang', value: 'rollOver' },
  ]

  const filterRepoOptions = [
    { label: 'Semua', value: 'all' },
    { label: 'Ya', value: 'yes' },
    { label: 'Tidak', value: 'no' },
  ]

  const conditionalRowStyles = [
    {
      when: (row) => justRolledOver === row?.id,
      style: {
        backgroundColor: '#F3FEFF',
      },
    },
  ]

  const columns = [
    {
      name: 'Penjual',
      cell: (row) => (
        <div className="flex-centered gap-2">
          <img
            src={row?.sellerAvatar ?? IPhoto}
            alt="pak tani"
            width={20}
            height={20}
            className="rounded-circle"
          />
          <span>{row?.sellerName}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Jatuh Tempo',
      cell: (row) => <span>{formatDatePMB(row?.dueDate, row?.dueDate)}</span>,
      sortable: true,
    },
    {
      name: 'Komoditas',
      cell: (row) => {
        return (
          <div className="d-flex flex-column">
            <span className="flex-row">{`${row?.escrowpayment?.order?.orderitem?.[0]?.item?.nameItem} • ${row?.escrowpayment?.order?.orderitem?.[0]?.amount} ${row?.escrowpayment?.order?.orderitem?.[0]?.unit}`}</span>
            <span className="fw-light">
              {row?.escrowpayment?.order?.orderitem?.length > 1
                ? `dan ${row?.escrowpayment?.order?.orderitem?.length - 1} komoditas lainnya`
                : ''}
            </span>
          </div>
        )
      },
    },
    {
      name: 'Total Tagihan',
      cell: (row) => {
        const totalAmount = row?.escrowpayment?.escrowcreditv2?.useFreeTx
          ? row?.escrowpayment?.escrowcreditv2?.totalVAT +
            (row?.escrowpayment?.amount +
              (row?.previousUserPinjamanId
                ? row?.escrowpayment?.escrowcreditv2?.margin2
                : row?.escrowpayment?.escrowcreditv2?.margin) ||
              row?.amountPlusInterest) +
            (row?.escrowpayment?.escrowcreditv2?.feeTx -
              row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
            (row?.partialpaymentData?.fine || row?.fineData?.totalFine)
          : row?.escrowpayment?.escrowcreditv2?.feeTxVAT +
            row?.escrowpayment?.escrowcreditv2?.totalVAT +
            (row?.escrowpayment?.amount +
              (row?.previousUserPinjamanId
                ? row?.escrowpayment?.escrowcreditv2?.margin2
                : row?.escrowpayment?.escrowcreditv2?.margin) ||
              row?.amountPlusInterest) +
            (row?.escrowpayment?.escrowcreditv2?.feeTx -
              row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
            (row?.partialpaymentData?.fine || row?.fineData?.totalFine)

        return <span>Rp {formatAmount(totalAmount)}</span>
      },
      sortable: true,
    },
    {
      name: 'Dibayar oleh',
      cell: (row) =>
        row?.pmbactions ? (
          <div className="flex-centered gap-2">
            <img
              src={row?.paidOffUserAvatar ?? IPhoto}
              alt="pak tani"
              width={20}
              height={20}
              className="rounded-circle"
            />
            <span>{row?.pmbactions?.verifiedprofile?.name ?? '-'}</span>
          </div>
        ) : (
          <>
            <div className="">-</div>
          </>
        ),
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => {
        let tdd = moment(row.lateDueDate ?? row.dueDate).tz('Asia/Jakarta')
        let diffInDays = moment().tz('Asia/Jakarta').diff(tdd, 'days')

        // Terlambat x hari:
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          diffInDays > 0
        ) {
          return (
            <div className="d-flex gap-1">
              <Badge size="sm" disabled className="badge-danger">
                Terlambat {diffInDays + 1} hari
              </Badge>
              <i
                className="bi bi-exclamation-circle-fill text-danger"
                style={{ fontSize: '10px' }}
              ></i>
            </div>
          )
        }

        // Lunas
        if (
          row.status?.toLowerCase() === 'rollover' ||
          row.status?.toLowerCase() === 'paidoff'
        ) {
          return (
            <Badge size="sm" disabled className="badge-verify">
              Lunas
            </Badge>
          )
        }

        // Jatuh Tempo Hari Ini
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          diffInDays === 0
        ) {
          return (
            <div className="d-flex gap-1">
              <Badge size="sm" className="badge-danger">
                Jatuh tempo hari ini
              </Badge>
              <i
                className="bi bi-exclamation-circle-fill text-danger"
                style={{ fontSize: '10px' }}
              ></i>
            </div>
          )
        }

        // Diperpanjang (x) hari
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          row.previousUserPinjamanId &&
          diffInDays < 0
        ) {
          return (
            <Badge size="sm" className="badge-process">
              Diperpanjang {row?.escrowpayment?.escrowcreditv2?.loanDays2} hari
            </Badge>
          )
        }

        // Belum Lunas
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          !row.previousUserPinjamanId &&
          diffInDays < 0
        ) {
          return (
            <Badge size="sm" className="badge-warning">
              Belum Lunas
            </Badge>
          )
        }
      },
      sortable: true,
    },
    {
      name: '',
      selector: (row) => (
        <div
          onClick={() => {
            toggleSidebar()
            dispatch(setSelectedRow(row))
          }}
          style={{ cursor: 'pointer' }}
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <>
      <div className="w-100 px-3">
        <SidebarTalanginInvoice
          isOpen={isOpenSidebar}
          toggle={toggleSidebar}
          row={selectedRow}
        />
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs breadCrumbActive={'Tagihan Talangin'} />
        </div>
        <Card className="border-0 shadow-sm" body>
          <CardText tag="h5" className="py-2 fw-semibold">
            Tagihan Talangin
          </CardText>
          <div className="my-4">
            <div className="d-flex flex-row gap-3">
              <PMBSearchInputGroup
                label={'Cari'}
                value={searchInput}
                placeholder="Cari..."
                onChange={(e) => onHandleSearchChange(e.target.value)}
                maxWidth="100%"
                onKeyDown={handleKeyDown}
              />
              <div>
                <p className="mb-2">Tanggal</p>
                <div className="d-flex align-items-center justify-content-center border rounded">
                  <Calendar size={17} className="ms-3" />
                  <Flatpickr
                    options={{
                      mode: 'range',
                      dateFormat: 'd-m-Y',
                      defaultDate: [
                        moment(talanginTable.startDate, 'YYYY-MM-DD').toDate(),
                        moment(talanginTable.endDate, 'YYYY-MM-DD').toDate(),
                      ],
                      onClose: handleDateChange,
                      maxDate: moment().format('YYYY-MM-DD'),
                    }}
                    className="form-control flat-picker bg-transparent border-0 shadow-none"
                  />
                </div>
              </div>
              <div style={{ width: '250px' }}>
                <p className="mb-1">Jenis tagihan</p>
                <PMBSelect
                  style={{ width: '200px' }}
                  isSearchable={false}
                  onChange={handleFilterChange}
                  options={filterOptions}
                  value={
                    filterOptions.find(
                      (opt) => opt.value === talanginTable.selectedFilter,
                    ) || filterOptions[0]
                  }
                  placeholder="Pilih "
                />
              </div>

              <div style={{ width: '250px' }}>
                <p className="mb-1">Repo</p>
                <PMBSelect
                  style={{ width: '200px' }}
                  isSearchable={false}
                  onChange={handleRepoFilterChange}
                  options={filterRepoOptions}
                  value={
                    filterRepoOptions.find(
                      (opt) => opt.value === talanginTable.selectedRepo,
                    ) || filterRepoOptions[0]
                  }
                  placeholder="Pilih "
                />
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={dataTalangin?.data?.items}
            progressPending={
              dataTalangin?.isLoading || dataTalangin?.isFetching
            }
            conditionalRowStyles={conditionalRowStyles}
            sortServer
            onSort={(column, direction) => handleSort(column, direction)}
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={dataTalangin?.data?.totalItems}
                currentPage={talanginTable?.page}
                rowsPerPage={talanginTable?.size}
                setCurrentPage={(e) => {
                  dispatch(
                    setPageRedux({
                      page: e,
                    }),
                  )
                }}
                setRowsPerPage={(e) => {
                  dispatch(
                    setSize({
                      size: e,
                    }),
                  )
                }}
                props={props}
              />
            )}
          />
        </Card>
      </div>
    </>
  )
}

export default TalanginInvoice
