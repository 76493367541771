import Logo from 'assets/images/pmg-logo-beta.png'
import { ClientJS } from 'clientjs'
import { P } from 'components'
import CustomButton from 'components/Buttons/CustomButton'
import { checkInputType, validateEmail } from 'helpers'
import useEnterSubmit from 'hooks/useEnterSubmit'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { Sheet } from 'react-modal-sheet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Modal, Spinner } from 'reactstrap'
import { URL_LOGIN_PASSWORD } from 'utils/pathUrl'
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid'
import {
  useCreateDeviceMutation,
  useLazyCheckDeviceV1Query,
  useLazyCheckDeviceV2Query,
  usePrecheckLoginMutation,
  useSendOtpLoginMutation,
} from '../login-new/loginNewApiSlice'
import './login-universal.css'
import Tnc from './Tnc'

function ModalContent({
  rightLabel,
  leftLabel,
  onPressRight,
  onPressLeft,
  loading,
  isEmail,
}) {
  return (
    <div>
      <P bold size="medium">
        {isEmail ? 'Email' : 'Nomor'} belum terdaftar
      </P>
      <P style={{ marginTop: 10 }}>
        Daftarkan akun Anda dengan {isEmail ? 'email' : 'nomor'} ini?
      </P>
      {leftLabel ? (
        <div className="d-flex gap-2 mt-4">
          <CustomButton
            onClick={onPressLeft}
            color="secondary"
            className="btn-sm-block me-1 w-100"
            outline
            style={{ borderColor: '#D0D5DD' }}
            disabled={loading}
          >
            {leftLabel}
          </CustomButton>
          <CustomButton
            color="primary"
            className="btn-sm-block ms-1 w-100"
            onClick={onPressRight}
            disabled={loading}
          >
            {rightLabel}
          </CustomButton>
        </div>
      ) : (
        <Button
          className="w-100 mt-4"
          color="primary"
          onClick={onPressRight}
          disabled={loading}
        >
          <P>{rightLabel}</P>
        </Button>
      )}
    </div>
  )
}

function ResponsiveModal({
  isMobile,
  onPressRight,
  isOpen,
  onPressLeft,
  loading,
  isEmail,
}) {
  return isMobile ? (
    <Sheet isOpen={isOpen} snapPoints={[220, 0]} onClose={onPressLeft}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content style={{ padding: 15 }}>
          <ModalContent
            rightLabel={'Daftar sekarang'}
            onPressRight={onPressRight}
            loading={loading}
            isEmail={isEmail}
          />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onPressLeft} />
    </Sheet>
  ) : (
    <Modal
      isOpen={isOpen}
      backdrop="static"
      centered
      style={{ borderRadius: 90, border: 'none' }}
    >
      <div style={{ padding: 20 }}>
        <ModalContent
          rightLabel={'Daftar'}
          leftLabel={'Kembali'}
          onPressRight={onPressRight}
          onPressLeft={onPressLeft}
          loading={loading}
          isEmail={isEmail}
        />
      </div>
    </Modal>
  )
}

const Login = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const params = new URLSearchParams(window.location.search)
  const { token } = useSelector((s) => s.auth)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [preCheck, { isLoading: isLoadingPreCheck }] =
    usePrecheckLoginMutation()
  const [sendOtp, { isLoading: isLoadingSendOTP }] = useSendOtpLoginMutation()
  const [suggestions, setSuggestions] = useState([])
  const [isShowRegistModal, setShowRegistModal] = useState({
    modal: false,
    otp: false,
  })
  const [payload, setPayload] = useState({})
  const [userData, setUserData] = useState({})
  const [isFetchingOtp, setIsFetchingOtp] = useState(false)

  const [isMobile, setIsMobile] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const searchParams = new URLSearchParams(window.location.search)

  const getFromLocalStorage = (key, paramKey) => {
    const paramValue = searchParams.get(paramKey)
    if (paramValue) {
      localStorage.setItem(key, paramValue)
      return paramValue
    }
    return localStorage.getItem(key)
  }

  const [hash, setDeviceHash] = useState(() =>
    getFromLocalStorage('PMB_deviceHash', 'deviceHash'),
  )
  const [fcmToken, setFcmToken] = useState(() =>
    getFromLocalStorage('PMB_fcmToken', 'fcmToken'),
  )
  const [mobileVersion, setMobileVersion] = useState(() =>
    getFromLocalStorage('PMB_mobileVersion', 'mobileVersion'),
  )

  useEffect(() => {
    setDeviceHash(getFromLocalStorage('PMB_deviceHash', 'deviceHash'))
    setFcmToken(getFromLocalStorage('PMB_fcmToken', 'fcmToken'))
    setMobileVersion(getFromLocalStorage('PMB_mobileVersion', 'mobileVersion'))
  }, [])

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches)
    }

    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  const [createDevice, resultCreateDevice] = useCreateDeviceMutation()
  const [checkDeviceV1] = useLazyCheckDeviceV1Query()
  const [checkDeviceV2] = useLazyCheckDeviceV2Query()

  const publicVersion = useSelector((s) => s.publicVersion)
  const noCsPasmik = publicVersion?.data?.csNumber

  useEffect(() => {
    fetchClientData()
  }, [])

  const [identityList, setIdentityList] = useState([])

  useEffect(() => {
    const storedNumbers = JSON.parse(localStorage.getItem('identity')) || []
    setIdentityList(storedNumbers)
  }, [])

  const savePhoneNumberToLocalStorage = (number) => {
    let storedNumbers = JSON.parse(localStorage.getItem('identity')) || []

    storedNumbers = storedNumbers.filter((num) => num !== number)
    storedNumbers.unshift(number)

    if (storedNumbers.length > 3) {
      storedNumbers = storedNumbers.slice(0, 3)
    }

    localStorage.setItem('identity', JSON.stringify(storedNumbers))
    setIdentityList(storedNumbers)
  }

  const fetchOtp = async (status) => {
    try {
      setIsFetchingOtp(true)

      const res5 = await sendOtp({
        identity: payload?.identity,
        deviceHash: hash ? hash : null,
        type: status,
        channel: validateEmail(payload?.identity) ? 'EMAIL' : 'WHATSAPP',
        browserHash: userData?.browserHash,
        browserFingerPrint: userData?.browserFingerPrint,
        browserCustomFingerPrint: userData?.browserCustomFingerPrint,
      })

      if (res5?.data?.meta?.error || res5?.error?.data?.meta?.error) {
        enqueueSnackbar(
          res5?.data?.meta?.message ??
            res5?.data?.message ??
            res5?.error?.data?.meta?.message ??
            'Terjadi kesalahan.',
          {
            variant: 'error',
          },
        )
        return
      } else {
        navigate('/otp', {
          state: {
            type: validateEmail(payload?.identity) ? 'email' : 'phone',
            identity: payload?.identity,
            deviceHash: hash ? hash : null,
            countDown: res5?.data?.data?.expireTime,
            browserHash: userData?.browserHash,
            browserFingerPrint: userData?.browserFingerPrint,
            browserCustomFingerPrint: userData?.browserCustomFingerPrint,
            status: status,
            fcmToken: fcmToken ?? '',
            mobileVersion: mobileVersion ?? '',
          },
        })
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar(
        error?.data?.meta?.message ??
          error?.data?.message ??
          error?.error?.data?.meta?.message ??
          'Terjadi kesalahan.',
        {
          variant: 'error',
        },
      )
    } finally {
      setIsFetchingOtp(false)
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await preCheck({
        identity: payload?.identity,
        ...extractUserData(userData),
      })

      if (res.error) throw res
      setUserData((prevState) => ({ ...prevState, ...res?.data?.data }))
      savePhoneNumberToLocalStorage(payload?.identity)
      handleNavigation(res?.data?.data)
    } catch (error) {
      handleError(error)
    }
  }

  useEnterSubmit(handleSubmit, !payload?.identity)

  const handleNavigation = (data) => {
    if (data?.sendOtp && data?.allow) {
      data?.status === 'REGISTERED'
        ? navigate(URL_LOGIN_PASSWORD, {
            state: {
              type: validateEmail(payload?.identity) ? 'email' : 'phone',
              identity: payload?.identity,
              deviceHash: hash || null,
              fcmToken: fcmToken ?? '',
              mobileVersion: mobileVersion ?? '',
              ...extractUserData(userData),
              ...data,
            },
          })
        : setShowRegistModal({ modal: true, otp: true })
    } else if (!data?.sendOtp && !data?.sendDeviceInfo && data?.allow) {
      navigate(
        data?.status === 'REGISTERED' ? URL_LOGIN_PASSWORD : '/register',
        {
          state: {
            type: validateEmail(payload?.identity) ? 'email' : 'phone',
            identity: payload?.identity,
            deviceHash: hash || null,
            fcmToken: fcmToken ?? '',
            mobileVersion: mobileVersion ?? '',
            ...extractUserData(userData),
            ...data,
          },
        },
      )
    }
  }

  const extractUserData = (userData) => ({
    browserHash: userData?.browserHash,
    browserFingerPrint: userData?.browserFingerPrint,
    browserCustomFingerPrint: userData?.browserCustomFingerPrint,
  })

  const handleError = (error) => {
    fetchClientData()
    console.error(error)
    enqueueSnackbar(
      error?.error?.data?.meta?.message ??
        error?.data?.meta?.message ??
        error?.data?.message ??
        'Terjadi kesalahan.',
      {
        variant: 'error',
      },
    )
  }

  const fetchClientData = async () => {
    const client = new ClientJS()
    const clientData = getClientData(client)
    clientData.hash = generateHash(clientData)
    await handleCheckDevice(clientData)
  }

  const getClientData = (client) => {
    const methods = Object.getOwnPropertyNames(
      Object.getPrototypeOf(client),
    ).filter(
      (method) =>
        typeof client[method] === 'function' && method !== 'constructor',
    )

    const keysToRemove = new Set([
      'getCPU',
      'getBrowserVersion',
      'getUserAgentLowerCase',
      'isMobileIOS',
      'isIphone',
      'getDeviceType',
      'getUserAgent',
      'isChrome',
      'isFirefox',
      'isIphone',
      'isIpod',
      'isMac',
      'isMobile',
      'isMobileBlackBerry',
      'isMobileMajor',
      'isMobileOpera',
      'isMobileSafari',
      'isMobileWindows',
      'isOpera',
      'isSafari',
      'isUbuntu',
      'isWindows',
      'isIpad',
      'isJava',
      'isLinux',
      'isSolaris',
      'isIE',
      'isMobileAndroid',
      'isFont',
    ])

    return methods.reduce((acc, method) => {
      if (!keysToRemove.has(method)) {
        try {
          acc[method] = client[method]()
        } catch (error) {
          acc[method] = `Error: ${error.message}`
        }
      }
      return acc
    }, {})
  }

  const generateHash = (data) => {
    const combinedFingerprint = `${data.fingerprint}${data.customFingerprint}`
    return uuidv5(combinedFingerprint, uuidv4()).replace(/-/g, '')
  }

  const handleCheckDevice = async (data) => {
    let storedData = getStoredBrowserData()
    if (!storedData.browserHash) {
      storedData = setNewBrowserData(data)
    }

    try {
      const res = hash
        ? await checkDeviceV1({ deviceHash: hash })
        : await checkDeviceV2(storedData)
      if (isMatchingDevice(res?.data?.data, storedData)) {
        setUserData(res?.data?.data)
      } else {
        const resCreate = await createDevice({
          ...storedData,
          deviceToken: data?.hash,
          deviceInfo: { browserInfo: data },
          ...(hash && { deviceHash: hash }),
        })
        setUserData(resCreate?.data?.data)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const getStoredBrowserData = () => ({
    browserHash: localStorage.getItem('browserHash') || null,
    browserFingerPrint: localStorage.getItem('browserFingerPrint') || null,
    browserCustomFingerPrint:
      localStorage.getItem('browserCustomFingerPrint') || null,
  })

  const setNewBrowserData = (data) => {
    const newHash = uuidv4().replace(/-/g, '')
    localStorage.setItem('browserHash', newHash)
    localStorage.setItem('browserFingerPrint', data?.getFingerprint)
    localStorage.setItem('browserCustomFingerPrint', data?.getCustomFingerprint)
    return {
      browserHash: newHash,
      browserFingerPrint: data?.getFingerprint,
      browserCustomFingerPrint: data?.getCustomFingerprint,
    }
  }

  const isMatchingDevice = (serverData, localData) => {
    return (
      serverData?.browserCustomFingerPrint ===
        localData?.browserCustomFingerPrint &&
      serverData?.browserFingerPrint === localData?.browserFingerPrint &&
      serverData?.browserHash === localData?.browserHash
    )
  }

  useEffect(() => {
    const storedNumbers = JSON.parse(localStorage.getItem('phoneNumbers')) || []
    setSuggestions(storedNumbers)
  }, [])

  useEffect(() => {
    if (token) {
      // dispatch(setIsV3(true))
      window.location.replace('/')
    }
  }, [token])

  const inputRef = useRef(null)
  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 100)
  }, [])

  useEffect(() => {
    setIsEmail(checkInputType(payload?.identity))
  }, [payload?.identity])

  useEffect(() => {
    if (location?.pathname === '/login') {
      navigate('/login', { replace: true })
    }
  }, [])

  return (
    <div className="centered-container">
      {/* Konten Utama */}
      <div
        className="w-100 card-wrapper"
        style={{
          borderRadius: 10,
          maxWidth: '600px',
        }}
      >
        <img
          src={Logo}
          alt="Logo PasarMikro"
          style={{
            marginBottom: 24,
            maxWidth: '177px',
            height: 'auto',
          }}
        />
        <P>Masuk atau daftar di sini</P>
        <div style={{ marginTop: '20px' }}>
          <P size="regular">Email atau nomor telepon</P>
          <Input
            innerRef={inputRef}
            className="mt-2"
            placeholder="Masukkan email atau nomor telepon"
            autoComplete="username"
            style={{ fontSize: '14px' }}
            onChange={(e) => {
              setPayload((prevState) => ({
                ...prevState,
                ['identity']: e?.target?.value,
              }))
            }}
            value={payload?.identity}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setTimeout(() => setIsFocused(false), 200)}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                payload?.identity &&
                !isLoadingPreCheck &&
                !isLoadingSendOTP
              ) {
                handleSubmit()
              }
            }}
          />

          {isFocused && identityList.length > 0 && (
            <div className="suggestion-container">
              {identityList.map((suggestion, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setPayload((prevState) => ({
                      ...prevState,
                      identity: suggestion,
                    }))
                  }}
                >
                  <p className="suggestion-item">{suggestion}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-100 mt-3">
          <Button
            disabled={
              !payload?.identity || isLoadingPreCheck || isLoadingSendOTP
            }
            className="w-100 d-flex justify-content-center align-items-center"
            color="primary"
            onClick={handleSubmit}
          >
            {(isLoadingPreCheck || isLoadingSendOTP) && (
              <Spinner className="me-2" size="sm" color="light" />
            )}
            <P size="regular">Masuk</P>
          </Button>
        </div>
        <div className="d-flex gap-2 mt-3" style={{ justifyContent: 'center' }}>
          <P size="regular">Butuh bantuan? </P>
          <div style={{ cursor: 'pointer' }}>
            <P
              size="regular"
              color={'#006386'}
              onClick={() => {
                if (noCsPasmik) {
                  window.open(`https://wa.me/${noCsPasmik}`, '_blank')
                }
              }}
              style={{ cursor: 'pointer' }}
              disabled={!noCsPasmik}
            >
              Hubungi kami
            </P>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer
        className="w-100 text-center border border-bottom-0"
        style={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Tnc />
      </footer>
      {renderModal()}
    </div>
  )

  function renderModal() {
    return (
      <>
        <div>
          <ResponsiveModal
            isOpen={isShowRegistModal?.modal}
            isMobile={isMobile}
            onPressRight={() => {
              fetchOtp(userData?.status === 'LOGIN' ? 'login' : 'registration')
            }}
            loading={isFetchingOtp}
            onPressLeft={() => setShowRegistModal({ modal: false })}
            isEmail={isEmail}
          />
        </div>
      </>
    )
  }
}

export default Login
