import { P } from 'components'
import { useDebounce } from 'helpers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Spinner } from 'reactstrap'
import { useLazyFindAddressBookQuery } from '../addressBookApiSlice'
import { setIsModalOpenAddAddressBook } from '../addressBookSlice'
import './styles.scss'

const InputAddressBook = ({
  title = 'Penjual',
  placeholderDesc = 'Cari nama atau nomor handphone dari daftar kontak Anda',
  isRequired = false,
  isPhoneUnRegistered = false,
  isPhoneRegistered = false,
  isEmailUnRegistered = false,
  isEmailRegistered = false,
  isPhoneVerified = false,
  isEmailVerified = false,
  onSelectContact,
  isUppercaseValue,
  fieldName,
  invalid,
  onClickAdd,
  editValue,
  isVessel,
  onFetchingChange,
  innerRef,
}) => {
  const dispatch = useDispatch()
  const { isOpenSelectionAddressBook, searchAddressBook } = useSelector(
    (s) => s.addressBook,
  )
  const { isModalOpenAddAddressBook } = useSelector((s) => s.addressBook)
  const [isOpenSelection, setIsOpenSelection] = useState(false)
  const [localSearch, setLocalSearch] = useState('')
  const [addressBookData, setAddressBookData] = useState([])
  const [isFetchingLocal, setIsFetchingLocal] = useState(false)
  const [lastActiveField, setLastActiveField] = useState(null)

  const [fetchAddressBook] = useLazyFindAddressBookQuery()

  const debouncedSearch = useDebounce((value) => {
    const trimmedValue = value.trim()
    if (trimmedValue) {
      setIsFetchingLocal(true)
      fetchAddressBook({
        s: trimmedValue,
        ...(isPhoneUnRegistered && { isPhoneUnRegistered: true }),
        ...(isPhoneRegistered && { isPhoneRegistered: true }),
        ...(isEmailUnRegistered && { isEmailUnRegistered: true }),
        ...(isEmailRegistered && { isEmailRegistered: true }),
        ...(isPhoneVerified && { isPhoneVerified: true }),
        ...(isEmailVerified && { isEmailVerified: true }),
      }).then((response) => {
        setAddressBookData(response?.data?.items || [])
        setIsFetchingLocal(false)
        setIsOpenSelection(true)
      })
    } else {
      setIsOpenSelection(false)
      setAddressBookData([])
    }
  }, 600)

  const handleManualChange = (e) => {
    const value = e.target.value
    setLocalSearch(value)

    if (onSelectContact) {
      onSelectContact({}, fieldName)
    }

    if (value.length > 2) {
      debouncedSearch(value)
    } else {
      setIsOpenSelection(false)
      setAddressBookData([])
    }
  }

  const handleSelectContact = (contact) => {
    const relationName = isUppercaseValue
      ? contact?.relationName?.toUpperCase()
      : contact?.relationName

    const displayText = contact?.relationPhoneNumber
      ? `${relationName} (${contact?.relationPhoneNumber})`
      : contact?.relationEmail
        ? `${relationName} (${contact?.relationEmail})`
        : relationName

    setLocalSearch(displayText)
    setIsOpenSelection(false)

    if (onSelectContact) {
      onSelectContact(contact, fieldName)
    }
  }

  const handleInitialFetch = () => {
    setLastActiveField(fieldName)
    // if (!editValue) { // FOR AT THE MOMENT COMMENT THIS HANDLE
    setIsFetchingLocal(true)
    fetchAddressBook({
      s: '',
      size: 10,
      page: 0,
      sortby: 'createdAt',
      sortdir: 'desc',
      ...(isPhoneUnRegistered && { isPhoneUnRegistered }),
      ...(isPhoneRegistered && { isPhoneRegistered }),
      ...(isEmailUnRegistered && { isEmailUnRegistered }),
      ...(isEmailRegistered && { isEmailRegistered }),
      ...(isPhoneVerified && { isPhoneVerified }),
      ...(isEmailVerified && { isEmailVerified }),
    }).then((response) => {
      setAddressBookData(response?.data?.items || [])
      setIsFetchingLocal(false)
      setIsOpenSelection(true)
    })
    // }
  }

  useEffect(() => {
    return () => {
      setLocalSearch('')
      setAddressBookData([])
      setLastActiveField(null)
    }
  }, [])

  useEffect(() => {
    if (editValue) {
      setLocalSearch(editValue)
    }
  }, [editValue])

  useEffect(() => {
    if (onFetchingChange) {
      onFetchingChange(isFetchingLocal)
    }
  }, [isFetchingLocal, onFetchingChange])

  useEffect(() => {
    if (!isModalOpenAddAddressBook && lastActiveField === fieldName) {
      handleInitialFetch()
    }
  }, [isModalOpenAddAddressBook, fieldName, lastActiveField])

  const toggleOpenCloseAddContact = () => {
    dispatch(setIsModalOpenAddAddressBook(true))
  }

  return (
    <div style={{ position: 'relative' }}>
      <P size={isVessel ? 'regular' : 'default'} className="mb-2">
        {title}
        {isRequired && <span className="text-danger">*</span>}
      </P>

      <Input
        type="text"
        value={localSearch}
        onChange={handleManualChange}
        placeholder={placeholderDesc}
        onFocus={handleInitialFetch}
        onBlur={() => setTimeout(() => setIsOpenSelection(false), 200)}
        invalid={invalid}
        innerRef={innerRef}
      />
      {isOpenSelection && (
        <div
          style={{
            position: 'absolute',
            background: 'white',
            width: '100%',
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            zIndex: 10,
            marginTop: '4px',
          }}
        >
          <div
            style={{
              maxHeight: '250px',
              overflowY: 'auto',
            }}
          >
            {isFetchingLocal ? (
              <div
                style={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100px',
                }}
              >
                <Spinner size="sm" color="primary" />
              </div>
            ) : (
              <>
                {addressBookData?.length > 0 ? (
                  addressBookData
                    ?.filter((e) => e?.relationPhoneNumber || e?.relationEmail)
                    ?.map((contact, index) => (
                      <div
                        key={index}
                        className="contact-item p-2 d-flex flex-column"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSelectContact(contact)}
                      >
                        <P color="#101828">{contact?.relationName}</P>
                        <P color="#667085">
                          {contact?.relationPhoneNumber
                            ? contact?.relationPhoneNumber
                            : contact?.relationEmail
                              ? contact?.relationEmail
                              : ''}
                        </P>
                      </div>
                    ))
                ) : (
                  <div
                    style={{
                      padding: '8px',
                      color: '#667085',
                    }}
                  >
                    Pencarian tidak ditemukan
                  </div>
                )}
              </>
            )}
          </div>
          {!isFetchingLocal && (
            <div
              style={{
                padding: '8px',
                cursor: 'pointer',
                color: '#006386',
                background: 'white',
              }}
              onClick={() => {
                toggleOpenCloseAddContact()
                onClickAdd(localSearch)
              }}
            >
              + Tambah "{localSearch}" ke daftar kontak
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default InputAddressBook
