import { Input } from 'reactstrap'

const PMBInputAmount = ({ value = '', onChange, name, placeholder }) => (
  <Input
    type="text"
    value={value}
    onChange={onChange}
    name={name}
    placeholder={placeholder}
  />
)

export default PMBInputAmount
