import React, { useState } from 'react'
import './modal.css'
import FullModal from 'components/Modal/FullModal'
import { P } from 'components'
import Logo from 'assets/images/pmg-logo-beta.png'
import { Button, FormGroup, Input, InputGroupText, Spinner } from 'reactstrap'
import { passwordRequirements } from 'constants'
import { Circle, Eye, EyeOff } from 'react-feather'
import IsCheckCircle from 'assets/icons/ic_check_circle.svg'
import { useSelector } from 'react-redux'

export default function SetPasswordModal({
  isOpen,
  onHandleSetPassword,
  isLoading,
  isError,
  errorMsg,
  onClose,
}) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordRequirementsData, setPasswordRequirementsData] = useState(
    passwordRequirements.map((item) => ({ ...item, checked: false })),
  )

  const publicVersion = useSelector((s) => s.publicVersion)
  const noCsPasmik = publicVersion?.data?.csNumber

  if (!isOpen) return null

  const handlePasswordChange = (e) => {
    const value = e.target.value
    setPassword(value)

    const updatedRequirements = passwordRequirements.map((req) => {
      let isChecked = false
      switch (req.id) {
        case 1:
          isChecked = /[a-z]/.test(value)
          break
        case 2:
          isChecked = /[A-Z]/.test(value)
          break
        case 3:
          isChecked = value.length >= 8
          break
        case 4:
          isChecked = /[0-9]/.test(value)
          break
        case 5:
          isChecked = /[^a-zA-Z0-9]/.test(value)
          break
        default:
          break
      }
      return { ...req, checked: isChecked }
    })

    setPasswordRequirementsData(updatedRequirements)
  }

  return (
    <FullModal isOpen={true} title={'Buat password'}>
      {/* <div className="register-container"> */}
      <div className="card-wrapper-password">
        <img src={Logo} alt="Logo PasarMikro" className="logo-pm" />
        <P bold>Buat password Anda</P>
        {errorMsg ? (
          <div>
            <P color={'#E04246'}>{errorMsg}</P>
          </div>
        ) : null}

        <div className="mt-4">
          <P size="regular">Password</P>
          <FormGroup className="input-group mt-1">
            <Input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Masukkan password"
              className="password-input"
            />
            <InputGroupText
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeOff size={15} style={{ cursor: 'pointer' }} />
              ) : (
                <Eye size={15} style={{ cursor: 'pointer' }} />
              )}
            </InputGroupText>
          </FormGroup>
        </div>
        <div className="mt-2">
          <P size="regular">Konfirmasi password</P>
          <FormGroup className="input-group mt-1">
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Masukkan password"
              className="password-input"
            />
            <InputGroupText
              className="toggle-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <EyeOff size={15} style={{ cursor: 'pointer' }} />
              ) : (
                <Eye size={15} style={{ cursor: 'pointer' }} />
              )}
            </InputGroupText>
          </FormGroup>
        </div>
        <div className="mt-3">
          <P size="regular" bold>
            Password harus terdiri dari setidaknya
          </P>
        </div>
        <div className="mt-2">
          {passwordRequirementsData.map((el) => {
            return (
              <div className="d-flex align-items-center gap-2 mb-2" key={el.id}>
                {el.checked ? (
                  <img
                    src={IsCheckCircle}
                    width={10}
                    height={10}
                    alt={`checked-${el.label}`}
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  <Circle size={20} />
                )}
                <P size="regular" color="#344054">
                  {el.label}
                </P>
              </div>
            )
          })}
        </div>
        <div className="w-100 mt-4">
          <div className="btn-lanjut">
            <Button
              onClick={() => {
                onHandleSetPassword({ password })
              }}
              color="primary"
              className="w-100 d-flex justify-content-center align-items-center"
              disabled={
                !passwordRequirementsData.every((req) => req.checked) ||
                !password ||
                !confirmPassword ||
                password !== confirmPassword ||
                isLoading
              }
            >
              {isLoading ? (
                <Spinner className="me-2" size="sm" color="light" />
              ) : null}
              <P>Lanjut</P>
            </Button>
            <Button
              onClick={onClose}
              style={{ borderColor: '#D0D5DD' }}
              color="neutral"
              className="w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <P>Kembali</P>
            </Button>
            {isError ? (
              <Button
                disabled={isLoading}
                className="mt-2 w-100 d-flex justify-content-center align-items-center"
                color="transparent"
                onClick={() =>
                  window.open(`https://wa.me/${noCsPasmik}`, '_blank')
                }
              >
                {isLoading ? (
                  <Spinner className="me-2" size="sm" color="light" />
                ) : null}
                <P color={'#006386'}>Hubungi PasarMIKRO</P>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      {/* </div> */}
    </FullModal>
  )
}
