import { FormattedNumber } from 'react-intl'
import { Table } from 'reactstrap'

const ExpandableComponent2 = ({ data }) => {
  return (
    <div className="p-3">
      {data ? (
        <Table size="sm" className="table-hover text-center table-bordered">
          <thead>
            <tr>
              <th className="text-start" scope="col">
                <small>Judul</small>
              </th>
              <th className="text-start" scope="col">
                <small>Deskripsi</small>
              </th>
              <th className="text-end" scope="col">
                <small>Kemajuan</small>
              </th>
              <th className="text-start" scope="col">
                <small>Status</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.tutorialcontent.length > 0
              ? data?.tutorialcontent?.map((e, i) => (
                  <tr key={e.id}>
                    <th className="text-start">
                      <small>{e?.title}</small>
                    </th>
                    <td className="text-start">
                      <small>{e?.description}</small>
                    </td>
                    <td className="text-end">
                      <small>
                        {e?.finishedAt ? (
                          <small>100%</small>
                        ) : (
                          <div>
                            <FormattedNumber
                              value={(() => {
                                const watched = Number(e?.watchedDuration)
                                const content = Number(e?.contentDuration)

                                if (
                                  isNaN(watched) ||
                                  isNaN(content) ||
                                  content <= 0
                                ) {
                                  return 0
                                }

                                return Math.min(watched / content, 1) * 100
                              })()}
                              useGrouping="always"
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                            %
                          </div>
                        )}
                      </small>
                    </td>
                    <td className="text-start">
                      {e?.contentDuration === e?.watchedDuration ||
                      e?.finishedAt ? (
                        <small className="badge rounded-pill bg-success-50 text-success">
                          selesai
                        </small>
                      ) : (
                        <small className="badge rounded-pill bg-warning-50 text-warning-700">
                          belum selesai
                        </small>
                      )}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ExpandableComponent2
