import { P } from 'components'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Input, Modal, ModalBody, Spinner } from 'reactstrap'
import {
  useAddAddressBookMutation,
  useLazyFindAddressBookQuery,
  useUpdateAddressBookMutation,
} from './addressBookApiSlice'
import { setIsOpenSelectionAddressBook } from './addressBookSlice'

const ModalAddContact = ({
  isOpen,
  toggle,
  isLoading = false,
  dataAddressBookById = null,
  refetch,
  isOutsideAddressBook = false,
  isPhoneUnRegistered = false,
  isPhoneRegistered = false,
  isEmailUnRegistered = false,
  isEmailRegistered = false,
  isPhoneVerified = false,
  isEmailVerified = false,
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { searchAddressBook } = useSelector((s) => s.addressBook)

  const [addAddressBook, { isLoading: isAdding }] = useAddAddressBookMutation()
  const [updateAddressBook, { isLoading: isUpdating }] =
    useUpdateAddressBookMutation()

  const [findAddressBook] = useLazyFindAddressBookQuery()

  const inputRef = useRef(null)

  const isEditMode = Boolean(dataAddressBookById)

  const [payload, setPayload] = useState({
    relationPhoneNumber: null,
    relationEmail: null,
    relationName: null,
    relationInstitution: null,
  })

  // HANDLE EDIT AND SET VALUE
  useEffect(() => {
    if (isEditMode) {
      setPayload({
        relationPhoneNumber: dataAddressBookById?.relationPhoneNumber || null,
        relationEmail: dataAddressBookById?.relationEmail || null,
        relationName: dataAddressBookById?.relationName || null,
        relationInstitution: dataAddressBookById?.relationInstitution || null,
      })
    } else if (isOutsideAddressBook) {
      const isEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(searchAddressBook)
      const isPhoneNumber = /^(\+?\d+)$/.test(searchAddressBook)
      const isTextOnly = /^[a-zA-Z ]+$/.test(searchAddressBook)

      // HANDLE FORMAT "Nama (Nomor)"
      const nameWithPhonePattern = /^(.+)\s\((\+?\d+)\)$/
      const nameWithPhoneMatch = searchAddressBook.match(nameWithPhonePattern)

      // HANDLE FORMAT "Nama (Email)"
      const nameWithEmailPattern = /^(.+)\s\(([\w-.]+@[\w-]+\.[a-z]{2,4})\)$/
      const nameWithEmailMatch = searchAddressBook.match(nameWithEmailPattern)

      // HANLE FORMAT "Nomor (Email)"
      const phoneWithEmailPattern =
        /^(\+?\d+)\s\(([\w-.]+@[\w-]+\.[a-z]{2,4})\)$/
      const phoneWithEmailMatch = searchAddressBook.match(phoneWithEmailPattern)

      if (nameWithPhoneMatch) {
        setPayload({
          relationName: nameWithPhoneMatch[1] || null,
          relationPhoneNumber: nameWithPhoneMatch[2] || null,
        })
      } else if (phoneWithEmailMatch) {
        setPayload({
          relationPhoneNumber: phoneWithEmailMatch[1] || null,
          relationEmail: phoneWithEmailMatch[2] || null,
        })
      } else if (nameWithEmailMatch) {
        setPayload({
          relationName: nameWithEmailMatch[1] || null,
          relationEmail: nameWithEmailMatch[2] || null,
        })
      } else if (isEmail) {
        setPayload({ relationEmail: searchAddressBook?.trim() || null })
      } else if (isPhoneNumber) {
        setPayload({ relationPhoneNumber: searchAddressBook?.trim() || null })
      } else if (isTextOnly) {
        setPayload({ relationName: searchAddressBook?.trim() || null })
      } else {
        setPayload({
          relationPhoneNumber: null,
          relationEmail: null,
          relationName: searchAddressBook?.trim() || null,
          relationInstitution: null,
        })
      }
    } else {
      setPayload({
        relationPhoneNumber: null,
        relationEmail: null,
        relationName: null,
        relationInstitution: null,
      })
    }
  }, [dataAddressBookById, isOpen, searchAddressBook])

  // HANDLE CHANGE VALUE
  const handleChange = (e) => {
    const { name, value } = e.target
    setPayload((prev) => ({
      ...prev,
      [name]: value.trim() === '' ? null : value,
    }))
  }

  const handleSubmit = async () => {
    const sanitizedPayload = Object.fromEntries(
      Object.entries(payload).map(([key, value]) => [
        key,
        value === '' ? null : value,
      ]),
    )

    try {
      if (isEditMode) {
        const res = await updateAddressBook({
          id: dataAddressBookById.id,
          ...sanitizedPayload,
        }).unwrap()
        if (!res?.error) {
          enqueueSnackbar('Kontak berhasil diperbarui!', { variant: 'success' })
          refetch?.()
          toggle()
          if (isOutsideAddressBook) {
            findAddressBook({
              s: searchAddressBook,
              ...(isPhoneUnRegistered ? { isPhoneUnRegistered: true } : {}),
              ...(isPhoneRegistered ? { isPhoneRegistered: true } : {}),
              ...(isEmailUnRegistered ? { isEmailUnRegistered: true } : {}),
              ...(isEmailRegistered ? { isEmailRegistered: true } : {}),
              ...(isPhoneVerified ? { isPhoneVerified: true } : {}),
              ...(isEmailVerified ? { isEmailVerified: true } : {}),
            })
            dispatch(setIsOpenSelectionAddressBook(true))
          }
        }
      } else {
        const res = await addAddressBook(sanitizedPayload).unwrap()
        if (!res?.error) {
          enqueueSnackbar('Kontak berhasil ditambahkan!', {
            variant: 'success',
          })
          refetch?.()
          toggle()
          if (isOutsideAddressBook) {
            findAddressBook({
              s: searchAddressBook,
              ...(isPhoneUnRegistered ? { isPhoneUnRegistered: true } : {}),
              ...(isPhoneRegistered ? { isPhoneRegistered: true } : {}),
              ...(isEmailUnRegistered ? { isEmailUnRegistered: true } : {}),
              ...(isEmailRegistered ? { isEmailRegistered: true } : {}),
            })
            dispatch(setIsOpenSelectionAddressBook(true))
          }
        }
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Kontak gagal diperbarui!',
        {
          variant: 'error',
        },
      )
    }
  }

  // AUTO FOCUS
  useEffect(() => {
    if (isOpen) setTimeout(() => inputRef.current?.focus(), 100)
  }, [isOpen])

  return (
    <>
      <Modal
        className="p-4"
        centered
        isOpen={isOpen}
        toggle={toggle}
        autoFocus={false}
      >
        <ModalBody>
          <div className="d-flex flex-column gap-1">
            <P className="text-sm pb-4" size="medium" color="#101828" bold>
              {isEditMode ? 'Edit Kontak' : 'Tambah Kontak'}
            </P>

            <div className="pb-3">
              <FormGroup>
                <P className="text-sm pb-1" size="regular" color="#344054">
                  Nama
                </P>
                <Input
                  placeholder="Masukkan nama"
                  name="relationName"
                  value={payload.relationName || ''}
                  onChange={handleChange}
                  autoComplete="off"
                  innerRef={inputRef}
                />
              </FormGroup>

              <FormGroup>
                <P className="text-sm pb-1" size="regular" color="#344054">
                  Nomor handphone
                </P>
                <Input
                  placeholder="Masukkan nomor handphone"
                  name="relationPhoneNumber"
                  value={payload.relationPhoneNumber || ''}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={15}
                />
              </FormGroup>

              <FormGroup>
                <P className="text-sm pb-1" size="regular" color="#344054">
                  Email
                </P>
                <Input
                  type="email"
                  placeholder="Masukkan email"
                  name="relationEmail"
                  value={payload.relationEmail || ''}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </FormGroup>

              <FormGroup>
                <P className="text-sm pb-1" size="regular" color="#344054">
                  Nama institusi (opsional)
                </P>
                <Input
                  placeholder="Masukkan nama institusi"
                  name="relationInstitution"
                  value={payload.relationInstitution || ''}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </FormGroup>
            </div>
          </div>

          <div className="d-flex gap-3">
            <Button
              onClick={() => toggle()}
              className="btn-cancel"
              outline
              block
            >
              Batal
            </Button>
            <Button
              color="primary"
              block
              disabled={isLoading || isUpdating || isAdding}
              onClick={handleSubmit}
            >
              {(isLoading || isUpdating || isAdding) && (
                <Spinner size="sm" className="me-2" />
              )}
              {isEditMode ? 'Ubah' : 'Tambah'}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalAddContact
