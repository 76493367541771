import IcCheckCircle from 'assets/icons/ic_check_circle.svg'
import Logo from 'assets/images/pmg-logo-beta.png'
import { P } from 'components'
import { passwordRequirements } from 'constants'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { ArrowLeft, Circle, Eye, EyeOff } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, FormGroup, Input, InputGroupText } from 'reactstrap'
import { version } from 'utility/Utils'
import './register.css'
import { useRegisterUserMutation } from './registerSlice'
import { useResetPasswordMutation } from '../login-new/loginNewApiSlice'

export default function Register() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordRequirementsData, setPasswordRequirementsData] = useState(
    passwordRequirements.map((item) => ({ ...item, checked: false })),
  )
  const location = useLocation()
  const dataState = location.state
  const navigate = useNavigate()
  const [registerUser] = useRegisterUserMutation()
  const [resetPassword] = useResetPasswordMutation()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)

    const handleBack = (event) => {
      event.preventDefault()
      window.history.pushState(null, '', window.location.href)
    }

    window.addEventListener('popstate', handleBack)

    return () => {
      window.removeEventListener('popstate', handleBack)
    }
  }, [])

  const handleRegister = async () => {
    try {
      let payload = {
        identity: dataState?.identity,
        deviceHash: dataState?.deviceHash,
        password: password,
        fcmToken: dataState?.fcmToken ?? '',
        version: version,
        browserHash: dataState?.browserHash,
        browserFingerPrint: dataState?.browserFingerPrint,
        browserCustomFingerPrint: dataState?.browserCustomFingerPrint,
        otp: dataState?.otp,
      }
      let res
      if (dataState?.status === 'resetPassword') {
        res = await resetPassword(payload)
      } else {
        res = await registerUser(payload)
      }
      if (res?.error?.data?.meta?.error) {
        throw res
      } else {
        if (dataState?.status === 'resetPassword') {
          enqueueSnackbar('Password berhasil diubah', {
            variant: 'success',
          })
          navigate('/login')
        } else {
          navigate('/questionnaire', {
            state: { ...res?.data?.data },
            replace: true,
          })
        }
      }
    } catch (err) {
      console.log('1', { err })
      enqueueSnackbar(
        err?.data?.message ||
          err?.data?.error?.message ||
          err?.error?.data?.meta?.message ||
          'Terjadi kesalahan',
        {
          variant: 'error',
        },
      )

      if (!dataState) {
        navigate('/login')
      }
    }
  }

  const handlePasswordChange = (e) => {
    const value = e.target.value
    setPassword(value)

    const updatedRequirements = passwordRequirements.map((req) => {
      let isChecked = false
      switch (req.id) {
        case 1:
          isChecked = /[a-z]/.test(value)
          break
        case 2:
          isChecked = /[A-Z]/.test(value)
          break
        case 3:
          isChecked = value.length >= 8
          break
        case 4:
          isChecked = /[0-9]/.test(value)
          break
        case 5:
          isChecked = /[^a-zA-Z0-9]/.test(value)
          break
        default:
          break
      }
      return { ...req, checked: isChecked }
    })

    setPasswordRequirementsData(updatedRequirements)
  }

  return (
    <div className="register-container">
      <div className="mobile-header" onClick={() => window.history.back()}>
        <ArrowLeft size={20} className="chevron-icon" />
      </div>

      <div className="card-wrapper">
        <img src={Logo} alt="Logo PasarMikro" className="logo" />
        <P bold>Buat password Anda</P>

        <div className="mt-4">
          <P size="regular">Password</P>
          <FormGroup className="input-group mt-1">
            <Input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Masukkan password"
              className="password-input"
            />
            <InputGroupText
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeOff size={15} style={{ cursor: 'pointer' }} />
              ) : (
                <Eye size={15} style={{ cursor: 'pointer' }} />
              )}
            </InputGroupText>
          </FormGroup>
        </div>
        <div className="mt-2">
          <P size="regular">Konfirmasi password</P>
          <FormGroup className="input-group mt-1">
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Masukkan password"
              className="password-input"
            />
            <InputGroupText
              className="toggle-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <EyeOff size={15} style={{ cursor: 'pointer' }} />
              ) : (
                <Eye size={15} style={{ cursor: 'pointer' }} />
              )}
            </InputGroupText>
          </FormGroup>
        </div>
        <div className="mt-3">
          <P size="regular" bold>
            Password harus terdiri dari setidaknya
          </P>
        </div>
        <div className="mt-2">
          {passwordRequirementsData.map((el) => {
            return (
              <div className="d-flex align-items-center gap-2 mb-2" key={el.id}>
                {el.checked ? (
                  <img
                    src={IcCheckCircle}
                    width={20}
                    height={20}
                    alt={`checked-${el.label}`}
                  />
                ) : (
                  <Circle size={20} />
                )}
                <P size="regular" color="#344054">
                  {el.label}
                </P>
              </div>
            )
          })}
        </div>
        <div className="w-100 mt-4">
          <div className="btn-lanjut">
            <Button
              onClick={() => {
                handleRegister()
              }}
              color="primary"
              className="w-100"
              disabled={
                !passwordRequirementsData.every((req) => req.checked) ||
                !password ||
                !confirmPassword ||
                password !== confirmPassword
              }
            >
              <P>Lanjut</P>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
