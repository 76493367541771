import { P } from 'components'
import React from 'react'
import { Button, Modal } from 'reactstrap'

export default function ModalTalanginConfirmation({
  isOpen,
  onClose,
  onOpenCamera,
}) {
  return (
    <Modal isOpen={isOpen} centered onAbort={onClose}>
      <div style={{ padding: 20 }}>
        <P bold>Foto Konfirmasi</P>
        <div className="mt-3">
          <P size="regular">
            Sebagai bentuk persetujuan untuk pembelian melalui Talangan
            PasarMIKRO, kami membutuhkan foto selfie kamu sebagai konfirmasi.
          </P>
        </div>
        <div className="w-100 d-flex gap-2 mt-3">
          <Button
            className="w-100"
            color="transparent"
            style={{ border: '1px solid #006386' }}
            onClick={onClose}
          >
            <P color={'#006386'}>Batal</P>
          </Button>
          <Button
            onClick={onOpenCamera}
            className="w-100"
            color="primary"
            style={{ border: '1px solid black' }}
          >
            <P>Buka kamera</P>
          </Button>
        </div>
      </div>
    </Modal>
  )
}
