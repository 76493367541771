import LoginView, { loader as loginLoader } from 'features/public-views/login'
import Login from 'features/public-views/login-universal/login'

export const authRouter = [
  {
    element: <Login />,
    path: '/login',
    loader: loginLoader,
  },
]
