import PMBTabSwitcher from 'components/PMBTabSwitcher'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_FARMERS, URL_FARMERS_GROUPS } from 'utils/pathUrl'
const tabManageStaff = [
  {
    id: 1,
    label: 'Anggota',
    page: URL_FARMERS,
    key: URL_FARMERS,
  },
  {
    id: 2,
    label: 'Grup',
    page: URL_FARMERS_GROUPS,
    key: URL_FARMERS_GROUPS,
  },
]

export default function TabManageStaffGroup() {
  const navigate = useNavigate()
  let location = useLocation()

  const handleTabChange = (tabKey) => {
    navigate(tabKey)
  }

  return (
    <PMBTabSwitcher
      activeTab={location?.pathname}
      tabs={tabManageStaff}
      onTabChange={handleTabChange}
    />
  )
}
