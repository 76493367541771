import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiEndpoint } from 'configs'

export const registerApiSlice = createApi({
  reducerPath: 'register/api',
  baseQuery: fetchBaseQuery({ baseUrl: apiEndpoint }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/register`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    getQuestionnaires: builder.query({
      query: (token) => ({
        url: `/api/v1/userregistrationinfo/selection`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    submitRegistrationInfo: builder.mutation({
      query: ({
        userId,
        fullname,
        profession,
        objective,
        nib,
        referralCode,
        token,
      }) => ({
        url: `/api/v2/userregistrationinfo`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          userId,
          registrationInfo: {
            fullname,
            profession,
            objective,
            nib,
            referralCode,
          },
        },
      }),
    }),
  }),
})

export const {
  useRegisterUserMutation,
  useLazyGetQuestionnairesQuery,
  useSubmitRegistrationInfoMutation,
} = registerApiSlice
