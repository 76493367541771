import { P } from 'components'
import React, { useEffect, useState } from 'react'
import { Button, Input } from 'reactstrap'
import {
  useLazyGetQuestionnairesQuery,
  useSubmitRegistrationInfoMutation,
} from './registerSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import checkbox from 'assets/icons/check-box.svg'
import checkboxChecked from 'assets/icons/checkbox-checked.svg'
import radio from 'assets/icons/radio.svg'
import radioChecked from 'assets/icons/radio-checked.svg'
import { useSnackbar } from 'notistack'
import './register.css'
import { validateEmail } from 'helpers'

const SelectItem = ({
  type = 'radio',
  label,
  name,
  value,
  onChange,
  checked,
}) => {
  return (
    <div
      className="d-flex align-items-center gap-2 p-2"
      style={{
        border: checked ? '1px solid #8ABBCC' : '1px solid #EAECF0',
        borderRadius: 8,
        cursor: 'pointer',
        background: checked ? '#ECFAFF' : 'transparent',
      }}
      onClick={() => onChange(value)}
    >
      <img
        src={
          type === 'radio'
            ? checked
              ? radioChecked
              : radio
            : checked
              ? checkboxChecked
              : checkbox
        }
        alt="check-icon"
        style={{ width: 16, height: 16 }}
      />
      <P size="regular">{label}</P>
    </div>
  )
}

export default function Questionnaire() {
  const [getQuestionnaires] = useLazyGetQuestionnairesQuery()
  const [submitRegistrationInfo] = useSubmitRegistrationInfoMutation()
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [name, setName] = useState('')
  const [selectedProfession, setSelectedProfession] = useState(null)
  const [selectedObjectives, setSelectedObjectives] = useState([])
  const [selectedNib, setSelectedNib] = useState(null)

  const [nibOptions, setNibOptions] = useState([])
  const [objectiveOptions, setObjectiveOptions] = useState([])
  const [professionOptions, setProfessionOptions] = useState([])
  const [isInputNameDisabled, setInputNameDisabled] = useState(false)

  const [dataState] = useState(() => {
    const savedData = localStorage.getItem('dataState')
    return location.state || (savedData ? JSON.parse(savedData) : null)
  })

  useEffect(() => {
    if (
      dataState?.name.toLowerCase().includes('pmg_') ||
      validateEmail(dataState?.name)
    ) {
      setInputNameDisabled(false)
    } else {
      setName(dataState?.name)
      setInputNameDisabled(true)
    }
  }, [])

  useEffect(() => {
    if (dataState) {
      localStorage.setItem('dataState', JSON.stringify(dataState))
      fetchQuestionnaires(dataState.api_token)
    }
  }, [dataState])

  useEffect(() => {
    const handleBack = () => {
      window.location.replace('/')
    }

    window.history.pushState(null, '', window.location.href)
    window.onpopstate = handleBack

    return () => {
      window.onpopstate = null
    }
  }, [navigate])

  const fetchQuestionnaires = async () => {
    try {
      const res = await getQuestionnaires(dataState?.api_token)
      if (res?.error) {
        throw res
      }
      setNibOptions(res?.data?.data?.nib || [])
      setObjectiveOptions(res?.data?.data?.objective || [])
      setProfessionOptions(res?.data?.data?.profession || [])
    } catch (err) {
      console.error('Error fetching questionnaires:', err)
      enqueueSnackbar(
        err?.data?.message ??
          err?.data?.error?.message ??
          err?.error?.data?.meta?.message ??
          'Terjadi kesalahan',
        {
          variant: 'error',
        },
      )
    }
  }

  const toggleObjective = (value) => {
    setSelectedObjectives((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value],
    )
  }

  const handleSubmit = async () => {
    try {
      const payload = {
        userId: dataState?.id,
        fullname: name,
        profession: selectedProfession,
        objective: selectedObjectives,
        nib: selectedNib,
        token: dataState?.api_token,
      }
      const res = await submitRegistrationInfo(payload)
      if (res?.error) {
        throw res
      } else {
        window.location.replace('/')
      }
    } catch (err) {
      enqueueSnackbar(
        err?.data?.message ??
          err?.data?.error?.message ??
          err?.error?.data?.meta?.message ??
          'Terjadi kesalahan',
        {
          variant: 'error',
        },
      )
    }
  }
  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
      }}
    >
      <div
        className="w-100 card-wrapper"
        style={{ borderRadius: 10, maxWidth: '600px' }}
      >
        <P size="medium">Tinggal selangkah lagi</P>
        <P color={'#344054'} className="mt-2">
          Mohon jawab pertanyaan berikut untuk menyelesaikan pendaftaran Anda.
        </P>
        <div className="mt-4">
          <P size="regular">Nama lengkap</P>
          <Input
            className="mt-2"
            placeholder="Kusuma Wardani"
            style={{ fontSize: '14px' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={isInputNameDisabled}
          />
        </div>

        <div className="mt-3">
          <P size="regular">Pekerjaan</P>
          {professionOptions.map((el) => (
            <div className="mt-2" key={el.id}>
              <SelectItem
                label={el.name}
                value={el.id}
                checked={selectedProfession === el.id}
                onChange={setSelectedProfession}
              />
            </div>
          ))}
        </div>

        <div className="mt-3">
          <P size="regular">Apa yang Anda cari di PasarMIKRO?</P>
          {objectiveOptions.map((el) => (
            <div className="mt-2" key={el.id}>
              <SelectItem
                type="checkbox"
                label={el.name}
                value={el.id}
                checked={selectedObjectives.includes(el.id)}
                onChange={toggleObjective}
              />
            </div>
          ))}
        </div>

        <div className="mt-3">
          <P size="regular">Apakah Anda memiliki badan usaha (NIB)?</P>
          {nibOptions.map((el) => (
            <div className="mt-2" key={el.id}>
              <SelectItem
                label={el.name}
                value={el.id}
                checked={selectedNib === el.id}
                onChange={setSelectedNib}
              />
            </div>
          ))}
        </div>

        <Button className="w-100 mt-4" color="primary" onClick={handleSubmit}>
          <P>Lanjut</P>
        </Button>
      </div>
    </div>
  )
}
