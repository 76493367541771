import Logo403 from 'assets/images/403-logo.png'
import { ArrowLeft } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import CustomButton from './Buttons/CustomButton'

const PageForbidden = () => {
  let navigate = useNavigate()

  return (
    <div className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center gap-4">
      <img src={Logo403} alt="error-image" className="img-fluid" />
      <div className="text-center">
        <h3 className="fw-bold">Anda tidak memiliki akses ke halaman ini</h3>
        <p className="text-muted">
          Silakan kontak admin Anda, atau gunakan akun yang memiliki akses.
        </p>
      </div>
      <div className="d-flex">
        <CustomButton
          onClick={() => navigate(-1)}
          color="secondary"
          className="btn-md mb-2 me-2"
          outline
        >
          <ArrowLeft size={20} className="me-1" />
          Kembali
        </CustomButton>
        <CustomButton
          tag="a"
          href="/"
          color="primary"
          className="btn-md mb-2 ms-2"
        >
          Beranda
        </CustomButton>
      </div>
    </div>
  )
}
export default PageForbidden
