import ILDefaultAva from 'assets/icons/default-ava-profile-user.svg'
import Flatpickr from 'react-flatpickr'
import { useDispatch, useSelector } from 'react-redux'
import {
  Badge,
  Card,
  CardBody,
  CardText,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'

// Form
import {
  setIsModalOpenAddAddressBook,
  setSearchAddressBook,
} from 'features/private-views/address-book/addressBookSlice'
import InputAddressBook from 'features/private-views/address-book/InputAddressBook'
import ModalAddContact from 'features/private-views/address-book/ModalAddContact'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import { useEffect, useRef, useState } from 'react'
import { AlertCircle, Calendar } from 'react-feather'
import { emailCheck } from 'utils/emailCheck'
import ModalUnregis from '../ModalUnregis'
import { checkSellerInfo } from '../sellCommodityAction'
import {
  setInitialBuyer,
  setTrxDate,
  toggleModalUnregis,
} from '../sellCommoditySlice'

const InfoSeller = () => {
  const dispatch = useDispatch()
  const [dataSeller, setDataSeller] = useState('')
  const [savedContacts, setSavedContacts] = useState([])
  const addressBookRef = useRef(null)

  const { isModalOpenAddAddressBook, searchAddressBook } = useSelector(
    (e) => e.addressBook,
  )
  const { profile } = useSelector((s) => s.dashboard)
  const { orders } = useSelector((state) => state.sellCommodity)
  const { buyer } = orders

  const [errorMessage, setErrorMessage] = useState('')
  const [selectedContact, setSelectedContact] = useState(null)

  const handleSubmit = (val) => {
    if (val) {
      setErrorMessage('')
      dispatch(
        checkSellerInfo({ search: encodeURIComponent(val), type: 'SELL' }),
      ).then((e) => {
        if (e?.payload?.profileStatus === 'UNREGISTERED') {
          dispatch(toggleModalUnregis())
        }
        const { error } = e
        if (error?.message == 'Rejected') {
          setErrorMessage(e?.payload)
        }
      })
    }
  }

  let isEmail = emailCheck(buyer?.fullName)

  useEffect(() => {
    if (profile?.userId) {
      const saved = JSON.parse(
        localStorage.getItem(`savedContacts-${profile?.userId}`),
      )
      setSavedContacts(saved ?? [])
    }
  }, [profile])

  const toggleOpenCloseAddContact = () => {
    dispatch(setIsModalOpenAddAddressBook(!isModalOpenAddAddressBook))
  }

  const handleSelectedContact = (contact) => {
    setDataSeller(contact?.relationPhoneNumber ?? contact?.relationEmail ?? '')
    setSelectedContact(contact)
  }

  useEffect(() => {
    if (
      selectedContact?.relationPhoneNumber ||
      selectedContact?.relationEmail
    ) {
      handleSubmit(
        selectedContact?.relationPhoneNumber || selectedContact?.relationEmail,
      )
      dispatch(setInitialBuyer(null))
    } else {
      setDataSeller('')
      setErrorMessage('')
      dispatch(setInitialBuyer(null))
    }
  }, [selectedContact])

  // HANDLE AUTO FOCUS
  useEffect(() => {
    setTimeout(() => {
      addressBookRef.current?.focus()
    }, 0)
  }, [])

  return (
    <>
      <ModalUnregis
        data={dataSeller}
        setSelectedContact={setSelectedContact}
        selectedContact={selectedContact}
      />
      <ModalAddContact
        isOpen={isModalOpenAddAddressBook}
        toggle={toggleOpenCloseAddContact}
        isOutsideAddressBook
      />
      <Card className="border-0 shadow-sm">
        <CardBody className="p-4">
          <Row>
            <Col sm="12">
              <h5 className="text-md">Penjualan</h5>
              <FormGroup className="mt-4">
                <Label>Tanggal Transaksi</Label>
                <div className="d-flex align-items-center justify-content-center border rounded">
                  <Calendar size={17} className="ms-3" />
                  <Flatpickr
                    options={{
                      locale: Indonesian,
                      dateFormat: 'd-M-Y',
                      defaultDate: new Date(),
                      maxDate: new Date(),
                      onClose: (selectedDate, dateStr, instance) => {
                        dispatch(setTrxDate(selectedDate[0]))
                      },
                    }}
                    className="form-control flat-picker bg-transparent border-0 shadow-none"
                  />
                </div>
              </FormGroup>

              <div className="mb-4">
                <InputAddressBook
                  title="Pembeli"
                  onSelectContact={handleSelectedContact}
                  fieldName="relationName"
                  editValue={
                    selectedContact?.relationPhoneNumber
                      ? `${selectedContact?.relationName} (${selectedContact?.relationPhoneNumber})`
                      : selectedContact?.relationEmail
                        ? `${selectedContact?.relationName} (${selectedContact?.relationEmail})`
                        : selectedContact?.relationName
                  }
                  onClickAdd={(localSearch) =>
                    dispatch(setSearchAddressBook(localSearch))
                  }
                  innerRef={addressBookRef}
                />
              </div>
            </Col>
            {errorMessage && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errorMessage}
                </small>
              </FormGroup>
            )}
            <Col sm="12">
              {buyer ? (
                <CardBody className="p-0">
                  <CardText tag="h6" className="text-sm fw-semibold">
                    Informasi pembeli
                  </CardText>
                  <Card>
                    <CardBody>
                      <div className="flex-centered justify-content-between">
                        <div className="flex-centered gap-1 ">
                          <img
                            src={buyer.profileImg ?? ILDefaultAva}
                            alt="ava"
                            width={36}
                            height={35}
                            className="rounded-circle"
                          />
                          <span className="text-sm fw-semibold">
                            {buyer?.fullName}
                          </span>
                        </div>
                        {isEmail ? (
                          <></>
                        ) : (
                          <Badge
                            size="sm"
                            disabled
                            className={
                              buyer?.profileStatus.toLowerCase() === 'verified'
                                ? 'badge-verify'
                                : 'badge-warning'
                            }
                          >
                            {buyer?.profileStatus}
                          </Badge>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </CardBody>
              ) : (
                <CardBody className="p-0">
                  <div
                    className="d-flex gap-2"
                    style={{
                      minWidth: '100%',
                      overflow: 'auto',
                    }}
                  >
                    {savedContacts?.map((e, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            const reMappingData = {
                              id: e?.userId,
                              relationEmail: null,
                              relationInstitution: null,
                              relationName: e?.fullName ?? null,
                              relationPhoneNumber: e?.phoneNumber ?? null,
                            }

                            const displayText =
                              reMappingData?.relationPhoneNumber
                                ? `${reMappingData?.relationName} (${reMappingData?.relationPhoneNumber})`
                                : reMappingData?.relationEmail
                                  ? `${reMappingData?.relationName} (${reMappingData?.relationEmail})`
                                  : reMappingData?.relationName

                            dispatch(setSearchAddressBook(displayText))
                            setSelectedContact(reMappingData)
                          }}
                          className="rounded p-3 bg-gray-50 hover-100 pointer"
                        >
                          {e?.fullName}
                        </div>
                      )
                    })}{' '}
                  </div>
                </CardBody>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default InfoSeller
