import { P } from 'components'
import PropTypes from 'prop-types'

const PMBTabSwitcher = ({
  activeTab,
  tabs,
  onTabChange,
  boldLabel = false,
  labelSize = 'regular',
}) => {
  return (
    <div
      className="d-flex gap-3 mb-3"
      style={{ borderBottom: '1px solid #EAECF0' }}
    >
      {tabs?.map((tab) => (
        <div
          key={tab.key}
          style={{
            borderBottom: activeTab === tab.key ? '2px solid #005370' : '',
            paddingBottom: 14,
            color: activeTab === tab.key ? '#005370' : '#667085',
            cursor: 'pointer',
            fontSize: '14px',
          }}
          onClick={() => onTabChange(tab.key)}
        >
          <P size={labelSize} bold={boldLabel}>
            {tab.label}
          </P>
        </div>
      ))}
    </div>
  )
}

PMBTabSwitcher.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onTabChange: PropTypes.func.isRequired,
}

export default PMBTabSwitcher
