import { createAppSlice } from 'app/createAppSlice'
import { getPublicVersion } from './publicVersionAction'

const initialState = {
  data: {},
  loading: false,
}

export const publicVersionSlice = createAppSlice({
  name: 'publicVersion',
  initialState,
  reducers: (create) => ({}),
  extraReducers: (builder) => {
    builder
      .addCase(getPublicVersion.pending, (state) => {
        state.loading = true
      })
      .addCase(getPublicVersion.fulfilled, (state, { payload }) => {
        state.loading = false
        state.data = payload
      })
      .addCase(getPublicVersion.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {} = publicVersionSlice.actions

export default publicVersionSlice.reducer
