import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBModalRemove from 'components/PMBModalRemove'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import PMBTooltip from 'components/PMBTooltip'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useDebounce } from 'helpers'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { ChevronDown, Edit2, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, Spinner } from 'reactstrap'
import {
  useFetchAddressBookQuery,
  useRemoveAddressBookMutation,
} from './addressBookApiSlice'
import { setIsModalOpenAddAddressBook } from './addressBookSlice'
import ModalAddContact from './ModalAddContact'

const ListAddressBook = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { profile } = useSelector((s) => s.dashboard)
  const isModalOpenAddAddressBook = useSelector(
    (s) => s.addressBook.isModalOpenAddAddressBook,
  )
  const dispatch = useDispatch()

  const [searchInput, setSearchInput] = useState('')
  const [search, setSearch] = useState('')
  const [isNoDataFilterAvailable, setIsNoDataFilterAvailable] = useState(false)
  const [isRemove, setIsRemove] = useState(false)
  const [dataAddressBookById, setDataAddressBookById] = useState(null)

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch, isFetching } = useFetchAddressBookQuery(
    {
      userId: profile?.userId,
      s: search,
      size: rowsPerPage,
      page: currentPage,
      sortby: sortColumn,
      sortdir: sortDirection,
    },
    { skip: !profile?.userId },
  )

  // HANDLE LOADING FILTER
  useEffect(() => {
    if (!isFetching && data?.totalItems === 0) {
      setIsNoDataFilterAvailable(true)
    } else {
      setIsNoDataFilterAvailable(false)
    }
  }, [isFetching, data?.totalItems])

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction?.toLowerCase() === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: <span>Nama</span>,
      selector: 'relationName',
      sortable: true,
      cell: (row) => {
        return <span>{row?.relationName ?? '-'}</span>
      },
    },
    {
      name: <span>Nomor Handphone</span>,
      selector: 'relationPhoneNumber',
      sortable: true,
      cell: (row) => {
        return <span>{row?.relationPhoneNumber ?? '-'}</span>
      },
    },
    {
      name: <span>Email</span>,
      selector: 'relationEmail',
      sortable: true,
      cell: (row) => {
        return <span>{row?.relationEmail ?? '-'}</span>
      },
    },
    {
      name: <span>Nama Institusi</span>,
      selector: 'relationInstitution',
      sortable: true,
      cell: (row) => {
        return <span>{row?.relationInstitution ?? '-'}</span>
      },
    },
    {
      name: '',
      width: '150px',
      cell: (row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ gap: '16px', flexGrow: 1 }}
          >
            <div id={`edit-${row?.id}`}>
              <Edit2
                size={18}
                onClick={() => {
                  toggleOpenCloseAddContact()
                  setDataAddressBookById(row)
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <PMBTooltip target={`edit-${row?.id}`} text="Ubah" />

            <div id={`remove-${row?.id}`}>
              <Trash2
                color="red"
                size={18}
                onClick={() => {
                  onHandleOpenCloseModalRemove()
                  setDataAddressBookById(row)
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <PMBTooltip target={`remove-${row?.id}`} text="Hapus" />
          </div>
        )
      },
    },
  ]

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    refetch()
  }, 600)

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const toggleOpenCloseAddContact = () => {
    dispatch(setIsModalOpenAddAddressBook(!isModalOpenAddAddressBook))
  }

  const onHandleOpenCloseModalRemove = () => {
    setIsRemove((prev) => !prev)
  }

  const [removeAddressBookById, { isLoading: isLoadingRemove }] =
    useRemoveAddressBookMutation()

  const onRemoveAddressBookById = async (id) => {
    if (!id) {
      enqueueSnackbar('Gagal hapus, id tidak valid!', {
        variant: 'error',
      })
      return
    }

    try {
      const res = await removeAddressBookById({ id }).unwrap()
      if (!res?.error) {
        enqueueSnackbar('Daftar kontak berhasil dihapus!', {
          variant: 'success',
        })
        refetch()
        setDataAddressBookById(null)
        onHandleOpenCloseModalRemove()
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Daftar kontak gagal dihapus!',
        {
          variant: 'error',
        },
      )

      onHandleOpenCloseModalRemove()
    }
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Daftar Kontak" />
      </div>
      {/* MODAL ADD CONTACT */}
      <ModalAddContact
        isOpen={isModalOpenAddAddressBook}
        toggle={() => {
          toggleOpenCloseAddContact()
          setDataAddressBookById(null)
        }}
        dataAddressBookById={dataAddressBookById}
        setDataAddressBookById={setDataAddressBookById}
        refetch={refetch}
      />

      {/* MODAL REMOVE */}
      <PMBModalRemove
        isOpen={isRemove}
        onClose={() => {
          onHandleOpenCloseModalRemove()
          setDataAddressBookById(null)
        }}
        title="Hapus daftar kontak"
        dataId={dataAddressBookById?.id}
        desc={`Apakah Anda yakin ingin menghapus daftar kontak ${dataAddressBookById?.relationName ?? '-'}?`}
        isLoading={isLoadingRemove}
        onRemoveData={(id) => onRemoveAddressBookById(id)}
        buttonTitle="Hapus"
      />

      <Card className="mb-3 border-0 shadow-sm">
        <CardBody>
          <DataTable
            columns={columns}
            data={data?.items || []}
            progressPending={isLoading || isFetching}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                {isNoDataFilterAvailable
                  ? 'Tidak ada data yang sesuai dengan pencarian Anda'
                  : 'Belum ada daftar kontak yang didaftarkan'}
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.totalItems || 0}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Kontak</h5>

                  <Button
                    onClick={toggleOpenCloseAddContact}
                    size="sm"
                    color="primary"
                  >
                    + Tambah Kontak
                  </Button>
                </div>

                <div className="my-4">
                  <PMBSearchInputGroup
                    label={'Cari'}
                    value={searchInput}
                    placeholder="Cari nama kontak..."
                    onChange={(e) => onHandleSearchChange(e.target.value)}
                    maxWidth="25%"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default ListAddressBook
