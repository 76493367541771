import ICTopup from 'assets/icons/icon-topup.png'
import iconCard from 'assets/icons/red-wallet.png'
import CardAmount from 'assets/images/Card.png'
import axios from 'axios'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import ProgressBarExportFile from 'components/ProgressBarExportFile'
import { apiEndpoint } from 'configs'
import * as FileSaver from 'file-saver'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, Download } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Container,
  Input,
} from 'reactstrap'
import { formatDateOnlyPMB } from 'utils/formatDate'
import { URL_406 } from 'utils/pathUrl'
import { BUSINESS_WITHDRAWAL, MAKE, MANAGE } from 'utils/subjectActions'
import * as XLSX from 'xlsx'
import { useFetchSaldoQuery } from '../dashboard/dashboardApiSlice'
import ModalConfirmation from './ModalConfirmation'
import SectionPindahUangUsaha from './SectionPindahUangUsaha'
import {
  useFetchDisbursmentPricingQuery,
  useFetchWithdrawalQuery,
} from './withdrawApiSlice'
import { setSelectedPricing } from './withdrawSlice'
import { P } from 'components'

const Withdraw = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const makePurchase = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_WITHDRAWAL,
  })
  const managePurchase = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_WITHDRAWAL,
  })

  const intl = useIntl()
  const dispatch = useDispatch()
  const { data } = useFetchDisbursmentPricingQuery()
  const { data: dataSaldo, isLoading: dataSaldoLoading } = useFetchSaldoQuery()
  const { data: dataWithdraw } = useFetchWithdrawalQuery()
  const { selectedPricing } = useSelector((e) => e.withdraw)
  const { token } = useSelector((s) => s.auth)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [totalExportRows, setTotalExportRows] = useState(0)
  const [confirmExportFile, setConfirmExportFile] = useState(false)
  const [cancelTokenExportFile, setCancelTokenTokenExportFile] = useState(null)
  let [persentage, setPersentage] = useState(0)
  const toggleConfirmExportFile = () => setConfirmExportFile(!confirmExportFile)

  const handleExportExcel = () => {
    toggleConfirmExportFile()
    exportToExcel()
  }

  const handleExportCancelAxios = () => {
    if (cancelTokenExportFile) {
      cancelTokenExportFile.cancel('Request canceled by user')
      setPersentage(0)
      setConfirmExportFile(false)
    }
  }
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  useEffect(() => {
    if (typeof exporting === 'boolean' && !exporting) {
      setTimeout(() => {
        setPersentage(0)
        setConfirmExportFile(false)
      }, 1000)
    }
  }, [exporting])

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  const exportFile = (onReceiveChunk, onFinished, onError) => {
    let url = `${apiEndpoint}/api/v1/bussines/instantwithdraw`

    const source = axios.CancelToken.source()
    setCancelTokenTokenExportFile(source)

    axios
      .get(url, {
        cancelToken: source.token,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response)
        const itemsReceived = response?.data?.items?.length || 0

        setTotalExportRows(itemsReceived)

        setPersentage(itemsReceived)

        onReceiveChunk(response?.data?.items)

        if (itemsReceived > 0) {
          onFinished()
        }
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          onError(error)
        }
        onError(error)
      })
  }

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const bookType = 'xlsx'
    let count = 2

    const ws = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, [exportHeader])
    setExporting(true)

    exportFile(
      (chunk) => {
        const rechunk = chunk.map((row) =>
          exportColumns.map((col) => col.selector(row)),
        )
        XLSX.utils.sheet_add_json(ws, rechunk, {
          origin: `A${count}`,
          skipHeader: true,
        })
        count = count + chunk.length
      },
      () => {
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, {
          bookType: bookType,
          type: 'array',
          cellStyles: true,
        })
        const finalData = new Blob([excelBuffer], { type: fileType })
        setExporting(false)
        FileSaver.saveAs(finalData, 'data.xlsx')
      },
      (err) => {
        console.log(err)
        setExporting(false)
      },
    )
  }

  const exportColumns = [
    {
      name: 'Waktu',
      selector: (row) => moment(row.createdAt).format('YYYY/MM/DD HH:mm:ss'),
    },
    {
      name: 'Jumlah',
      selector: (row) => row.amount ?? '',
    },
    {
      name: 'Biaya',
      selector: (row) => row.fee,
    },
    {
      name: 'Nomor',
      selector: (row) => row.number,
    },
  ]
  const exportHeader = exportColumns.map((row) => row.name)

  const modalExport = () => {
    return (
      <>
        <ProgressBarExportFile
          isDialogShow={confirmExportFile}
          totalRows={totalExportRows}
          onCancel={handleExportCancelAxios}
          isExportingFile={exporting}
          isFull
        />
      </>
    )
  }

  console.log(dataWithdraw)

  return (
    <>
      {modalExport()}
      <ModalConfirmation isOpen={isOpenModal} toggle={toggleModal} />
      <Container
        className="custom-container pb-3"
        style={{ minHeight: '100vh' }}
      >
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs breadCrumbActive={'Uang usaha'} />
        </div>
        <div className="d-flex flex-column gap-3">
          {/* Card 1 */}
          <Card body style={{ position: 'relative' }}>
            <CardTitle tag="h6" className="fw-bold">
              <p>Uang Usaha</p>
            </CardTitle>
            <div
              style={{ position: 'relative', width: '100%', height: 'auto' }}
            >
              <img
                src={CardAmount}
                alt="Card Nominal"
                style={{ width: '100%', height: 'auto' }}
              />
              {dataSaldo && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                    textAlign: 'center',
                    color: 'white',
                    textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                  }}
                >
                  <small>Nominal</small>
                  <p className="fw-bold">
                    Rp
                    {intl.formatNumber(dataSaldo?.wallet2 ?? 0, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
              )}
            </div>
            {makePurchase || managePurchase ? (
              <>
                <CardTitle tag="h6" className="fw-bold mt-4">
                  <p>Pilih metode penarikan</p>
                </CardTitle>
                {data && (
                  <div className="d-flex flex-column gap-3">
                    {/* eslint-disable-next-line array-callback-return */}
                    {data?.items?.map((e, i) => {
                      if (e?.fee > 0) {
                        return (
                          <div key={i}>
                            <Card
                              style={{
                                backgroundColor:
                                  selectedPricing === e?.id
                                    ? '#ECFAFF'
                                    : e?.disable
                                      ? '#F9FAFB'
                                      : '',
                              }}
                              className={
                                selectedPricing === e?.id
                                  ? 'border-primary'
                                  : ''
                              }
                              onClick={() => {
                                if (!e?.disable) {
                                  dispatch(setSelectedPricing(e?.id))
                                }
                              }}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between flex-row gap-2">
                                  <div>
                                    <span className="fw-bold">
                                      Penarikan {e?.title}
                                    </span>
                                    <p className="text-secondary fw-bold">
                                      Rp
                                      {intl.formatNumber(e?.fee, {
                                        useGrouping: 'always',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      })}
                                    </p>
                                    <p
                                      style={{
                                        color:
                                          selectedPricing === e?.id
                                            ? '#006386'
                                            : '',
                                      }}
                                    >
                                      {e?.disable ? (
                                        <span style={{ color: '#B42318' }}>
                                          {e?.disableReason}
                                        </span>
                                      ) : (
                                        <span>{e.description}</span>
                                      )}
                                    </p>
                                  </div>
                                  <Input
                                    type="radio"
                                    checked={selectedPricing === e?.id}
                                    onChange={() => {
                                      if (!e?.disable) {
                                        dispatch(setSelectedPricing(e?.id))
                                      }
                                    }}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        )
                      }
                      return <></>
                    })}

                    <CustomButton
                      onClick={() => {
                        toggleModal()
                      }}
                      block
                      color="primary"
                      disabled={!selectedPricing}
                    >
                      Ajukan Penarikan
                    </CustomButton>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </Card>
          {/* End Card 1 */}
          {/* Card 2 */}
          <Card className="border-0 shadow-sm">
            <CardBody>
              <div
                onClick={() => {
                  toggle()
                }}
                className="d-flex justify-content-between"
              >
                <span className="fw-bold">Riwayat Penarikan</span>
                {isOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
            </CardBody>
            <Collapse isOpen={isOpen}>
              <CardBody style={{ paddingTop: '0px' }}>
                <Button
                  disabled={exporting || dataWithdraw?.items.length < 1}
                  color="primary"
                  size="md"
                  outline
                  onClick={() => {
                    if (exporting || dataWithdraw?.items.length < 1) {
                      //
                    } else {
                      handleExportExcel()
                    }
                  }}
                  className="mb-3"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <Download size={17} />
                    <P>Unduh Riwayat</P>
                  </div>
                </Button>
                {dataWithdraw?.items.length > 0 ? (
                  dataWithdraw?.items.map((e, i) => {
                    return (
                      <div key={i}>
                        <p className="text-secondary fw-medium">
                          {formatDateOnlyPMB(e?.createdAt)}
                        </p>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: '16px' }}
                        >
                          <img
                            src={iconCard}
                            style={{ width: '39px', height: '39px' }}
                            alt="Wallet"
                          />
                          <div className="d-flex justify-content-between w-100">
                            <div className="d-flex flex-column">
                              <span className="fw-bold">
                                Penarikan {e?.title}
                              </span>
                              <span className="text-secondary">
                                {e?.number}
                              </span>
                              <span className="text-secondary">
                                {moment(e?.createdAt).format('hh:mm')}
                              </span>
                            </div>
                            <div className="d-flex flex-column ">
                              <span className="text-danger fw-bold">
                                -
                                {intl.formatNumber(e?.amount, {
                                  useGrouping: 'always',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              <Badge
                                size="sm"
                                className={`${e?.status === 'Selesai' ? 'badge-verify' : e?.status === 'Dalam Proses' ? 'badge-warning' : 'badge-cancel'}`}
                              >
                                {e?.status}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="flex-centered flex-column gap-2">
                    <img
                      className="img-fluid"
                      style={{ width: '64px', height: '64px' }}
                      src={ICTopup}
                      alt="Wallet"
                    />
                    <p>Belum ada penarikan yang dilakukan</p>
                  </div>
                )}
              </CardBody>
            </Collapse>
          </Card>
          {/* End Card 2 */}
          {/* Card 3 */}
          {makePurchase || managePurchase ? (
            <SectionPindahUangUsaha
              wallet2={dataSaldo?.wallet2}
              isLoading={dataSaldoLoading}
            />
          ) : (
            <></>
          )}
          {/* End Card 3 */}
        </div>
      </Container>
    </>
  )
}

export default Withdraw
