import CardPin from 'components/CardPin'
import { validateEmail } from 'helpers'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { login } from '../login/loginSlice'
import {
  useLoginUserNewMutation,
  useSendOtpLoginMutation,
  useVerifyOtpLoginMutation,
} from './loginNewApiSlice'

const Otp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { token } = useSelector((s) => s.auth)
  const dataState = location.state
  const countdownInterval = useRef(null)
  const [isAction, setIsAction] = useState(false)
  const [countdown, setCountdown] = useState(Number(dataState?.countDown) ?? 60)
  const [errMsg, setErrMsg] = useState('')
  const [verifyOtp, resultVerifyOtp] = useVerifyOtpLoginMutation()
  const [sendOtp, resultSendOtp] = useSendOtpLoginMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [loginUser, { isLoading }] = useLoginUserNewMutation()

  const startCountdown = (time) => {
    setCountdown(time)
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current)
    }

    countdownInterval.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval.current)
          return 0
        }
        return prevCountdown - 1
      })
    }, 1000)
  }

  const handleSubmit = async (enteredPin) => {
    try {
      setErrMsg('')
      setIsAction(true)
      const res = await verifyOtp({
        identity: dataState?.identity,
        deviceHash: dataState?.deviceHash,
        type:
          dataState?.status?.toLowerCase() === 'login'
            ? 'login'
            : dataState?.status === 'resetPassword'
              ? 'resetPassword'
              : 'registration',
        otp: enteredPin,
        browserHash: dataState?.browserHash,
        browserFingerPrint: dataState?.browserFingerPrint,
        browserCustomFingerPrint: dataState?.browserCustomFingerPrint,
      })
      if (!res?.error) {
        if (dataState?.status?.toLowerCase() === 'login') {
          const loginPayload = {
            identity: dataState?.identity,
            deviceHash: dataState?.deviceHash,
            password: dataState.password,
            fcmToken: dataState?.fcmToken ?? '',
            version: ` ${dataState?.mobileVersion ? `PM ${process.env.REACT_APP_VERSION} (${dataState?.mobileVersion})` : `PMB ${process.env.REACT_APP_VERSION}`} `,
            browserHash: dataState?.browserHash,
            browserFingerPrint: dataState?.browserFingerPrint,
            browserCustomFingerPrint: dataState?.browserCustomFingerPrint,
          }
          const res = await loginUser(loginPayload)
          if (!res?.error) {
            dispatch(
              login({
                identity: dataState?.identity,
                ...res?.data,
              }),
            )
            navigate('/', { replace: true })
          } else {
            throw res
          }
        } else {
          navigate('/register', { state: { ...dataState, otp: enteredPin } })
        }
      } else {
        throw res
      }
    } catch (error) {
      enqueueSnackbar(
        error?.error?.data?.meta?.message ||
          error?.message ||
          error?.data?.message ||
          error?.data?.error?.message ||
          error?.error?.data?.meta?.error ||
          'Terjadi kesalahan',
        {
          variant: 'error',
        },
      )
    } finally {
      setIsAction(false)
    }
  }

  const handleResend = async () => {
    try {
      const res = await sendOtp({
        identity: dataState?.identity,
        deviceHash: dataState?.hash ?? null,
        type: dataState?.status,
        channel: validateEmail(dataState?.identity) ? 'EMAIL' : 'WHATSAPP',
        browserHash: dataState?.browserHash,
        browserFingerPrint: dataState?.browserFingerPrint,
        browserCustomFingerPrint: dataState?.browserCustomFingerPrint,
      })

      if (!res?.data?.meta?.error) {
        const newExpireTime = Number(res?.data?.data?.expireTime) || 60
        startCountdown(newExpireTime)
      } else {
        throw res
      }
    } catch (error) {
      enqueueSnackbar(
        error?.error?.data?.meta?.message ||
          error?.message ||
          error?.data?.message ||
          error?.data?.error?.message ||
          error?.error?.data?.meta?.error ||
          'Terjadi kesalahan',
        {
          variant: 'error',
        },
      )
    }
  }

  // useEffect(() => {
  //   if (token) {
  //     // dispatch(setIsV3(true))
  //     window.location.replace('/')
  //   }
  // }, [token])

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval)
          return 0
        }
        return prevCountdown - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    startCountdown(Number(dataState?.countDown) ?? 60)

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current)
      }
    }
  }, [])

  return (
    <>
      <CardPin
        title={`Konfirmasi ${dataState?.type === 'email' ? 'email' : 'nomor telepon'}`}
        desc={` Masukkan kode yang dikirim melalui${' '}
            ${dataState?.type === 'email' ? 'email' : 'WhatsApp'} ke${' '}
            ${dataState?.identity}.`}
        length={5}
        isOtp
        handleResendOtp={handleResend}
        handleSubmit={handleSubmit}
        expireTime={countdown}
        loading={isAction}
        setErrMsg={setErrMsg}
        errMsg={errMsg}
      />
    </>
  )
}

export default Otp
