import { createApiSlice } from 'app/createApiSlice'
import { API_ADDDRESS_BOOK, API_FIND_ADDDRESS_BOOK } from 'utils/apiList'

export const addressBookApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'addressBookApi/api',
  endpoints(builder) {
    return {
      fetchAddressBook: builder.query({
        query({
          userId,
          s,
          isAgent = 0,
          page = 0,
          size = 10,
          sortby = 'relationName',
          sortdir = 'asc',
          isPhone,
          isEmail,
          isRegistered,
        }) {
          const params = {
            userId,
            s,
            isAgent,
            page,
            size,
            sortby,
            sortdir,
          }

          if (isPhone !== undefined) params.isPhone = isPhone
          if (isEmail !== undefined) params.isEmail = isEmail
          if (isRegistered !== undefined) params.isRegistered = isRegistered

          return {
            url: API_ADDDRESS_BOOK,
            params,
          }
        },
      }),
      findAddressBook: builder.query({
        query({
          s,
          page = 0,
          size = 10,
          sortby = 'relationName',
          sortdir = 'asc',
          isPhoneUnRegistered,
          isPhoneRegistered,
          isEmailUnRegistered,
          isEmailRegistered,
          isPhoneVerified,
          isEmailVerified,
        }) {
          const params = {
            s,
            page,
            size,
            sortby,
            sortdir,
          }

          if (isPhoneUnRegistered !== undefined)
            params.isPhoneUnRegistered = isPhoneUnRegistered
          if (isPhoneRegistered !== undefined)
            params.isPhoneRegistered = isPhoneRegistered
          if (isEmailUnRegistered !== undefined)
            params.isEmailUnRegistered = isEmailUnRegistered
          if (isEmailRegistered !== undefined)
            params.isEmailRegistered = isEmailRegistered
          if (isPhoneVerified !== undefined)
            params.isPhoneVerified = isPhoneVerified
          if (isEmailVerified !== undefined)
            params.isEmailVerified = isEmailVerified

          return {
            url: API_FIND_ADDDRESS_BOOK,
            params,
          }
        },
      }),
      addAddressBook: builder.mutation({
        query(data) {
          const sanitizedData = Object?.fromEntries(
            Object?.entries(data)?.map(([key, value]) => [
              key,
              value === '' ? null : value,
            ]),
          )

          return {
            url: API_ADDDRESS_BOOK,
            method: 'POST',
            body: sanitizedData,
          }
        },
      }),
      removeAddressBook: builder.mutation({
        query({ id }) {
          return {
            url: `${API_ADDDRESS_BOOK}/${id}`,
            method: 'DELETE',
          }
        },
      }),
      updateAddressBook: builder.mutation({
        query({ id, ...data }) {
          const sanitizedData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [
              key,
              value === '' ? null : value,
            ]),
          )

          return {
            url: `${API_ADDDRESS_BOOK}/${id}`,
            method: 'PATCH',
            body: sanitizedData,
          }
        },
      }),
    }
  },
})

export const {
  useFetchAddressBookQuery,
  useLazyFetchAddressBookQuery,
  useFindAddressBookQuery,
  useLazyFindAddressBookQuery,
  useAddAddressBookMutation,
  useRemoveAddressBookMutation,
  useUpdateAddressBookMutation,
} = addressBookApiSlice
