import Logo from 'assets/images/pmg-logo-beta.png'
import { P } from 'components'
import { useEffect, useState } from 'react'
import { ArrowLeft, Delete } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Container, FormGroup, Input } from 'reactstrap'
import './otp.css'

const CardPin = ({
  title,
  desc,
  length,
  isOtp,
  handleResendOtp,
  handleSubmit,
  expireTime,
  loading,
  setErrMsg,
  errMsg,
}) => {
  const navigate = useNavigate()
  const [pin, setPin] = useState(['', '', '', '', ''])
  const [active, setActive] = useState('form-control-0')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const nextInput = (id) => {
    const input = document.getElementById(id)
    if (input) input.focus()
  }

  const handleKeypadClick = (number) => {
    setErrMsg('')
    const nextIndex = pin.findIndex((p) => p === '')

    if (nextIndex !== -1) {
      const newPin = [...pin]
      newPin[nextIndex] = number
      setPin(newPin)

      if (nextIndex < 4) {
        setActive(`form-control-${nextIndex + 1}`)
        nextInput(`form-control-${nextIndex + 1}`)
      }
    }
  }

  const handleDelete = () => {
    setErrMsg('')
    const lastIndex = pin.findIndex((p) => p === '')
    const indexToDelete = lastIndex === -1 ? 4 : lastIndex - 1

    if (indexToDelete >= 0) {
      const newPin = [...pin]
      newPin[indexToDelete] = ''
      setPin(newPin)
      setActive(`form-control-${indexToDelete}`)
      nextInput(`form-control-${indexToDelete}`)
    }
  }

  const formatCountdown = (count) => {
    const minutes = Math.floor(count / 60)
    const seconds = count % 60
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  useEffect(() => {
    if (pin.length === length && pin.every((pin) => pin !== '' && !loading)) {
      handleSubmit(pin.join(''))
    }
  }, [pin])

  return (
    <Container
      style={{ minHeight: '100vh', maxWidth: '600px' }}
      className="d-flex flex-column justify-content-between py-3 w-100"
    >
      <div
        onClick={() => {
          navigate(-1)
        }}
        className="d-flex justify-content-start d-block d-md-none"
      >
        <ArrowLeft size={20} />
      </div>

      <div
        className="bg-white rounded mt-0 mt-md-4 shadow-web"
        style={{ borderRadius: 99 }}
      >
        {/* Header Section */}
        <div>
          <img
            src={Logo}
            alt="Logo PasarMikro"
            className="img-web-only"
            style={{
              marginBottom: 24,
              maxWidth: '177px',
              height: 'auto',
            }}
          />

          <P size="large">{title}</P>
          <P size="regular" color={'#475467'}>
            {desc}
          </P>
        </div>

        {/* PIN */}
        <div className="d-flex flex-column">
          <div className="mt-2">
            <P className="text-danger" size="regular">
              {errMsg}
            </P>
          </div>
          <FormGroup className="d-flex gap-3 mt-4">
            {[...Array(length)].map((_, i) => (
              <div key={i}>
                <div
                  className="position-absolute top-0 start-0 w-0 h-0"
                  style={{ zIndex: 2, backgroundColor: 'transparent' }}
                  onClick={() => nextInput(active)}
                ></div>
                <Input
                  readOnly={isMobile ? true : false}
                  style={{
                    zIndex: 1,
                    fontSize: 30,
                    textAlign: 'center',
                  }}
                  autoFocus={i === 0}
                  className={`w-100 text-center ${
                    isMobile
                      ? 'fs-2 border border-dark border-top-0 border-start-0 border-end-0 rounded-0'
                      : ''
                  }`}
                  id={`form-control-${i}`}
                  key={i}
                  maxLength={1}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onFocus={(e) => {
                    e.target.style.boxShadow = 'none'
                  }}
                  value={pin[i]}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    const value = e.target.value

                    if (!e.code.includes('Digit') && e.code !== 'Backspace') {
                      e.preventDefault()
                    } else if (e.code === 'Backspace') {
                      if (i > 0 || pin[i] !== '') {
                        setPin((prevPin) => {
                          const newPin = [...prevPin]
                          newPin[i] = ''
                          return newPin
                        })
                        setActive(`form-control-${i - 1}`)
                        nextInput(`form-control-${i - 1}`)
                      }
                    } else if (e.code?.includes('Digit')) {
                      if (value.match(/^[0-9]$/)) {
                        setPin((prev) => {
                          const newPin = [...prev]
                          newPin[i] = value
                          return newPin
                        })

                        if (e.target.id !== 'form-control-5') {
                          setActive(`form-control-${i + 1}`)
                          nextInput(`form-control-${i + 1}`)
                        }
                      }
                    } else if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
                      e.preventDefault()
                      navigator.clipboard.readText().then((pasteData) => {
                        const cleanedData = pasteData.replace(/\D/g, '')
                        if (cleanedData.length > 0) {
                          setPin((prev) => {
                            const newPin = [...prev]
                            cleanedData.split('').forEach((char, index) => {
                              if (index < 5) {
                                newPin[index] = char
                              }
                            })
                            return newPin
                          })
                          nextInput('form-control-4')
                        }
                      })
                    }
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    const pasteData = e.clipboardData
                      .getData('Text')
                      .replace(/\D/g, '')
                    if (pasteData.length > 0) {
                      setPin((prev) => {
                        const newPin = [...prev]
                        pasteData.split('').forEach((char, index) => {
                          if (index < 5) {
                            newPin[index] = char
                          }
                        })
                        return newPin
                      })
                      nextInput('form-control-4')
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value

                    if (value.match(/^[0-9]$/)) {
                      setPin((prev) => {
                        const newPin = [...prev]
                        newPin[i] = value
                        return newPin
                      })

                      if (e.target.id !== 'form-control-4') {
                        setActive(`form-control-${i + 1}`)
                        nextInput(`form-control-${i + 1}`)
                      }
                    }
                  }}
                />
              </div>
            ))}
          </FormGroup>

          {/* Show/Hide PIN */}
          {isOtp ? (
            <div
              onClick={() => {
                if (expireTime === 0) {
                  handleResendOtp()
                }
              }}
            >
              <P
                size="regular"
                color={'#475467'}
                className={expireTime > 0 ? 'text-muted' : ''}
              >
                Tidak menerima kode?{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Klik untuk kirim ulang kode
                </span>{' '}
                ({formatCountdown(expireTime)})
              </P>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* Keypad */}
      <div className="mobile-only">
        <div className="d-flex flex-column mt-4">
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 border-start-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(1)}
            >
              1
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(2)}
            >
              2
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 border-top-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(3)}
            >
              3
            </Button>
          </ButtonGroup>
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 border-start-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(4)}
            >
              4
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(5)}
            >
              5
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(6)}
            >
              6
            </Button>
          </ButtonGroup>
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 border-start-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(7)}
            >
              7
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(8)}
            >
              8
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(9)}
            >
              9
            </Button>
          </ButtonGroup>
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border-top-0 border-start-0 rounded-0 p-3"
              size="lg"
            ></Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 rounded-0 p-3"
              size="lg"
              onClick={() => handleKeypadClick(0)}
            >
              0
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 rounded-0 p-3"
              size="lg"
              onClick={handleDelete}
            >
              <Delete size={20} />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Container>
  )
}

export default CardPin
