import { P } from 'components'
import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import { useState } from 'react'
import { ArrowLeft, ChevronDown, ChevronRight } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { URL_TRANSFER_INVENTORY } from 'utils/pathUrl'
import { useFetchInventoryTransferByIdQuery } from './transferInventoryApiSlice'

const DetailTransferInventory = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get('type')

  const {
    data,
    isLoading,
    refetch: refetch,
  } = useFetchInventoryTransferByIdQuery(
    {
      id,
      sortby: 'createdAt',
      sortdir: 'desc',
      type,
    },
    { skip: !id && !type },
  )

  const units = data?.items?.[0]?.warehousetransferitem?.map(
    (item) => item?.inventory?.unit,
  )
  const unitCode = data?.items?.[0]?.warehousetransferitem?.[0]?.inventory?.unit
  const isSameUnit = units?.every((unit) => unit === units[0])
  const isWaitingConfirm = !data?.items?.[0]?.approvedAt

  const totalAmount = isSameUnit
    ? data?.items?.[0]?.warehousetransferitem?.reduce(
        (sum, item) => sum + (item?.amount ?? 0),
        0,
      )
    : 0

  const getTimelineItem = (description, date) => (
    <div className="d-flex timeline-item">
      <div className="timeline-icon">
        <span className="circle-icon-wrap">
          <span className="circle-icon"></span>
        </span>
      </div>
      <div className="timeline-content">
        <div className="text-sm flex align-items-center gap-2">
          <span>{description}</span>
        </div>
        <small style={{ color: '#667085' }}>{formatDatePMBV2(date)}</small>
      </div>
    </div>
  )

  const isActionSell = data?.items?.[0]?.action === 'SELL'

  const extraData =
    data?.items?.[0]?.warehousetransferitem?.[0]?.inventory?.extradata

  let parsedData = {}
  if (extraData) {
    try {
      parsedData = JSON.parse(extraData)
    } catch (error) {
      parsedData = extraData
    }
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    // <div className="container mt-4">
    <div className="w-100 px-4">
      {/* Breadcrumb */}
      <div className="mt-4">
        <BreadCrumbs
          breadCrumbParent2="Transfer Inventori"
          breadCrumbParent2Link={`${URL_TRANSFER_INVENTORY}`}
          breadCrumbActive={`Detail Transfer Inventory`}
        />
      </div>

      <div className="row align-items-center py-3">
        <div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
          <ArrowLeft
            size={20}
            className="me-2"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`${URL_TRANSFER_INVENTORY}`)}
          />
          <h6 className="fw-bold mb-0">Detail Transfer Inventory</h6>
        </div>
      </div>

      <Row>
        {/* LEFT COLUMN  */}
        <Col md="5">
          <Card className="shadow-sm border-0 mb-3">
            <CardBody>
              <h6 className="fw-semibold mb-3">Informasi pengiriman</h6>
              <Row className="mb-2">
                <Col xs="6">
                  <P size="regular" color={'#101828'}>
                    Gudang penerima
                  </P>
                </Col>
                <Col xs="6">
                  <P size="regular" bold>
                    {data?.items?.[0]?.warehousereceiver?.name ?? '-'}
                  </P>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="6">
                  <P size="regular" color={'#101828'}>
                    Status
                  </P>
                </Col>
                <Col xs="6">
                  <Col
                    xs="6"
                    className="text-sm"
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {data?.items?.[0]?.approvedAt ? (
                      <div
                        className="badge rounded-pill bg-success-50 text-success-700 px-2"
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        Diterima
                      </div>
                    ) : data?.items?.[0]?.rejectedAt ? (
                      <div
                        className="badge rounded-pill bg-danger-50 text-danger-700 px-2"
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        Ditolak
                      </div>
                    ) : (
                      <div
                        className="badge rounded-pill bg-warning-50 text-warning-700 px-2"
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        Menunggu konfirmasi penerima
                      </div>
                    )}
                  </Col>
                </Col>
              </Row>
              {!isWaitingConfirm && (
                <Row className="mb-2">
                  <Col xs="6">
                    <P size="regular" color={'#101828'}>
                      Diterima oleh
                    </P>
                  </Col>
                  <Col xs="6">
                    <P size="regular" bold>
                      {data?.items?.[0]?.receiver?.name ?? '-'}
                    </P>
                  </Col>
                </Row>
              )}
              <hr className="px-3" />
              <Row className="mb-2">
                <Col xs="6">
                  <P size="regular" color={'#101828'}>
                    Gudang pengirim
                  </P>
                </Col>
                <Col xs="6">
                  <P size="regular" bold>
                    {data?.items?.[0]?.warehousesender?.name ?? '-'}
                  </P>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="6">
                  <P size="regular" color={'#101828'}>
                    Dibuat Oleh
                  </P>
                </Col>
                <Col xs="6">
                  <P size="regular" bold>
                    {data?.items?.[0]?.sender?.name ?? '-'}
                  </P>
                </Col>
              </Row>

              {/* HANDLE SHOW ACTION */}
              {data?.items?.[0]?.action === 'DELETED' && (
                <>
                  <hr className="px-3" />
                  <Row className="mb-2">
                    <Col xs="6">
                      <P size="regular" color={'#101828'}>
                        Aksi
                      </P>
                    </Col>
                    <Col xs="6">
                      <P size="regular" bold>
                        Pembuangan
                      </P>
                    </Col>
                  </Row>
                </>
              )}
              {data?.items?.[0]?.action === 'SELL' && (
                <>
                  <hr className="px-3" />
                  <Row className="mb-2">
                    <Col xs="6">
                      <P size="regular" color={'#101828'}>
                        Aksi
                      </P>
                    </Col>
                    <Col xs="6">
                      <P size="regular" bold>
                        Penjualan
                      </P>
                    </Col>
                  </Row>
                  {parsedData?.buyerName && (
                    <Row className="mb-2">
                      <Col xs="6">
                        <P size="regular" color={'#101828'}>
                          Nama Pembeli
                        </P>
                      </Col>
                      <Col xs="6">
                        <P size="regular" bold>
                          {parsedData?.buyerName}
                        </P>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {/* END HANDLE SHOW ACTION */}
            </CardBody>
          </Card>

          <Card className="shadow-sm border-0 mb-3">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="fw-semibold mb-3">Alur Transaksi</h6>
              </div>

              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                {data?.items?.[0]?.createdDate || data?.items?.[0]?.createdAt
                  ? getTimelineItem(
                      'Pengiriman dibuat',
                      data?.items?.[0]?.createdDate ??
                        data?.items?.[0]?.createdAt,
                    )
                  : null}
                {data?.items?.[0]?.approvedAt
                  ? getTimelineItem(
                      'Pengiriman dikonfirmasi',
                      data?.items?.[0]?.approvedAt,
                    )
                  : null}
                {data?.items?.[0]?.rejectedAt && (
                  <>
                    {getTimelineItem(
                      'Pengiriman dibatalkan',
                      data?.items?.[0]?.rejectedAt,
                    )}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* RIGHT COLUMN */}
        <Col md="7">
          <Card className="shadow-sm border-0 mb-3">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="fw-semibold mb-3">Rincian pengiriman</h6>
              </div>

              {data?.items?.[0]?.warehousetransferitem?.map((item) => {
                let parsedData = {}
                if (item?.inventory?.extradata) {
                  try {
                    parsedData = JSON.parse(item.inventory.extradata)
                  } catch (error) {
                    parsedData = item.inventory.extradata
                  }
                }

                const amount = Number(item?.amount ?? 0)
                const price = Number(parsedData?.price ?? 0)
                const unitPrice = amount > 0 ? price / amount : 0

                return (
                  <div className="mb-2" key={item?.id}>
                    <p className="mb-1">
                      <span className="text-sm">
                        {item?.inventory?.item?.nameItem ?? '-'}
                      </span>

                      {isActionSell ? (
                        <span className="float-end fw-semibold">
                          Rp
                          {newFormatThousandSeparator(price)}
                        </span>
                      ) : (
                        <span className="float-end fw-semibold">
                          {newFormatThousandSeparator(amount)}{' '}
                          {item?.inventory?.unit ?? '-'}
                        </span>
                      )}
                    </p>

                    {isActionSell && price > 0 && (
                      <p className="text-sm text-primary">
                        {newFormatThousandSeparator(amount)}{' '}
                        <span className="text-capitalize">
                          {item?.inventory?.unit}{' '}
                        </span>
                        x Rp
                        {newFormatThousandSeparator(unitPrice, 0)}
                      </p>
                    )}
                  </div>
                )
              })}

              {isSameUnit && (
                <>
                  <hr />
                  <h6>
                    Total{' '}
                    <strong className="float-end fw-semibold">
                      {isActionSell ? (
                        <>
                          Rp
                          {newFormatThousandSeparator(
                            data?.items?.[0]?.warehousetransferitem?.reduce((total, item) => {
                              let parsedData = {}
                              try {
                                parsedData = JSON.parse(item?.inventory?.extradata ?? '{}')
                              } catch (error) {
                                parsedData = item?.inventory?.extradata ?? {}
                              }
                              return total + Number(parsedData?.price ?? 0)
                            }, 0),
                            0,
                          )}
                        </>
                      ) : (
                        <>
                          {newFormatThousandSeparator(
                            Number(totalAmount ?? 0),
                            0,
                          )}{' '}
                          {unitCode ?? ''}
                        </>
                      )}
                    </strong>
                  </h6>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* END RIGHT COLUMN */}
      </Row>
    </div>
  )
}

// **Recursive Component untuk Nested Group**
const Group = ({ group, level }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className="mb-2"
      style={{
        paddingLeft: `${level * 20}px`,
      }}
    >
      {/* Header Grup */}
      <div
        className="fw-bold d-flex align-items-center p-2"
        style={{ cursor: 'pointer', width: '100%', backgroundColor: '#f9f9f9' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <ChevronDown size={18} className="me-1" />
        ) : (
          <ChevronRight size={18} className="me-1" />
        )}

        {group.name}
      </div>

      {/* Konten Grup */}
      <Collapse isOpen={isOpen}>
        <div className="ps-4">
          {group?.items?.map((item) => (
            <RenderItem key={item.id} {...item} />
          ))}

          {group.subGroups.map((subGroup) => (
            <Group key={subGroup.id} group={subGroup} level={level + 1} />
          ))}
        </div>
      </Collapse>
    </div>
  )
}

// **Component untuk Render Item Kopi**
const RenderItem = ({ name, code, weight }) => (
  <div className="d-flex justify-content-between align-items-center py-2">
    <div className="d-flex align-items-center">
      <img
        src="https://img.freepik.com/free-vector/fresh-cherry-fruit-healthy_24877-82070.jpg?t=st=1740047396~exp=1740050996~hmac=eebd65e36aedea338b836d11e417f162a67e644a601bd95b322d408700da560a&w=1380"
        alt={name}
        className="me-2 rounded-circle"
        onError={(e) => (e.target.src = 'https://via.placeholder.com/30')}
        style={{ width: 24, height: 24, borderRadius: '50%' }}
      />
      <div>
        <P size="regular">{name}</P>
        <P size="regular" color="#667085">
          {code}
        </P>
      </div>
    </div>
    <P size="regular" fontWeightCustom={500}>
      {weight} kg
    </P>
  </div>
)

export default DetailTransferInventory
