import React from 'react'
import { P } from 'components'
import { Button, Modal } from 'reactstrap'
import './modal.css'

export default function InfoSetPinModal({
  isOpen,
  type,
  onPressSetPinPassword,
}) {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="info-set-pin-modal">
        <div className="modal-pin-content">
          <div className="set-pin-info-image">
            <img src={require('../assets/images/pinPassword.png')} alt="PIN" />
          </div>

          <div className="description">
            <P size="medium">
              {type === 'pin'
                ? 'Buat PIN untuk verifikasi transaksi Anda'
                : 'Buat password Anda'}
            </P>
          </div>

          <div className="subtext">
            <P size="regular">
              {type === 'pin'
                ? 'Kami telah menyatukan layanan kami dalam satu aplikasi. Demi keamanan, setiap transaksi memerlukan verifikasi dengan PIN. Silakan buat PIN Anda sekarang.'
                : 'Kami telah menyatukan layanan kami dalam satu aplikasi. Tambahkan kata sandi untuk masuk dengan lebih aman dan mudah di berbagai perangkat.'}
            </P>
          </div>

          <div className="button-container">
            <Button
              className="w-100"
              color="primary"
              onClick={() => {
                onPressSetPinPassword(type)
              }}
            >
              <P>Buat {type === 'pin' ? 'PIN' : ' kata sandi'}</P>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
