import { apiEndpoint } from 'configs'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const setPassword = createAsyncThunk(
  'auth/setPassword',
  async (password, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/setpassword`,
        password,
        config,
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const setPin = createAsyncThunk(
  'auth/setPin',
  async (pin, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/setpin`,
        { pin },
        config,
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
