import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'

const getInventory = createAsyncThunk(
  'manage/inventory',
  async (
    { page, size, search, sortBy, asc, groupId, warehouseId, excludeId, all },
    { rejectWithValue, getState },
  ) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventory?page=${page}&size=${size ?? 10}`
      if (search) {
        url += `&search=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }
      if (warehouseId) {
        url += `&warehouseId=${warehouseId}`
      }
      if (groupId) {
        url += `&groupId=${groupId}`
      }
      if (excludeId) {
        url += `&excludeId=${excludeId}`
      }
      if (all) {
        url += `&all=${all}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getPackingDetail = createAsyncThunk(
  'manage/packingDetail',
  async ({ page, search, sortBy, asc, id }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventorygroups/${id}?page=${page}&size=10`
      if (search) {
        url += `&search=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getWarehouse = createAsyncThunk(
  'manage/warehousePIC',
  async ({ isForFilter, isAdd }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/warehouse${isForFilter ? '?isForFilter=1' : ''}`
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      let allItems = null
      const { data } = await axios.get(url, config)
      allItems = data
      if (data?.items?.length < data?.totalItems) {
        const res = await axios.get(url, config)
        allItems = res?.data
      }
      let newData = allItems?.items?.map((e) => {
        return {
          ...e,
          label: e?.name,
          value: e?.id,
        }
      })
      if (isAdd) {
        newData = [
          {
            name: 'Semua Gudang',
            label: 'Semua Gudang',
            id: undefined,
          },
          ...newData,
        ]
      }
      return { ...data, newData, ...(isForFilter && { isForFilter: true }) }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getWarehouseForAdd = createAsyncThunk(
  'manage/warehouseForAdd',
  async ({ isForFilter }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/warehouse${isForFilter ? '?isForFilter=1' : ''}`
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      let allItems = null
      const { data } = await axios.get(url, config)
      allItems = data
      if (data?.items?.length < data?.totalItems) {
        const res = await axios.get(url, config)
        allItems = res?.data
      }
      const newData = allItems?.items?.map((e) => {
        return {
          ...e,
          label: e?.name,
          value: e?.id,
        }
      })
      return { ...data, newData, ...(isForFilter && { isForFilter: true }) }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getGroupInventory = createAsyncThunk(
  'manage/groupInventory',
  async (
    { page, size, search, sortBy, asc, warehouseId },
    { rejectWithValue, getState },
  ) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventorygroups?page=${page}&size=${size ?? 10}`
      if (search) {
        url += `&s=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }
      if (warehouseId) {
        url += `&warehouseId=${warehouseId}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getItemsInventory = createAsyncThunk(
  'manage/items',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const url = `${apiEndpoint}/api/v1/bussines/items?page=0&size=9999`
      const { data } = await axios.get(url, config)

      const { items } = data

      if (!items || items.length === 0) {
        return rejectWithValue('No items found')
      }

      const responseData = {
        items,
        totalItems: items.length,
        totalPages: 1,
        currentPage: 0,
      }
      return responseData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const createStock = createAsyncThunk(
  'manage/create',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const updateInventory = createAsyncThunk(
  'manage/update',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/update`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const transformInventory = createAsyncThunk(
  'manage/transform',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/transform`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const splitInventory = createAsyncThunk(
  'manage/split',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/splittomany`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const mergeInventory = createAsyncThunk(
  'manage/split',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/merge`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const removeInventory = createAsyncThunk(
  'manage/split',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/api/v1/bussines/inventory`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        data: payload,
      }
      const { data } = await axios.request(config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getLabelMenuItems = createAsyncThunk(
  'manage/getLabelMenuItems',
  async ({ rawItemId }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const url = `${apiEndpoint}/api/v1/bussines/items-create-label?size=9999&page=0&sortby=createdAt&sortdir=desc&rawItemId=${rawItemId}`
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const fetchDynamicItems = createAsyncThunk(
  'manage/fetchDynamicItems',
  async ({ createMethodLabel, rawItemId }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      const url = `${apiEndpoint}/api/v1/bussines/items?size=9999&page=0&sortby=createdAt&sortdir=desc&rawItemId=${rawItemId}&createMethodLabel=${createMethodLabel}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message)
    }
  },
)

const packingStock = createAsyncThunk(
  'manage/packing',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventorygroups`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const packingAddStock = createAsyncThunk(
  'manage/packingAddStock',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventorygroups/${payload?.id}`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const packingUpdate = createAsyncThunk(
  'manage/packingUpdate',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventorygroups/${payload?.id}`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const removePacking = createAsyncThunk(
  'manage/removePacking',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/api/v1/bussines/inventorygroups`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        data: payload,
      }
      const { data } = await axios.request(config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const removeItemPacking = createAsyncThunk(
  'manage/removeItemPacking',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventorygroups/${payload?.id}`,
        payload,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const splitToManyInventory = createAsyncThunk(
  'manage/splitToMany',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/inventory/splittomany`,
        { ...payload, action: 'TRANSFORM' },
        config,
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const manageGroupForGroup = createAsyncThunk(
  'manage/groupAction',
  async ({ actionType, payload, id = null }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      let endpoint = `${apiEndpoint}/api/v1/bussines/inventorygroupsforgroup`

      if (id) {
        endpoint += actionType === 'create' ? `/add/${id}` : `/${id}`
      }

      const response = await axios.post(endpoint, payload, config)
      return response.data
    } catch (error) {
      let errorMessage
      if (
        error?.response?.status === 500 ||
        error?.response?.data?.message === 'Validation error'
      ) {
        errorMessage = 'Nama grup sudah digunakan.'
      } else {
        errorMessage =
          error.response?.data?.message ||
          error.message ||
          'Unknown error occurred'
      }

      return rejectWithValue(errorMessage)
    }
  },
)

const fetchInventoryGroups = createAsyncThunk(
  'manage/fetchInventoryGroups',
  async (
    { page = 0, size, search, sortBy, asc, warehouseId, parentGroupId },
    { rejectWithValue, getState },
  ) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      let url = `${apiEndpoint}/api/v1/bussines/inventorygroups/outer?page=${page}&size=${size ?? 10}`

      if (search) {
        url += `&s=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy ?? 'updatedAt'}&sortdir=${asc ?? 'desc'}`
      }
      if (warehouseId) {
        url += `&warehouseId=${warehouseId}`
      }
      if (parentGroupId) {
        url += `&parentGroupId=${parentGroupId}`
      }

      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const deleteInventory = createAsyncThunk(
  'manage/deleteInventory',
  async (
    {
      arrInventoryIds,
      note,
      action = 'DELETED',
      buyerName,
      price,
      arrPartialAmounts,
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const payload = {
        arrInventoryIds,
        note,
        action,
        buyerName,
        price,
        arrPartialAmounts,
      }

      const url = `${apiEndpoint}/api/v1/bussines/inventory`

      const { data } = await axios.delete(url, {
        data: payload,
        headers: config.headers,
      })

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export {
  createStock,
  deleteInventory,
  fetchDynamicItems,
  fetchInventoryGroups,
  getGroupInventory,
  getInventory,
  getItemsInventory,
  getLabelMenuItems,
  getPackingDetail,
  getWarehouse,
  getWarehouseForAdd,
  manageGroupForGroup,
  mergeInventory,
  packingAddStock,
  packingStock,
  packingUpdate,
  removeInventory,
  removeItemPacking,
  removePacking,
  splitInventory,
  splitToManyInventory,
  transformInventory,
  updateInventory,
}
