import { P } from 'components'
import CustomButton from 'components/Buttons/CustomButton'
import InputPhoneNumber from 'components/InputPhoneNumber'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, FormGroup, Label } from 'reactstrap'

const Tnc = () => {
  const navigate = useNavigate()

  return (
    <div className="pt-2">
      <p className="text-center">
        <P size="regular">
          Dengan masuk atau daftar, kamu menyetujui{' '}
          <span
            className="text-primary"
            onClick={() =>
              navigate('/syarat-dan-ketentuan', {
                state: { isNewLogin: true },
              })
            }
          >
            {' '}
            Ketentuan Layanan
          </span>{' '}
          dan
          <span
            className="text-primary"
            onClick={() =>
              navigate('/syarat-dan-ketentuan', {
                state: { isNewLogin: true },
              })
            }
          >
            {' '}
            Kebijakan Privasi
          </span>{' '}
          dari PasarMIKRO
        </P>
      </p>
      <div className="text-xs text-center text-muted">
        {process.env.REACT_APP_VERSION}
      </div>
    </div>
  )
}

export default Tnc
