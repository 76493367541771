import { useEffect } from 'react'

const useEnterSubmit = (callback, isDisabled) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && !isDisabled) {
        callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback, isDisabled])
}

export default useEnterSubmit
