import { createAppSlice } from 'app/createAppSlice'
import {
  getDetailRollover,
  getLateInvoice,
  getTenors,
} from './talanginInvoiceAction'

const initialState = {
  tenors: null,
  selectedRow: null,
  selectedTenor: null,
  detailRollover: null,
  justRolledOver: '',
  isOpenSidebar: false,
  isOpenModalPartial: false,
  isOpenModalPassword: false,
  lateInvoice: null,
}

export const talanginInvoiceSlice = createAppSlice({
  name: 'talanginInvoice',
  initialState,
  reducers: (create) => ({
    setSelectedRow: create.reducer((state, { payload }) => {
      state.selectedRow = payload
    }),
    setSelectedTenor: create.reducer((state, { payload }) => {
      state.selectedTenor = payload
    }),
    setJustRolledOver: create.reducer((state, { payload }) => {
      state.justRolledOver = payload
    }),
    toggleSidebarTalangin: create.reducer((state) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    toggleModalPartial: create.reducer((state) => {
      state.isOpenModalPartial = !state.isOpenModalPartial
    }),
    toggleModalPassword: create.reducer((state) => {
      state.isOpenModalPassword = !state.isOpenModalPassword
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(getTenors.fulfilled, (state, { payload }) => {
      state.tenors = payload
    })
    builder.addCase(getDetailRollover.fulfilled, (state, { payload }) => {
      state.detailRollover = payload
    })
    builder.addCase(getLateInvoice.fulfilled, (state, { payload }) => {
      state.lateInvoice = payload
    })
  },
})

export const {
  setSelectedRow,
  setSelectedTenor,
  setJustRolledOver,
  toggleSidebarTalangin,
  toggleModalPartial,
  toggleModalPassword,
} = talanginInvoiceSlice.actions

export default talanginInvoiceSlice.reducer
