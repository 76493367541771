import { createAppSlice } from 'app/createAppSlice'
import moment from 'moment'

const initialState = {
  page: 0,
  search: '',
  size: 10,
  sortBy: undefined,
  sortDir: undefined,
  status: undefined,
  selectedFilter: 'all',
  pmbSearch: '',
  pmbLunas: false,
  pmbBelumLunas: false,
  pmbDueDateToday: false,
  pmbLate: false,
  pmbRollover: false,
  startDate: moment().startOf('month').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
}

export const talanginTableSlice = createAppSlice({
  name: 'talanginTable',
  initialState,
  reducers: (create) => ({
    setFilter: create.reducer((state, { payload }) => {
      Object.assign(state, payload)
    }),

    resetFilter: create.reducer((state) => {
      Object.assign(state, initialState)
    }),

    setPage: create.reducer((state, { payload }) => {
      Object.assign(state, {
        page: payload.page,
      })
    }),

    setSize: create.reducer((state, { payload }) => {
      Object.assign(state, {
        size: payload.size,
      })
    }),
  }),
})

export const { setFilter, setPage, resetFilter, setSize } =
  talanginTableSlice.actions
export default talanginTableSlice.reducer
