import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBModalImagePreview from 'components/PMBModalImagePreview'
import PMBModalRemove from 'components/PMBModalRemove'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import PMBTooltip from 'components/PMBTooltip'
import { Can } from 'context/Acl'
import { useDebounce } from 'helpers'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Eye, MapPin, Trash2 } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Spinner } from 'reactstrap'
import { BUSINESS_VESSELS, MANAGE } from 'utils/subjectActions'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import ModalPemetaan from './ModalPemetaan'
import {
  useDeleteVesselByIdMutation,
  useFetchVesselsQuery,
} from './vesselsListApiSlice'
import { setDetailVessel, toggleModalPemetaan } from './vesselsListSlice'

const VessselsTable = () => {
  const [deleteVesselById, { isLoading: isLoadingDelete }] =
    useDeleteVesselByIdMutation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [dataDetailRemove, setDataDetailRemove] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [search, setSearch] = useState('')
  const [modalOpenImage, setModalOpenImage] = useState(false)
  const [imageItems, setImagesItems] = useState([])
  const [isNoDataFilterAvailable, setIsNoDataFilterAvailable] = useState(false)
  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('vesselName')
  const [sortDirection, setSortDirection] = useState('ASC')

  const onOpenCloseModalDelete = () => {
    setIsOpenModalDelete((prev) => !prev)
  }

  const onClickIconRemove = (row) => {
    setDataDetailRemove(row)
    onOpenCloseModalDelete()
  }

  const { data, isLoading, refetch, isFetching } = useFetchVesselsQuery({
    page: currentPage ?? 0,
    size: rowsPerPage ?? 10,
    searchvalue: search,
    sortby: sortColumn,
    sortdir: sortDirection,
  })

  // HANDLE LOADING FILTER
  useEffect(() => {
    if (!isFetching && data?.totalItems === 0) {
      setIsNoDataFilterAvailable(true)
    } else {
      setIsNoDataFilterAvailable(false)
    }
  }, [isFetching, data?.totalItems])

  const onHandleOpenImageModal = (imageUrl) => {
    const images = Array.isArray(imageUrl) ? imageUrl : [imageUrl]
    setImagesItems(images)
    setModalOpenImage(true)
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: <span>Vessel Name</span>,
      selector: 'vesselName',
      sortable: true,
      width: '250px',
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <img
              src={row?.linkImage !== '' ? row?.linkImage : VesselNotFound}
              alt={row?.vesselName ?? 'vessel-img'}
              width={32}
              height={32}
              className="rounded-circle"
              onClick={() =>
                onHandleOpenImageModal(row?.linkImage || VesselNotFound)
              }
              style={{ cursor: 'pointer' }}
            />
            <span className="fw-bold text-primary m-0">
              {row?.vesselName ?? '-'}
            </span>
          </div>
        )
      },
    },
    {
      name: <span>Vessel GT</span>,
      selector: 'vesselGT',
      sortable: true,
      width: '120px',
      cell: (row) => {
        return <span>{row?.vesselGT ?? '-'}</span>
      },
    },
    {
      name: <span>Vessel Gear</span>,
      selector: 'vesselGear',
      sortable: true,
      width: '150px',
      cell: (row) => {
        return <span>{row?.vesselGear ?? '-'}</span>
      },
    },
    {
      name: <span>AP2HI UVI</span>,
      selector: 'ap2hiUVI',
      sortable: true,
      width: '300px',
      cell: (row) => {
        return <span>{row?.ap2hiUVI ?? '-'}</span>
      },
    },
    {
      name: <span>Fisheries</span>,
      // selector: 'fisheries',
      // sortable: true,
      width: '300px',
      cell: (row) => {
        return (
          <span>
            {row?.fisheriesList?.length
              ? row.fisheriesList.map((item, index) => (
                  <li key={index} className="py-1">
                    {item?.fisheriesData?.name}
                  </li>
                ))
              : '-'}
          </span>
        )
      },
    },
    {
      name: <span>Certification Status</span>,
      selector: 'certStatus',
      sortable: true,
      cell: (row) => {
        const parseCertification = row?.certStatus ?? null

        if (!parseCertification || parseCertification === "['']") {
          return <span className="pl-4">-</span>
        }

        const validJson = parseCertification.replace(/'/g, '"')
        let certStatusArray = []

        try {
          certStatusArray = JSON.parse(validJson)
        } catch (error) {
          console.error('Failed to parse certification status JSON:', error)
          return <span>Invalid certification data</span>
        }

        return (
          <ul style={{ margin: 0, padding: 0 }}>
            {certStatusArray.map((cert, index) => (
              <li key={index} className="py-1">
                {cert}
              </li>
            ))}
          </ul>
        )
      },
    },
    {
      name: '',
      width: '150px',
      cell: (row) => {
        return (
          <Can I={MANAGE} this={BUSINESS_VESSELS}>
            <div
              className="d-flex align-items-center"
              style={{ gap: '12px', flexGrow: 1 }}
            >
              <div id={`pemetaan-vessel-${row?.id}`}>
                <MapPin
                  size={18}
                  onClick={() => {
                    dispatch(setDetailVessel(row))
                    dispatch(toggleModalPemetaan())
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <PMBTooltip
                target={`pemetaan-vessel-${row?.id}`}
                text="Pemetaan"
              />

              <div id={`vessel-${row?.id}`}>
                <Eye
                  size={18}
                  onClick={() => {
                    navigate(`/vessels/${row?.id}`)
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <PMBTooltip target={`vessel-${row?.id}`} text="Lihat Detail" />

              <div id={`remove-vessel-${row?.id}`}>
                <Trash2
                  size={18}
                  onClick={() => {
                    onClickIconRemove(row)
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <PMBTooltip target={`remove-vessel-${row?.id}`} text="Hapus" />
            </div>
          </Can>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  const onRemoveDataVessel = async (id) => {
    try {
      if (!id) {
        enqueueSnackbar('Vessel gagal dihapus!', { variant: 'error' })

        return
      }

      const res = await deleteVesselById({ id }).unwrap()
      if (res) {
        enqueueSnackbar('Vessel berhasil di hapus!', { variant: 'success' })

        refetch()
        onOpenCloseModalDelete()
      }
    } catch (error) {
      console.error('Error saat menghapus vessel!', error)
      enqueueSnackbar('Error saat menghapus vessel!', { variant: 'error' })
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    refetch()
  }, 600)

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Data Kapal" />
      </div>

      {/* MODAL PEMETAAN NELAYAN */}
      <ModalPemetaan />

      {/* MODAL REMOVE */}
      <PMBModalRemove
        isOpen={isOpenModalDelete}
        onClose={() => onOpenCloseModalDelete()}
        title={`Apakah Anda yakin ingin menghapus data ${
          dataDetailRemove?.vesselName
        }?`}
        dataId={dataDetailRemove?.id}
        isLoading={isLoadingDelete}
        onRemoveData={(id) => {
          onRemoveDataVessel(id)
        }}
      />

      <Card className="mb-3 border-0 shadow-sm">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || isFetching}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={true}
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                {isNoDataFilterAvailable
                  ? 'Tidak ada data yang sesuai dengan filter Anda.'
                  : 'Belum ada vessel yang didaftarkan'}
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <PMBPagination
                  rowCount={data?.totalItems || 0}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRowsPerPage={setRowsPerPage}
                  props={props}
                />
              )
            }}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Kapal</h5>

                  <Can I={MANAGE} this={BUSINESS_VESSELS}>
                    <Button
                      onClick={() => navigate('/vessels/add')}
                      size="sm"
                      color="primary"
                    >
                      + Tambah Kapal
                    </Button>
                  </Can>
                </div>

                <div className="my-4">
                  <PMBSearchInputGroup
                    label={'Cari'}
                    value={searchInput}
                    placeholder="Cari nama kapal..."
                    onChange={(e) => onHandleSearchChange(e.target.value)}
                    maxWidth="25%"
                  />
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      {/* MODAL IMAGE PREVIEW */}
      <PMBModalImagePreview
        isOpen={modalOpenImage}
        toggle={() => setModalOpenImage(!modalOpenImage)}
        images={imageItems}
      />
    </div>
  )
}

export default VessselsTable
