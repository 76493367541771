import ICUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import ICTalangin from 'assets/icons/uang-usaha.png'
import PMBTabSwitcher from 'components/PMBTabSwitcher'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Col, Label } from 'reactstrap'
import { setFetchOption, toggleSidebarFilter } from '../dashboardSlice'
import PMBSelect from 'components/PMBSelect'

const HeaderTable = ({ type, abilityBuy, abilitySell, handleFilter }) => {
  const dispatch = useDispatch()
  const { tag } = useSelector((s) => s.dashboard)
  const [activeTab, setActiveTab] = useState(
    type || (abilityBuy ? 'BUY' : abilitySell ? 'SELL' : null),
  )

  const paymentOptions = [
    {
      value: undefined,
      label: 'Semua',
    },
    {
      value: 'direct',
      label: (
        <div className="d-flex align-items-center gap-2">
          <img width={20} height={20} src={ICUangBelanja} alt="Uang Belanja" />{' '}
          Uang Belanja
        </div>
      ),
    },
    {
      value: 'talangan',
      label: (
        <div className="d-flex align-items-center gap-2">
          <img width={20} height={20} src={ICTalangin} alt="Talangin" />{' '}
          Talangin
        </div>
      ),
    },
  ]

  const statusOptions = [
    {
      value: undefined,
      label: 'Semua',
    },
    {
      value: 'Dibatalkan',
      label: 'Dibatalkan',
    },
    {
      value: 'Diproses',
      label: 'Diproses',
    },
    {
      value: 'Menunggu Approval Admin',
      label: 'Menunggu Approval Admin',
    },
    {
      value: 'Menunggu Proses Penjual',
      label: 'Menunggu Proses Penjual',
    },
    {
      value: 'Menunggu Pembayaran Pembeli',
      label: 'Menunggu Pembayaran Pembeli',
    },
    {
      value: 'Selesai',
      label: 'Selesai',
    },
  ]

  useEffect(() => {
    if (!type) {
      dispatch(
        setFetchOption({
          type: abilityBuy ? 'BUY' : abilitySell ? 'SELL' : '',
        }),
      )
    }
  }, [type, abilityBuy, abilitySell, dispatch])

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey)
    dispatch(
      setFetchOption({
        type: tabKey,
      }),
    )
  }

  const tabs = [
    { key: 'BUY', label: 'Pembelian' },
    { key: 'SELL', label: 'Penjualan' },
  ].filter(
    (tab) =>
      (tab.key === 'BUY' && abilityBuy) || (tab.key === 'SELL' && abilitySell),
  )

  return (
    <div className="w-100">
      {tabs.length > 0 && (
        <div className="mb-2 d-flex justify-content-start">
          <PMBTabSwitcher
            activeTab={activeTab}
            tabs={tabs}
            onTabChange={handleTabChange}
          />
        </div>
      )}

      <div className="d-flex flex-wrap gap-3 mt-2">
        <Col className="mb-3" style={{ maxWidth: '300px', flex: '1' }}>
          <p className="mb-2">Status</p>
          <PMBSelect
            style={{ width: '100%' }}
            options={statusOptions}
            value={
              statusOptions.find((option) => option.value === tag.status) ??
              statusOptions[0]
            }
            onChange={(e) => {
              dispatch(
                setFetchOption({
                  status: e?.value ?? undefined,
                }),
              )
            }}
          />
        </Col>

        <Col className="mb-3" style={{ maxWidth: '300px', flex: '1' }}>
          <p className="mb-2">Metode Pembayaran</p>
          <PMBSelect
            style={{ width: '100%' }}
            options={paymentOptions}
            value={
              paymentOptions.find((option) => option.value === tag.payment) ??
              paymentOptions[0]
            }
            onChange={(e) => {
              dispatch(
                setFetchOption({
                  payment: e?.value ?? undefined,
                }),
              )
            }}
          />
        </Col>
      </div>
    </div>
  )
}

export default HeaderTable
