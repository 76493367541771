import { createApiSlice } from 'app/createApiSlice'

export const talanginInvoiceApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'talanginInvoice/hooks',
  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchTalanginInvoice: builder.query({
        query(payload) {
          let url = `/api/v1/bussines/talanginv2`

          const params = {
            page: payload.page,
            size: payload?.size,
            sortBy: payload.sortBy,
            sortDir: payload.sortDir,
            repo:
              payload.selectedRepo === 'yes'
                ? 'yes'
                : payload.selectedRepo === 'no'
                  ? 'no'
                  : 'all',
            from: payload.from,
            to: payload.to,
          }

          if (payload.pmbSearch) {
            params.pmbSearch = payload.pmbSearch
          }

          if (payload.pmbLunas) params.pmbLunas = 1
          else if (payload.pmbBelumLunas) params.pmbBelumLunas = 1
          else if (payload.pmbDueDateToday) params.pmbDueDateToday = 1
          else if (payload.pmbLate) params.pmbLate = 1
          else if (payload.pmbRollover) params.pmbRollover = 1

          return {
            url,
            params,
          }
        },
      }),
      fetchTalanginInvoiceDetail: builder.query({
        query({ id }) {
          return `/api/v1/bussines/talangin/detail?userpinjamanId=${id}`
        },
      }),
      postPayTalangin: builder.mutation({
        query(payload) {
          return {
            url: `/api/v2/bussines/talangin`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      postCheckPass: builder.mutation({
        query(payload) {
          const { pin } = payload
          return {
            url: `/api/v1/bussines/checkpasswordtrx`,
            method: 'POST',
            body: {
              pin: pin,
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchTalanginInvoiceQuery,
  usePostPayTalanginMutation,
  usePostCheckPassMutation,
  useFetchTalanginInvoiceDetailQuery,
} = talanginInvoiceApiSlice
