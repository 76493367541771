import { useEffect } from 'react'
import { CheckCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, CardBody } from 'reactstrap'
import { getVesselsDetail } from '../list-vessels/vesselsListAction'
import { useFetchVesselsDetailQuery } from '../list-vessels/vesselsListApiSlice'
import ModalChangeProfil from './ModalChangeProfil'
import { toggleBannerDetail, toggleModalChangeProfile } from './detailSlice'

import LoadingV2 from 'components/LoadingV2'
import BreadCrumbs from 'components/breadcrumbs'
import { VESSEL_OTHER_STATUS_OPTIONS } from 'constants'
import IconAvatar from '../../../../assets/icons/icon-avatar.png'
import IconCheckStatus from '../../../../assets/icons/icon-check.svg'
import IconXStatus from '../../../../assets/icons/icon-x.svg'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import ModalPemetaan from '../list-vessels/ModalPemetaan'
import { toggleModalPemetaan } from '../list-vessels/vesselsListSlice'

const DetailVessel = () => {
  const dispatch = useDispatch()
  const vesselId = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )

  const { data, isLoading } = useFetchVesselsDetailQuery({ id: vesselId })

  const { isOpenBanner } = useSelector((s) => s.farmersDetail)

  useEffect(() => {
    if (vesselId) {
      dispatch(getVesselsDetail())
    }
  }, [vesselId, data, dispatch])

  const certStatusRaw = data?.certStatus
  const cleanedCertStatus =
    certStatusRaw && certStatusRaw.replace(/[\[\]']/g, '').trim().length > 0
      ? certStatusRaw
          .replace(/[\[\]']/g, '')
          .split(',')
          .map((status) => status.trim())
      : []

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <>
      <div className="w-100 px-3 gap-10">
        <div className="mt-3">
          <BreadCrumbs
            breadCrumbTitle="Master Kapal"
            breadCrumbParent2="Daftar Kapal"
            breadCrumbParent2Link={'/vessels'}
            breadCrumbActive="Lihat Detail"
          />
        </div>

        <ModalPemetaan row={data} />

        <ModalChangeProfil row={data || {}} />

        <div>
          <Alert
            isOpen={isOpenBanner}
            toggle={() => dispatch(toggleBannerDetail())}
            color="success"
            className="d-flex align-items-center my-4 w-100"
          >
            <span>
              <CheckCircle size={17} /> Data diri berhasil diubah.
            </span>
          </Alert>
        </div>

        <div className="row">
          {/* Left Column */}
          <div className="col-md-6 mb-3">
            <Card className="border-0 shadow-sm">
              <CardBody className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Data Kapal</h5>
                  <h6
                    onClick={() => dispatch(toggleModalChangeProfile())}
                    style={{ cursor: 'pointer' }}
                    className="text-primary"
                  >
                    Ubah
                  </h6>
                </div>
                <div className="text-center my-4">
                  <img
                    src={
                      data?.linkImage !== '' ? data?.linkImage : VesselNotFound
                    }
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: '50%',
                    }}
                    alt="img"
                  />
                  <h5 style={{ marginTop: 10 }}>{data?.vesselName}</h5>
                </div>

                <div className="border border-b mb-3" />

                {/* Vessel Information */}
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">
                      Vessel Registration
                    </span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.licenseNumber ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">UVI</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.ap2hiUVI ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">License Number</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.licenseNumber ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Owner Name</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.ownerName ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Vessel Flag</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselFlag ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Vessel GT</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselGT ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Gear Type</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselGear ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Vessel Type</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselType ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">MOTR Mark</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.motrMark?.trim() ? data?.motrMark : '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">
                      Certification Status
                    </span>
                  </div>
                  <div className="col-6">
                    {cleanedCertStatus.length > 0
                      ? cleanedCertStatus.map((status, index) => (
                          <p key={index} className="text-sm text-muted mb-1">
                            <img
                              src={IconCheckStatus}
                              loading="lazy"
                              alt="icon"
                            />{' '}
                            {status}
                          </p>
                        ))
                      : '-'}
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className="mt-2">
              <div className="col-12">
                <Card className="border-0 shadow-sm">
                  <CardBody className="d-flex flex-column gap-2">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Nelayan</h5>
                        <h6
                          onClick={() => dispatch(toggleModalPemetaan())}
                          style={{ cursor: 'pointer' }}
                          className="text-primary"
                        >
                          Ubah
                        </h6>
                      </div>
                      {data?.uservessels?.length > 0 ? (
                        data?.uservessels?.map((item, index) => (
                          <div
                            key={index}
                            className="text-muted my-2 d-flex align-items-center"
                          >
                            <img
                              src={IconAvatar}
                              loading="lazy"
                              alt="Avatar"
                              style={{
                                width: 30,
                                height: 30,
                                marginRight: 10,
                              }}
                            />
                            <p className="text-sm" style={{ margin: 0 }}>
                              {item?.verifiedprofile?.name ?? '-'}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="text-muted my-2">{'-'}</p>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-md-6 mb-3">
            <Card className="border-0 shadow-sm">
              <CardBody className="d-flex flex-column gap-2">
                <h5>Pemetaan Kapal</h5>
                <div>
                  <span className="text-sm fw-semibold">Companies</span>
                  {data?.companiesList?.length
                    ? data?.companiesList?.map((item, index) => (
                        <p key={index} className="text-sm text-muted m-0">
                          - {item?.companiesData?.name}
                        </p>
                      ))
                    : '-'}
                </div>
                <div>
                  <span className="text-sm fw-semibold">Fisheries</span>
                  {data?.fisheriesList?.length
                    ? data?.fisheriesList?.map((item, index) => (
                        <p key={index} className="text-sm text-muted m-0">
                          - {item?.fisheriesData?.name}
                        </p>
                      ))
                    : '-'}
                </div>
                <div>
                  <span className="text-sm fw-semibold">Ports</span>
                  {data?.portsList?.length
                    ? data?.portsList?.map((item, index) => (
                        <p key={index} className="text-sm text-muted m-0">
                          - {item?.portsData?.name}
                        </p>
                      ))
                    : '-'}
                </div>
                <div>
                  <span className="text-sm fw-semibold">WPP-RI</span>
                  <p className="text-sm text-muted m-0">
                    {data?.wppRIList ?? '-'}
                  </p>
                </div>
                <div>
                  <span className="text-sm fw-semibold">District</span>
                  <p className="text-sm text-muted m-0">
                    {data?.district ?? '-'}
                  </p>
                </div>
              </CardBody>
            </Card>

            <div className="mt-2">
              <Card className="border-0 shadow-sm">
                <CardBody className="d-flex flex-column gap-2">
                  <h5>Other Status</h5>
                  <div className="container">
                    {VESSEL_OTHER_STATUS_OPTIONS.map((option, index) => (
                      <div className="row" key={index}>
                        <div className="col-6 p-0">
                          <span className="text-sm fw-semibold">
                            {option.label}
                          </span>
                        </div>
                        <div className="col-6">
                          <p className="text-sm text-muted mb-0">
                            {data?.[option.value] ? (
                              <img
                                src={IconCheckStatus}
                                loading="lazy"
                                alt="icon"
                              />
                            ) : (
                              <img
                                src={IconXStatus}
                                loading="lazy"
                                alt="icon"
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailVessel
