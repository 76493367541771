import { createApiSlice } from 'app/createApiSlice'
import { API_WAREHOUSE } from 'utils/apiList'

export const inventoryTransferApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'inventory-transfer/api',
  endpoints(builder) {
    return {
      fetchInventory: builder.query({
        query({ isForTransaction, size, groupId, warehouseId }) {
          return {
            url: `api/v1/bussines/inventory`,
            params: {
              isForTransaction: isForTransaction,
              size: size,
              groupId,
              warehouseId,
            },
          }
        },
      }),
      fetchPacking: builder.query({
        query({ size, isForTransaction, warehouseId }) {
          return {
            url: `api/v1/bussines/inventorygroups/outer`,
            params: {
              isForTransaction: isForTransaction,
              size: size,
              warehouseId,
            },
          }
        },
      }),
      fetchPackingDetail: builder.query({
        query({ id }) {
          return {
            url: `api/v1/bussines/inventorygroups/${id}`,
          }
        },
      }),
      fetchWarehouses: builder.query({
        query({ size, isForTransfer, isForFilter }) {
          return {
            url: `${API_WAREHOUSE}?${isForTransfer ? 'isForTransfer=1' : ''}${isForTransfer && isForFilter ? '&' : ''}${isForFilter ? 'isForFilter=1' : ''}${size ? `&size=${size}` : ''}`,
          }
        },
        transformResponse: (response, _meta, arg) => {
          const newData = response?.items?.map((e) => {
            return {
              value: e.id,
              label: e.name,
              ...e,
            }
          })
          return {
            ...response,
            newData,
          }
        },
      }),
      postTransfer: builder.mutation({
        query(payload) {
          return {
            url: `api/v1/bussines/warehousetransferV2`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      confirmTransfer: builder.mutation({
        query(payload) {
          return {
            url: `api/v1/bussines/warehousetransfer/${payload?.id}/confirm`,
            method: 'POST',
            body: {
              approved: payload?.body,
            },
          }
        },
      }),
      fetchInventoryTransfer: builder.query({
        query({
          search,
          page,
          size = 10,
          sortby,
          sortdir,
          type,
          receiverwarehouseId,
          senderwarehouseId,
          status,
          start,
          end,
        }) {
          return {
            url: `api/v1/bussines/warehousetransfer`,
            params: {
              s: search,
              page,
              size,
              sortby,
              sortdir,
              type,
              receiverwarehouseId,
              senderwarehouseId,
              status,
              start,
              end,
            },
          }
        },
      }),
      fetchInventoryTransferById: builder.query({
        query({ id, sortby, sortdir, type }) {
          return {
            url: `api/v1/bussines/warehousetransfer`,
            params: {
              id,
              sortby,
              sortdir,
              type,
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchWarehousesQuery,
  useLazyFetchWarehousesQuery,
  useFetchInventoryQuery,
  useLazyFetchInventoryQuery,
  useFetchPackingQuery,
  useLazyFetchPackingQuery,
  usePostTransferMutation,
  useFetchInventoryTransferQuery,
  useLazyFetchInventoryTransferQuery,
  useFetchInventoryTransferByIdQuery,
  useLazyFetchInventoryTransferByIdQuery,
  useFetchPackingDetailQuery,
  useConfirmTransferMutation,
} = inventoryTransferApiSlice
