import React from 'react'
import './style.css'

export default function SetPasswordModal({ isOpen, onClose, children }) {
  if (!isOpen) return null

  return (
    <div className="custom-fullscreen-modal">
      <div className="modal-content-wrapper">{children}</div>
    </div>
  )
}
