import { GA_ID } from 'configs'
import ReactGA from 'react-ga4'

const TRACKING_ID = GA_ID

export const initGA = () => {
  if (!TRACKING_ID) {
    console.log('error track')
    return
  }
  ReactGA.initialize(TRACKING_ID)
}

export const trackPageView = (page) => {
  ReactGA.send({ hitType: 'pageview', page, title: document.title })
}

export const logErrorEvent = (error) => {
  ReactGA.event('exception', {
    description: `${error.message}${error.filename || error.lineNumber || error.columnNumber ? ` (${error.filename ?? ''}:${error.lineNumber ?? ''}:${error.columnNumber ?? ''})` : ''}`,
    fatal: false,
  })
}
