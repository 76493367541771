import { useDispatch, useSelector } from 'react-redux'
import InfoSetPinModal from './InfoSetPinModal'
import { SetPinModal } from './SetPinModal'
import { useEffect, useState } from 'react'
import SetPasswordModal from './SetPasswordModal'
import {
  setPassword,
  setPin,
} from 'features/public-api/setPinPassword/setPinPasswordAction'
import { useSnackbar } from 'notistack'

export default function HomeModal() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { profile } = useSelector((s) => s?.dashboard)
  const [isSetPin, setIsSetPin] = useState(false)
  const [isSetPassword, setIsSetPassword] = useState(false)
  const [isShowInfoSetPinPassword, setIsShowInfoSetPinPassword] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isProfileFetched, setIsProfileFetched] = useState(false)

  useEffect(() => {
    if (profile) {
      setIsProfileFetched(true)
    }
  }, [profile])

  useEffect(() => {
    if (!isProfileFetched) return

    const isSetPasswordValid = Boolean(profile?.setPasswordAt)
    const isSetPinValid = Boolean(profile?.setPinAt)

    if (!isSetPasswordValid || !isSetPinValid) {
      setIsShowInfoSetPinPassword(true)
    }
  }, [isProfileFetched, profile?.setPasswordAt, profile?.setPinAt])

  const onPressToCreatePinPassword = (type) => {
    if (type === 'pin') {
      setIsShowInfoSetPinPassword(false)
      setIsSetPin(true)
    } else {
      setIsSetPassword(true)
    }
  }

  const onHandleSetPassword = async (data) => {
    setIsLoading(true)
    setErrorMsg('')
    try {
      await dispatch(setPassword({ password: data?.password })).unwrap()
      setIsShowInfoSetPinPassword(false)
      setIsSetPassword(false)
      enqueueSnackbar('Password berhasil dibuat', { variant: 'success' })
    } catch (err) {
      setErrorMsg(err)
      enqueueSnackbar(err, { variant: 'error' })
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const onHandleSetPin = async (pin) => {
    setIsLoading(true)
    try {
      await dispatch(setPin(pin?.join(''))).unwrap()
      setIsShowInfoSetPinPassword(false)
      setIsSetPin(false)
      enqueueSnackbar('Pin berhasil dibuat', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' })
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <InfoSetPinModal
        isOpen={isShowInfoSetPinPassword}
        type={
          !profile?.setPasswordAt ? 'password' : !profile?.setPinAt ? 'pin' : ''
        }
        onPressSetPinPassword={onPressToCreatePinPassword}
      />

      <SetPinModal
        isOpen={isSetPin}
        onHandleSetPin={onHandleSetPin}
        isLoading={isLoading}
        isError={isError}
      />
      <SetPasswordModal
        isOpen={isSetPassword}
        onHandleSetPassword={onHandleSetPassword}
        isLoading={isLoading}
        isError={isError}
        errorMsg={errorMsg}
        onClose={() => setIsSetPassword(false)}
      />
    </>
  )
}
