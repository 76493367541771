import Logo404 from 'assets/images/404-logo.png'
import { logErrorEvent } from 'hooks/analytics'
import { useEffect } from 'react'
import { ArrowLeft } from 'react-feather'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'
import CustomButton from './Buttons/CustomButton'
import Page404 from './Page404'

const PageError = () => {
  const error = useRouteError()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (error) {
      logErrorEvent(error, location.pathname)
    }
  }, [error, location.pathname])

  if (error?.status === 404) {
    return <Page404 />
  }

  return (
    <div className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center gap-4">
      <img src={Logo404} alt="error-image" className="img-fluid" />
      <div className="text-center">
        <h3 className="fw-bold">Terjadi kendala</h3>
        <p className="text-muted">
          Maaf, terjadi kendala dan permintaan Anda tidak bisa diproses. Silakan
          ulangi lagi.
        </p>
      </div>
      <div className="d-flex">
        <CustomButton
          onClick={() => navigate(-1)}
          color="secondary"
          className="btn-md mb-2 me-2"
          outline
        >
          <ArrowLeft size={20} className="me-1" />
          Kembali
        </CustomButton>
        <CustomButton
          tag="a"
          href="/"
          color="primary"
          className="btn-md mb-2 ms-2"
        >
          Beranda
        </CustomButton>
      </div>
    </div>
  )
}

export default PageError
