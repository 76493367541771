/* eslint-disable eqeqeq */
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import SidebarDetails from 'components/SidebarDetails'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, FileText } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Collapse,
  Progress,
  Spinner,
} from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'
import ModalPartialPayment from './ModalPartialPayment'
import { useFetchTalanginInvoiceDetailQuery } from './talanginInvoiceApiSlice'
import { toggleModalPartial } from './talanginInvoiceSlice'
import RoundedComponent from 'components/Rounded'

const SidebarTalanginInvoice = ({ isOpen, toggle }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(false)
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const toggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse)
  }
  const { token } = useSelector((s) => s.auth)
  const row = useSelector((s) => s.talanginInvoice.selectedRow)
  const { data, isLoading, refetch } = useFetchTalanginInvoiceDetailQuery(
    { id: row?.id },
    { skip: !isOpen },
  )

  const alurMaker = (data) => {
    let alur = []

    if (data.escrowpayment?.createdAt || data.disbursedDate) {
      alur.push({
        label: 'Pembeli mengajukan talangin',
        date: data.escrowpayment?.createdAt || data.disbursedDate,
      })
    }

    if (data.disbursedDate) {
      if (!data?.previousUserPinjamanId)
        alur.push({
          label: 'Tagihan talangin disetujui',
          date: data?.disbursedDate,
        })
    }

    if (data.rolloverDate)
      alur.push({
        label: 'Pembeli mengajukan perpanjangan tempo pembayaran',
        date: data.rolloverDate,
      })

    for (let i = 0; i < data.partialpayments?.length; i++) {
      let amount = Number(data.partialpayments[i].paymentAmount)
      // if (data.partialpayments[i].fine) {
      //   amount += Number(data.partialpayments[i].fine)
      // }
      alur.push({
        label:
          'Pembeli membayar tagihan Rp' +
          intl.formatNumber(amount, {
            useGrouping: 'always',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        date: data?.partialpayments[i].createdAt,
      })
    }
    if (data.status == 'PAIDOFF') {
      alur.push({
        label: 'Tagihan lunas',
        date: data?.paidoffDate,
      })
    }

    setHistory(alur)
  }

  const today = moment().tz('Asia/Jakarta').startOf('day').toDate()

  const subtotal =
    data?.escrowpayment?.amount +
    (data?.previousUserPinjamanId
      ? data?.escrowpayment?.escrowcreditv2?.margin2
      : data?.escrowpayment?.escrowcreditv2?.margin)

  const feeTx = data?.escrowpayment?.escrowcreditv2?.useFreeTx
    ? 0
    : data?.escrowpayment?.escrowcreditv2?.feeTx -
      data?.escrowpayment?.escrowcreditv2?.feeTxVAT

  const vat = data?.escrowpayment?.escrowcreditv2?.useFreeTx
    ? 0
    : data?.escrowpayment?.escrowcreditv2?.feeTxVAT

  const amountToPay =
    data?.status == 'PAIDOFF'
      ? 0
      : data?.netOutstanding
        ? data?.netOutstanding
        : data?.amount +
          data?.fineData?.totalFine -
          data?.discountData?.discount

  const totalAmountToPay = data?.escrowpayment?.escrowcreditv2?.useFreeTx
    ? data?.escrowpayment?.escrowcreditv2?.totalVAT +
      (subtotal || data?.amountPlusInterest) +
      (data?.escrowpayment?.escrowcreditv2?.feeTx -
        data?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
      (data?.partialpaymentData?.fine || data?.fineData?.totalFine)
    : data?.escrowpayment?.escrowcreditv2?.feeTxVAT +
      data?.escrowpayment?.escrowcreditv2?.totalVAT +
      (subtotal || data?.amountPlusInterest) +
      (data?.escrowpayment?.escrowcreditv2?.feeTx -
        data?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
      (data?.partialpaymentData?.fine || data?.fineData?.totalFine)

  const paidAmout = totalAmountToPay - amountToPay

  let totalUpfront = 0
  if (data?.creditVersion === 2) {
    if (!data?.previousUserPinjamanId) {
      totalUpfront =
        data?.escrowpayment?.escrowcreditv2?.upfront +
        data?.escrowpayment?.escrowcreditv2?.totalVAT +
        (data?.escrowpayment?.escrowcreditv2?.useFreeTx
          ? 0
          : data?.escrowpayment?.escrowcreditv2?.feeTx) +
        data?.escrowpayment?.escrowcreditv2?.margin
    } else {
      if (
        data?.escrowpayment?.escrowcreditv2?.margin2 &&
        data?.previousUserPinjamanId
      ) {
        totalUpfront =
          data?.escrowpayment?.escrowcreditv2?.upfront2 +
          data?.escrowpayment?.escrowcreditv2?.margin2
      } else {
        totalUpfront += data?.escrowpayment?.escrowcreditv2?.upfront2
          ? data?.escrowpayment?.escrowcreditv2?.upfront2
          : data?.escrowpayment?.escrowcreditv2?.upfront +
            data?.escrowpayment?.escrowcreditv2?.margin2
      }
    }
  } else {
    totalUpfront = data?.collateral
  }

  const downloadAsPdf = (data, url, type) => {
    setLoading(true)
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...{ responseType: 'blob' },
      })
      .then((response) => {
        const defName = `TRX/ID/${data.loan ? 'PMG-' : ''}${data.id}`
        var pdfNumber = defName
        if (data.deponumbers && data.deponumbers.length > 1) {
          pdfNumber = data.deponumbers[0].fullNumber
          if (data.loan || data.creditRequested > 0) {
            pdfNumber.replace(type, `${type}/PMG-`)
          }
        }
        var filename = `${pdfNumber.replaceAll('/', '-')}.pdf`
        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const blobFile = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blobFile)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(function (error) {})
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (isOpen && row) {
      refetch({ id: row?.id })
    }
  }, [isOpen, row])

  useEffect(() => {
    if (data) {
      alurMaker(data)
    }
  }, [data])

  return (
    <>
      <ModalPartialPayment
        row={row}
        amountToPay={amountToPay}
        toggleSidebar={toggle}
      />
      <SidebarDetails
        open={isOpen}
        title="Detail Tagihan"
        size="lg"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={toggle}
      >
        {isLoading || !data ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <div className="h-100">
            <Card
              body
              onClick={() => {
                toggleCollapse()
              }}
              className="my-3 border-0 shadow-sm"
            >
              <div className="d-flex gap-2 flex-row align-items-center justify-content-between">
                <div className="d-flex flex-row gap-2">
                  <div className="flex-centered justify-content-start gap-1 align-items-center">
                    <img src={ICTransaction} alt="ava" width={40} height={40} />
                  </div>
                  <div>
                    <CardText tag="h6" className="fw-bold text-primary">
                      Alur Transaksi
                    </CardText>
                    <CardText tag="p" className="m-0 fw-light">
                      Tagihan pinjaman disetujui
                    </CardText>
                    <CardText className="fw-light" tag="p">
                      {`(${formatDatePMBV2(data?.createdAt)})`}
                    </CardText>
                  </div>
                </div>
                {data?.createdAt &&
                  (isOpenCollapse ? (
                    <ChevronUp size={15} />
                  ) : (
                    <ChevronDown size={15} />
                  ))}
              </div>
              <Collapse isOpen={isOpenCollapse}>
                <CardBody>
                  {history.map((e, i) => {
                    return (
                      <div className="my-2">
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                          <RoundedComponent
                            childern={i + 1}
                            style={{
                              backgroundColor: '#C0E5F2',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                          <div
                            className="d-flex flex-column"
                            style={{
                              maxWidth: '80%',
                              wordWrap: 'break-word',
                            }}
                          >
                            <span>{e?.label}</span>
                            <small className="text-secondary">{`(${formatDatePMBV2(e?.date)})`}</small>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </CardBody>
              </Collapse>
            </Card>
            {/* penjual */}
            <Card body className="mb-3">
              <CardText tag="h6" className="fw-bold">
                Tagihan dari
              </CardText>
              <div className="d-flex flex-column justify-content-start gap-1">
                <span>PT PasarMIKRO Global</span>
                <small className="fw-light">
                  Mewakili toko: {row?.sellerTokoName}
                </small>
              </div>
            </Card>

            {/* Progress bar */}
            <Card body className="mb-3">
              <div className="d-flex justify-content-between mb-2 fw-bold">
                <span>Sisa Tagihan</span>
                <span>
                  Rp
                  {intl.formatNumber(amountToPay, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>

              <Card className="p-2" body>
                <div className="d-flex justify-content-between fw-light">
                  <small>Sudah terbayarkan</small>
                  <small>
                    {' '}
                    Rp
                    {intl.formatNumber(paidAmout, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </small>
                </div>
                <Progress
                  value={(paidAmout / totalAmountToPay) * 100}
                  color="success"
                  style={{
                    borderRadius: '10px',
                    backgroundColor: '#D1FADF',
                    height: '12px',
                  }}
                />
              </Card>
            </Card>

            {/* Rincian Pesanan */}
            <Card body className="mb-3">
              <CardText tag="h6" className="fw-bold">
                Rincian Pesanan
              </CardText>
              <div className="gap-1">
                {data?.escrowpayment?.order?.orderitem?.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="d-flex flex-column mb-2">
                        <span>{e?.item?.nameItem}</span>
                        <span>
                          <span className="fw-light">
                            {e?.amount} {e?.item?.unit ?? e?.unit} •{' '}
                          </span>{' '}
                          <span className="text-primary">
                            Rp
                            {intl.formatNumber(
                              e?.sellPriceBeforeVAT
                                ? e?.sellPriceBeforeVAT / e?.amount
                                : e?.price,
                              {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            )}
                          </span>
                        </span>
                      </div>
                      <span>
                        Rp
                        {intl.formatNumber(
                          e?.sellPriceBeforeVAT ?? 0 ?? e?.totalPrice ?? 0,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  )
                })}
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Subtotal</p>
                  <p>
                    Rp
                    {intl.formatNumber(subtotal, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Biaya transaksi</p>
                  <p>
                    Rp
                    {intl.formatNumber(feeTx, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span>PPN</span>
                  <span>
                    Rp
                    {intl.formatNumber(vat, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Total transaksi</p>
                  <p>
                    Rp
                    {intl.formatNumber(totalAmountToPay, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Pembayaran diawal</span>
                  <span>
                    Rp
                    {intl.formatNumber(totalUpfront, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
                <hr></hr>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-bold">Tagihan talangin</p>
                    <p className="fw-bold">
                      Rp
                      {intl.formatNumber(amountToPay, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>

                  {data?.partialpayments && (
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Pembayaran tagihan</span>
                      <span>
                        Rp
                        {intl.formatNumber(
                          data?.partialpayments?.reduce(
                            (sum, e) => sum + e.paymentAmount,
                            0,
                          ),
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <>
                  <hr></hr>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Sisa Tagihan</p>
                    <p>
                      Rp
                      {intl.formatNumber(amountToPay, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>
                </>
              </div>
            </Card>
            {/* invoice */}
            <Card body className="mb-3">
              <CardTitle className="fw-bold">Proforma invoice</CardTitle>
              <CustomButton
                onClick={() => {
                  downloadAsPdf(row, data?.invoice?.pmToBuyer?.download, 'INVF')
                }}
                disabled={loading || isLoading}
                color="dark"
                outline
              >
                <FileText /> Unduh Proforma Invoice
              </CustomButton>
            </Card>
            {/* rollover */}
            <Card
              body
              className="mb-3"
              style={{
                backgroundColor:
                  data?.status !== 'DISBURSED' ||
                  data?.previousUserPinjamanId ||
                  new Date(data?.dueDate).setHours(0, 0, 0, 0) <
                    new Date(today).setHours(0, 0, 0, 0)
                    ? '#F9FAFB'
                    : '',
              }}
            >
              <CardTitle>
                {new Date(data?.dueDate).setHours(0, 0, 0, 0) <
                new Date(today).setHours(0, 0, 0, 0)
                  ? 'Anda tidak dapat memperpanjang tempo pembayaran'
                  : data?.previousUserPinjamanId
                    ? 'Anda sudah memperpanjang tempo pembayaran.'
                    : 'Anda dapat memperpanjang tempo pembayaran.'}
              </CardTitle>
              <CustomButton
                style={{ backgroundColor: 'white', color: 'black' }}
                onClick={() => {
                  navigate('/perpanjang-tempo-pembayaran')
                }}
                disabled={
                  data?.status !== 'DISBURSED' ||
                  data?.previousUserPinjamanId ||
                  new Date(data?.dueDate).setHours(0, 0, 0, 0) <
                    new Date(today).setHours(0, 0, 0, 0)
                }
              >
                Perpanjang
              </CustomButton>
            </Card>
            {data?.status === 'DISBURSED' && (
              <CustomButton
                block
                color="primary"
                size="sm"
                onClick={() => {
                  dispatch(toggleModalPartial())
                }}
              >
                Bayar Tagihan
              </CustomButton>
            )}
          </div>
        )}
      </SidebarDetails>
    </>
  )
}

export default SidebarTalanginInvoice
