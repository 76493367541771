import CustomButton from 'components/Buttons/CustomButton'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { setInitialBuyer, toggleModalUnregis } from '../sellCommoditySlice'

function ModalUnregis({
  handleFinished,
  data,
  setSelectedContact,
  selectedContact,
}) {
  const dispatch = useDispatch()

  const { isOpenModalUnregis, orders } = useSelector((s) => s.sellCommodity)

  const [dataBuyer, setDataBuyer] = useState({})

  const toggle = useCallback(() => {
    dispatch(toggleModalUnregis())
  }, [dispatch])

  useEffect(() => {
    if (selectedContact && selectedContact?.relationName) {
      setDataBuyer({ fullName: selectedContact?.relationName })
    }
  }, [selectedContact])

  return (
    <Modal
      className="p-5 w-100"
      isOpen={isOpenModalUnregis}
      toggle={toggle}
      centered
    >
      <ModalBody className="w-100">
        <h5 className="fw-bold">Pengguna belum terdaftar</h5>
        <p>
          Nomor handphone atau email yang Anda masukkan belum terdaftar di
          PasarMIKRO. Tambahkan data secara manual untuk melanjutkan transaksi.
        </p>
        <FormGroup>
          <Label>Nama pembeli</Label>
          <Input
            placeholder="Masukkan nama"
            type="text"
            name="fullName"
            value={dataBuyer?.fullName}
            onChange={(e) => {
              setDataBuyer({ fullName: e.target.value })
            }}
          />
        </FormGroup>

        <div className="d-flex justify-content-between gap-2">
          <CustomButton
            onClick={() => {
              toggle()
              setSelectedContact(null)
              setDataBuyer({})
            }}
            className="flex-grow-1 btn-cancel"
            outline
            block
            color="secondary"
          >
            Batal
          </CustomButton>
          <CustomButton
            block
            onClick={() => {
              dispatch(
                setInitialBuyer({
                  ...orders.buyer,
                  ...dataBuyer,
                  phoneNumber: data,
                  ...(data.includes('@') && {
                    email: data,
                  }),
                }),
              )
              toggle()
              setDataBuyer({})
            }}
            className="flex-grow-1"
            color="primary"
          >
            Tambah Data
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalUnregis
