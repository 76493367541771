import ApprovalPage from 'features/private-views/approval'
import DashboardView from 'features/private-views/dashboard'
import PurchaseCommodity from 'features/private-views/purchase-commodity'
import SellCommodityView from 'features/private-views/sell-commodity'
import SuccessTrx from 'features/private-views/sell-commodity/SuccessTrx'
import SettingView from 'features/private-views/settings'
import TalanginInvoice from 'features/private-views/talangin-invoice'
import ConfirmRollover from 'features/private-views/talangin-invoice/rollover/ConfirmRollover'
import Rollover from 'features/private-views/talangin-invoice/rollover/Rollover'
import Withdraw from 'features/private-views/withdraw'

import Transactions from 'private-views/transactions'
import AddComodity from 'private-views/transactions/AddComodity'
import SuccesTransaction from 'private-views/transactions/SuccesTransaction'
import ConfirmPayment from 'private-views/transactions/confirmPayment'
import Payment from 'private-views/transactions/payment'
import TalanginConfirm from 'private-views/transactions/talanginConfirm'

import ApprovalTable from 'features/private-views/approval/ApprovalTable'
import TableRequestSell from 'features/private-views/approval/TableRequestSell'
import TableRequestWithdrawal from 'features/private-views/approval/TableRequestWithdrawal'
import CashFlow from 'features/private-views/cash-flow'
import CashFlowNotes from 'features/private-views/cash-flow-notes'
import FormCashFlow from 'features/private-views/cash-flow/FormCashFlow'
import CommodityRepo from 'features/private-views/commodity-repo'
import ListRepo from 'features/private-views/commodity-repo/ListRepo'
import SubmissionRepo from 'features/private-views/commodity-repo/SubmissionRepo'
import FarmersWeb from 'features/private-views/farmers-web'
import CheckFarmers from 'features/private-views/farmers-web/check-farmers'
import DetailFarmers from 'features/private-views/farmers-web/detail'
import FarmersTable from 'features/private-views/farmers-web/farmers-list'
import FarmersPendingList from 'features/private-views/farmers-web/farmers-pending-list'
import FormRegis from 'features/private-views/farmers-web/forms'
import PayableNotes from 'features/private-views/payable-notes'
import ApprovalList from 'features/private-views/payable-notes/ApprovalList'
import FormPayableNotes from 'features/private-views/payable-notes/FormPayableNotes'
import ReceivableNotes from 'features/private-views/receivable-notes'
import Tnc from 'features/private-views/tnc'
import TopupOnePayment from 'features/private-views/topup/index2'
import { ManageStock } from 'features/public-views/manageStock'
import Spatial from 'features/public-views/spatial'
import ConfirmCashPayment from 'private-views/transactions/confirmCashPayment'
// VESSELS
import Vessels from 'features/private-views/vessels'
import DetailVessels from 'features/private-views/vessels/detail'
import FormAddVessel from 'features/private-views/vessels/forms'
import VessselsTable from 'features/private-views/vessels/list-vessels'
// CATCHES
import Catches from 'features/private-views/catches'
import DetailCatches from 'features/private-views/catches/detail'
import CatchesTable from 'features/private-views/catches/list-catches'
// GEARS
import Gears from 'features/private-views/gears'
import GearsTable from 'features/private-views/gears/list-gears'
// PORTS
import Ports from 'features/private-views/ports'
import PortsTable from 'features/private-views/ports/list-ports'
// COMPANIES
import Companies from 'features/private-views/companies'
import FormAddCompanies from 'features/private-views/companies/forms'
import CompaniesTable from 'features/private-views/companies/list-companies'
// FISHERIES
import Fisheries from 'features/private-views/fisheries'
import FormAddFisheries from 'features/private-views/fisheries/forms'
import FisheriesTable from 'features/private-views/fisheries/list-fisheries'
// WHISTLE BLOWING
import WhistleBlowings from 'features/private-views/whistle-blowings'
import WhistleBlowingsTable from 'features/private-views/whistle-blowings/list-whistle-blowings'
// APPROVAL FARMERS
import ApprovalFarmers from 'features/private-views/farmers-web/approval'
// ADDRESS BOOK
import ListAddressBook from 'features/private-views/address-book/ListAddressBook'

import Budget from 'features/private-views/balance/budget'
import HistoryCashFlow from 'features/private-views/cash-flow/HistoryCashFlow'
import DetailTransaction from 'features/private-views/dashboard/DetailTransaction'
import GroupsList from 'features/private-views/farmers-web/groups-list'
import StaffList from 'features/private-views/farmers-web/staffList'
import Learning from 'features/private-views/learning'
import LearningReport from 'features/private-views/learning-report'
import TransferInventory from 'features/private-views/transfer-inventory'
import DetailTransferInventory from 'features/private-views/transfer-inventory/DetailTransferInventory'
import FormTransferInventory from 'features/private-views/transfer-inventory/FormTfInventory'
import Warehouse from 'features/private-views/warehouse'
import FormWarehouse from 'features/private-views/warehouse/FormWarehouse'
import DetailWarehouse from 'features/private-views/warehouse/detail'
import { Insight, Logs } from 'features/public-views/ap2hi'
import InventoryMembers from 'features/public-views/inventoryMembers/InventoryMembers'
import { DetailInventoriesPacking } from 'features/public-views/manageStock/DetailInventoriesPacking'
import {
  ARUS_KAS,
  ARUS_KAS_FORM,
  HISTORY_ARUS_KAS,
  URL_ADDRESS_BOOK,
  URL_AGENT_APPROVAL,
  URL_AP2HI_INSIGHT,
  URL_AP2HI_LOG,
  URL_APPROVAL_BUY,
  URL_APPROVAL_SELL,
  URL_APPROVAL_WITHDRAWAL,
  URL_ARUS_KAS,
  URL_BALANCE,
  URL_CATCHES,
  URL_CATCHES_DETAIL,
  URL_CHECK_FARMERS,
  URL_COMPANIES,
  URL_COMPANIES_ADD,
  URL_CONFIRM_PIN_TALANGIN,
  URL_CONFIRM_PIN_TRANSAKSI,
  URL_DETAIL_INVENTORY,
  URL_DETAIL_TRANSACTION_ID,
  URL_DETAIL_WAREHOUSE,
  URL_FARMERS,
  URL_FARMERS_DETAIL,
  URL_FARMERS_GROUPS,
  URL_FARMERS_PENDING,
  URL_FARMERS_REGIST,
  URL_FARMERS_SUBGROUP,
  URL_FISHERIES,
  URL_FISHERIES_ADD,
  URL_FORM_TRANSFER_INVENTORY,
  URL_FORM_UTANG_PIUTANG,
  URL_FORM_WAREHOUSE,
  URL_GEARS,
  URL_INVENTORY,
  URL_INVENTORY_MEMBERS,
  URL_KURSUS,
  URL_LEARNIING_REPORT,
  URL_PIUTANG,
  URL_PORTS,
  URL_TNC_PRIVATE,
  URL_TRANSFER_INVENTORY,
  URL_TRANSFER_INVENTORY_DETAIL,
  URL_UTANG,
  URL_UTANG_APPROVAL,
  URL_VESSELS,
  URL_VESSELS_ADD,
  URL_VESSELS_DETAIL,
  URL_WAREHOUSE,
  URL_WHISTLE_BLOWINGS,
} from 'utils/pathUrl'
import {
  BUSINESS_AUTOREPO,
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_WITHDRAWAL,
  MANAGE,
  READ,
} from 'utils/subjectActions'
import { settingRoutes } from './settingRoutes'
import PinConfirm from 'features/private-views/talangin-invoice/PinConfirm'
import CheckPinTrx from 'private-views/transactions/CheckPinTrx'

export const privateRouter = [
  {
    path: '/',
    element: <DashboardView />,
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL,
    name: 'Dashboard',
  },
  {
    element: <Transactions />,
    children: [
      {
        path: '/transaksi-pembelian-tambah-komoditas',
        element: <AddComodity />,
      },
      {
        path: '/transaksi-pembelian-pembayaran',
        element: <Payment />,
      },
      {
        path: '/transaksi-pembelian-pembayaran-uang-belanja-konfirmasi',
        element: <ConfirmPayment />,
      },
      {
        path: '/transaksi-pembelian-pembayaran-talangin',
        element: <TalanginConfirm />,
      },
    ],
  },
  {
    path: URL_DETAIL_TRANSACTION_ID,
    element: <DetailTransaction />,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL,
  },
  {
    path: '/transaksi-pembelian-cash',
    element: <ConfirmCashPayment />,
  },
  {
    path: '/transaksi-pembelian-sukses',
    element: <SuccesTransaction />,
  },
  {
    path: '/purchase-commodity',
    element: <PurchaseCommodity />,
  },
  {
    path: '/sell-commodity',
    element: <SellCommodityView />,
  },
  {
    path: '/sell-commodity-success',
    element: <SuccessTrx />,
  },
  {
    path: '/topup',
    element: <TopupOnePayment />,
    // element: <Topup action={MANAGE} subject={BUSINESS_TOPUP} />,
  },
  // {
  //   path: '/topup-instruksi',
  //   element: <TransferBank action={READ} subject={BUSINESS_TOPUP} />,
  // },
  {
    path: '/tagihan-talangin',
    element: <TalanginInvoice action={READ} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: '/penarikan-uang',
    element: <Withdraw action={READ} subject={BUSINESS_WITHDRAWAL} />,
  },
  {
    path: '/perpanjang-tempo-pembayaran',
    element: <Rollover action={MANAGE} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: '/perpanjang-tempo-pembayaran-konfirmasi',
    element: <ConfirmRollover action={MANAGE} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: URL_APPROVAL_BUY,
    element: <ApprovalPage />,
    action: READ,
    subject: BUSINESS_MYAPPROVAL,
    name: 'Approval',
    children: [
      {
        index: true,
        element: <ApprovalTable />,
      },
      {
        path: URL_APPROVAL_SELL,
        element: <TableRequestSell />,
      },
      {
        path: URL_APPROVAL_WITHDRAWAL,
        element: <TableRequestWithdrawal />,
      },
    ],
  },
  {
    path: URL_AGENT_APPROVAL,
    element: <ApprovalFarmers />,
  },
  {
    path: URL_FARMERS,
    element: <FarmersWeb />,
    children: [
      {
        index: true,
        element: <StaffList />,
      },
      {
        path: URL_FARMERS_REGIST,
        element: <FormRegis />,
      },
      {
        path: URL_FARMERS_DETAIL,
        element: <DetailFarmers />,
      },
      {
        path: URL_CHECK_FARMERS,
        element: <CheckFarmers />,
      },
      {
        path: URL_FARMERS_PENDING,
        element: <FarmersPendingList />,
      },
      {
        path: URL_FARMERS_GROUPS,
        element: <GroupsList />,
      },
      {
        path: URL_FARMERS_SUBGROUP,
        element: <FarmersTable />,
      },
    ],
  },
  {
    element: <SettingView />,
    children: settingRoutes,
  },
  {
    element: <CommodityRepo />,
    action: MANAGE,
    subject: BUSINESS_AUTOREPO,
    name: 'Repo Komoditas',
    pathUrl: '/repo-komoditas/pengajuan',
    path: '/repo-komoditas',
    children: [
      {
        index: true,
        element: <SubmissionRepo />,
      },
      {
        path: 'list',
        element: <ListRepo />,
      },
    ],
  },
  {
    path: '/spatial',
    element: <Spatial />,
  },
  {
    path: URL_INVENTORY,
    element: <ManageStock />,
  },
  {
    path: URL_INVENTORY_MEMBERS,
    element: <InventoryMembers />,
  },
  {
    path: URL_DETAIL_INVENTORY,
    element: <DetailInventoriesPacking />,
  },
  {
    path: ARUS_KAS,
    element: <CashFlow />,
  },
  {
    path: HISTORY_ARUS_KAS,
    element: <HistoryCashFlow />,
  },
  {
    path: ARUS_KAS_FORM,
    element: <FormCashFlow />,
  },
  {
    path: URL_ARUS_KAS,
    element: <CashFlowNotes />,
  },
  {
    path: URL_UTANG,
    element: <PayableNotes />,
  },
  {
    path: URL_UTANG_APPROVAL,
    element: <ApprovalList />,
  },
  {
    path: URL_FORM_UTANG_PIUTANG,
    element: <FormPayableNotes />,
  },
  {
    path: URL_PIUTANG,
    element: <ReceivableNotes />,
  },
  {
    path: URL_TNC_PRIVATE,
    element: <Tnc />,
  },
  {
    path: URL_VESSELS,
    element: <Vessels />,
    children: [
      {
        index: true,
        element: <VessselsTable />,
      },
      {
        path: URL_VESSELS_ADD,
        element: <FormAddVessel />,
      },
      {
        path: URL_VESSELS_DETAIL,
        element: <DetailVessels />,
      },
    ],
  },
  {
    path: URL_CATCHES,
    element: <Catches />,
    children: [
      {
        index: true,
        element: <CatchesTable />,
      },
      {
        path: URL_CATCHES_DETAIL,
        element: <DetailCatches />,
      },
    ],
  },
  {
    path: URL_WHISTLE_BLOWINGS,
    element: <WhistleBlowings />,
    children: [
      {
        index: true,
        element: <WhistleBlowingsTable />,
      },
    ],
  },
  {
    path: URL_GEARS,
    element: <Gears />,
    children: [
      {
        index: true,
        element: <GearsTable />,
      },
    ],
  },
  {
    path: URL_PORTS,
    element: <Ports />,
    children: [
      {
        index: true,
        element: <PortsTable />,
      },
    ],
  },
  {
    path: URL_COMPANIES,
    element: <Companies />,
    children: [
      {
        index: true,
        element: <CompaniesTable />,
      },
      {
        path: URL_COMPANIES_ADD,
        element: <FormAddCompanies />,
      },
    ],
  },
  {
    path: URL_FISHERIES,
    element: <Fisheries />,
    children: [
      {
        index: true,
        element: <FisheriesTable />,
      },
      {
        path: URL_FISHERIES_ADD,
        element: <FormAddFisheries />,
      },
    ],
  },
  {
    path: URL_AP2HI_LOG,
    element: <Logs />,
  },
  {
    path: URL_AP2HI_INSIGHT,
    element: <Insight />,
  },
  {
    path: URL_KURSUS,
    element: <Learning />,
  },
  {
    path: URL_WAREHOUSE,
    element: <Warehouse />,
  },
  {
    path: URL_FORM_WAREHOUSE,
    element: <FormWarehouse />,
  },
  {
    path: URL_TRANSFER_INVENTORY,
    element: <TransferInventory />,
  },
  {
    path: URL_TRANSFER_INVENTORY_DETAIL,
    element: <DetailTransferInventory />,
  },
  {
    path: URL_FORM_TRANSFER_INVENTORY,
    element: <FormTransferInventory />,
  },
  {
    path: URL_DETAIL_WAREHOUSE,
    element: <DetailWarehouse />,
  },
  {
    path: URL_BALANCE,
    element: <Budget />,
  },
  {
    path: URL_LEARNIING_REPORT,
    element: <LearningReport />,
  },
  {
    path: URL_ADDRESS_BOOK,
    element: <ListAddressBook />,
  },
  {
    path: URL_CONFIRM_PIN_TALANGIN,
    element: <PinConfirm />,
  },
  {
    path: URL_CONFIRM_PIN_TRANSAKSI,
    element: <CheckPinTrx />,
  },
]
