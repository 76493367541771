import { P } from 'components'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, FormGroup, Input, Modal, Spinner } from 'reactstrap'

export const SetPinModal = ({ isOpen, onHandleSetPin, isLoading, isError }) => {
  const [active, setActive] = useState('form-control-0')
  const [pin, setPin] = useState(['', '', '', '', ''])
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const publicVersion = useSelector((s) => s.publicVersion)
  const noCsPasmik = publicVersion?.data?.csNumber

  const nextInput = (id) => {
    const input = document.getElementById(id)
    if (input) input.focus()
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const firstInput = document.getElementById('form-control-0')
        if (firstInput) {
          firstInput.focus()
        }
      }, 100)
    }
  }, [isOpen])

  const isValidPinArray = (pinArray) => {
    return (
      Array.isArray(pinArray) &&
      pinArray.length >= 6 &&
      pinArray.every((pin) => pin.trim() !== '')
    )
  }

  return (
    <Modal isOpen={isOpen} centered>
      <div style={{ paddingRight: 15, paddingLeft: 15 }}>
        <div className="mt-3">
          <P>Buat PIN Anda</P>
        </div>
        <FormGroup className="d-flex gap-3 mt-4">
          {[...Array(6)].map((_, i) => (
            <div key={i}>
              <div
                className="position-absolute top-0 start-0 w-0 h-0"
                style={{ zIndex: 2, backgroundColor: 'transparent' }}
                onClick={() => nextInput(active)}
              ></div>
              <Input
                // readOnly
                style={{
                  zIndex: 1,
                  fontSize: 30,
                  textAlign: 'center',
                }}
                autoFocus={i === 0}
                className={`w-100 text-center ${
                  isMobile
                    ? 'fs-2 border border-dark border-top-0 border-start-0 border-end-0 rounded-0'
                    : ''
                }`}
                id={`form-control-${i}`}
                key={i}
                maxLength={1}
                inputMode="numeric"
                pattern="[0-9]*"
                onFocus={(e) => {
                  e.target.style.boxShadow = 'none'
                }}
                value={pin[i]}
                autoComplete="off"
                onKeyDown={(e) => {
                  const value = e.target.value
                  if (!e.code.includes('Digit') && e.code !== 'Backspace') {
                    e.preventDefault()
                  } else if (e.code === 'Backspace') {
                    if (i > 0 || pin[i] !== '') {
                      setPin((prevPin) => {
                        const newPin = [...prevPin]
                        newPin[i] = ''
                        return newPin
                      })
                      setActive(`form-control-${i - 1}`)
                      nextInput(`form-control-${i - 1}`)
                    }
                  } else if (e.code?.includes('Digit')) {
                    if (value.match(/^[0-9]$/)) {
                      setPin((prev) => {
                        const newPin = [...prev]
                        newPin[i] = value
                        return newPin
                      })

                      if (e.target.id !== 'form-control-5') {
                        setActive(`form-control-${i + 1}`)
                        nextInput(`form-control-${i + 1}`)
                      }
                    }
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.match(/^[0-9]$/)) {
                    setPin((prev) => {
                      const newPin = [...prev]
                      newPin[i] = value
                      return newPin
                    })

                    if (e.target.id !== 'form-control-5') {
                      setActive(`form-control-${i + 1}`)
                      nextInput(`form-control-${i + 1}`)
                    }
                  }
                }}
              />
            </div>
          ))}
        </FormGroup>
        <div className="w-100 pb-3">
          <Button
            disabled={isLoading || !isValidPinArray(pin)}
            className="w-100 d-flex justify-content-center align-items-center"
            color="primary"
            onClick={() => onHandleSetPin(pin)}
          >
            {isLoading ? (
              <Spinner className="me-2" size="sm" color="light" />
            ) : null}
            <P>Lanjut</P>
          </Button>
          {isError ? (
            <Button
              disabled={isLoading}
              className="mt-2 w-100 d-flex justify-content-center align-items-center"
              color="transparent"
              onClick={() =>
                window.open(`https://wa.me/${noCsPasmik}`, '_blank')
              }
            >
              {isLoading ? (
                <Spinner className="me-2" size="sm" color="light" />
              ) : null}
              <P color={'#006386'}>Hubungi PasarMIKRO</P>
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}
