import * as FileSaver from 'file-saver'
import moment from 'moment'
import * as XLSX from 'xlsx'

export const handleDownloadExcel = (
  warehouseName,
  res,
  setIsDownload,
  setFirstRows,
  setTotalRows,
) => {
  if (!res?.items?.length) {
    console.error('No data available for download')
    return
  }

  setIsDownload(false)
  setFirstRows(0)
  setTotalRows(0)

  setTimeout(() => {
    try {
      setIsDownload(true)
      setFirstRows(0)
      setTotalRows(res.totalItems)

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const bookType = 'xlsx'
      let count = 2

      const totalItems = res.totalItems || res.items.length
      const chunkSize = Math.max(Math.floor(totalItems * 0.1), 100)

      const exportHeader = ['No', 'Nama Inventori', 'Jumlah', 'Satuan']
      const ws = XLSX.utils.book_new()
      XLSX.utils.sheet_add_aoa(ws, [exportHeader])

      const processChunk = (start) => {
        if (start >= totalItems) {
          setFirstRows(totalItems)
          setTimeout(() => {
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
            const excelBuffer = XLSX.write(wb, { bookType, type: 'array' })
            const finalData = new Blob([excelBuffer], { type: fileType })

            FileSaver.saveAs(
              finalData,
              `${warehouseName}_Stok_${moment().format('DD-MM-YYYY')}.xlsx`,
            )

            setTimeout(() => {
              setIsDownload(false)
            }, 2000)
          }, 500)

          return
        }

        const chunk = res.items
          .slice(start, start + chunkSize)
          .map((row, index) => [
            index + 1,
            row.nameItem || '-',
            row.total || 0,
            row.unit || '-',
          ])

        XLSX.utils.sheet_add_json(ws, chunk, {
          origin: `A${count}`,
          skipHeader: true,
        })
        count += chunk.length

        setFirstRows(start + chunk.length)

        setTimeout(() => processChunk(start + chunkSize), 300)
      }

      processChunk(0)
    } catch (error) {
      console.error('Error generating excel:', error)
      setIsDownload(false)
      setFirstRows(0)
      setTotalRows(0)
    }
  }, 300)
}
