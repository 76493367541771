import { useEffect, useMemo } from 'react'
import { useFetchProfileQuery as getProfile } from 'features/private-views/dashboard/dashboardApiSlice'
import { useDispatch } from 'react-redux'
import {
  setIndividualUser,
  setOwnerData,
  setProfile,
} from 'features/private-views/dashboard/dashboardSlice'

const useGetMyProfileAvaQuery = ({ skip }) => {
  const dispatch = useDispatch()

  const profile = getProfile({}, { skip })
  const data = useMemo(() => profile, [profile])

  useEffect(() => {
    dispatch(setProfile(profile?.data?.makerData))
    dispatch(setOwnerData(profile?.data?.ownerData))
    dispatch(setIndividualUser(profile?.data?.individualUser))
  }, [dispatch, profile])

  return data
}

export default useGetMyProfileAvaQuery
