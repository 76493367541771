import { initGA, trackPageView } from 'hooks/analytics'
import useAutoFetchPublicVersion from 'hooks/useAutoFetchPublicVersion'
import { getTranslationKey } from 'hooks/usePageTitle'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Outlet, useLocation } from 'react-router-dom'

const DefaultLayout = () => {
  const intl = useIntl()
  const location = useLocation()
  useAutoFetchPublicVersion()

  useEffect(() => {
    const translationKey = getTranslationKey(location.pathname)
    document.title = `PasarMIKRO Business - ${intl.formatMessage({ id: translationKey })}`
    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location) {
      const translationKey = getTranslationKey(location.pathname)
      document.title = `PasarMIKRO Business - ${intl.formatMessage({ id: translationKey })}`
      trackPageView(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <Outlet />
}

export default DefaultLayout
