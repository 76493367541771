import BreadCrumbs from 'components/breadcrumbs'
import PMBSelect from 'components/PMBSelect'
import {
  CERTIFICATION_OPTIONS,
  VESSEL_OTHER_STATUS_OPTIONS,
  WPP_OPTIONS,
} from 'constants'
import {
  setIsModalOpenAddAddressBook,
  setSearchAddressBook,
} from 'features/private-views/address-book/addressBookSlice'
import InputAddressBook from 'features/private-views/address-book/InputAddressBook'
import ModalAddContact from 'features/private-views/address-book/ModalAddContact'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { toggleModalChangeProfile } from '../detail/detailSlice'
import { useFetchVesselsQuery } from '../list-vessels/vesselsListApiSlice'
import CardUploadImage from './CardUploadImage'
import {
  useCreationVesselMutation,
  useFetchAllCompaniesVesselQuery,
  useFetchAllFisheriesVesselQuery,
  useFetchAllGearsVesselQuery,
  useFetchAllPortsVesselQuery,
  useUpdateVesselMutation,
} from './formApiSlice'
import { setPayloadSlice, setUrlImageVessel } from './formSlice'
import './style.css'

const FormAddVessel = ({ row }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tag } = useSelector((s) => s.vesselsList)
  const { urlImageVessel } = useSelector((s) => s.vesselsForm)
  const { isModalOpenAddAddressBook } = useSelector((e) => e.addressBook)

  const [creationVessel, { isLoading: isLoadingCreate }] =
    useCreationVesselMutation()
  const [updateVessel, { isLoading: isLoadingUpdate }] =
    useUpdateVesselMutation()
  // GET ALL GEARS
  const { data: dataGears } = useFetchAllGearsVesselQuery()
  const gearsOptions =
    dataGears?.items?.map((gear) => ({
      value: gear.name,
      label: gear.name,
    })) || []
  // GET ALL GEARS
  const { data: dataFisheries } = useFetchAllFisheriesVesselQuery({
    page: 0,
  })
  const fisheriesOptions =
    dataFisheries?.items?.map((fishery) => ({
      value: fishery.id,
      label: fishery.name,
    })) || []
  // GET ALL PORTS
  const { data: dataPorts } = useFetchAllPortsVesselQuery({
    page: 0,
    size: 999,
  })
  const portsOptions =
    dataPorts?.items?.map((fishery) => ({
      value: fishery.id,
      label: fishery.name,
    })) || []

  const { refetch } = useFetchVesselsQuery({
    page: 0,
    size: 10,
    searchvalue: '',
  })
  // const [fetchVessels] = useLazyFetchVesselsQuery()

  let [page, setPage] = useState({ page: tag.page })
  const { enqueueSnackbar } = useSnackbar()

  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState({})
  const [isClick, setIsClick] = useState(false)
  const [certificationStatus, setCertificationStatus] = useState([])
  const [isShowImageEdit, setIsShowImageEdit] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedGear, setSelectedGear] = useState(null)
  const [selectedWPP, setSelectedWPP] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([{}])
  const [searchCompany, setSearchCompany] = useState('')
  const [selectedFisheries, setSelectedFisheries] = useState([])
  const [selectedPorts, setSelectedPorts] = useState([])
  const [selectedContactOwner, setSelectedContactOwner] = useState(null)
  const [selectedContactCaptain, setSelectedContactCaptain] = useState(null)

  const handleHasAnswered = () => {
    const fieldRequireds = [
      'vesselName',
      'licenseNumber',
      'ap2hiUVI',
      'linkImage',
      'ownerName',
      'captain',
      'vesselFlag',
      'vesselGT',
      'vesselType',
    ]

    const fieldRequired = fieldRequireds

    const data = fieldRequired?.map((e) => {
      return Object.prototype.hasOwnProperty.call(payload, e) && payload[e]
    })
    return data.every((e) => e)
  }

  // HANDLE CERTIFICATION
  const handleCertificationChange = (e) => {
    const { checked, value } = e.target

    setCertificationStatus((prev) => {
      if (checked) {
        return [...prev, value]
      } else {
        return prev?.filter((status) => status !== value)
      }
    })
  }

  // HANDLE OTHER STATUS
  const [selectedStatuses, setSelectedStatuses] = useState(
    VESSEL_OTHER_STATUS_OPTIONS.reduce((acc, option) => {
      acc[option.value] = false
      return acc
    }, {}),
  )

  const handleStatusChange = (e) => {
    const { value, checked } = e.target
    setSelectedStatuses((prev) => ({
      ...prev,
      [value]: checked,
    }))
  }

  // GET DATA COMPANIES
  const { data: dataCompanies, refetch: refetchCompanies } =
    useFetchAllCompaniesVesselQuery({
      page: 0,
      size: 50,
      searchvalue: searchCompany,
    })

  // HANDLE FOR SEARCHING COMPANY
  const handleCompanySearch = (text) => {
    if (text?.trim() !== '' && text?.length > 3) {
      setSearchCompany(text)
      refetchCompanies()
    } else {
      setSearchCompany('')
      refetchCompanies()
    }
  }

  // HANDLE FOR ADD NEW COMPANY
  const handleAddCompany = () => {
    setSelectedCompanies([...selectedCompanies, {}])
  }

  // HANDLE CHANGES ON DROPDOWN OPTION BASED ON INDEX
  const handleCompanyChange = (selectedOption, index) => {
    const updatedOffices = [...selectedCompanies]
    updatedOffices[index] = selectedOption
    setSelectedCompanies(updatedOffices)
  }

  // REMOVE COMPANY BASED ON INDEX
  const handleRemoveCompany = (index) => {
    const updatedOffices = [...selectedCompanies]
    updatedOffices.splice(index, 1)
    setSelectedCompanies(updatedOffices)
  }

  // VALIDATE ALREADY SELECTED OR NOT
  const validateCompany = () => {
    return selectedCompanies?.some((office) => !office?.value)
  }

  // FILTER REMOVE FROM OPTIONS IF THE DATA ALREADY SELECTED
  const getFilteredOptions = () => {
    const selectedCompanyId = selectedCompanies
      .map((office) => office?.value)
      .filter(Boolean)
    return (
      dataCompanies?.items?.filter(
        (company) => !selectedCompanyId.includes(company.id),
      ) || []
    )
  }

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      certStatus: JSON.stringify(certificationStatus).replaceAll('"', `'`),
    }))
  }, [certificationStatus])

  useEffect(() => {
    if (urlImageVessel) {
      setPayload((prev) => ({
        ...prev,
        linkImage: urlImageVessel,
      }))
    }
  }, [urlImageVessel])

  const idVessel = row?.id

  const handleSubmit = async () => {
    try {
      const adjustedPayload = {
        ...payload,
        vesselGear: selectedGear?.value ?? '',
        sharkPolicy: selectedStatuses.sharkPolicy ?? false,
        iuuList: selectedStatuses?.iuuList ?? false,
        codeOfConduct: selectedStatuses?.codeOfConduct ?? false,
        nameChangedStatus: selectedStatuses?.nameChangedStatus ?? false,
        radioStatus: selectedStatuses?.radioStatus ?? false,
        vmsChange: selectedStatuses?.vmsChange ?? false,
        trackerStatus: selectedStatuses?.trackerStatus ?? false,
        flagStateChange: selectedStatuses?.flagStateChange ?? false,
        eLogBook: selectedStatuses?.eLogBook ?? false,
        sticker: selectedStatuses?.sticker ?? false,
        portsList: selectedPorts?.length
          ? selectedPorts?.map((item) => item?.value)
          : null,
        fisheriesList: selectedFisheries?.length
          ? selectedFisheries?.map((item) => item?.value)
          : null,
        companiesList: selectedCompanies?.length
          ? selectedCompanies?.map((item) => item?.value)
          : null,
        wppRIList: selectedWPP?.length
          ? selectedWPP.map((item) => item?.value).join(', ')
          : null,
      }

      const res = row
        ? await updateVessel({ payload: adjustedPayload, id: idVessel })
        : await creationVessel(adjustedPayload)

      if (res?.error) {
        enqueueSnackbar(
          res?.error?.data?.meta?.message ||
            `Vessel gagal  ${idVessel ? 'dirubah' : 'ditambahkan'}!`,
          { variant: 'error' },
        )
        setErrMsg(res?.error?.data?.meta?.message)
      } else if (!res?.error) {
        if (idVessel) {
          dispatch(toggleModalChangeProfile())
        }
        refetch()
        enqueueSnackbar(
          `Vessel berhasil  ${idVessel ? 'dirubah' : 'ditambahkan'}!`,
          { variant: 'success' },
        )

        navigate('/vessels')
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        `Vessel gagal  ${idVessel ? 'dirubah' : 'ditambahkan'}!`,
        { variant: 'error' },
      )
    }
  }

  // HANDLE EDIT
  useMemo(() => {
    if (row) {
      const certStatusRaw = row?.certStatus
      const cleanedCertStatus =
        certStatusRaw && certStatusRaw.replace(/[\[\]']/g, '').trim().length > 0
          ? certStatusRaw
              .replace(/[\[\]']/g, '')
              .split(',')
              .map((status) => status.trim())
          : []

      setSelectedGear({
        value: row?.vesselGear,
        label: row?.vesselGear,
      })

      setSelectedStatuses({
        nameChangedStatus: row?.nameChangedStatus,
        radioStatus: row?.radioStatus,
        vmsChange: row?.vmsChange,
        sharkPolicy: row?.sharkPolicy,
        trackerStatus: row?.trackerStatus,
        iuuList: row?.iuuList,
        flagStateChange: row?.flagStateChange,
        codeOfConduct: row?.codeOfConduct,
        eLogBook: row?.eLogBook,
        sticker: row?.sticker,
      })

      setSelectedWPP(
        row?.wppRIList?.split(',')?.map((item) => ({
          value: item.trim(),
          label: item.trim(),
        })),
      )

      setSelectedCompanies(
        row?.companiesList?.map((item) => ({
          value: item.companiesId,
          label: item.companiesData?.name,
        })),
      )

      setSelectedFisheries(
        row?.fisheriesList?.map((item) => ({
          value: item?.fisheriesId,
          label: item?.fisheriesData?.name,
        })),
      )

      setSelectedPorts(
        row?.portsList?.map((item) => ({
          value: item?.portsId,
          label: item?.portsData?.name,
        })),
      )

      setSelectedContactOwner({
        relationName: row?.ownerName,
      })

      setSelectedContactCaptain({
        relationName: row?.captain,
      })

      setIsShowImageEdit(row?.linkImage ? true : false)
      setPayload(() => ({
        vesselName: row?.vesselName ?? '',
        licenseNumber: row?.licenseNumber ?? '',
        ap2hiUVI: row?.ap2hiUVI ?? '',
        linkImage: row?.linkImage ?? '',
        ownerName: row?.ownerName ?? '',
        captain: row?.captain ?? '',
        vesselFlag: row?.vesselFlag ?? '',
        vesselGT: row?.vesselGT ?? '',
        vesselGear: row?.vesselGear ?? '',
        vesselType: row?.vesselType ?? '',
        motrMark: row?.motrMark ?? '',
        district: row?.district ?? '',
        vesselLength: row?.vesselLength ?? '',
        horsePower: row?.horsePower ?? '',
        yearOfConstruct: row?.yearOfConstruct ?? '',
        placeOfConstruct: row?.placeOfConstruct ?? '',
        materialType: row?.materialType ?? '',
        engineType: row?.engineType ?? '',
        engineBrand: row?.engineBrand ?? '',
      }))
      // Certification Status
      if (row?.certStatus) {
        setCertificationStatus(cleanedCertStatus)
      }
      // Set Image Vessel
      dispatch(setUrlImageVessel(row?.linkImage))
    }
  }, [row])

  // const styleRemoveImage = {
  //   position: 'absolute',
  //   top: '-8px',
  //   right: '-5px',
  //   zIndex: '10',
  //   backgroundColor: '#98A2B3',
  //   color: 'white',
  //   border: 'none',
  //   borderRadius: '50%',
  //   width: '24px',
  //   height: '24px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   cursor: 'pointer',
  // }

  // const handleRemoveImage = (e) => {
  //   setSelectedFile(e)
  //   dispatch(toggleModalDeleteFile())
  // }

  const normalizedCertificationStatus = certificationStatus.map((status) =>
    status.trim().toLowerCase(),
  )

  const handleSelectContact = (contact = {}, fieldName) => {
    if (fieldName === 'ownerName') {
      if (Object.keys(contact).length !== 0) {
        setSelectedContactOwner(contact)
      } else {
        setSelectedContactOwner(null)
      }
    } else {
      if (Object.keys(contact).length !== 0) {
        setSelectedContactCaptain(contact)
      } else {
        setSelectedContactCaptain(null)
      }
    }
    setPayload((prev) => ({
      ...prev,
      [fieldName]: contact?.relationName?.toUpperCase() ?? '',
    }))
  }

  const toggleOpenCloseAddContact = (field) => {
    dispatch(setIsModalOpenAddAddressBook(!isModalOpenAddAddressBook))
  }

  const onClickAddContact = (localSearch) => {
    dispatch(setSearchAddressBook(localSearch))
  }

  return (
    <Container className="custom-container d-flex flex-column gap-3 py-3">
      {!idVessel && (
        <BreadCrumbs
          breadCrumbTitle="Master Kapal"
          breadCrumbParent2="Daftar Kapal"
          breadCrumbParent2Link={'/vessels'}
          breadCrumbActive="Tambah Kapal"
        />
      )}
      <ModalAddContact
        isOpen={isModalOpenAddAddressBook}
        toggle={toggleOpenCloseAddContact}
        isOutsideAddressBook
      />
      {/* INFORMASI UMUM */}
      <Card className="border-0 shadow-sm">
        <CardBody className="d-flex flex-column gap-2">
          {!row && (
            <div className="">
              <h5>Tambah Kapal</h5>
              <p className="text-muted">
                Informasi umum terkait informasi kapal.
              </p>
            </div>
          )}

          <FormGroup>
            <Label className="text-sm">Vessel Name</Label>
            <Input
              placeholder="Masukkan nama kapal"
              autoComplete="off"
              name="vesselName"
              id="vesselName"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.vesselName ?? null}
              invalid={!payload?.vesselName && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Vessel Registration</Label>
            <Input
              placeholder="Masukkan nomor registrasi kapal"
              autoComplete="off"
              name="licenseNumber"
              id="licenseNumber"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.licenseNumber ?? null}
              invalid={!payload?.licenseNumber && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">UVI</Label>
            <Input
              placeholder="Masukkan nomor unik kapal"
              autoComplete="off"
              name="ap2hiUVI"
              id="ap2hiUVI"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.ap2hiUVI ?? null}
              invalid={!payload?.ap2hiUVI && isClick}
            />
          </FormGroup>
          <FormGroup>
            {idVessel ? (
              <div className="d-flex align-items-center justify-content-between">
                <Label className="text-sm">Vessel Image</Label>
                <Label
                  onClick={() => {
                    if (isShowImageEdit) {
                      setIsShowImageEdit(false)
                    } else {
                      setIsShowImageEdit(true)
                    }
                  }}
                  className="text-sm text-primary"
                  style={{ cursor: 'pointer' }}
                >
                  {isShowImageEdit ? 'Ubah' : 'Batal'}
                </Label>
              </div>
            ) : (
              <Label className="text-sm">Vessel Image</Label>
            )}
            <div className="d-flex flex-wrap gap-3">
              <div style={{ position: 'relative', width: '100%' }}>
                {idVessel && isShowImageEdit ? (
                  <>
                    {/* WAITING BE TILL PROVIDE DELETE IMAGE */}
                    {/* {isShowImageEdit && (
                      <>
                        {' '}
                        <button
                          style={styleRemoveImage}
                          onClick={() => handleRemoveImage(e)}
                        >
                          X
                        </button>
                      </>
                    )} */}

                    <img
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '400px',
                        maxWidth: '400px',
                      }}
                      className="img-fluid rounded"
                      src={urlImageVessel}
                      alt="IMAGE_VESSEL"
                      loading="lazy"
                    />
                  </>
                ) : (
                  <>
                    <CardUploadImage
                      type="IMAGE_VESSEL"
                      className={`${!payload?.linkImage && isClick ? 'border-danger' : ''}`}
                    />
                  </>
                )}
              </div>
            </div>
            {/* <CardUploadImage
              type="IMAGE_VESSEL"
              className={`${!payload?.linkImage && isClick ? 'border-danger' : ''}`}
            /> */}
          </FormGroup>
          <div className="mb-3">
            <InputAddressBook
              isVessel
              key="ownerName"
              title="Owner Name"
              placeholderDesc="Pilih dari kontak Anda"
              onSelectContact={handleSelectContact}
              isUppercaseValue
              fieldName="ownerName"
              invalid={!payload?.ownerName && isClick}
              onClickAdd={onClickAddContact}
              editValue={
                selectedContactOwner
                  ? selectedContactOwner?.relationPhoneNumber
                    ? `${selectedContactOwner?.relationName?.toUpperCase()} (${selectedContactOwner?.relationPhoneNumber})`
                    : selectedContactOwner?.relationEmail
                      ? `${selectedContactOwner?.relationName?.toUpperCase()} (${selectedContactOwner?.relationEmail})`
                      : (selectedContactOwner?.relationName?.toUpperCase() ??
                        '')
                  : ''
              }
            />
          </div>
          <div className="mb-3">
            <InputAddressBook
              isVessel
              key="captain"
              title="Captain"
              placeholderDesc="Pilih dari kontak Anda"
              onSelectContact={handleSelectContact}
              isUppercaseValue
              fieldName="captain"
              invalid={!payload?.captain && isClick}
              onClickAdd={onClickAddContact}
              editValue={
                selectedContactCaptain
                  ? selectedContactCaptain?.relationPhoneNumber
                    ? `${selectedContactCaptain?.relationName?.toUpperCase()} (${selectedContactCaptain?.relationPhoneNumber})`
                    : selectedContactCaptain?.relationEmail
                      ? `${selectedContactCaptain?.relationName?.toUpperCase()} (${selectedContactCaptain?.relationEmail})`
                      : (selectedContactCaptain?.relationName?.toUpperCase() ??
                        '')
                  : ''
              }
            />
          </div>
          <FormGroup>
            <Label className="text-sm">Vessel Type</Label>
            <Input
              placeholder="Masukkan tipe kapal"
              autoComplete="off"
              name="vesselType"
              id="vesselType"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.vesselType ?? null}
              invalid={!payload?.vesselType && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Vessel Flag</Label>
            <Input
              placeholder="Masukkan bendera kapal"
              autoComplete="off"
              name="vesselFlag"
              id="vesselFlag"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.vesselFlag ?? null}
              invalid={!payload?.vesselFlag && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Vessel GT</Label>
            <Input
              type="number"
              min={0}
              placeholder="Masukkan GT kapal"
              autoComplete="off"
              name="vesselGT"
              id="vesselGT"
              onChange={(e) => {
                const value = e.target.value
                if (!isNaN(value)) {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: value,
                  }))
                }
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'e' ||
                  e.key === 'E' ||
                  e.key === '+' ||
                  e.key === '-' ||
                  e.key === '.' ||
                  e.key === ','
                ) {
                  e.preventDefault()
                }
              }}
              value={payload?.vesselGT ?? null}
              invalid={!payload?.vesselGT && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Gear</Label>
            <PMBSelect
              value={selectedGear}
              onChange={(selectedOptions) => setSelectedGear(selectedOptions)}
              options={gearsOptions}
              isInvalid={isClick && !selectedGear}
              placeholder="Pilih gear yang digunakan"
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">MOTR Mark</Label>
            <Input
              placeholder="Masukkan MOTR mark"
              autoComplete="off"
              name="motrMark"
              id="motrMark"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.motrMark ?? null}
              invalid={!payload?.motrMark && isClick}
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Certification Status</Label>

            {CERTIFICATION_OPTIONS.map((option, index) => {
              const normalizedValue = option?.value?.trim().toLowerCase()
              const isChecked =
                normalizedCertificationStatus?.includes(normalizedValue)

              return (
                <div
                  key={index}
                  className="d-flex w-full p-3 rounded mb-2"
                  style={{
                    color: isChecked ? '#004259' : '',
                    backgroundColor: isChecked ? '#ECFAFF' : '',
                    borderColor: isChecked ? '#004259' : '#ccc',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                >
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      value={option.value}
                      onChange={handleCertificationChange}
                      checked={isChecked}
                    />
                    <Label className="text-sm" check>
                      {option.label}
                    </Label>
                  </FormGroup>
                </div>
              )
            })}
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Other Status</Label>

            {VESSEL_OTHER_STATUS_OPTIONS.map((option, index) => {
              const isChecked = selectedStatuses[option.value]
              return (
                <div
                  key={index}
                  className="d-flex w-full p-3 rounded mb-2"
                  style={{
                    color: isChecked ? '#004259' : '',
                    backgroundColor: isChecked ? '#ECFAFF' : '',
                    borderColor: isChecked ? '#004259' : '#ccc',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                >
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      value={option.value}
                      onChange={handleStatusChange}
                      checked={isChecked}
                    />
                    <Label className="text-sm" check>
                      {option.label}
                    </Label>
                  </FormGroup>
                </div>
              )
            })}
          </FormGroup>
        </CardBody>
      </Card>
      {/* END INFORMASI UMUM */}

      {/* PEMETAAN KAPAL */}
      <Card className="border-0 shadow-sm">
        <CardBody className="d-flex flex-column gap-2">
          <div className="">
            <h5>Pemetaan Kapal</h5>
            <p className="text-muted">
              Informasi yang menghubungkan kapal dengan beberapa entitas
              lainnya.
            </p>
          </div>

          <FormGroup>
            <Label>WPP-RI</Label>
            <PMBSelect
              value={selectedWPP}
              onChange={(selectedOptions) => setSelectedWPP(selectedOptions)}
              options={WPP_OPTIONS}
              isInvalid={isClick && !selectedWPP?.length}
              placeholder="Pilih WPP"
              isMulti
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">District</Label>
            <Input
              placeholder="Masukkan District"
              autoComplete="off"
              name="district"
              id="district"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.district ?? null}
              invalid={!payload?.district && isClick}
            />
          </FormGroup>

          {/* COMPANY */}
          <FormGroup>
            <Label className="text-sm">Company</Label>
            {selectedCompanies?.map((office, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between mb-2"
              >
                <div style={{ flexGrow: 1 }}>
                  <PMBSelect
                    value={office}
                    onChange={(selectedOption) =>
                      handleCompanyChange(selectedOption, index)
                    }
                    options={getFilteredOptions()?.map((company) => ({
                      value: company.id,
                      label: company.name,
                    }))}
                    onInputChange={handleCompanySearch}
                    placeholder="Pilih perusahaan..."
                    isInvalid={isClick && !office?.value}
                  />
                </div>
                <Button
                  color="danger"
                  className="ms-2"
                  onClick={() => handleRemoveCompany(index)}
                  disabled={selectedCompanies.length === 1}
                >
                  X
                </Button>
              </div>
            ))}

            {/* ADD NEW SUPPORTING OFFICE */}
            <Button
              color="primary"
              onClick={handleAddCompany}
              className="mt-2 text-sm"
              disabled={validateCompany()}
            >
              Tambah Company
            </Button>
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Fishery</Label>
            <PMBSelect
              value={selectedFisheries}
              onChange={(selectedOptions) =>
                setSelectedFisheries(selectedOptions)
              }
              options={fisheriesOptions}
              isInvalid={isClick && !selectedFisheries?.length}
              placeholder="Pilih fishery"
              isMulti
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Port</Label>
            <PMBSelect
              value={selectedPorts}
              onChange={(selectedOptions) => setSelectedPorts(selectedOptions)}
              options={portsOptions}
              isInvalid={isClick && !selectedPorts?.length}
              placeholder="Pilih port"
              isMulti
            />
          </FormGroup>
        </CardBody>
      </Card>
      {/* END PEMETAAN KAPAL */}

      {/* DETAIL ATRIBUT KAPAL */}
      <Card className="border-0 shadow-sm">
        <CardBody className="d-flex flex-column gap-2">
          <div className="">
            <h5>Detail Atribut Kapal</h5>
            <p className="text-muted">
              Informasi lainnya terkait dengan informasi kapal.
            </p>
          </div>

          <FormGroup>
            <Label className="text-sm">Length</Label>
            <Input
              placeholder="Masukkan panjang kapal"
              autoComplete="off"
              name="vesselLength"
              id="vesselLength"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.vesselLength ?? null}
              // invalid={!payload?.vesselLength && isClick}
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Horse Power</Label>
            <Input
              placeholder="Masukkan horse power kapal"
              autoComplete="off"
              name="horsePower"
              id="horsePower"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.horsePower ?? null}
              // invalid={!payload?.horsePower && isClick}
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Year of Construction</Label>
            <Input
              placeholder="Masukkan tanggal pembuatan kapal"
              autoComplete="off"
              name="yearOfConstruct"
              id="yearOfConstruct"
              // type="date" // DATE OR TEXTFIELD
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.yearOfConstruct ?? null}
              // invalid={!payload?.yearOfConstruct && isClick}
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Place of Construction</Label>
            <Input
              placeholder="Masukkan lokasi pembuatan kapal"
              autoComplete="off"
              name="placeOfConstruct"
              id="placeOfConstruct"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.placeOfConstruct ?? null}
              // invalid={!payload?.placeOfConstruct && isClick}
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Material Type</Label>
            <Input
              placeholder="Masukkan jenis material dari kapal"
              autoComplete="off"
              name="materialType"
              id="materialType"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.materialType ?? null}
              // invalid={!payload?.materialType && isClick}
            />
          </FormGroup>

          <FormGroup>
            <Label className="text-sm">Engine Brand</Label>
            <Input
              placeholder="Masukkan brand kapal"
              autoComplete="off"
              name="engineBrand"
              id="engineBrand"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.engineBrand ?? null}
              // invalid={!payload?.engineBrand && isClick}
            />
          </FormGroup>
        </CardBody>
      </Card>
      {/* END ATRIBUT KAPAL */}

      <span className="text-semibold">
        Dengan {row ? 'merubah' : 'mendaftarkan'} kapal, saya menjamin kebenaran
        data yang dimasukkan dan bertanggung jawab atas ketidaksesuaian data.
      </span>

      {errMsg && (
        <small className="text-danger">
          <AlertCircle size={15} /> {errMsg}
        </small>
      )}

      <div className="d-flex gap-3">
        {row ? (
          <Button
            onClick={() => {
              dispatch(toggleModalChangeProfile())
            }}
            outline
            color="primary"
            block
          >
            Batal
          </Button>
        ) : (
          <></>
        )}

        {!row && (
          <Button
            onClick={() => navigate('/vessels')}
            outline
            color="primary"
            block
          >
            Batal
          </Button>
        )}
        <Button
          disabled={isLoadingCreate}
          onClick={() => {
            const res = handleHasAnswered()
            setIsClick(true)
            if (res) {
              setIsClick(false)
              dispatch(setPayloadSlice(payload))
              handleSubmit()
            }
          }}
          color="primary"
          block
        >
          Simpan
        </Button>
      </div>
    </Container>
  )
}

// ;<div className="d-flex gap-3">
//   <Button
//     onClick={() => {
//       toggle()
//       resetState()
//     }}
//     color="primary"
//     outline
//     block
//   >
//     Batal
//   </Button>
//   <Button
//     disabled={
//       resUpdateFishItem?.isLoading ||
//       !payload?.amount ||
//       Number(payload?.amount) === 0 ||
//       Number(payload?.amount) === Number(row?.amount)
//     }
//     color="primary"
//     block
//     onClick={handleSubmit}
//   >
//     Simpan
//   </Button>
// </div>

export default FormAddVessel
