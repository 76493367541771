import CustomButton from 'components/Buttons/CustomButton'
import { Phone } from 'react-feather'
import { useSelector } from 'react-redux'
import { Card, CardBody, Modal } from 'reactstrap'

const ModalPendingTopup = ({ isOpen, toggle }) => {
  const publicVersion = useSelector((s) => s.publicVersion)

  return (
    <Modal centered size="sm" isOpen={isOpen} toggle={toggle}>
      <Card className="border-0 shadow-sm">
        <CardBody className="d-flex flex-column align-items-center">
          <p className="fw-semibold">Transaksi Diproses</p>
          <p className="small">
            Transaksi Anda sedang dalam proses. Kami akan memberi notifikasi
            apabila proses sudah selesai.
          </p>
          <CustomButton
            block
            outline
            color="primary"
            onClick={() =>
              window.open(
                `https://wa.me/${publicVersion?.data?.csNumber}`,
                '_blank',
              )
            }
          >
            <Phone size={17} /> <span> Hubungi CS</span>
          </CustomButton>
        </CardBody>
      </Card>
    </Modal>
  )
}

export default ModalPendingTopup
