import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiEndpoint } from 'configs'

export const loginNewApiSlice = createApi({
  reducerPath: 'login-new/api',
  baseQuery: fetchBaseQuery({ baseUrl: apiEndpoint }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    loginUserNew: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/login`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
      transformResponse: (response, _meta, arg) => {
        const newData = response?.data?.abilityRole?.map((obj) => {
          const newItem = { ...obj, subject: obj.subjects }
          delete newItem?.subjects
          delete newItem?.fields
          delete newItem?.conditions
          return newItem
        })

        return {
          ...response?.data,
          abilityRole: newData,
          token: response?.data?.api_token,
        }
      },
    }),
    precheckLogin: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/check`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    checkDeviceV1: builder.query({
      query: ({ deviceHash }) => ({
        url: `/api/v1/public/device/check`,
        method: 'GET',
        params: { deviceHash },
      }),
    }),
    checkDeviceV2: builder.query({
      query: (payload) => ({
        url: `/api/v2/public/device/check`,
        method: 'GET',
        params: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    createDevice: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/device`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    sendOtpLogin: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/otp/send`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    verifyOtpLogin: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/otp/verify`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    getVersion: builder.query({
      query: () => `/api/v1/public/version`,
      transformResponse(response, meta, arg) {
        return response
      },
    }),
    checkPassword: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/check-password`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: `/api/v2/public/authentication/reset-password`,
        method: 'POST',
        body: payload,
        headers: {
          Authorization: 'cG1iUFVCc2VjcmVjdDoyMDI1',
        },
      }),
    }),
  }),
})

export const {
  useLoginUserNewMutation,
  usePrecheckLoginMutation,
  useCreateDeviceMutation,
  useSendOtpLoginMutation,
  useVerifyOtpLoginMutation,
  useLazyCheckDeviceV1Query,
  useLazyCheckDeviceV2Query,
  useGetVersionQuery,
  useCheckPasswordMutation,
  useResetPasswordMutation,
} = loginNewApiSlice
