import { createApiSlice } from 'app/createApiSlice'

export const approvalApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'approval/api',

  refetchOnMountOrArgChange: true,
  endpoints(builder) {
    return {
      fetchApproval: builder.query({
        query() {
          return `/api/v1/bussines/approval`
        },
      }),
      fetchApprovalCount: builder.query({
        query() {
          return `/api/v1/bussines/approvalcount?status=pending`
        },
      }),
      fetchRequestApproval: builder.query({
        query({ filter, page = 0, size = 10, status = 'pending' }) {
          return {
            url: `/api/v1/bussines/approval`,
            params: { filter, size, page, status },
          }
        },
      }),
      approveItem: builder.mutation({
        query: ({ requestId }) => ({
          url: `/api/v1/bussines/transaction/checker`,
          method: 'POST',
          body: {
            action: 'APPROVE_TRADESHORTCUT',
            requestIds: requestId,
          },
        }),
      }),
      rejectItem: builder.mutation({
        query: ({ requestId }) => ({
          url: `/api/v1/bussines/transaction/checker`,
          method: 'POST',
          body: {
            action: 'REJECT_TRADESHORTCUT',
            requestIds: requestId,
          },
        }),
      }),
      approveWithdraw: builder.mutation({
        query: ({ requestId }) => ({
          url: `/api/v1/bussines/transaction/checker`,
          method: 'POST',
          body: {
            action: 'APPROVE_WITHDRAWAL',
            requestIds: requestId,
          },
        }),
      }),
      rejectWithdraw: builder.mutation({
        query: ({ requestId }) => ({
          url: `/api/v1/bussines/transaction/checker`,
          method: 'POST',
          body: {
            action: 'REJECT_WITHDRAWAL',
            requestIds: requestId,
          },
        }),
      }),
      sellerConfirm: builder.mutation({
        query: ({ escrowpaymentId, tradeshortcutId, confirm }) => ({
          url: `/api/v1/bussines/transaction/seller-confirm`,
          method: 'POST',
          body: {
            escrowpaymentId,
            tradeshortcutId,
            confirm,
          },
        }),
      }),
    }
  },
})

export const {
  useFetchApprovalQuery,
  useApproveItemMutation,
  useFetchApprovalCountQuery,
  useRejectItemMutation,
  useFetchRequestApprovalQuery,
  useApproveWithdrawMutation,
  useRejectWithdrawMutation,
  useSellerConfirmMutation,
} = approvalApiSlice
