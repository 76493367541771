import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBInputAmount from 'components/PMBInputAmount'
import PMBSelect from 'components/PMBSelect'
import RoundedComponent from 'components/Rounded'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { AlertCircle, MinusCircle, Plus } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardText,
  Container,
  FormGroup,
  Label,
} from 'reactstrap'
import { URL_TRANSFER_INVENTORY } from 'utils/pathUrl'
import { getPackingDetail } from './transferInventoryAction'
import {
  useLazyFetchInventoryQuery,
  useLazyFetchInventoryTransferQuery,
  useLazyFetchPackingQuery,
  useLazyFetchWarehousesQuery,
  usePostTransferMutation,
} from './transferInventoryApiSlice'

const FormTransferInventory = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [listOptions, setListOptions] = useState([])
  const [listOptionsOriginatingWarehouse, setListOptionsOriginatingWarehouse] =
    useState([])
  const [listOptionsWarehouse, setListOptionsWarehouse] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState(null)
  const [pics, setPics] = useState([''])
  const [createTransfer, resultTransfer] = usePostTransferMutation()
  const [getInventory, res] = useLazyFetchInventoryQuery()
  const [getGudang, resWarehouse] = useLazyFetchWarehousesQuery()
  const [getPacking, resPackings] = useLazyFetchPackingQuery()
  const [getTfInventori, resTfInventori] = useLazyFetchInventoryTransferQuery()

  const formatAmount = (val) => {
    if (!val && val !== 0) return ''
    return intl.formatNumber(val, {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  const parseAmount = (value) => {
    if (!value) return 0
    return parseFloat(value.replace(/\./g, '').replace(',', '.')) || 0
  }

  const handleAmountChange = (index, value) => {
    const numericValue = parseAmount(value)
    const maxAmount = parseFloat(pics[index]?.amount) || 0
    const finalAmount = Math.min(numericValue, maxAmount)

    const updatedPics = [...pics]
    updatedPics[index] = {
      ...updatedPics[index],
      transferAmount: finalAmount,
    }
    setPics(updatedPics)
  }

  const handleSubmit = async () => {
    try {
      const inventories = []

      for (const e of pics) {
        if (e?.isPacking) {
          const res = await dispatch(getPackingDetail({ id: e?.id }))

          if (res?.payload?.content) {
            inventories.push(
              ...res.payload.content.map((item) => ({
                id: item?.id,
                amount: item?.transferAmount || item?.amount,
              })),
            )
          }
        } else {
          inventories.push({
            id: e?.id,
            amount: e?.transferAmount || e?.amount,
          })
        }
      }

      const body = {
        fromWarehouseId: payload?.fromWarehouse?.id,
        toWarehouseId: payload?.toWarehouse?.id,
        inventories,
      }

      const result = await createTransfer(body)
      if (result?.error) {
        throw result
      } else {
        navigate(URL_TRANSFER_INVENTORY)
        enqueueSnackbar(`Pengiriman Inventori berhasil dibuat.`, {
          variant: 'success',
        })
        await getTfInventori({})
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.message ?? error?.message)
    }
  }

  const handleAdd = () => {
    setErrMsg('')
    setPics([...pics, ''])
  }

  const handleDelete = (index) => {
    setErrMsg('')
    setPics(pics.filter((_, i) => i !== index))
  }

  const getFilteredOptions = () => {
    const selectedIds = pics?.map((pic) => Number(pic?.id))
    // PROCESS TRANSFORM OPTIONS TO INCLUDE SEARCHABLE TEXT
    const transformedOptions = listOptions?.map((group) => ({
      ...group,
      options: group.options?.map((option) => {
        let searchableText = ''
        if (group.label === 'GRUP INVENTORI') {
          // FOR GRUP INVENTORI
          searchableText = option.label?.props?.children || ''
        } else {
          // FOR SEMUA INVENTORI
          const itemNameSpan =
            option.label?.props?.children[0]?.props?.children[1]
          searchableText = itemNameSpan?.props?.children || ''
        }

        return {
          ...option,
          searchableText,
          isDisabled: selectedIds?.includes(option?.id),
        }
      }),
    }))

    return transformedOptions
  }

  const fetchInv = async (warehouseId) => {
    try {
      let resInv = await getInventory({
        isForTransaction: 1,
        groupId: 'null',
        warehouseId,
      })

      if (resInv?.data?.totalItems > resInv?.data?.items?.length) {
        const updatedResInv = await getInventory({
          isForTransaction: 1,
          size: resInv?.data?.totalItems,
          groupId: 'null',
          warehouseId,
        })

        if (updatedResInv?.data) {
          resInv = updatedResInv
        }
      }
      let resPacking = await getPacking({ isForTransaction: 1, warehouseId })
      if (resPacking?.data?.totalItems > resPacking?.data?.items?.length) {
        const updatedResPacking = await getPacking({
          isForTransaction: 1,
          size: resPacking?.data?.totalItems,
          warehouseId,
        })
        if (updatedResPacking?.data) {
          resPacking = updatedResPacking
        }
      }

      setListOptions([
        {
          label: 'GRUP INVENTORI',
          options: resPacking?.data?.items?.map((e) => ({
            label: <span>{e?.label}</span>,
            value: e?.id,
            id: e?.id,
            isPacking: true,
          })),
        },
        {
          label: 'SEMUA INVENTORI',
          options: resInv?.data?.items?.map((e) => ({
            amount: e?.amount,
            label: (
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <RoundedComponent
                    childern={
                      <img
                        width={29}
                        height={29}
                        alt={e?.id}
                        src={e?.item?.linkFoto}
                      />
                    }
                    style={{
                      width: '29px',
                      height: '29px',
                    }}
                  />
                  <span>{e?.item?.nameItem}</span>
                </div>
                <span>
                  {intl.formatNumber(e?.amount, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{' '}
                  {e?.unit || e?.item?.unit}{' '}
                </span>
              </div>
            ),
            value: e?.id,
            id: e?.id,
          })),
        },
      ])
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    let isMounted = true
    setLoading(true)

    const fetchData = async () => {
      try {
        let resGudangAsal = await getGudang({ isForFilter: 1 })
        if (
          isMounted &&
          resGudangAsal?.data?.totalItems > resGudangAsal?.data?.items?.length
        ) {
          const updatedResGudangAsal = await getGudang({
            isForFilter: 1,
            size: resGudangAsal?.data?.totalItems,
          })
          if (updatedResGudangAsal?.data) {
            resGudangAsal = updatedResGudangAsal
          }
        }
        if (isMounted) {
          setListOptionsOriginatingWarehouse(resGudangAsal?.data?.newData)
        }
        let resGudang = await getGudang({ isForTransfer: 1 })
        if (
          isMounted &&
          resGudang?.data?.totalItems > resGudang?.data?.items?.length
        ) {
          const updatedResGudang = await getGudang({
            isForTransfer: 1,
            size: resGudang?.data?.totalItems,
          })
          if (updatedResGudang?.data) {
            resGudang = updatedResGudang
          }
        }
        if (isMounted) {
          setListOptionsWarehouse(resGudang?.data?.newData)
        }
      } catch (error) {
        if (isMounted) {
          setErrMsg(error?.message ?? 'Gagal mengambil data')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (payload?.fromWarehouse) {
      fetchInv(payload?.fromWarehouse?.id)
    }
  }, [payload?.fromWarehouse])

  return (
    <>
      <Container className="custom-container d-flex flex-column gap-1 py-2">
        <div className="pt-3">
          <BreadCrumbs
            breadCrumbActive={'Kirim Inventori'}
            breadCrumbParent={'Transfer Inventori'}
            breadCrumbParentLink={URL_TRANSFER_INVENTORY}
          />
        </div>
        <Card className="border-0 shadow-sm">
          <CardBody>
            <CardText tag="h5" className="pb-4">
              Kirim Inventori
            </CardText>

            <FormGroup>
              <Label className="fw-500">Gudang pengirim</Label>
              <PMBSelect
                value={payload?.fromWarehouse}
                onChange={(e) => {
                  setErrMsg('')
                  setPayload((prev) => ({
                    ...prev,
                    fromWarehouse: e,
                  }))
                }}
                options={
                  listOptionsOriginatingWarehouse?.filter(
                    (warehouse) =>
                      Number(warehouse?.value) !==
                      Number(payload?.toWarehouse?.value),
                  ) ?? []
                }
                placeholder="Pilih gudang pengirim..."
                isDisabled={pics?.some((pic) => pic !== '') ? true : false}
                isSearchable={true}
                filterOption={(option, inputValue) => {
                  if (!inputValue) return true
                  const label = option?.data?.label || ''
                  return label.toLowerCase().includes(inputValue.toLowerCase())
                }}
                noOptionsMessage={() => 'Tidak ada gudang yang sesuai'}
              />
            </FormGroup>

            <FormGroup>
              <Label className="fw-500">Gudang penerima</Label>
              <PMBSelect
                disabled={!payload?.toWarehouse}
                value={payload?.toWarehouse}
                onChange={(e) => {
                  setErrMsg('')
                  setPayload((prev) => ({
                    ...prev,
                    toWarehouse: e,
                  }))
                }}
                options={
                  listOptionsWarehouse?.filter(
                    (warehouse) =>
                      Number(warehouse?.value) !==
                      Number(payload?.fromWarehouse?.value),
                  ) ?? []
                }
                placeholder="Pilih gudang penerima..."
                isSearchable={true}
                filterOption={(option, inputValue) => {
                  if (!inputValue) return true
                  const label = option?.data?.label || ''
                  return label.toLowerCase().includes(inputValue.toLowerCase())
                }}
                noOptionsMessage={() => 'Tidak ada gudang yang sesuai'}
              />
            </FormGroup>

            <FormGroup>
              <Label className="fw-500">Inventori yang dipindahkan</Label>
              <div className="d-flex flex-column gap-3">
                {pics?.map((pic, index) => (
                  <div
                    key={pic.id}
                    className="d-flex w-100 align-items-center gap-2"
                  >
                    <Select
                      isLoading={loading}
                      className="flex-grow-1"
                      placeholder="Pilih inventori"
                      options={getFilteredOptions() ?? []}
                      value={pics[index]}
                      onChange={(e) => {
                        setErrMsg('')
                        const updatedPics = [...pics]
                        updatedPics[index] = {
                          ...e,
                          transferAmount: e?.amount,
                        }
                        setPics(updatedPics.filter((pic) => pic !== ''))
                      }}
                      isDisabled={!payload?.fromWarehouse}
                      isSearchable={true}
                      filterOption={(option, inputValue) => {
                        if (!inputValue) return true
                        return option?.data?.searchableText
                          ?.toLowerCase()
                          ?.includes(inputValue.toLowerCase())
                      }}
                      noOptionsMessage={() => 'Tidak ada data yang sesuai'}
                    />

                    {pic?.amount && (
                      <div style={{ width: '200px' }}>
                        <PMBInputAmount
                          value={formatAmount(
                            pic?.transferAmount ?? pic?.amount,
                          )}
                          onChange={(e) =>
                            handleAmountChange(index, e.target.value)
                          }
                          name={`amount-${index}`}
                          placeholder="Masukkan jumlah"
                        />
                      </div>
                    )}

                    <MinusCircle
                      onClick={() => handleDelete(index)}
                      className="text-danger pointer"
                      style={{
                        visibility: !payload?.fromWarehouse ? 'hidden' : '',
                      }}
                    />
                  </div>
                ))}
              </div>
            </FormGroup>

            <FormGroup>
              <CustomButton
                onClick={handleAdd}
                outline
                color={
                  !payload?.toWarehouse ||
                  !payload?.fromWarehouse ||
                  pics?.some((pic) => pic === '')
                    ? ''
                    : 'primary'
                }
                size="sm"
                disabled={
                  !payload?.toWarehouse ||
                  !payload?.fromWarehouse ||
                  pics?.some((pic) => pic === '')
                }
              >
                <Plus size={20} /> Tambah
              </CustomButton>
            </FormGroup>
            <hr></hr>

            {errMsg && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </FormGroup>
            )}

            <div className="d-flex gap-3">
              <Button
                className="btn-cancel"
                onClick={() => navigate(URL_TRANSFER_INVENTORY)}
                outline
                block
              >
                Batal
              </Button>
              <CustomButton
                onClick={() => handleSubmit()}
                disabled={
                  res?.isLoading ||
                  resPackings?.isLoading ||
                  resWarehouse?.isLoading ||
                  resultTransfer?.isLoading ||
                  !payload?.toWarehouse ||
                  !payload?.fromWarehouse ||
                  pics[0] === ''
                }
                block
                color="primary"
              >
                Kirim Inventori
              </CustomButton>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default FormTransferInventory
