import NavbarDashboard from 'components/Navbar'
import NavbarSide from 'components/NavbarSide'
import {
  setAlertMsg,
  toggleAlert,
} from 'features/public-views/login/loginSlice'
import { useAuthAccess } from 'hooks/useAuthAccess'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'
import HomeModal from 'ModalHome/HomeModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isOpenNavbar } = useSelector((s) => s.dashboard)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isEffectCompleted, setIsEffectCompleted] = useState(false)
  const { token, alreadychangepass, userNotPartnership, isV3 } = useAuthAccess()
  const profile = useGetMyProfileAvaQuery({ skip: !isEffectCompleted })

  const version = process.env.REACT_APP_VERSION
  const versionLocalStorage = localStorage.getItem('PMB_version')

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const alertStatus = localStorage.getItem('PMB_alert')

    if (alertStatus === 'true') {
      dispatch(toggleAlert(true))
      dispatch(
        setAlertMsg(
          'Sesi otomatis berakhir karena ada pembaharuan versi aplikasi. Masuk kembali ke akun Anda untuk melanjutkan.',
        ),
      )
      localStorage.removeItem('PMB_alert')
    }
  }, [dispatch])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    const deviceHash = searchParams.get('deviceHash')
    const fcmToken = searchParams.get('fcmToken')
    const mobileVersion = searchParams.get('mobileVersion')
    if (deviceHash) localStorage.setItem('PMB_deviceHash', deviceHash)
    if (fcmToken) localStorage.setItem('PMB_fcmToken', fcmToken)
    if (mobileVersion) localStorage.setItem('PMB_mobileVersion', mobileVersion)

    if (versionLocalStorage !== null && versionLocalStorage !== version) {
      localStorage.clear()
      localStorage.setItem('PMB_version', version)
      localStorage.setItem('PMB_alert', 'true')
      window.location.reload()
    } else if (versionLocalStorage === null) {
      localStorage.setItem('PMB_version', version)
    } else if (
      token === null ||
      (token !== null && !userNotPartnership && alreadychangepass === 0)
    ) {
      navigate('/login', { replace: true })
    } else {
      setIsEffectCompleted(true)
    }
  }, [
    token,
    userNotPartnership,
    alreadychangepass,
    versionLocalStorage,
    version,
    isV3,
    navigate,
    dispatch,
  ])

  if (!isEffectCompleted) {
    return null
  }

  const isMobile = screenWidth <= 768
  const isTabletOrDesktop = screenWidth >= 1100

  return (
    <>
      <div
        style={{
          minHeight: '100vh',
          overflowX: 'hidden',
          paddingTop: '75px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
        className="bg-light"
      >
        <NavbarDashboard color="white" expand="md" profile={profile} />

        {/* Sidebar */}
        <NavbarSide />

        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            marginLeft: isTabletOrDesktop
              ? isOpenNavbar
                ? '280px'
                : '80px'
              : '0',

            width: isTabletOrDesktop
              ? `calc(100% - ${isOpenNavbar ? '280px' : '80px'})`
              : '100%',
            transition: 'margin-left 0.3s ease',
          }}
          className="bg-light d-flex content-container"
        >
          <Outlet context={profile} />
        </div>
      </div>
      <HomeModal />
    </>
  )
}

export default App
