import axios from 'axios'
import { useState } from 'react'
import { ArrowLeft, FileText } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Spinner } from 'reactstrap'
import { generateDeliveryNote } from '../../dashboardAction'
import { useSnackbar } from 'notistack'

const HeaderSection = ({ transaction, isBuyer, refetch }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useSelector((s) => s.auth)
  const dashboard = useSelector((s) => s.dashboard)

  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  const downloadAsPdf = (data, url, type) => {
    setIsLoadingDownload(true)
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...{ responseType: 'blob' },
      })
      .then((response) => {
        const defName = `TRX/ID/${data.loan ? 'PMG-' : ''}${data.id}`
        var pdfNumber = defName
        if (data.deponumbers && data.deponumbers.length > 1) {
          pdfNumber = data.deponumbers[0].fullNumber
          if (data.loan || data.creditRequested > 0) {
            pdfNumber.replace(type, `${type}/PMG-`)
          }
        }
        var filename = `${pdfNumber.replaceAll('/', '-')}.pdf`
        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const blobFile = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blobFile)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(function (error) {})
      .finally(() => setIsLoadingDownload(false))
  }

  const handleGenerateDeliveryNote = async (id) => {
    setIsLoadingDownload(true)
    try {
      let res = await dispatch(
        generateDeliveryNote({ escrowpaymentId: id }),
      ).unwrap()
      enqueueSnackbar('Surat Jalan berhasil dibuat!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Gagal membuat Surat Jalan', { variant: 'error' })
    } finally {
      refetch()
      setIsLoadingDownload(false)
    }
  }

  return (
    <div className="row align-items-center py-3">
      <div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
        <ArrowLeft
          size={20}
          className="me-2"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        <h6 className="fw-bold mb-0">
          Detail {isBuyer ? 'Pembelian' : 'Penjualan'}
        </h6>
      </div>

      <div className="col-12 col-md-6 d-flex justify-content-md-end gap-2 flex-column flex-md-row">
        {transaction?.status === 'Selesai' && transaction?.invoice && (
          <Button
            outline
            color="primary"
            className="d-flex align-items-center w-md-auto mb-2 mb-md-0 mt-2"
            onClick={() => {
              downloadAsPdf(
                transaction,
                transaction?.escrowpayment?.userpinjamanId
                  ? transaction?.userId === transaction?.buyerDetail?.userId
                    ? transaction?.invoice?.pmToBuyer?.download
                    : transaction?.invoice?.pmToSeller?.download
                  : transaction?.invoice?.download,
                'INVF',
              )
            }}
            disabled={isLoadingDownload}
          >
            {isLoadingDownload ? (
              <Spinner size="sm" className="me-1" />
            ) : (
              <FileText size={16} className="me-1" />
            )}
            Unduh Invoice
          </Button>
        )}

        {transaction?.status === 'Diproses' &&
        dashboard?.ownerData?.userId == transaction?.sellerDetail?.userId ? (
          <Button
            outline
            color="primary"
            className="d-flex align-items-center w-md-auto mb-2 mb-md-0 mt-2"
            onClick={() => {
              if (transaction?.deliveryNote) {
                downloadAsPdf(transaction, transaction?.deliveryNote, 'DO')
              } else {
                handleGenerateDeliveryNote(transaction?.escrowpaymentId)
              }
            }}
            disabled={isLoadingDownload}
          >
            {isLoadingDownload ? (
              <Spinner size="sm" className="me-1" />
            ) : (
              <FileText size={16} className="me-1" />
            )}
            {transaction?.deliveryNote
              ? 'Unduh Surat Jalan'
              : 'Buat Surat Jalan'}
          </Button>
        ) : null}

        {/* HIDE FOR AT THE MOMENT */}
        {/* <Button
            outline
            color="primary"
            className="d-flex align-items-center w-md-auto"
          >
            <Edit3 size={16} className="me-1" />
            Penyesuaian Pesanan
          </Button> */}
      </div>
    </div>
  )
}

export default HeaderSection
