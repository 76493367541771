import { P } from 'components'
import CustomButton from 'components/Buttons/CustomButton'
import React, { useState } from 'react'
import { ChevronLeft, ChevronRight, Plus, Search } from 'react-feather'
import { Input, InputGroup, InputGroupText, Modal } from 'reactstrap'

function Breadcrumb({ items, onToSpecificGroup }) {
  const renderBreadcrumb = () => {
    return items.map((item, index) => {
      const isLast = index === items.length - 1

      if (items.length > 3 && index === 0) {
        return (
          <React.Fragment key={index}>
            <span
              style={{ cursor: 'pointer' }}
              // onClick={() => console.log(item)}
            >
              <P size="small">{item.label}</P>
            </span>
            <div style={{ paddingLeft: 5, paddingRight: 5 }}>
              <P size="small">{'>'}</P>
            </div>
            <span
              style={{ cursor: 'pointer' }}
              // onClick={() => onClick('...')}
              key="ellipsis"
            >
              <P size="small">...</P>
            </span>
            <div style={{ paddingLeft: 5, paddingRight: 5 }}>
              <P size="small">{'>'}</P>
            </div>
          </React.Fragment>
        )
      }

      if (items.length > 3 && index < items.length - 2 && index > 0) {
        return null
      }

      return (
        <React.Fragment key={index}>
          <span
            style={{
              cursor: isLast ? 'default' : 'pointer',
              color: isLast ? '#667085' : 'inherit',
            }}
            onClick={() => !isLast && onToSpecificGroup(item, index)}
          >
            <P size="small">{item.label}</P>
          </span>
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            {!isLast && <P size="small">{'>'}</P>}
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {renderBreadcrumb()}
    </div>
  )
}

export default function AddStockGroupModal({
  isOpen,
  groupList,
  inventoryList,
  onShowCreateGroup,
  onPressCancel,
  onPressOpenFolder,
  folderList,
  onToSpecificGroup,
  onPressBack,
  selectedFolder,
  onPressSelectFolder,
  onGroupInventory,
}) {
  const [searchQuery, setSearchQuery] = useState('')

  const filteredGroupList = groupList?.filter(
    (group) =>
      group.label.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !inventoryList.some(
        (inventory) =>
          inventory.groupId === group.id || inventory?.id === group?.id,
      ),
  )

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  return (
    <Modal isOpen={isOpen} centered size="lg">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div className="px-4 pt-4">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2">
              {folderList?.length > 1 && (
                <div onClick={onPressBack} style={{ cursor: 'pointer' }}>
                  <ChevronLeft />
                </div>
              )}

              <P size="medium" fontWeightCustom={600}>
                Tambah {inventoryList?.length} inventori ke
              </P>
            </div>
            <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: 'pointer' }}
              onClick={onShowCreateGroup}
            >
              <Plus size={18} color="#005370" />
              <P size="regular" color={'#005370'} fontWeightCustom={600}>
                Grup Baru
              </P>
            </div>
          </div>
          <div className="mt-3 mb-4">
            <Breadcrumb
              items={folderList}
              onToSpecificGroup={(data, index) =>
                onToSpecificGroup(data, index)
              }
            />
          </div>
          <div>
            <InputGroup>
              <Input
                placeholder="Cari grup..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <InputGroupText
                style={{
                  backgroundColor: '#ffffff',
                  borderLeft: '1px solid #ced4da',
                }}
              >
                <Search size={15} style={{ cursor: 'pointer' }} />
              </InputGroupText>
            </InputGroup>
          </div>
          {filteredGroupList?.length ? (
            <>
              <div
                className="mt-2"
                style={{
                  maxHeight: 300,
                  overflowY: 'auto',
                }}
              >
                {filteredGroupList?.map((el, i) => (
                  <div
                    key={i}
                    className="my-2"
                    style={{
                      cursor: 'pointer',
                      border: `${el?.id === selectedFolder?.id ? '1px solid #005370' : '1px solid #EAECF0'}`,
                      borderRadius: 8,
                      padding: 10,
                    }}
                    onDoubleClick={() => {
                      if (Number(el?.totalInnerGroup) > 0) {
                        onPressOpenFolder(el)
                      }
                    }}
                    onClick={() => {
                      onPressSelectFolder(el)
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <P size="regular">{el?.label}</P>
                      {Number(el?.totalInnerGroup) > 0 ? (
                        <ChevronRight size={20} color="#758CA4" />
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div
              className="py-3"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <P>Belum ada grup yang ditambahkan.</P>
            </div>
          )}
        </div>
        <div
          className="d-flex justify-content-between p-4 mt-2"
          style={{ borderTop: '1px solid #EAECF0' }}
        >
          <CustomButton
            style={{ width: '49%', border: '1px solid #D0D5DD' }}
            color="transparent"
            onClick={onPressCancel}
          >
            <P>Batal</P>
          </CustomButton>
          <CustomButton
            color="primary"
            style={{ width: '49%' }}
            disabled={selectedFolder?.id ? false : true}
            onClick={onGroupInventory}
          >
            <P>Tambah ke Grup</P>
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}
