import { translations } from 'assets/titles/title.js'

export const getTranslationKey = (pathname) => {
  if (translations[pathname]) {
    return pathname
  }

  const match = pathname.match(/^\/[^/]+\/?/)
  if (match) {
    const basePath = match[0]

    const foundKey = Object.keys(translations).find((key) =>
      key.startsWith(basePath),
    )

    if (foundKey) {
      return foundKey
    }
  }

  return pathname
}
