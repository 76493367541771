import { getPublicVersion } from 'features/public-api/publicVersion/publicVersionAction'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const TEN_MINUTES = 10 * 60 * 1000 // 10 menit

const useAutoFetchPublicVersion = () => {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.publicVersion)

  const lastFetchTimeRef = useRef(
    Number(localStorage.getItem('lastFetchTime')) || 0,
  )

  useEffect(() => {
    const now = Date.now()

    if (!data?.unitList || now - lastFetchTimeRef.current >= TEN_MINUTES) {
      dispatch(getPublicVersion())
      localStorage.setItem('lastFetchTime', now.toString())
      lastFetchTimeRef.current = now
    }
  }, [dispatch, data])

  return data
}

export default useAutoFetchPublicVersion
