import { TableComponent as DataTable } from 'components/TableComponent'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { newFormatThousandSeparator } from 'utils/formatNumber'

const ScrollableContainer = styled.div`
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 10px;
  -webkit-overflow-scrolling: touch;

  /* Ensure scrollbar is always visible */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: ${(props) => (props.isMobile ? '6px' : '8px')};
    display: block !important;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: ${(props) => (props.isMobile ? '1px' : '5px')};
    display: block !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: ${(props) => (props.isMobile ? '1px' : '5px')};
    display: block !important;
    min-width: 60px;
    &:hover {
      background: #c4c4c4;
    }
  }

  /* Firefox */
  scrollbar-width: thin !important;
  scrollbar-color: #d4d4d4 #f1f1f1;

  /* IE/Edge */
  -ms-overflow-style: scrollbar !important;

  /* Force scrollbar visibility */
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: transparent;
    pointer-events: none;
  }

  /* Force minimum scroll area */
  &::after {
    content: '';
    display: block;
    min-width: ${(props) => (props.isMobile ? '800px' : '1200px')};
    height: 1px;
    opacity: 0;
  }
`

const ChartContainer = styled.div`
  min-width: ${(props) => (props.isMobile ? '800px' : '1200px')};
`

const MonthlyTab = ({ selectedMonthYear, dataCashFlow, res }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const generateMonthlyData = (selectedMonthYear, rawData) => {
    const selectedMonth = moment(selectedMonthYear).format('YYYY-MM')
    const daysInMonth = moment(selectedMonthYear).daysInMonth()

    const monthlyData = Array?.from({ length: daysInMonth }, (_, index) => {
      const fullDate = moment(
        `${selectedMonth}-${index + 1}`,
        'YYYY-MM-DD',
      ).format('D MMMM YYYY')
      return {
        date: fullDate,
        income: 0,
        expense: 0,
        profit: 0,
      }
    })

    rawData?.forEach((item) => {
      const dayIndex = moment(item?.createddate).date() - 1
      if (monthlyData[dayIndex]) {
        if (item?.incomeorexpense === 'income') {
          monthlyData[dayIndex].income += item?.amount
        } else if (item?.incomeorexpense === 'expense') {
          monthlyData[dayIndex].expense += item?.amount
        }

        monthlyData[dayIndex].profit =
          monthlyData[dayIndex].income - monthlyData[dayIndex].expense
      }
    })

    return monthlyData
  }

  const processedMonthlyData = generateMonthlyData(
    selectedMonthYear,
    dataCashFlow?.items || [],
  )

  const columnsMonthly = [
    {
      name: <span>Periode</span>,
      selector: (row) => row?.date,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pemasukan</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.income)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pengeluaran</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.expense)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Untung/Rugi</span>,
      selector: (row) => (
        <span style={{ color: row.profit >= 0 ? '#027A48' : '#B42318' }}>
          {row.profit >= 0 ? '+ ' : '- '}
          Rp{newFormatThousandSeparator(Math.abs(row.profit))}
        </span>
      ),
      wrap: true,
      grow: 2,
    },
  ]

  const optionsMonthly = {
    chart: {
      type: 'bar',
      height: isMobile ? 400 : 500,
      zoom: { enabled: false },
      toolbar: { show: false },
      stacked: false,
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        borderRadius: isMobile ? 1 : 5,
        horizontal: false,
        columnWidth: '50%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
        distributed: false,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        shape: 'circle',
      },
    },
    colors: ['#039855', '#D92D20'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter: function (value) {
          return newFormatThousandSeparator(value)
        },
      },
    },
    xaxis: {
      categories: processedMonthlyData.map((data) => data.date),
      labels: {
        style: { fontSize: isMobile ? '10px' : '12px', colors: '#6E6E6E' },
        rotate: isMobile ? -90 : -45,
        offsetX: 0,
        offsetY: 0,
        trim: false,
        hideOverlappingLabels: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickPlacement: 'on',
      tickAmount: 31, // Show all days in the month
    },
    grid: {
      borderColor: '#f1f1f1',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: isMobile ? 5 : 10,
      },
    },
    title: {
      text: 'Arus Kas Bulanan (Rupiah)',
      offsetY: isMobile ? 0 : 5,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return newFormatThousandSeparator(value)
        },
        style: { fontSize: isMobile ? '10px' : '12px', colors: '#6E6E6E' },
        offsetX: isMobile ? -10 : 0,
        rotate: isMobile ? -60 : -0,
        align: 'right',
      },
      tickAmount: isMobile ? 4 : 5,
      min: 0,
      forceNiceScale: true,
      floating: false,
      decimalsInFloat: 0,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
  }

  const seriesMonthly = [
    {
      name: 'Pemasukan',
      data: processedMonthlyData.map((data) => data.income),
    },
    {
      name: 'Pengeluaran',
      data: processedMonthlyData.map((data) => data.expense),
    },
  ]

  return (
    <>
      <ScrollableContainer isMobile={isMobile}>
        <ChartContainer isMobile={isMobile}>
          <Chart
            options={optionsMonthly}
            series={seriesMonthly}
            type="bar"
            height={isMobile ? 400 : 500}
          />
        </ChartContainer>
      </ScrollableContainer>

      <DataTable
        columns={columnsMonthly}
        data={processedMonthlyData}
        progressPending={res?.isFetching}
        progressComponent={
          <div style={{ margin: '100px 0px' }}>
            <Spinner color="primary" />
          </div>
        }
      />
    </>
  )
}

export default MonthlyTab
