import { P } from 'components'
import CustomButton from 'components/Buttons/CustomButton'
import PMBSelect from 'components/PMBSelect'
import PMBTabSwitcher from 'components/PMBTabSwitcher'
import BreadCrumbs from 'components/breadcrumbs'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { AlertCircle, Calendar, Eye, EyeOff, Info, Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import ModalDisbursement from '../detail/ModalDisbursement'
import { toggleModalChangeProfile } from '../detail/detailSlice'
import { getGroupList } from '../groups-list/groupListAction'
import CardUploadImage from './CardUploadImage'
import ModalConfirmation from './ModalConfirmation'
import ModalDeletePaymentMethod from './ModalDeletePaymentMethod'
import { useFetchBanksQuery, usePreCheckFarmerMutation } from './formApiSlice'
import { setPayloadSlice, toggleModal } from './formSlice'
import {
  getListCity,
  getListDistrict,
  getListPostalCode,
  getListProvinces,
  getListSubdistrict,
} from './formsAction'
import moment from 'moment-timezone'
import Flatpickr from 'react-flatpickr'

const tabAddStaff = [
  {
    key: 1,
    label: 'Data Akun',
  },
  {
    key: 2,
    label: 'Identitas & Basic Info (opsional)',
  },
  {
    key: 3,
    label: 'Metode Pembayaran (opsional)',
  },
]

const FormRegis = ({ row }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataState = location.state
  const {
    provinces,
    cities,
    districts,
    subDistricts,
    postalCodes,
    payloadOcr,
    urlSelfie,
    urlKtp,
  } = useSelector((s) => s.farmersForm)
  const { isOpenNavbar } = useSelector((s) => s.dashboard)
  const { data: banks } = useFetchBanksQuery()
  const [precheck, result] = usePreCheckFarmerMutation()
  const { enqueueSnackbar } = useSnackbar()

  const [disableCity, setDisableCity] = useState(false)
  const [disableDistrict, setDisableDistrict] = useState(false)
  const [disableSubDistrict, setDisableSubDistrict] = useState(false)
  const [disablePostalCode, setDisablePostalCode] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState({})
  const [isClick, setIsClick] = useState(false)
  const [isOpenModalDisbursement, setIsOpenModalDisbursement] = useState(false)
  // AGENTS NEEDS
  const [currentMethodName, setCurrentMethodName] = useState(null)
  const [tempLogoBank, setTempLogoBank] = useState(null)
  const [agentSelected, setAgentSelected] = useState(null)
  const [isShowModalDeletePaymentMethod, setIsShowModalDeletePaymentMethod] =
    useState(false)
  const [isSwitchAgent, setIsSwitchAgent] = useState(false)
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [tabIndex, setIndexTab] = useState(tabAddStaff[0]?.key)
  const [isShowPin, setIsShowPin] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { loading, bannerMsg, groups, allGroupCount } = useSelector(
    (s) => s.groupsList,
  )

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  const isMobile = screenWidth < 1100

  const handleDob = (date) => {
    const parts = date?.split('-')
    const formattedDate = parts?.reverse().join('-')
    return formattedDate
  }

  const handleHasAnswered = () => {
    const fieldRequiredRegister = [
      'identity',
      'password',
      // 'imgKTP',
      // 'imgSelfie',
      'fullName',
      // 'dob',
      // 'sex',
      // 'noKTP',
      // 'province',
      // 'city',
      // 'district',
      // 'subDistrict',
      // 'postalCode',
      // 'address',
      // 'bankCode',
      // 'bankName',
      // 'bankAccountNumber',
      // 'bankAccountName',
    ]

    const fieldRequiredEdit = [
      'fullName',
      'dob',
      'sex',
      'noKTP',
      'province',
      'city',
      'district',
      'subDistrict',
      'postalCode',
      'address',
    ]

    const fieldRequired = !row ? fieldRequiredRegister : fieldRequiredEdit

    const data = fieldRequired.map((e) => {
      return Object.prototype.hasOwnProperty.call(payload, e) && payload[e]
    })
    return data.every((e) => e)
  }

  useEffect(() => {
    if (provinces.length < 1) {
      dispatch(getListProvinces())
    }
    if (postalCodes.length > 0) {
      setPayload((prev) => ({
        ...prev,
        postalCode: postalCodes[0],
      }))
    }
    if (urlSelfie) {
      setPayload((prev) => ({
        ...prev,
        imgSelfie: urlSelfie,
      }))
    }
    if (urlKtp) {
      setPayload((prev) => ({
        ...prev,
        imgKTP: urlKtp,
      }))
    }
  }, [provinces, postalCodes, urlSelfie, urlKtp])

  useEffect(() => {
    if (payloadOcr) {
      setPayload((prev) => ({
        ...prev,
        dob: payloadOcr?.dob,
        city: payloadOcr?.city,
        fullName: payloadOcr?.fullName,
        sex:
          payloadOcr?.gender === 'LAKI-LAKI'
            ? '1'
            : payloadOcr?.gender === 'PEREMPUAN'
              ? '0'
              : '',
        noKTP: payloadOcr?.nik,
        province: {
          value: payloadOcr?.regionInfo?.province?.nama,
          label: payloadOcr?.regionInfo?.province?.nama,
          id: payloadOcr?.regionInfo?.province?.id,
        },
        city: {
          value: payloadOcr?.regionInfo?.city?.nama,
          label: payloadOcr?.regionInfo?.city?.nama,
          id: payloadOcr?.regionInfo?.city?.id,
        },
        district: {
          value: payloadOcr?.regionInfo?.district?.nama,
          label: payloadOcr?.regionInfo?.district?.nama,
          id: payloadOcr?.regionInfo?.district?.id,
        },
        subDistrict: {
          value: payloadOcr?.regionInfo?.subDistrict?.nama,
          label: payloadOcr?.regionInfo?.subDistrict?.nama,
          id: payloadOcr?.regionInfo?.subDistrict?.id,
        },
        address: payloadOcr?.address,
      }))
      dispatch(getListCity({ id: payloadOcr?.regionInfo?.province?.id }))
      dispatch(getListDistrict({ id: payloadOcr?.regionInfo?.city?.id }))
      dispatch(getListSubdistrict({ id: payloadOcr?.regionInfo?.district?.id }))
      dispatch(
        getListPostalCode({ id: payloadOcr?.regionInfo?.subDistrict?.id }),
      )
    }
  }, [payloadOcr])

  useEffect(() => {
    if (result) {
      if (result?.error) {
        setErrMsg(result?.error?.data?.meta?.message)
      }
    }
    if (row) {
      setPayload((prev) => ({
        ...prev,
        dob: row?.dob,
        city: row?.city,
        fullName: row?.fullName,
        sex: row?.sex,
        noKTP: row?.noKTP,
        email: row?.email,
        province: row?.province
          ? {
              value: row?.province,
              label: row?.province,
            }
          : null,
        city: row?.city
          ? {
              value: row?.city,
              label: row?.city,
            }
          : null,
        district: row?.district
          ? {
              value: row?.district,
              label: row?.district,
            }
          : null,
        subDistrict: row?.subDistrict
          ? {
              value: row?.subDistrict,
              label: row?.subDistrict,
            }
          : null,
        postalCode: row?.postalCode
          ? {
              value: row?.postalCode,
              label: row?.postalCode,
            }
          : null,
        address: row?.address,
      }))
    } else {
      setPayload({ identity: dataState?.phoneNumber })
    }
  }, [result, row])

  const toggleModalDisbursement = () => {
    setIsOpenModalDisbursement((prev) => !prev)
    setIsEdit((prev) => !prev)
  }

  const handleAddDisbursement = (data) => {
    enqueueSnackbar(
      `Metode pembayaran ${isEdit ? 'berhasil dirubah!' : 'baru berhasil ditambahkan'}`,
      {
        variant: 'success',
      },
    )
    if (data.method === 'bank') {
      const bankDetails = data?.bankDetails ?? null
      setTempLogoBank(bankDetails.bankSelected.image ?? null)
      const bankAccountNumber = bankDetails?.accountNumber ?? null
      const bankName = bankDetails?.bankSelected.label ?? null
      const bankCode = bankDetails?.bankSelected.value ?? null
      const bankAccountName = bankDetails?.accountName ?? null

      setPayload((prev) => ({
        ...prev,
        bankAccountNumber: bankAccountNumber,
        bankName: bankName,
        bankCode: bankCode,
        bankAccountName: bankAccountName,
      }))
    } else {
      setAgentSelected(data?.agentInfo ?? null)
      if (data?.isDefault === 0 && !payload?.bankAccountNumber) {
        setIsSwitchAgent(true)
      } else if (data?.isDefault) {
        setIsSwitchAgent(data?.isDefault === 1 ? true : false)
      }
    }

    // CLOSE MODAL DISBURSEMENT
    toggleModalDisbursement()
  }

  // REMOVE PAYMENT METHOD BASED ON BANK OR AGENT
  const handleRemoveDisbursement = (method) => {
    enqueueSnackbar('Metode pembayaran berhasil dihapus!', {
      variant: 'success',
    })
    if (method === 'bank') {
      setTempLogoBank(null)
      setPayload((prev) => ({
        ...prev,
        bankAccountNumber: null,
        bankName: null,
        bankCode: null,
        bankAccountName: null,
      }))
    } else if (method === 'agent') {
      setAgentSelected(null)
      setIsSwitchAgent(false)
    }
  }

  const toggleModalDeletePaymentMethod = () =>
    setIsShowModalDeletePaymentMethod((prev) => !prev)

  const handleAddPaymentMethod = () => {
    setIsAddingPaymentMethod(true)
    setIsOpenModalDisbursement(true)
    setIsEdit(false)

    if (payload?.bankAccountNumber && !agentSelected?.relationUserId) {
      setCurrentMethodName('agent')
    } else if (!payload?.bankAccountNumber && agentSelected?.relationUserId) {
      setCurrentMethodName('bank')
    } else if (payload?.bankAccountNumber && agentSelected?.relationUserId) {
      setCurrentMethodName('bank')
    } else {
      setCurrentMethodName(null)
    }
  }

  const handleEditPaymentMethod = (methodName) => {
    setIsAddingPaymentMethod(false)
    setIsOpenModalDisbursement(true)
    setIsEdit(true)

    if (methodName === 'bank' && payload?.bankAccountNumber) {
      setCurrentMethodName(methodName)
    } else if (methodName === 'agent' && agentSelected?.relationUserId) {
      setCurrentMethodName(methodName)
    }
  }

  const handleSetDefaultPayment = async (value) => {
    setIsSwitchAgent(value)
    enqueueSnackbar('Metode pembayaran utama berhasil diubah!', {
      variant: 'success',
    })
  }

  // HANDLE SWITCH
  useEffect(() => {
    if (payload?.bankAccountNumber && !agentSelected?.relationUserId) {
      setIsSwitchAgent(false)
    } else if (!payload?.bankAccountNumber && agentSelected?.relationUserId) {
      setIsSwitchAgent(true)
    }
  }, [payload])

  useEffect(() => {
    dispatch(getGroupList({}))
  }, [])

  const renderTabContent = {
    1: renderDataAccount,
    2: renderBasicInfo,
    3: renderPayment,
  }

  return (
    <>
      <Container className="custom-container d-flex flex-column gap-2 py-3">
        <BreadCrumbs
          breadCrumbActive={'Tambah Anggota'}
          breadCrumbParent2={'Daftar Anggota'}
          breadCrumbParent2Link={'/farmers'}
        />
        <div>
          <P size="large">Tambah Anggota</P>
        </div>
        <div className="mt-2">
          <PMBTabSwitcher
            activeTab={tabIndex}
            tabs={tabAddStaff}
            onTabChange={(id) => setIndexTab(id)}
            boldLabel={true}
          />
        </div>
        {/* MODAL REMOVE AGENT CONFIRMATION */}
        <ModalDeletePaymentMethod
          isOpen={isShowModalDeletePaymentMethod}
          toggle={toggleModalDeletePaymentMethod}
          methodName={currentMethodName}
          onConfirm={handleRemoveDisbursement}
        />
        {/* END MODAL REMOVE AGENT CONFIRMATION */}

        {/* MODAL DISBURSEMENT */}
        <ModalDisbursement
          isOpen={isOpenModalDisbursement}
          toggle={toggleModalDisbursement}
          handleAddDisbursement={handleAddDisbursement}
          noHpFarmer={agentSelected?.relationPhoneNumber ?? ''}
          methodName={currentMethodName}
          isBankExist={payload?.bankAccountNumber ? true : false}
          isAgentExist={agentSelected?.relationUserId ? true : false}
          {...(!isAddingPaymentMethod && {
            dataFormEdit: payload,
            agentSelected: agentSelected,
            isSwitchAgent: isSwitchAgent,
          })}
          isEdit={isEdit}
        />
        {/* END MODAL DISBURSEMENT */}

        <ModalConfirmation row={row} />
        {renderTabContent[tabIndex] ? renderTabContent[tabIndex]() : null}
      </Container>
      {renderFooter()}
    </>
  )

  function renderDataAccount() {
    return (
      <>
        {!row ? (
          <>
            {/* Data Akun */}
            <Card className="border-0 shadow-sm">
              <CardBody className="d-flex flex-column gap-2">
                {/* <h5 className="fw-semibold">Daftarkan Pengguna</h5> */}
                <div className="mt-1">
                  <P size="medium">Data akun</P>
                  <P color={'#667085'} size="regular">
                    Digunakan untuk login ke akun PasarMIKRO
                  </P>
                </div>

                <FormGroup className="mt-3">
                  <P size="regular">Nama lengkap</P>
                  <Input
                    className="mt-1"
                    placeholder="Masukkan nama lengkap"
                    autoComplete="off"
                    name="fullName"
                    id="fullName"
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }}
                    value={payload?.fullName ?? ''}
                    invalid={!payload?.fullName && isClick}
                    disabled={row}
                  />
                </FormGroup>

                <FormGroup>
                  <P size="regular">Nomor telepon</P>
                  <Input
                    className="mt-1"
                    placeholder="Masukkan nomor telepon"
                    id="identity"
                    name="identity"
                    value={payload?.identity ?? ''}
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                      setErrMsg('')
                    }}
                    autoComplete="new-identity"
                    invalid={!payload?.identity && isClick}
                  />
                </FormGroup>
                <FormGroup>
                  <P size="regular">Kata sandi</P>
                  <InputGroup className="mt-1">
                    <Input
                      placeholder="Masukkan 6 digit PIN"
                      type={isShowPin ? null : 'password'}
                      name="password"
                      id="password"
                      onChange={(e) => {
                        setPayload((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value.replace(
                            /[^0-9]+/g,
                            '',
                          ),
                        }))
                      }}
                      value={payload?.password ?? ''}
                      autoComplete="new-password"
                      maxLength={6}
                      // onFocus={() => {
                      //   if (payload?.identity) {
                      //     precheck({ identity: payload?.identity })
                      //   }
                      // }}
                      invalid={!payload?.password && isClick}
                    />
                    <InputGroupText
                      style={{
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsShowPin((prevState) => !prevState)}
                    >
                      {isShowPin ? <Eye size={18} /> : <EyeOff size={18} />}
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <P size="regular">Grup (opsional)</P>
                  <PMBSelect
                    menuPlacement="top"
                    className="mt-1"
                    placeholder="Pilih grup untuk dimasukkan"
                    options={groups?.items?.map((el) => ({
                      ...el,
                      label: el?.name,
                      value: el?.name,
                    }))}
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        group: e,
                        groupCode: e?.code,
                      }))
                    }}
                    value={payload?.group}
                  />
                </FormGroup>
                {errMsg && (
                  <FormGroup>
                    <small className="text-danger">
                      <AlertCircle size={15} /> {errMsg}
                    </small>
                  </FormGroup>
                )}
              </CardBody>
            </Card>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  function renderBasicInfo() {
    return (
      <>
        <div className="mb-3">
          <P size="regular" color={'#344054'}>
            Dengan melengkapi identitas dan data diri, akun dari anggota ini
            akan langsung diverifikasi.
          </P>
        </div>
        {/* Data Foto */}
        <Card className="border-0 shadow-sm">
          <CardBody className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center gap-2">
              <P size="medium">Identitas (opsional)</P>
              <Info size={16} />
            </div>
            <FormGroup className="mt-3">
              <P size="regular">Foto KTP</P>
              <CardUploadImage
                className="mt-2"
                type="KTP"
                // className={`${!payload?.imgKTP && isClick ? 'border-danger' : ''}`}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Foto Selfie</P>
              <CardUploadImage
                className="mt-2"
                type="SELFIE"
                // className={`${!payload?.imgSelfie && isClick ? 'border-danger' : ''}`}
              />
            </FormGroup>
          </CardBody>
        </Card>
        {/* Data Diri */}
        <Card className="border-0 shadow-sm">
          <CardBody className="d-flex flex-column gap-2">
            {!row ? (
              <>
                <div>
                  <P size="medium">Data diri (opsional)</P>
                  <P size="regular" color={'#667085'}>
                    Beberapa data terisi secara otomatis dari foto KTP yang
                    di-upload. Periksa kembali sebelum lanjut.
                  </P>
                </div>
              </>
            ) : (
              <></>
            )}
            <FormGroup className="mt-3">
              <P size="regular">NPWP</P>
              <Input
                className="mt-1"
                placeholder="Masukkan nomor NPWP"
                autoComplete="off"
                name="noNPWP"
                id="noNPWP"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }}
                value={payload?.noNPWP ?? ''}
                invalid={!payload?.noNPWP && isClick}
                disabled={row}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Tanggal lahir</P>
              {/* <Input
                className="mt-1"
                placeholder="-"
                autoComplete="off"
                type="date"
                name="dob"
                id="dob"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: handleDob(e.target.value),
                  }))
                }}
                value={handleDob(payload.dob) ?? ''}
                invalid={!payload?.dob && isClick && row}
              /> */}
              <div className="d-flex align-items-center justify-content-center border rounded">
                <Calendar size={17} className="ms-3" />
                <Flatpickr
                  options={{
                    dateFormat: 'd-M-Y',
                    defaultDate: payload.dob
                      ? moment(payload.dob, 'DD-MM-YYYY').toDate()
                      : '',
                    onClose: (selectedDate, dateStr, instance) => {
                      setPayload((prev) => ({
                        ...prev,
                        dob: handleDob(
                          moment(selectedDate[0]).local().format('YYYY-MM-DD'),
                        ),
                      }))
                    },
                  }}
                  value={[
                    payload.dob
                      ? new Date(handleDob(payload.dob, 'DD-MM-YYYY'))
                      : '',
                  ]}
                  className="form-control flat-picker bg-transparent border-0 shadow-none"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <P size="regular">Jenis Kelamin</P>
              <Row className="mt-1">
                <Col>
                  <div
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        sex: '1',
                      }))
                    }}
                    className={`d-flex justify-content-between align-items-center p-2 rounded border
                      ${!payload?.sex && isClick && row ? 'border-danger' : ''}`}
                  >
                    Laki-laki
                    <Input
                      name="sex"
                      id="Laki-laki"
                      onChange={(e) => {
                        setPayload((prev) => ({
                          ...prev,
                          [e.target.name]: '1',
                        }))
                      }}
                      value={payload?.sex ?? ''}
                      checked={payload?.sex === '1'}
                      type="radio"
                      invalid={!payload?.sex && isClick && row}
                    />
                  </div>
                </Col>
                <Col>
                  <div
                    onClick={() => {
                      setPayload((prev) => ({
                        ...prev,
                        sex: '0',
                      }))
                    }}
                    className={`d-flex justify-content-between align-items-center p-2 rounded border
                    ${!payload?.sex && isClick && row ? 'border-danger' : ''}`}
                  >
                    Perempuan
                    <Input
                      name="sex"
                      id="Perempuan"
                      onChange={(e) => {
                        setPayload((prev) => ({
                          ...prev,
                          [e.target.name]: '0',
                        }))
                      }}
                      value={payload?.sex ?? ''}
                      checked={payload?.sex === '0'}
                      type="radio"
                      invalid={!payload?.sex && isClick && row}
                    />
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <P size="regular">Email</P>
              <Input
                className="mt-1"
                placeholder="-"
                autoComplete="off"
                name="email"
                id="email"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }}
                value={payload?.email ?? ''}
                type="email"
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">NIK</P>
              <Input
                className="mt-1"
                name="noKTP"
                id="noKTP"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value.replace(/[^0-9]+/g, ''),
                  }))
                }}
                value={payload?.noKTP ?? ''}
                placeholder="-"
                autoComplete="off"
                invalid={!payload?.noKTP && isClick && row}
                // disabled={row}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Provinsi</P>
              <Select
                className="mt-1"
                options={provinces}
                name="province"
                id="province"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    province: e,
                    city: null,
                    district: null,
                    subDistrict: null,
                    postalCode: null,
                  }))
                  dispatch(getListCity({ id: e.id }))
                  setDisableDistrict(true)
                  setDisableSubDistrict(true)
                  setDisablePostalCode(true)
                }}
                value={payload?.province ?? ''}
                placeholder="Pilih provinsi"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      !payload?.province && isClick && row ? 'red' : '#dee2e6',
                  }),
                }}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Kota</P>
              <Select
                className="mt-1"
                options={cities}
                isDisabled={disableCity}
                name="city"
                id="city"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    city: e,
                    district: null,
                    subDistrict: null,
                    postalCode: null,
                  }))
                  dispatch(getListDistrict({ id: e.id }))
                  setDisableDistrict(false)
                  setDisableSubDistrict(true)
                  setDisablePostalCode(true)
                }}
                value={payload?.city ?? ''}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      !payload?.city && isClick && row ? 'red' : '#dee2e6',
                  }),
                }}
                placeholder="Pilih kota"
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Kecamatan</P>
              <Select
                className="mt-1"
                options={districts}
                isDisabled={disableDistrict}
                name="district"
                id="district"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    district: e,
                    subDistrict: null,
                    postalCode: null,
                  }))
                  dispatch(getListSubdistrict({ id: e.id }))
                  setDisableSubDistrict(false)
                }}
                value={payload?.district ?? ''}
                placeholder="Pilih kecamatan"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      !payload?.district && isClick && row ? 'red' : '#dee2e6',
                  }),
                }}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Kelurahan</P>
              <Select
                className="mt-1"
                options={subDistricts}
                name="subDistrict"
                id="subDistrict"
                isDisabled={disableSubDistrict}
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    subDistrict: e,
                    postalCode: null,
                  }))
                  dispatch(getListPostalCode({ id: e.id }))
                  setDisablePostalCode(false)
                }}
                value={payload?.subDistrict ?? ''}
                placeholder="Pilih kelurahan"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      !payload?.subDistrict && isClick && row
                        ? 'red'
                        : '#dee2e6',
                  }),
                }}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Kode pos</P>
              <Select
                className="mt-1"
                options={postalCodes}
                name="postalCode"
                id="postalCode"
                isDisabled={disablePostalCode}
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    postalCode: e,
                  }))
                }}
                value={payload?.postalCode ?? ''}
                placeholder="Pilih kode pos"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      !payload?.postalCode && isClick && row
                        ? 'red'
                        : '#dee2e6',
                  }),
                }}
              />
            </FormGroup>
            <FormGroup>
              <P size="regular">Alamat</P>
              <Input
                className="mt-1"
                name="address"
                id="address"
                placeholder="-"
                onChange={(e) => {
                  setPayload((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }}
                value={payload?.address ?? ''}
                autoComplete="off"
                invalid={!payload?.address && isClick && row}
              />
            </FormGroup>
          </CardBody>
        </Card>
        <div style={{ marginBottom: 150 }} />
      </>
    )
  }

  function renderPayment() {
    return (
      <>
        {/* SECTION METODE PEMBAYARAN */}
        {!row && (
          <Card className="border-0 shadow-sm">
            <CardBody className="d-flex flex-column gap-2">
              <div>
                <P size="medium">Metode pembayaran diterima (opsional)</P>
                <P size="regular" color={'#667085'}>
                  Digunakan untuk menerima pembayaran saat melakukan penjualan
                </P>
              </div>

              {/* SWITCH PAYMENT METHOD */}
              {agentSelected?.relationUserId && (
                <FormGroup switch>
                  <Input
                    disabled={
                      agentSelected?.relationUserId &&
                      !payload?.bankAccountNumber
                    }
                    type="switch"
                    checked={isSwitchAgent}
                    onClick={(e) => handleSetDefaultPayment(e?.target?.checked)}
                    style={{ cursor: 'pointer' }}
                  />
                  <Label check>Jadikan agen menjadi pembayaran utama</Label>
                </FormGroup>
              )}
              {/* END SWITCH PAYMENT METHOD */}

              {/* CARD BANK */}
              {payload?.bankAccountNumber && (
                <Card className="border">
                  <CardBody className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span className="fw-semibold mb-2">
                        Transfer bank {!isSwitchAgent && '(Utama)'}
                      </span>
                      <div className="d-flex gap-2">
                        {/* EDIT */}
                        <span
                          className="text-primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleEditPaymentMethod('bank')}
                        >
                          Ubah
                        </span>

                        {/* REMOVE */}
                        <span
                          style={{ color: '#D92C20', cursor: 'pointer' }}
                          onClick={() => {
                            setCurrentMethodName('bank')
                            setIsShowModalDeletePaymentMethod(true)
                          }}
                        >
                          Hapus
                        </span>
                      </div>
                    </div>
                    {tempLogoBank && (
                      <img
                        width={50}
                        height={50}
                        src={tempLogoBank}
                        alt="Bank Logo"
                      />
                    )}
                    <span className="text-sm">
                      {payload?.bankAccountNumber}
                    </span>
                    <span className="text-sm">{payload?.bankAccountName}</span>
                  </CardBody>
                </Card>
              )}
              {/* END CARD BANK */}

              {/* CARD AGENT */}
              {agentSelected?.relationUserId && (
                <Card className="border mt-2">
                  <CardBody className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span className="fw-semibold">
                        Pembayaran lewat agen {isSwitchAgent && '(Utama)'}
                      </span>

                      <div className="d-flex gap-2">
                        {/* EDIT */}
                        <span
                          className="text-primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleEditPaymentMethod('agent')}
                        >
                          Ubah
                        </span>

                        {/* REMOVE */}
                        <span
                          style={{ color: '#D92C20', cursor: 'pointer' }}
                          onClick={() => {
                            setCurrentMethodName('agent')
                            setIsShowModalDeletePaymentMethod(true)
                          }}
                        >
                          Hapus
                        </span>
                      </div>
                    </div>

                    {agentSelected?.status === 'PENDING' && (
                      <div className="w-20">
                        <Badge size="sm" disabled className="badge-warning">
                          <span className="">Menunggu konfirmasi agen</span>
                        </Badge>
                      </div>
                    )}

                    <span className="fw-semibold text-sm">
                      Nomor handphone agen
                    </span>
                    <span className="text-sm" style={{ color: '#667085' }}>
                      {agentSelected?.relationPhoneNumber ?? '-'}
                    </span>
                    <span className="fw-semibold text-sm">Nama agen</span>
                    <span className="text-sm" style={{ color: '#667085' }}>
                      {agentSelected?.partnership?.name ??
                        agentSelected?.relationName ??
                        '-'}
                    </span>
                  </CardBody>
                </Card>
              )}
              {/* END CARD AGENT */}

              {/* SHOW BUTTON IF THE USER DIDN'T HAVE PAYMENT METHOD BY AGENT */}
              {!(
                agentSelected?.relationUserId && payload.bankAccountNumber
              ) && (
                <>
                  {!(
                    agentSelected?.relationUserId || payload.bankAccountNumber
                  ) && (
                    <P className="m-0 my-3" size="regular">
                      Belum ada metode pembayaran yang ditambahkan.
                    </P>
                  )}
                  {!payload?.bankAccountNumber ? (
                    <div className="mt-2">
                      <CustomButton
                        // onClick={() => setIsOpenModalDisbursement(true)}
                        onClick={handleAddPaymentMethod}
                        outline
                        color="white"
                        size="sm"
                        style={{
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          padding: 10,
                        }}
                        className="d-flex align-items-center gap-2"
                      >
                        <Plus size={15} color="#344054" />
                        <P size="regular" bold color={'#344054'}>
                          Tambah Metode Pembayaran
                        </P>
                      </CustomButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {/* END SHOW BUTTON IF THE USER DIDN'T HAVE PAYMENT METHOD */}
            </CardBody>
          </Card>
        )}
      </>
    )
  }

  function renderFooter() {
    return (
      <>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: isMobile ? 0 : isOpenNavbar ? 280 : 80,
            backgroundColor: 'white',
            padding: '1rem',
            // boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid #EAECF0',
            zIndex: 1000,
            transition: 'all 0.3s',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className="custom-container">
            <P size="regular">
              Dengan mendaftarkan anggota, saya menjamin kebenaran data yang
              dimasukkan dan bertanggung jawab atas ketidaksesuaian data.
            </P>
            <div className="mt-3 d-flex gap-3">
              <CustomButton
                onClick={() => {
                  if (row) {
                    dispatch(toggleModalChangeProfile())
                  } else {
                    window.history.back()
                  }
                }}
                outline
                className="btn-cancel"
                block
              >
                Batal
              </CustomButton>
              <CustomButton
                disabled={
                  payload?.fullName &&
                  payload?.identity &&
                  payload?.password?.length > 5
                    ? false
                    : true
                }
                onClick={() => {
                  const res = handleHasAnswered()
                  setIsClick(true)
                  if (res) {
                    const updatedPayload = {
                      ...payload,
                      disbursementViaAgent: isSwitchAgent,
                      agentUserId: agentSelected?.relationUserId,
                    }
                    setIsClick(false)
                    dispatch(toggleModal())
                    dispatch(setPayloadSlice(updatedPayload))
                  }
                }}
                color="primary"
                block
              >
                {!row ? 'Daftarkan dan Tambah Anggota' : 'Simpan'}
              </CustomButton>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FormRegis
