import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import { useLoaderData, useNavigate } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { URL_FORGET_PASSWORD_STEP_2, URL_LOGIN_PASSWORD } from 'utils/pathUrl'

import axios from 'axios'
import { P } from 'components'
import { apiEndpoint, recaptchaKey } from 'configs'
import useRecaptchaV3 from 'hooks/useRecaptchaV3'
import { useEffect, useRef, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import StepThreeForgetPassword from '../StepThree'

const StepOneForgetPassword = () => {
  const recaptcha = useRecaptchaV3(recaptchaKey)
  const nav = useNavigate()

  const existingIdentity = JSON.parse(localStorage.getItem('identity'))
  const [email, setEmail] = useState(existingIdentity?.[0] ?? '')
  const [errorMessage, setErroMessage] = useState('')
  const [errorMessageApi, setErroMessageApi] = useState('')

  const [isInvalid, setIsInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { token } = useLoaderData()

  const handleSubmitCaptcha = async (e) => {
    e.preventDefault()
    if (!email) {
      setIsInvalid(true)
      return setErroMessage('Email belum diisi.')
    }

    try {
      setIsLoading(true)
      let keyRecaptha = await recaptcha()
      const res = await axios.post(
        `${apiEndpoint}/api/v1/bussines/public/forgotpassword`,
        {
          'g-recaptcha-response': keyRecaptha,
          email,
        },
      )
      if (res.status === 200) {
        nav(`${URL_FORGET_PASSWORD_STEP_2}`, { replace: true })
      }
    } catch (error) {
      setErroMessageApi(error.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const inputRef = useRef(null)
  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 100)
  }, [])

  if (token) return <StepThreeForgetPassword token={token} />

  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        className="w-100 card-wrapper"
        style={{
          borderRadius: 10,
          maxWidth: '600px',
        }}
      >
        <img
          src={LogoPasmik}
          alt="Logo PasarMikro"
          style={{
            marginBottom: 24,
            maxWidth: '177px',
            height: 'auto',
          }}
        />
        <P className="mb-3">Atur ulang password</P>

        <Form onSubmit={handleSubmitCaptcha}>
          {errorMessageApi && (
            <Alert color="danger" isOpen={errorMessageApi}>
              <span className="text-xs">{errorMessageApi}</span>
            </Alert>
          )}

          <FormGroup>
            <Label>Email</Label>
            <Input
              innerRef={inputRef}
              name="email"
              type="email"
              autoComplete="off"
              value={email}
              onChange={(e) => {
                setErroMessage('')
                setIsInvalid(false)
                setErroMessageApi('')

                setEmail(e.target.value)
                if (e.target.value.length < 0) {
                  setIsInvalid(true)
                  setErroMessage('Email belum diisi.')
                }
              }}
              invalid={isInvalid}
            />
          </FormGroup>

          {errorMessage && (
            <FormGroup>
              <small className="text-danger">{errorMessage}</small>
            </FormGroup>
          )}
          {/* <Link to={URL_LOGIN_PASSWORD} style={{ textDecoration: 'none' }}>
                <P size="default">Kembali Ke Halaman Login</P>
              </Link> */}
          <div
            className="mt-2 d-flex"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              nav('/login', { replace: true })
            }}
          >
            <ArrowLeft
              size={20}
              className="me-1"
              style={{ color: '#006386' }}
            />
            <P size="regular" color={'#006386'}>
              Kembali Ke Halaman Login
            </P>
          </div>
          <div className="w-100 mt-3">
            <Button
              disabled={isLoading}
              className="w-100"
              color="primary"
              type="submit"
            >
              <P size="regular">Lanjut</P>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default StepOneForgetPassword

export const loader = (props) => {
  const { request } = props
  let token =
    request.url?.split('?')[1]?.split('&')[0]?.split('=')[1] ?? undefined
  return { token }
}
