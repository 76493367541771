import { TableComponent as DataTable } from 'components/TableComponent'
import { monthsOptions } from 'constants'
import moment from 'moment-timezone'
import Chart from 'react-apexcharts'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { useState, useEffect } from 'react'

const ScrollableContainer = styled.div`
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  -webkit-overflow-scrolling: touch;

  /* Ensure scrollbar is always visible */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: ${props => props.isMobile ? '6px' : '8px'};
    display: block !important;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: ${props => props.isMobile ? '1px' : '5px'};
    display: block !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: ${props => props.isMobile ? '1px' : '5px'};
    display: block !important;
    min-width: 60px;
    &:hover {
      background: #c4c4c4;
    }
  }

  /* Firefox */
  scrollbar-width: thin !important;
  scrollbar-color: #d4d4d4 #f1f1f1;
  
  /* IE/Edge */
  -ms-overflow-style: scrollbar !important;
  
  /* Force scrollbar visibility */
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: transparent;
    pointer-events: none;
  }

  /* Force minimum scroll area */
  &::after {
    content: '';
    display: block;
    min-width: ${props => props.isMobile ? '800px' : '1200px'};
    height: 1px;
    opacity: 0;
  }
`

const ChartContainer = styled.div`
  min-width: ${props => props.isMobile ? '800px' : '1200px'};
`

const AnnualTab = ({ dataCashFlow, dataIncome, dataExpense, res }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const optionsAnnual = {
    chart: {
      type: 'bar',
      height: isMobile ? 400 : 500,
      zoom: { enabled: false },
      toolbar: { show: false },
      stacked: false,
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        borderRadius: isMobile ? 1 : 5,
        horizontal: false,
        columnWidth: '50%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
        distributed: false,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        shape: 'circle',
      },
    },
    colors: ['#039855', '#D92D20'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter(value) {
          return newFormatThousandSeparator(value)
        },
      },
    },
    xaxis: {
      categories: monthsOptions.map((month) => month?.label?.slice(0, 3)),
      labels: {
        style: { fontSize: isMobile ? '10px' : '12px', colors: '#6E6E6E' },
        rotate: isMobile ? -90 : -45,
        offsetY: isMobile ? 0 : 5,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      text: 'Jumlah (Rupiah)',
      offsetY: isMobile ? 0 : 5,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    yaxis: {
      labels: {
        formatter(value) {
          return newFormatThousandSeparator(value)
        },
        style: { fontSize: isMobile ? '10px' : '12px', colors: '#6E6E6E' },
        offsetX: isMobile ? -10 : 0,
        rotate: isMobile ? -60 : -0,
        align: 'right',
      },
      tickAmount: isMobile ? 4 : 5,
      min: 0,
      max: Math.max(...dataIncome, ...dataExpense) * 1.2,
      forceNiceScale: true,
      floating: false,
      decimalsInFloat: 0,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      borderColor: '#f1f1f1',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: isMobile ? 5 : 10,
      },
    },
  }

  const seriesAnnual = [
    { name: 'Pemasukan', data: dataIncome },
    { name: 'Pengeluaran', data: dataExpense },
  ]

  const monthsMap = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]

  const transformedData = monthsMap.map((month, index) => {
    const monthlyIncome = dataCashFlow?.items
      ?.filter(
        (item) =>
          moment(item.createddate).month() === index &&
          item.incomeorexpense === 'income',
      )
      ?.reduce((sum, item) => sum + item.amount, 0)

    const monthlyExpense = dataCashFlow?.items
      ?.filter(
        (item) =>
          moment(item.createddate).month() === index &&
          item.incomeorexpense === 'expense',
      )
      ?.reduce((sum, item) => sum + item.amount, 0)

    return {
      month,
      income: monthlyIncome,
      expense: monthlyExpense,
      profit: monthlyIncome - monthlyExpense,
    }
  })

  const columnsAnnual = [
    {
      name: <span>Periode</span>,
      selector: (row) => row?.month,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pemasukan</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.income)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Pengeluaran</span>,
      selector: (row) => `Rp${newFormatThousandSeparator(row.expense)}`,
      wrap: true,
      grow: 2,
    },
    {
      name: <span>Untung/Rugi</span>,
      selector: (row) => (
        <span style={{ color: row.profit >= 0 ? '#027A48' : '#B42318' }}>
          {row.profit >= 0 ? '+ ' : '- '}
          Rp{newFormatThousandSeparator(Math.abs(row.profit))}
        </span>
      ),
      wrap: true,
      grow: 2,
    },
  ]

  return (
    <>
      <ScrollableContainer isMobile={isMobile}>
        <ChartContainer isMobile={isMobile}>
          <Chart
            options={optionsAnnual}
            series={seriesAnnual}
            type="bar"
            height={isMobile ? 400 : 500}
          />
        </ChartContainer>
      </ScrollableContainer>

      <DataTable
        columns={columnsAnnual}
        data={transformedData}
        progressPending={res?.isFetching}
        progressComponent={
          <div style={{ margin: '100px 0px' }}>
            <Spinner color="primary" />
          </div>
        }
      />
    </>
  )
}

export default AnnualTab
