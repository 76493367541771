import LogoPasmik from 'assets/icons/PasarMIKRO-Primary-Logogram-3.svg'
import BcaLogo from 'assets/images/BCA-01.png'
import BriLogo from 'assets/images/BRI-01.png'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { apiEndpoint } from 'configs'
import { useEffect, useState } from 'react'
import { AlertCircle, ChevronDown, ChevronRight } from 'react-feather'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Card,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap'

const OneLinkTopup = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const dataState = location.state
  const [topupMethod, setTopupMethod] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [tfMethod, setTfMethod] = useState('')
  const [disbBanks, setDisbBanks] = useState([])
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(0)
  const [isClick, setIsClick] = useState(false)
  const [banks, setBanks] = useState([])
  const [pending, setPending] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [token, setToken] = useState(null)
  const [dataUser, setDataUser] = useState(null)
  const publicVersion = useSelector((s) => s.publicVersion)

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
    setIsClick(false)
  }
  const fetchDisbursementBanks = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/page/tradeshorcut/banks?filter=disbursementtopup`,
        {
          headers: {
            Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
          },
        },
      )
      setDisbBanks(data?.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchContext = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/onepaymentpage/context`,
        {
          headers: {
            OnepaymentKey: `${token}`,
          },
        },
      )
      if (data?.methodData) {
        if (data?.method === 'BCAVA') {
          if (data?.methodData?.status?.toLowerCase() === 'canceled') {
            if (data?.purpose === 'TOPUP') {
              navigate('/public-payment-failed', {
                replace: true,
              })
            }
          } else if (data?.methodData?.status?.toLowerCase() === 'confirmed') {
            navigate('/public-payment-success', {
              replace: true,
            })
          } else {
            navigate('/public-payment-instruksi', {
              state: {
                ...data,
                ...dataState,
                ...data?.methodData,
                bankCodeReceiver: 'BCA',
                isVa: true,
                token: token,
              },
              replace: true,
            })
          }
        } else if (data?.method === 'BRIVA') {
          if (data?.methodData?.status?.toLowerCase() === 'canceled') {
            if (data?.purpose === 'TOPUP') {
              navigate('/public-payment-failed', {
                replace: true,
              })
            }
          } else if (data?.methodData?.status?.toLowerCase() === 'confirmed') {
            navigate('/public-payment-success', {
              replace: true,
            })
          } else {
            navigate('/public-payment-instruksi', {
              state: {
                ...data,
                ...dataState,
                ...data?.methodData,
                bankCodeReceiver: 'BRI',
                isVa: true,
                token: token,
              },
              replace: true,
            })
          }
        } else if (data?.method === 'BCAUNIQUECODE') {
          if (data?.methodData?.status?.toLowerCase() === 'canceled') {
            if (data?.purpose === 'TOPUP') {
              navigate('/public-payment-failed', {
                replace: true,
              })
            }
          } else if (data?.methodData?.status?.toLowerCase() === 'confirmed') {
            navigate('/public-payment-success', {
              replace: true,
            })
          } else {
            navigate('/public-payment-instruksi', {
              state: {
                ...data,
                ...dataState,
                ...data?.methodData,
                token: token,
              },
              replace: true,
            })
          }
        } else if (data?.method === 'DURIANPAYDISBURSE') {
          if (
            data?.methodData?.status?.toLowerCase() === 'failed' ||
            data?.methodData?.status?.toLowerCase() === 'canceled'
          ) {
            if (data?.purpose === 'TOPUP') {
              navigate('/public-payment-failed', {
                replace: true,
              })
            }
          } else if (data?.methodData?.status?.toLowerCase() === 'done') {
            navigate('/public-payment-success', {
              replace: true,
            })
          } else {
            navigate('/public-payment-instruksi', {
              state: {
                ...data,
                ...dataState,
                ...data?.methodData,
                token: token,
              },
              replace: true,
            })
          }
        }
      }
      setDataUser(data)
    } catch (error) {
      navigate('/public-payment-cancel')
    } finally {
      setLoading(false)
    }
  }

  const postBriVa = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topups`,
        {
          customerCode: dataUser?.customerCode,
          amount: dataUser?.amount,
          description: '',
        },
        {
          headers: {
            OnepaymentKey: `${token}`,
          },
        },
      )

      if (data.status?.toLowerCase() === 'pending') {
        navigate('/public-payment-instruksi', {
          state: {
            ...data,
            ...dataState,
            bankCodeReceiver: 'BRI',
            isVa: true,
            token: token,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const postBcaUniqueCode = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topupuniquecode`,
        {
          customerCode: dataUser?.customerCode,
          amount: dataUser?.amount,
          description: '',
        },
        {
          headers: {
            OnepaymentKey: `${token}`,
          },
        },
      )

      if (data.status?.toLowerCase() === 'pending') {
        navigate('/public-payment-instruksi', {
          state: {
            ...data,
            ...dataState,
            token: token,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const postBcaVa = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topupbca`,
        {
          customerCode: dataUser?.customerCode,
          amount: dataUser?.amount,
          description: '',
        },
        {
          headers: {
            OnepaymentKey: `${token}`,
          },
        },
      )

      if (data.status?.toLowerCase() === 'pending') {
        navigate('/public-payment-instruksi', {
          state: {
            ...data,
            ...dataState,
            bankCodeReceiver: 'BCA',
            isVa: true,
            token: token,
          },
          replace: true,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  /* global Durianpay */
  const postDp = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${apiEndpoint}/api/v1/topuppg`,
        {
          amount: dataUser?.amount,
          gateway: 'DURIANPAY-PAYMENTLINK',
          description: '',
        },
        {
          headers: {
            OnepaymentKey: `${token}`,
          },
        },
      )

      if (res) {
        const data = JSON.parse(res?.data?.dumpRequest)?.data
        const script = document.createElement('script')
        script.src = 'https://js.durianpay.id/0.1.48/durianpay.min.js'
        script.async = true
        document.body.appendChild(script)

        script.onload = () => {
          const dpay = Durianpay.init({
            name: 'dpaysdk',
            environment: 'production',
            locale: 'id',
            access_key: data.access_token,
            method: 'getCheckoutButton',

            container_elem: 'pay-btn-container',
            order_info: {
              id: data.id,
              customer_info: {
                id: data.customer_id,
              },
            },
            btn_class_name: 'btn filled',
          })

          dpay.getCheckoutButton()
          document
            .getElementById('dpay-checkout-btn')
            .setAttribute('hidden', 'true')
          document.getElementById('dpay-checkout-btn').click()
        }
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      setErrMsg(error?.response?.data?.message ?? error.message)
    }
  }

  const postDpDisbursement = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/topuppg/disbursetopup`,
        { bankCode: tfMethod?.code, amount: dataUser?.amount },
        {
          headers: {
            OnepaymentKey: `${token}`,
          },
        },
      )
      if (data.status?.toLowerCase() === 'processing') {
        navigate('/public-payment-instruksi', {
          state: {
            ...data,
            ...data?.methodData,
            ...dataState,
            token: token,
          },
          replace: true,
        })
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleLastPick = (e) => {
    localStorage.setItem(
      `lastPickedBank-${dataUser?.userId}`,
      JSON.stringify(e),
    )
  }

  const handleSubmit = async (e) => {
    try {
      if (topupMethod === 'dpVa') {
        await postDp()
      } else if (topupMethod === 'transferDp') {
        if (tfMethod?.code === 'BCA') {
          await postBcaUniqueCode()
        } else {
          await postDpDisbursement()
        }
      } else if (topupMethod === 'briVa') {
        await postBriVa()
      } else if (topupMethod === 'bcaVa') {
        await postBcaVa()
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.data?.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    setToken(token)
  }, [])

  useEffect(() => {
    setBanks(
      isClick
        ? disbBanks
        : !isClick && disbBanks.length > 3
          ? disbBanks.slice(0, 4)
          : disbBanks,
    )
  }, [disbBanks, isClick])

  useEffect(() => {
    if (token) {
      fetchContext()
      fetchDisbursementBanks()
    }
  }, [token])

  useEffect(() => {
    const lastPickedBank =
      JSON.parse(localStorage.getItem(`lastPickedBank-${dataUser?.userId}`)) ||
      []
    if (typeof lastPickedBank !== 'string') {
      setTopupMethod(lastPickedBank?.method)
      setTfMethod(lastPickedBank)
    } else {
      setTopupMethod(lastPickedBank)
    }
  }, [dataUser])

  return (
    <div className="flex-centered" style={{ minHeight: '100vh' }}>
      <Container
        style={{ backgroundColor: '#F7F7F7' }}
        className="custom-container rounded"
      >
        <Modal centered isOpen={isOpenModal} toggle={toggleModal}>
          <ModalHeader className="border-0" toggle={toggleModal}>
            <small className="fw-600">Pilih metode pembayaran</small>
          </ModalHeader>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <div className="d-flex flex-column gap-3">
              <div className="mb-2">
                <p className="fw-500">Transfer Bank</p>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="d-flex flex-column gap-2">
                    {banks?.map((e, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            backgroundColor:
                              topupMethod === 'transferDp' &&
                              tfMethod?.code === e?.code
                                ? '#ECFAFF'
                                : '',
                            cursor: 'pointer',
                          }}
                          className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded ${
                            (topupMethod && tfMethod?.code === e?.code) ===
                            'transferDp'
                              ? 'border-primary'
                              : ''
                          }`}
                          onClick={() => {
                            setTopupMethod('transferDp')
                            setTfMethod(e)
                            handleLastPick({ method: 'transferDp', ...e })
                            toggleModal()
                            setErrMsg('')
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img
                              style={{ height: 'auto', width: '30px' }}
                              src={e?.logo}
                              alt={e?.name}
                            />
                            <div>Transfer {e?.name}</div>
                          </div>
                          <Input
                            type="radio"
                            checked={
                              topupMethod === 'transferDp' &&
                              tfMethod?.code === e?.code
                            }
                            onChange={() => {
                              setTopupMethod('transferDp')
                              setTfMethod(e)
                              handleLastPick({ method: 'transferDp', ...e })
                              toggleModal()
                              setErrMsg('')
                            }}
                          />
                        </div>
                      )
                    })}
                    {!isClick && banks.length > 0 ? (
                      <div
                        style={{ cursor: 'pointer' }}
                        className="text-center"
                        onClick={() => setIsClick(true)}
                      >
                        <ChevronDown className="text-secondary" />
                        Lihat {disbBanks.length - 4} Bank Lainnya
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-2">
                <p className="fw-500">Virtual Account</p>
                <div className="d-flex flex-column gap-3">
                  <div
                    style={{
                      backgroundColor: topupMethod === 'briVa' ? '#ECFAFF' : '',
                      cursor: 'pointer',
                    }}
                    className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded ${
                      topupMethod === 'briVa' ? 'border-primary' : ''
                    }`}
                    onClick={() => {
                      setTopupMethod('briVa')
                      handleLastPick('briVa')
                      toggleModal()
                      setErrMsg('')
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <img
                        style={{ height: '20px', width: '40px' }}
                        src={BriLogo}
                        alt="bri"
                      />
                      <div>BRI Virtual Account</div>
                    </div>
                    <Input
                      type="radio"
                      checked={topupMethod === 'briVa'}
                      onChange={() => {
                        setTopupMethod('briVa')
                        handleLastPick('briVa')
                        toggleModal()
                        setErrMsg('')
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: topupMethod === 'bcaVa' ? '#ECFAFF' : '',
                      cursor: 'pointer',
                    }}
                    className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded ${
                      topupMethod === 'bcaVa' ? 'border-primary' : ''
                    }`}
                    onClick={() => {
                      setTopupMethod('bcaVa')
                      handleLastPick('bcaVa')
                      toggleModal()
                      setErrMsg('')
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <img
                        style={{ height: '20px', width: '40px' }}
                        src={BcaLogo}
                        alt="bri"
                      />
                      <div>BCA Virtual Account</div>
                    </div>
                    <Input
                      type="radio"
                      checked={topupMethod === 'bcaVa'}
                      onChange={() => {
                        setTopupMethod('bcaVa')
                        handleLastPick('bcaVa')
                        toggleModal()
                        setErrMsg('')
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className="fw-500">Pembayaran Lainnya</p>
                <div
                  style={{
                    backgroundColor: topupMethod === 'dpVa' ? '#ECFAFF' : '',
                    cursor: 'pointer',
                  }}
                  className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded ${
                    topupMethod === 'dpVa' ? 'border-primary' : ''
                  }`}
                  onClick={() => {
                    setTopupMethod('dpVa')
                    handleLastPick('dpVa')
                    toggleModal()
                    setErrMsg('')
                  }}
                >
                  <span>Pembayaran Lainnya</span>
                  <Input
                    checked={topupMethod === 'dpVa'}
                    onChange={() => {
                      setTopupMethod('dpVa')
                      handleLastPick('dpVa')
                      toggleModal()
                      setErrMsg('')
                    }}
                    type="radio"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div className="d-flex flex-column gap-3 py-3">
          <div className="flex-centered py-3">
            {' '}
            <img
              style={{ width: '100%', maxWidth: '80px', height: 'auto' }}
              src={LogoPasmik}
              alt="PasarMIKRO logo"
            />{' '}
          </div>
          <div>
            <Card body className="d-flex flex-row justify-content-between">
              <span>Subtotal</span>
              <span className="fw-500">
                Rp
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  intl.formatNumber(dataUser?.amount ?? 0, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                )}
              </span>
            </Card>
          </div>
          <div>
            {' '}
            <Card body>
              <span className="fw-700">Metode pembayaran</span>
              <div
                onClick={toggleModal}
                style={{ cursor: 'pointer' }}
                className="d-flex justify-content-between border border-2 p-2 rounded mt-2"
              >
                {topupMethod === 'briVa' ? (
                  <div className="d-flex align-items-center gap-2">
                    <img
                      style={{ height: '20px', width: '40px' }}
                      src={BriLogo}
                      alt="bri"
                    />
                    <div>BRI Virtual Account</div>
                  </div>
                ) : topupMethod === 'bcaVa' ? (
                  <div className="d-flex align-items-center gap-2">
                    <img
                      style={{ height: '20px', width: '40px' }}
                      src={BcaLogo}
                      alt="bri"
                    />
                    <div>BCA Virtual Account</div>
                  </div>
                ) : topupMethod === 'transferDp' ? (
                  <div className="d-flex align-items-center gap-2">
                    <img
                      style={{ height: 'auto', width: '30px' }}
                      src={tfMethod?.logo}
                      alt={tfMethod?.name}
                    />
                    <div>Transfer {tfMethod?.name}</div>
                  </div>
                ) : topupMethod === 'dpVa' ? (
                  <span>Pembayaran Lainnya</span>
                ) : (
                  <span>Pilih metode pembayaran</span>
                )}
                <ChevronRight className="text-secondary" />
              </div>
            </Card>
          </div>
          <div>
            {' '}
            <Card
              body
              className="d-flex flex-row gap-1 justify-content-between align-items-center"
            >
              <span>
                Hubungi kami untuk mendiskusikan metode pembayaran alternatif
              </span>
              <CustomButton
                onClick={() =>
                  window.open(
                    `https://wa.me/${publicVersion?.data?.csNumber}`,
                    '_blank',
                  )
                }
                outline
                size="sm"
                color="primary"
                className="fw-600"
              >
                Hubungi Kami
              </CustomButton>
            </Card>
          </div>
          {errMsg && (
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          )}
          <div>
            <CustomButton
              onClick={() => handleSubmit()}
              disabled={loading || !topupMethod}
              color="primary"
              block
            >
              Bayar
            </CustomButton>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default OneLinkTopup
