// Path URL ERROR
export const URL_406 = '/unauthorized'

// Path URL PUBLIC
export const URL_FORGET_PASSWORD_STEP_1 = '/resetpassword'
export const URL_FORGET_PASSWORD_STEP_2 = '/resetpassword/email'
export const URL_FORGET_PASSWORD_STEP_4 = '/resetpassword/success'

export const URL_LOGIN = '/login'

// Path URL Private
export const HOME = '/'
export const URL_WAREHOUSE = '/gudang'
export const URL_FORM_WAREHOUSE = '/tambah-gudang'
export const URL_DETAIL_WAREHOUSE = '/gudang/:id'

export const URL_DETAIL_TRANSACTION = '/detail-transaction'
export const URL_DETAIL_TRANSACTION_ID = '/detail-transaction/:id'

// Path URL APPROVAL
export const URL_APPROVAL_BUY = 'approval-saya'
export const URL_APPROVAL_SELL = 'sell'
export const URL_APPROVAL_WITHDRAWAL = 'withdrawal'

// Path Url Farmers
export const URL_FARMERS = '/farmers'
export const URL_FARMERS_REGIST = '/farmers/registrasi'
export const URL_FARMERS_DETAIL = '/farmers/:id'
export const URL_SPATIAL = '/spatial'
export const URL_MANAGE_STOCK = '/manage-stock'
export const URL_CHECK_FARMERS = '/farmers/check'
export const URL_FARMERS_PENDING = '/farmers/pending'
export const URL_FARMERS_GROUPS = '/farmers/groups'
export const URL_FARMERS_SUBGROUP = '/farmers/groups/:id'
export const URL_LEARNIING_REPORT = '/learning-report'
export const URL_AGENT_APPROVAL = '/agent-approval'

// pathname repo-komoditas
export const REPO_KOMODITAS = '/repo-komoditas'
export const REPO_KOMODITAS_LIST = '/repo-komoditas/list'

//arus kas
export const ARUS_KAS = '/catatan-arus-kas'
export const HISTORY_ARUS_KAS = '/history-arus-kas'
export const ARUS_KAS_FORM = '/catatan-arus-kas-form'

//Path Url Catatan
export const URL_ARUS_KAS = '/catatan-arus-kas'
export const URL_UTANG = '/catatan-utang'
export const URL_UTANG_APPROVAL = '/catatan-utang-approval'
export const URL_FORM_UTANG_PIUTANG = '/catatan-utang-piutang/form'
export const URL_PIUTANG = '/catatan-piutang'

//url connect account
export const URL_CONNECT_ACCOUNT = '/sambungkan-akun'

//url tnc private
export const URL_TNC_PRIVATE = '/snk'

//url peran dan hak akses
export const URL_ACCESS_ROLE = '/peran-dan-hak-akses'

//URL Master Vessel
export const URL_VESSELS = '/vessels'
export const URL_VESSELS_ADD = '/vessels/add'
export const URL_VESSELS_DETAIL = '/vessels/:id'

// URL USER CATCHES
export const URL_CATCHES = '/catches'
export const URL_CATCHES_DETAIL = '/catches/:id'

// URL GEARS
export const URL_GEARS = '/gears'
export const URL_GEARS_DETAIL = '/gears/:id'

// URL PORTS
export const URL_PORTS = '/ports'
export const URL_PORTS_DETAIL = '/ports/:id'

// URL COMPANIES
export const URL_COMPANIES = '/companies'
export const URL_COMPANIES_ADD = '/companies/add'

// URL FISHERIES
export const URL_FISHERIES = '/fisheries'
export const URL_FISHERIES_ADD = '/fisheries/add'
export const URL_FISHERIES_DETAIL = '/fisheries/:id'

// URL WHISTLE BLOWING
export const URL_WHISTLE_BLOWINGS = '/whistle-blowings'

// URL Kursus
export const URL_KURSUS = '/kursus'
export const URL_KURSUS_DETAIL = '/kursus-detail'
export const URL_FINISH_KURSUS = '/kursus-finished'

// URL AP2HI
export const URL_AP2HI_LOG = '/ap2hi-log'
export const URL_AP2HI_INSIGHT = '/ap2hi-insight'

//inventory
export const URL_INVENTORY = '/inventory'
export const URL_DETAIL_INVENTORY = '/inventory/:id'
export const URL_TRANSFER_INVENTORY = '/transfer-inventory'
export const URL_FORM_TRANSFER_INVENTORY = '/form-transfer-inventory'
export const URL_TRANSFER_INVENTORY_DETAIL = '/transfer-inventory/:id'

// INVENTORY ANGGOTA
export const URL_INVENTORY_MEMBERS = '/inventory-members'
export const URL_DETAIL_INVENTORY_MEMBER = '/inventory-members/:id'

//Balance
export const URL_BALANCE = '/uang-belanja'

// DAFTAR KONTAK
export const URL_ADDRESS_BOOK = '/address-book'

// LOGIN PASSWORD
export const URL_LOGIN_PASSWORD = '/login-password'

//Pin
export const URL_CONFIRM_PIN_TALANGIN = '/konfirmasi-pin-talangin'
export const URL_CONFIRM_PIN_TRANSAKSI = '/konfirmasi-pin-transaksi'
