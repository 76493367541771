import CardPin from 'components/CardPin'
import useGetTrxTalanginQuery from 'hooks/useGetTrxTalanginQuery'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { postRollover } from './talanginInvoiceAction'
import {
  usePostCheckPassMutation,
  usePostPayTalanginMutation,
} from './talanginInvoiceApiSlice'
import {
  setJustRolledOver,
  toggleSidebarTalangin,
} from './talanginInvoiceSlice'

const PinConfirm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataState = location.state
  const talanginTable = useSelector((s) => s.talanginTable)
  const [loading, setLoading] = useState(false)
  const [postCheckPass, result] = usePostCheckPassMutation()
  const { refetch } = useGetTrxTalanginQuery({
    ...talanginTable,
  })
  const [postPayTalangin, resultPay] = usePostPayTalanginMutation()
  const { detailRollover, selectedTenor } = useSelector(
    (s) => s.talanginInvoice,
  )
  const { ownerData } = useSelector((s) => s.dashboard)

  const handleSubmit = async (pin) => {
    try {
      setLoading(true)
      const res = await postCheckPass({ pin: pin }).unwrap()
      if (res.success === 1 && res.tempToken) {
        if (dataState?.isRollover) {
          const res1 = await dispatch(
            postRollover({
              tempToken: res.tempToken,
              loanDays: selectedTenor?.loanDays,
              creditRequested: selectedTenor?.creditRequested2,
              userpinjamanId: detailRollover?.id,
            }),
          )
          if (res1.error) {
            throw res
          } else {
            navigate('/tagihan-talangin')
            refetch()
            dispatch(setJustRolledOver(detailRollover?.id))
            enqueueSnackbar('Perpanjangan tempo pembayaran berhasil.', {
              variant: 'success',
            })
          }
        } else {
          const res2 = await postPayTalangin({
            tempToken: res?.tempToken,
            userpinjamanId: dataState?.row?.id,
            partialAmount: dataState?.amountToPay,
            phonenumber: ownerData?.phoneNumber,
          }).unwrap()
          if (res2) {
            navigate('/tagihan-talangin')
            toggleSidebarTalangin()
            refetch()
            enqueueSnackbar('Pembayaran tagihan talangin selesai.', {
              variant: 'success',
            })
          }
        }
      }
    } catch (error) {
      enqueueSnackbar(`${error?.payload ?? error?.data?.message}`, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <CardPin
      title={`Konfirmasi PIN`}
      desc={` Masukkan PIN untuk melanjutkan`}
      length={6}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  )
}

export default PinConfirm
