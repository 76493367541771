import { apiEndpoint } from 'configs'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getPublicVersion = createAsyncThunk(
  'publicVersion',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiEndpoint}/api/v1/public/version`)

      const unitArray = data.unitList.split(',').map((unit) => ({
        value: unit,
        label: unit,
      }))
      return { ...data, unitArray }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
