import { persistor } from 'app/store'
import { logout } from 'features/public-views/login/loginSlice'
import { LogOut, Pocket, Settings, User } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from 'reactstrap'

const UserDropdown = (args) => {
  const { isV3 } = useSelector((s) => s.dashboard)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onClickLogout = async () => {
    await persistor.purge()
    localStorage.removeItem('transferInventoryFilters')
    localStorage.removeItem('defaultWarehouseInventory')
    localStorage.removeItem('lastFetchTime')
    dispatch(logout())
    window.location.replace('/')
  }

  return (
    <Nav className="nav navbar-nav align-items-center ml-auto">
      <UncontrolledDropdown tag="li" className="me-2" direction="down">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
        >
          {args?.profile?.isSuccess && (
            <div
              className="rounded-circle flex-centered"
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: '#ECFAFF',
              }}
            >
              <span>
                {args?.profile?.data?.makerData?.profilepicUrl !== null ? (
                  <img
                    src={args?.profile?.data?.makerData?.profilepicUrl}
                    alt="user avatar"
                    className="rounded-circle"
                    width={39}
                    height={39}
                  />
                ) : (
                  <span>
                    <User size={25} className="text-primary" />
                  </span>
                )}
              </span>
            </div>
          )}
        </DropdownToggle>

        <DropdownMenu className="shadow border-0">
          <DropdownItem className="p-3">
            <div className="d-flex gap-2">
              {args?.profile?.data?.makerData?.profilepicUrl !== null ? (
                <img
                  src={args?.profile?.data?.makerData?.profilepicUrl}
                  alt="user avatar"
                  className="rounded-circle"
                  width={39}
                  height={39}
                />
              ) : (
                <span>
                  <User size={25} className="text-primary" />
                </span>
              )}
              <div className="d-flex flex-column">
                <span className="fw-600">
                  {args?.profile?.data?.makerData?.name}
                </span>
                <small className="text-secondary">
                  {args?.profile?.data?.makerData?.email}
                </small>
              </div>
            </div>
          </DropdownItem>
          <hr className="p-0 m-0 text-secondary"></hr>
          <DropdownItem tag={Link} to="/pengaturan-profile" className="py-2">
            <Settings size={14} className="me-2" />
            <span className="align-middle">Pengaturan</span>
          </DropdownItem>
          <DropdownItem tag={Link} to="/snk" className="pb-2">
            <Pocket size={14} className="me-2" />
            <span className="align-middle">Syarat & Ketentuan</span>
          </DropdownItem>
          <hr className="p-0 m-0 text-secondary"></hr>
          <DropdownItem
            className="pt-2 pointer"
            tag="a"
            onClick={() => onClickLogout()}
          >
            <LogOut size={14} className="me-2 text-danger" />
            <span className="align-middle text-danger">Keluar</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
}

export default UserDropdown
