import { setIsV3 } from 'features/private-views/dashboard/dashboardSlice'
import { useEffect, useState } from 'react'
import { ArrowLeft, Delete, Eye, EyeOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Container, FormGroup, Input } from 'reactstrap'
import { version } from 'utility/Utils'
import { useLoginUserNewMutation } from './loginNewApiSlice'
import { login, setToken } from '../login/loginSlice'
import { P } from 'components'
import Logo from 'assets/images/pmg-logo-beta.png'
import { useRegisterUserMutation } from '../register-universal/registerSlice'

const Pin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { token } = useSelector((s) => s.auth)
  const dataState = location.state
  const [pin, setPin] = useState(['', '', '', '', '', ''])
  const [confirmPin, setConfirmPin] = useState(['', '', '', '', '', ''])
  const [active, setActive] = useState('form-control-0')
  const [isAction, setIsAction] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const toggleShowPw = () => setIsShow(!isShow)
  const [loginUser, resultLogin] = useLoginUserNewMutation()
  const [registerUser] = useRegisterUserMutation()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [stepRegister, setStepRegister] = useState(1)

  useEffect(() => {
    if (dataState?.status === 'UNREGISTERED') {
      if (stepRegister === 1) {
        setTitle('Buat PIN Anda')
        setDescription('Buat PIN untuk meningkatkan keamanan akun Anda.')
      } else {
        setTitle('Konfirmasi PIN Anda')
        setDescription('Masukkan PIN sekali lagi')
      }
    } else {
      setTitle('Masukkan PIN Anda')
      setDescription('')
    }
  }, [dataState])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleRegister = async () => {
    try {
      const res = await registerUser({
        identity: dataState?.identity,
        deviceHash: dataState?.deviceHash,
        password: pin.join(''),
        password: dataState?.password,
        fcmToken: dataState?.deviceHash,
        version: version,
        browserHash: dataState?.browserHash,
        browserFingerPrint: dataState?.browserFingerPrint,
        browserCustomFingerPrint: dataState?.browserCustomFingerPrint,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async () => {
    if (dataState?.status === 'UNREGISTERED') {
      handleRegister()
      return
    }
    try {
      setErrMsg('')
      setIsAction(true)
      const res = await loginUser({
        identity: dataState?.identity,
        deviceHash: dataState?.deviceHash,
        password: pin.join(''),
        fcmToken: dataState?.deviceHash,
        version: version,
        browserHash: dataState?.browserHash,
        browserFingerPrint: dataState?.browserFingerPrint,
        browserCustomFingerPrint: dataState?.browserCustomFingerPrint,
      })
      if (!res?.error) {
        dispatch(
          login({
            ...res?.data,
          }),
        )
        // dispatch(setIsV3(true))
        navigate('/', { replace: true })
      } else {
        throw res
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.error?.data?.meta?.message)
    } finally {
      setIsAction(false)
    }
  }

  const nextInput = (id) => {
    const input = document.getElementById(id)
    if (input) input.focus()
  }

  const handleKeypadClick = (number) => {
    setErrMsg('')
    const nextIndex = pin.findIndex((p) => p === '')

    if (nextIndex !== -1) {
      const newPin = [...pin]
      newPin[nextIndex] = number
      setPin(newPin)

      if (nextIndex < 5) {
        setActive(`form-control-${nextIndex + 1}`)
        nextInput(`form-control-${nextIndex + 1}`)
      }
    }
  }

  const handleDelete = () => {
    setErrMsg('')
    const lastIndex = pin.findIndex((p) => p === '')
    const indexToDelete = lastIndex === -1 ? 5 : lastIndex - 1

    if (indexToDelete >= 0) {
      const newPin = [...pin]
      newPin[indexToDelete] = ''
      setPin(newPin)
      setActive(`form-control-${indexToDelete}`)
      nextInput(`form-control-${indexToDelete}`)
    }
  }

  useEffect(() => {
    if (
      pin.every((digit) => digit !== '') &&
      confirmPin.every((digit) => digit === '')
    ) {
      setConfirmPin(pin)
      setPin(['', '', '', '', '', ''])
      setTitle('Konfirmasi PIN Anda')
      setDescription('Masukkan PIN sekali lagi')
      setStepRegister(2)
    } else if (
      pin.every((digit) => digit !== '') &&
      confirmPin.every((digit) => digit !== '')
    ) {
      if (pin.join('') === confirmPin.join('')) {
        setErrMsg('')
        handleSubmit()
      } else {
        setErrMsg('Konfirmasi PIN tidak cocok')
        setPin(['', '', '', '', '', ''])
      }
    }
  }, [pin, confirmPin])

  useEffect(() => {
    if (token) {
      // dispatch(setIsV3(true))
      navigate('/')
    }
  }, [token])

  return (
    <Container
      style={{ minHeight: '100vh', maxWidth: '600px' }}
      className="d-flex flex-column justify-content-between py-3 w-100"
    >
      {/* Header Section */}
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate('/login')
        }}
        className="d-flex justify-content-start d-block d-md-none"
      >
        <ArrowLeft size={20} />
      </div>

      {/* PIN */}
      <div
        className="bg-white rounded mt-0 mt-md-4 shadow-web"
        style={{ borderRadius: 99 }}
      >
        {/* Header Section */}
        <div>
          <img
            src={Logo}
            alt="Logo PasarMikro"
            className="img-web-only"
            style={{
              marginBottom: 24,
              maxWidth: '177px',
              height: 'auto',
            }}
          />

          <P size="large">{title}</P>
          <P size="regular" color={'#475467'}>
            {description}
          </P>
        </div>

        <div className="text-danger text-center mt-3">
          <P size="regular">{errMsg}</P>
        </div>
        <FormGroup className="d-flex gap-3 mt-4">
          {[...Array(6)].map((_, i) => (
            <div key={i}>
              <div
                className="position-absolute top-0 start-0 w-0 h-0"
                style={{ zIndex: 2, backgroundColor: 'transparent' }}
                onClick={() => nextInput(active)}
              ></div>
              <Input
                // readOnly
                style={{
                  zIndex: 1,
                  fontSize: 30,
                  textAlign: 'center',
                }}
                autoFocus={i === 0}
                className={`w-100 text-center ${
                  isMobile
                    ? 'fs-2 border border-dark border-top-0 border-start-0 border-end-0 rounded-0'
                    : ''
                }`}
                id={`form-control-${i}`}
                key={i}
                maxLength={1}
                inputMode="numeric"
                pattern="[0-9]*"
                onFocus={(e) => {
                  e.target.style.boxShadow = 'none'
                }}
                value={
                  isShow && pin[i] !== ''
                    ? pin[i]
                    : !isShow && pin[i] !== ''
                      ? '●'
                      : ''
                }
                autoComplete="off"
                onKeyDown={(e) => {
                  if (!e.code.includes('Digit') && e.code !== 'Backspace') {
                    e.preventDefault()
                  } else if (e.code === 'Backspace') {
                    if (i > 0 || pin[i] !== '') {
                      setPin((prevPin) => {
                        const newPin = [...prevPin]
                        newPin[i] = ''
                        return newPin
                      })
                      setActive(`form-control-${i - 1}`)
                      nextInput(`form-control-${i - 1}`)
                    }
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value

                  if (value.match(/^[0-9]$/)) {
                    setPin((prev) => {
                      const newPin = [...prev]
                      newPin[i] = value
                      return newPin
                    })

                    if (e.target.id !== 'form-control-5') {
                      setActive(`form-control-${i + 1}`)
                      nextInput(`form-control-${i + 1}`)
                    }
                  }
                }}
              />
            </div>
          ))}
        </FormGroup>

        {/* Show/Hide PIN */}
        <div
          onClick={toggleShowPw}
          className="d-flex gap-2 flex-centered text-primary fw-bold"
          style={{ cursor: 'pointer' }}
        >
          {isShow ? <Eye size={20} /> : <EyeOff size={20} />}{' '}
          <span className="text-primary">
            {isShow ? 'Sembunyikan PIN' : 'Lihat PIN'}
          </span>
        </div>
      </div>

      {/* Keypad */}
      <div className="mobile-only">
        <div className="d-flex flex-column mt-4">
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 border-start-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(1)}
            >
              1
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(2)}
            >
              2
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 border-top-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(3)}
            >
              3
            </Button>
          </ButtonGroup>
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 border-start-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(4)}
            >
              4
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(5)}
            >
              5
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(6)}
            >
              6
            </Button>
          </ButtonGroup>
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-top-0 border-start-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(7)}
            >
              7
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(8)}
            >
              8
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 rounded-0 p-3n button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(9)}
            >
              9
            </Button>
          </ButtonGroup>
          <ButtonGroup className="d-flex justify-content-center">
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-start-0 rounded-0 p-3 button-no-focus"
              size="lg"
              disabled
            >
              {' '}
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={() => handleKeypadClick(0)}
            >
              0
            </Button>
            <Button
              block
              outline
              color="primary"
              className="border border-bottom-0 border border-end-0 rounded-0 p-3 button-no-focus"
              size="lg"
              onClick={handleDelete}
            >
              <Delete size={20} />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Container>
  )
}

export default Pin
