import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  details: null,
  selectedContact: null,
  searchAddressBook: '',
  isModalOpenAddAddressBook: false,
  refetchAddressBook: null,
  isOpenSelectionAddressBook: false,
}

export const addressBookSlice = createAppSlice({
  name: 'addressBook',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setSelectedContact: create.reducer((state, { payload }) => {
      state.selectedContact = payload
    }),
    setSearchAddressBook: create.reducer((state, { payload }) => {
      state.searchAddressBook = payload
    }),
    setIsModalOpenAddAddressBook: create.reducer((state, { payload }) => {
      state.isModalOpenAddAddressBook = payload
    }),
    setRefetchAddressBook: (state, action) => {
      state.refetchAddressBook = action.payload
    },
    setIsOpenSelectionAddressBook: create.reducer((state, { payload }) => {
      state.isOpenSelectionAddressBook = payload
    }),
  }),
})

export const {
  setFetchOption,
  setSelectedContact,
  setSearchAddressBook,
  setIsModalOpenAddAddressBook,
  setRefetchAddressBook,
  setIsOpenSelectionAddressBook,
} = addressBookSlice.actions

export default addressBookSlice.reducer
